import React from 'react'
import { useIntl } from 'react-intl'

import { defaultNumberFormatter } from '@utils/analysis.utils'
import { Typography } from '@mui/material'
import { getChipColor } from '@utils/optimize.utils'

import OptimizeDrillDownGridItemComponent from '@components/(features)/optimize/optimize-drill-down-grid-item'
import OptimizeDrillDownChartComponent from '@components/(features)/optimize/optimize-drill-down-chart'
import ChipComponent from '@base/chips/Chip'

import useStyles from './OptimizeDrillDown.styles'

export interface OptimizeDrillDownComponentProps {
  drillDownData: Optimize.OptimizeDrillDownItem,
  exportId?: string,
}

const OptimizeDrillDownComponent: React.FC<OptimizeDrillDownComponentProps> = (props) => {
  const {
    drillDownData,
    exportId,
  } = props

  const { classes } = useStyles()
  const intl = useIntl()
  const {
    meta,
    data,
  } = drillDownData

  const {
    currentStock,
    currentStockInEur,
    currentReachInDays,
    restockRecommendation,
    gradientPercentage,
    stockStatus,
  } = meta

  const chipColor = getChipColor(stockStatus)
  const currentStockBlock = (
    <div className={classes.currentStockBlock}>
      <ChipComponent
        color={chipColor}
        label={stockStatus}
        textTransform='lowercase'
      />
      <Typography
        className={classes.currentStockValue}
        style={{ color: chipColor }}
      >
        {defaultNumberFormatter(currentStock)}
      </Typography>
    </div>
  )
  return (
    <div
      className={classes.root}
      data-testid={OptimizeDrillDownComponent.name}
      id={exportId}
    >
      <OptimizeDrillDownChartComponent
        dataset={data}
        gradientPercentage={gradientPercentage}
      />
      <div className={classes.gridRoot}>
        <OptimizeDrillDownGridItemComponent
          header={intl.formatMessage({ id: 'optimize.drillDown.currentStock' })}
          subHeader={intl.formatMessage({ id: 'optimize.drillDown.currentStockValue' }, { value: defaultNumberFormatter(currentStockInEur) })}
          value={currentStockBlock}
          borderBottom={true}
          borderRight={true}
        />
        <OptimizeDrillDownGridItemComponent
          header={intl.formatMessage({ id: 'optimize.drillDown.rangeOfStorage' })}
          value={intl.formatMessage({ id: 'optimize.drillDown.rangeOfStorageDays' }, { days: currentReachInDays })}
          borderBottom={true}
        />
        <OptimizeDrillDownGridItemComponent
          header={intl.formatMessage({ id: 'optimize.drillDown.leadTime' })}
          value={intl.formatMessage({ id: 'optimize.drillDown.leadTimeDays' }, { days: currentReachInDays })}
          borderRight={true}
        />
        <OptimizeDrillDownGridItemComponent
          header={intl.formatMessage({ id: 'optimize.drillDown.reorder' })}
          subHeader={intl.formatMessage({ id: 'optimize.drillDown.reorderRecommendation' })}
          value={String(defaultNumberFormatter(restockRecommendation))}
          colorfull={true}
        />
      </div>
    </div>
  )
}

export default OptimizeDrillDownComponent
