import { PARETOS_GRADIENT } from '@constants/ui.constants'
import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles<{ sidebarState: boolean }>()((theme: Theme, { sidebarState }) => {
  const mediaQuery = '@media (max-width: 1400px)'
  const breakpoint = sidebarState ? mediaQuery : theme.breakpoints.down('lg')

  return {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: theme.spacing(4),
      width: '100%',
      height: '280px',
      [breakpoint]: {
        width: '100%',
        height: 'auto',
        flexDirection: 'column',
      },
    },
    selectorRoot: {
      display: 'flex',
      flexDirection: 'row',
      width: '50%',
      background: 'linear-gradient(259.87deg, #666666 0.94%, #000000 97.62%)',
      borderRadius: theme.spacing(1, 0, 0, 1),
      height: '100%',
      [breakpoint]: {
        width: '100%',
        borderRadius: theme.spacing(1, 1, 0, 0),
      },
    },
    detailsRoot: {
      display: 'flex',
      flexDirection: 'row',
      width: '50%',
      background: PARETOS_GRADIENT,
      borderRadius: theme.spacing(0, 1, 1, 0),
      height: '100%',
      [breakpoint]: {
        width: '100%',
        borderRadius: theme.spacing(0, 0, 1, 1),
      },
    },
    rangePickerRoot: {
      width: '100%',
      marginBottom: theme.spacing(-0.5),
    },
  }
})

export default useStyles
