import { useMemo } from 'react'

import {
  DataGridPremiumProps,
} from '@mui/x-data-grid-premium'

import DataGridEmptyOverlayComponent from '@base/datagrid/data-grid-empty-overlay'
import DataGridLoadingOverlayComponent from '@base/datagrid/data-grid-loading-overlay'
import DataGridPaginationActionsComponent from '@base/datagrid/data-grid-pagination-actions'
import DataGridCustomToolbarComponent from '@base/datagrid/data-grid-custom-toolbar'
import DataGridIconButtonComponent from '@base/datagrid/data-grid-icon-button'
import DataGridSwitchBaseComponent from '@base/datagrid/data-grid-switch-base'
import DataGridSelectBaseComponent from '@base/datagrid/data-grid-select-base'
import DataGridBaseButtonComponent from '@base/datagrid/data-grid-base-button'
import DataGridBaseTextFieldComponent from '@base/datagrid/data-grid-base-text-field'
import DataGridCustomColumnMenuComponent from '@base/datagrid/data-grid-custom-column-menu'
import DataGridCheckboxBaseComponent from '@base/datagrid/data-grid-checkbox-base'
import DataGridCustomFooterComponent from '@base/datagrid/data-grid-custom-footer'

import TooltipComponent from '@base/tooltips/Tooltip'

import {
  ColumnMenuIcon,
  FilterMenuIcon,
  TableColumnSeparatorIcon,
  SmallFilterMenuIcon,
  ColumnSortedAscendingIcon,
  ColumnSortedDescendingIcon,
  GroupingCriteriaCollapseIcon,
  GroupingCriteriaExpandIcon,
  QuickFilterClearIcon,
  ColumnsMenuIcon,
  HideMenuIcon,
  PinLeftMenuIcon,
  PinRightMenuIcon,
  AggregationMenuIcon,
  ColumnMenuSortDescendingIcon,
  ColumnMenuSortAscendingIcon,
  GroupMenuIcon,
  UngroupMenuIcon,
  FilterMenuAddIcon,
  FilterMenuDeleteIcon,
  FilterMenuRemoveAllIcon,
  DetailPanelExpandIcon,
  DetailPanelCollapseIcon,
} from '@base/datagrid/data-grid/DataGrid.utils'

export interface useDataGridSlotsProps {
  slots: DataGridPremiumProps['slots']
}

export const useDataGridSlots = ({
  slots,
}: useDataGridSlotsProps) => {
  const finalSlotProps = useMemo(() => {
    return {
      columnMenuFilterIcon: FilterMenuIcon,
      columnMenuManageColumnsIcon: ColumnsMenuIcon,
      columnMenuSortAscendingIcon: ColumnMenuSortAscendingIcon,
      columnMenuSortDescendingIcon: ColumnMenuSortDescendingIcon,
      columnMenuHideIcon: HideMenuIcon,
      columnMenuPinLeftIcon: PinLeftMenuIcon,
      columnMenuPinRightIcon: PinRightMenuIcon,
      columnMenuAggregationIcon: AggregationMenuIcon,
      columnResizeIcon: TableColumnSeparatorIcon,
      columnMenuGroupIcon: GroupMenuIcon,
      columnMenuUngroupIcon: UngroupMenuIcon,
      filterPanelAddIcon: FilterMenuAddIcon,
      filterPanelDeleteIcon: FilterMenuDeleteIcon,
      filterPanelRemoveAllIcon: FilterMenuRemoveAllIcon,

      toolbar: DataGridCustomToolbarComponent,
      loadingOverlay: DataGridLoadingOverlayComponent,
      noRowsOverlay: DataGridEmptyOverlayComponent,
      pagination: DataGridPaginationActionsComponent,
      quickFilterClearIcon: QuickFilterClearIcon,
      groupingCriteriaExpandIcon: GroupingCriteriaExpandIcon,
      groupingCriteriaCollapseIcon: GroupingCriteriaCollapseIcon,
      columnSortedAscendingIcon: ColumnSortedAscendingIcon,
      columnSortedDescendingIcon: ColumnSortedDescendingIcon,
      openFilterButtonIcon: FilterMenuIcon,
      columnMenuIcon: ColumnMenuIcon,
      columnMenu: DataGridCustomColumnMenuComponent,
      columnFilteredIcon: SmallFilterMenuIcon,
      baseTooltip: TooltipComponent,
      baseIconButton: DataGridIconButtonComponent,
      baseSwitch: DataGridSwitchBaseComponent,
      baseSelect: DataGridSelectBaseComponent,
      baseButton: DataGridBaseButtonComponent,
      baseTextField: DataGridBaseTextFieldComponent,
      baseCheckbox: DataGridCheckboxBaseComponent,
      footer: DataGridCustomFooterComponent,
      detailPanelExpandIcon: DetailPanelExpandIcon,
      detailPanelCollapseIcon: DetailPanelCollapseIcon,
      ...slots,
    }
  }, [
    slots,
  ])

  return finalSlotProps
}
