import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import { Grid, Slide, Typography } from '@mui/material'
import { createId } from '@utils/common.utils'

import {
  DEFAULT_SUFFIX, defaultNumberFormatter,
  formatNumberWithSuffixUnit, getNumberSuffix,
} from '@utils/analysis.utils'

import SliderFieldComponent from '@components/form/slider-field'
import IconButtonComponent from '@base/buttons/IconButton'
import ChevronIcon from '@icons/flow/chevron.icon'
import ChevronTopIcon from '@icons/flow/chevronTop.icon'
import FormattedUnitValueComponent from '@components/(features)/trade-offs/formatted-unit-value'
import { SliderBarCount } from '@components/(features)/trade-offs/slider-bar-chart/SliderBarChart.component'

import useStyles from './TradeOffsConfiguration.styles'

export interface TradeOffsConfigurationComponentProps {
  className?: string,
  kpisOptions?: Socrates.KpiOption[],
  loading?: boolean,
  optimizeEnabled?: boolean,
  configuration: Socrates.TradeOffsConfiguration,
  handleChange: Socrates.HandleConfigurationChange,
  handleReset: () => void,
}

/** Renders trade-offs configuration */
const TradeOffsConfigurationComponent: React.FC<TradeOffsConfigurationComponentProps> = ({
  className,
  kpisOptions = [],
  loading,
  optimizeEnabled,
  handleChange,
  handleReset,
  configuration = {} as Socrates.TradeOffsConfiguration,
}) => {
  const { classes, cx } = useStyles()
  const intl = useIntl()
  const [slidersValue, setSlidersValue] = useState<{ [key: string]: any }>({})
  const [expanded, setExpanded] = useState(true)
  const isEmpty = (!kpisOptions || (kpisOptions.length === 0))

  const configurationToRender = (loading || isEmpty) ? null : (
    <Slide
      in={expanded}
      timeout={{
        exit: 500,
      }}
    >
      <Grid container={true} className={classes.chartRanage} data-testid={`${TradeOffsConfigurationComponent.name}-list`}>
        {
          kpisOptions.map((kpiOption, index: number) => {
            const sliderValue = slidersValue[kpiOption.value]
            const defaultSliderValue = configuration.SLIDER[kpiOption.value]
            const computedValue: [number, number] = [].concat(sliderValue || defaultSliderValue) as any

            const targetSuffix = optimizeEnabled ? getNumberSuffix(kpiOption.max) : '' as any
            const unit = intl.formatMessage({ id: 'optimize.selector.eurUnit' }, { suffix: targetSuffix?.key || DEFAULT_SUFFIX })

            const formattedMaxValue = (optimizeEnabled) ? formatNumberWithSuffixUnit(kpiOption.max, targetSuffix, true) : defaultNumberFormatter(kpiOption.max)
            const formattedMinValue = (optimizeEnabled) ? formatNumberWithSuffixUnit(kpiOption.min, targetSuffix, true) : defaultNumberFormatter(kpiOption.min)
            const tooltipFormatter = (value: number) => formatNumberWithSuffixUnit(
              value,
              targetSuffix,
              false,
              intl.formatMessage({ id: 'optimize.selector.eur' }),
            )

            return (
              <Grid item={true} xs={12} className={classes.chartRanageItem} key={createId(index, kpiOption.key)}>
                <Typography className={classes.chartRanageTitle}>
                  {kpiOption.label}
                </Typography>
                <div className={classes.sliderContainer}>

                  <FormattedUnitValueComponent
                    className={classes.sliderMin}
                    value={formattedMinValue}
                    unit={optimizeEnabled ? unit : ''}
                  />

                  <div className={classes.sliderRootContainer}>
                    <SliderFieldComponent
                      name={kpiOption.value}
                      value={computedValue}
                      defaultValue={[kpiOption.min, kpiOption.max] as any}
                      onChange={((e: Event, value: any) => setSlidersValue({ ...slidersValue, [kpiOption.value]: value })) as any}
                      onChangeCommitted={(e, value) => handleChange('SLIDER', kpiOption.value as any, value)}
                      valueLabelDisplay='auto'
                      step={null}
                      counts={kpiOption.counts as SliderBarCount[]}
                      marks={kpiOption.marks}
                      min={kpiOption.min}
                      max={kpiOption.max}
                      disabled={kpiOption.min === kpiOption.max}
                      className={classes.slider}
                      valueLabelFormat={optimizeEnabled ? tooltipFormatter : undefined}
                    />
                  </div>

                  <FormattedUnitValueComponent
                    className={classes.sliderMax}
                    value={formattedMaxValue}
                    unit={optimizeEnabled ? unit : ''}
                  />
                </div>
              </Grid>
            )
          })
        }
      </Grid>
    </Slide>
  )

  return (
    <>
      <div
        className={cx(classes.chartDetails, {
          [classes.disabled]: isEmpty,
        })}
      >
        <div>
          <Typography className={classes.chartDetailsMessege}>
            {intl.formatMessage({ id: 'trade_offs.details.title' })}
          </Typography>
        </div>
        {
          optimizeEnabled ? (
            <IconButtonComponent
              name='expand'
              label='Expand'
              tooltip={false}
              color='secondary'
              onClick={() => setExpanded(!expanded)}
              IconComponent={expanded ? ChevronIcon : ChevronTopIcon}
              disabled={false}
            />
          ) : (
            <Typography className={classes.chartResetMessege} onClick={handleReset}>
              {intl.formatMessage({ id: 'trade_offs.details.reset' })}
            </Typography>
          )
        }
      </div>
      <div
        className={cx(className, classes.root, {
          [classes.expanded]: expanded,
        })}
        data-testid={TradeOffsConfigurationComponent.name}
      >
        {configurationToRender}
      </div>
    </>
  )
}

export default TradeOffsConfigurationComponent
