import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { Box, Typography } from '@mui/material'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  ModalButtonComponent,
} from '@base/sidepanel/SidePanel'

import { sortByLabelKey } from '@utils/list.utils'
import { DESC_SORTING_ORDER } from '@constants/filters.constants'
import { getModalDetails, getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { DATA_SOURCES_MODAL_NAME, PARETOS_DATA_SOURCE_MODAL_NAME } from '@constants/modals.constants'
import { ParetosSourceOption, PARETOS_DATA_CATEGORY_LABEL_MAP, PARETOS_SOURCE_OPTIONS_BY_CATEGORY } from '@constants/flow.constants'

import SearchFieldComponent from '@base/forms/SearchField'

import useStyles from './ParetosDataSourceModal.styles'

export interface ParetosDataSourceModalDetails extends Common.ModalDetails {
  icon: React.ElementType
  title: string
  help: string
  connectionId: string
}

const ParetosDataSourceModalContainer = () => {
  const intl = useIntl()
  const { classes, cx } = useStyles()
  const dispatch = useDispatch()
  const [searchValue, setSearchValue] = useState('')

  const modalDetails = useSelector((state) => getModalDetails<ParetosDataSourceModalDetails>(state))
  const modalPageName = useSelector((state) => getOpenedModal(state))

  const open = modalPageName === PARETOS_DATA_SOURCE_MODAL_NAME
  const categories = Object.keys(PARETOS_SOURCE_OPTIONS_BY_CATEGORY)

  const {
    icon,
    title: sourceName,
    returnTo,
  } = modalDetails

  const handleClose = () => {
    setSearchValue('')

    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: returnTo,
        modalDetails: {
          ...modalDetails,
          connectionId: '',
          returnTo: DATA_SOURCES_MODAL_NAME,
        } as ParetosDataSourceModalDetails,
      }),
    )
  }

  const filterBySearch = (item: ParetosSourceOption) => {
    const name = intl.formatMessage({ id: item.labelKey }).toLowerCase()
    const searchTerm = searchValue.toLowerCase()

    if (!searchValue) {
      return true
    }

    return name.includes(searchTerm)
  }

  const listToRender = categories.map((category) => {
    const items = (sortByLabelKey(PARETOS_SOURCE_OPTIONS_BY_CATEGORY[category], 'labelKey', DESC_SORTING_ORDER, intl) as ParetosSourceOption[]).filter(filterBySearch)

    if (!items || items.length === 0) {
      return null
    }

    return (
      <Box key={category}>
        <Box className={cx(classes.tableRow, classes.categoryRow)}>
          <Typography
            className={classes.categoryName}
            variant='overline'
          >
            {intl.formatMessage({ id: PARETOS_DATA_CATEGORY_LABEL_MAP[category] })}
          </Typography>
        </Box>
        <Box>
          {
            items.map((item) => {
              return (
                <Box className={classes.tableRow} key={item.labelKey}>
                  <Typography
                    variant='body1'
                  >
                    {intl.formatMessage({ id: item.labelKey })}
                  </Typography>
                  <Typography
                    variant='overline'
                  >
                    {intl.formatMessage({ id: item.unitKey })}
                  </Typography>
                </Box>
              )
            })
          }
        </Box>
      </Box>
    )
  })

  const isEmptyList = listToRender.filter((item) => item !== null).length === 0

  return (
    <SidePanelComponent
      open={open}
      title={sourceName}
      handleClose={handleClose}
      SidePanelIcon={icon}
    >
      <>
        <SidePanelCardComponent>
          <Typography
            className={classes.modalSectionHeaderTitle}
            variant='h5'
          >
            {intl.formatMessage({ id: 'connect.modal.connect.connect_with_paretos.title' })}
          </Typography>
          <Typography
            className={classes.modalSectionHeaderText}
            variant='body1'
          >
            {intl.formatMessage({ id: 'connect.modal.connect.connect_with_paretos.description' })}
          </Typography>

          <Box className={classes.blockContainer}>
            <Box className={classes.tableHeader}>
              <Typography
                variant='h5'
              >
                {intl.formatMessage({ id: 'connect.modal.connect.connect_with_paretos.available_data' })}
              </Typography>
              <Box>
                <SearchFieldComponent
                  value={searchValue}
                  label={null}
                  fullWidth={false}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </Box>
            </Box>

            {
              isEmptyList ? (
                <Box className={classes.emptyState}>
                  <Typography variant='h4' className={classes.emptyStateHeader}>
                    {intl.formatMessage({ id: 'connect.modal.connect.connect_with_paretos.search_empty_title' })}
                  </Typography>
                  <Typography variant='subtitle1' className={classes.emptyStateBody}>
                    {intl.formatMessage({ id: 'connect.modal.connect.connect_with_paretos.search_empty_body' })}
                  </Typography>
                </Box>
              ) : (
                listToRender
              )
            }
          </Box>
        </SidePanelCardComponent>

        <SidePanelCardActionsComponent>
          <ModalButtonComponent
            name='paretosDataSourcesModalCloseButton'
            onClick={handleClose}
            type='back'
          />
        </SidePanelCardActionsComponent>
      </>
    </SidePanelComponent>
  )
}

export default ParetosDataSourceModalContainer
