import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { getTextBackgroundFillStyles } from '@utils/ui.utils'

const useStyles = makeStyles()((theme: Theme) => ({
  tooltipPopper: {
    zIndex: 1300,
    pointerEvents: 'auto !important' as any,
  },
  tooltip: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
    cursor: 'pointer',
  },
  tooltipArrow: {
    bottom: '25px',
    right: '-25px',
    position: 'absolute',
    borderBottom: '20px solid #FFFFFF',
    borderLeft: '20px solid transparent',
    filter: 'drop-shadow(5px -13px 6px rgba(130,130,130,0.25))',
  },
  tooltipInvite: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.new.black,
  },
  tooltipSocrates: {
    ...getTextBackgroundFillStyles(),
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    margin: theme.spacing(0, 0.5),
  },
  tooltipBeta: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2px 5px',
    background: '#AAA3CC',
    borderRadius: '4px',
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '11px',
    color: theme.palette.new.white,
  },
}))

export default useStyles
