import {
  takeEvery, put,
  select, call,
} from 'redux-saga/effects'

import moment from 'moment'

import { TOAST_TYPE_SUCCESS, TOAST_TYPE_ERROR } from '@constants/common.constants'
import { parseAndReportErrorResponse } from '@utils/redux.utils'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { changeToastAction } from '@redux/modules/common/common.actions'
import { ActionPayload, State } from '@redux/modules/types'

import {
  setLocalStorageCompanyId,
} from '@utils/local-storage.utils'

import { getCurrentUserInfo, getSelectedCompanyId } from './customer.selectors'

import {
  REQUEST_COMPANY_ITEM,
  CHANGE_COMPANY_ID,
  REQUEST_COMPANIES_LIST,
  REQUEST_COMPANY_USERS_LIST,
  CHANGE_USER_INFO,
  CLONE_COMPANY,
  CREATE_COMPANY,
  UPDATE_COMPANY,
  DELETE_COMPANY,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  REASSIGN_USER,
  CHANGE_USER_LANGUAGE,
} from './customer.action-types'

import {
  requestCompaniesListActionDone,
  requestCompanyUsersListActionDone,
  changeCompanyIdActionDone,
  changeUserInfoActionDone,
  stopFetchingCustomerAction,
  startFetchingCustomerAction,
  requestCompaniesListAction,
  requestCompanyUsersListAction,
  requestCompanyItemActionDone,
  changeCompanyIdAction,
  changeUserInfoAction,
} from './customer.actions'

import {
  CreateCompanyActionPayload,
  DeleteCompanyActionPayload,
  UpdateCompanyActionPayload,
  ChangeCompanyIdActionPayload,
  AddCompanyUsersPayload,
  EditCompanyUsersPayload,
  DeleteCompanyUsersPayload,
  FetchCompanyUsersPayload,
  CloneCompanyActionPayload,
  ReAssignCompanyUsersPayload,
  ChangeUserLanguageActionPayload,
} from './customer.types'

import * as API from './customer.api'

const CLONE_COMPANY_SUCCESS = 'companies.confirmation.clone'
const CREATE_COMPANY_SUCCESS = 'companies.confirmation.create'
const UPDATE_COMPANY_SUCCESS = 'companies.confirmation.update'
const DELETE_COMPANY_SUCCESS = 'companies.confirmation.delete'

const CREATE_USER_SUCCESS = 'customers.users.confirmation.create'
const UPDATE_USER_SUCCESS = 'customers.users.confirmation.update'
const DELETE_USER_SUCCESS = 'customers.users.confirmation.delete'
const REASSIGN_USER_SUCCESS = 'customers.users.confirmation.reassign'

function* changeCompanyIdGenerator({ payload } : ActionPayload<ChangeCompanyIdActionPayload>) {
  yield put(changeCompanyIdActionDone(payload.companyId))

  if (payload.saveToLs) {
    const state: State = yield select()
    const userInfo: Common.UserInfo = yield call(getCurrentUserInfo, state)
    const currentUserId = userInfo.sub

    setLocalStorageCompanyId(currentUserId, payload.companyId)
  }
}

function* changeUserInfoGenerator({ payload } : ActionPayload<Common.UserInfo>) {
  /* Set moment locale */
  moment.locale(payload.language)

  yield put(changeUserInfoActionDone(payload))
}

function* requestCompanyItemGenerator({ payload } : ActionPayload<API.GetCompanyPayload>) {
  try {
    yield put(startFetchingCustomerAction(REQUEST_COMPANIES_LIST))

    const company: Customer.CompanyItem = yield call(API.getCompany, payload)

    yield put(requestCompanyItemActionDone(company))
  } catch (e: any) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingCustomerAction(REQUEST_COMPANIES_LIST))
  }
}

function* requestCompaniesListGenerator({ payload } : ActionPayload<{}>) {
  try {
    yield put(startFetchingCustomerAction(REQUEST_COMPANIES_LIST))

    const companies: Customer.CompanyItem[] = yield call(API.fetchCompanies)

    yield put(requestCompaniesListActionDone(companies))
  } catch (e: any) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingCustomerAction(REQUEST_COMPANIES_LIST))
  }
}

function* requestCompanyUsersListGenerator({ payload } : ActionPayload<FetchCompanyUsersPayload>) {
  try {
    yield put(startFetchingCustomerAction(REQUEST_COMPANY_USERS_LIST))

    const companyUsers: Customer.UserItem[] = yield call(API.fetchCompanyUsers, payload)

    yield put(requestCompanyUsersListActionDone(companyUsers))
  } catch (e: any) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingCustomerAction(REQUEST_COMPANY_USERS_LIST))
  }
}

function* cloneCompanyGenerator({ payload } : ActionPayload<CloneCompanyActionPayload>) {
  try {
    yield put(startFetchingCustomerAction(CLONE_COMPANY))

    yield call(API.cloneCompany, payload)

    yield put(changeToastAction({ useIntl: true, message: CLONE_COMPANY_SUCCESS, severity: TOAST_TYPE_SUCCESS }))

    yield put(requestCompaniesListAction())
  } catch (e: any) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingCustomerAction(CLONE_COMPANY))
  }
}

function* createCompanyGenerator({ payload } : ActionPayload<CreateCompanyActionPayload>) {
  try {
    yield put(startFetchingCustomerAction(CREATE_COMPANY))

    yield call(API.addCompany, payload)

    yield put(setPrimaryModalPageName(null))

    yield put(changeToastAction({ useIntl: true, message: CREATE_COMPANY_SUCCESS, severity: TOAST_TYPE_SUCCESS }))

    yield put(requestCompaniesListAction())
  } catch (e: any) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingCustomerAction(CREATE_COMPANY))
  }
}

function* updateCompanyGenerator({ payload } : ActionPayload<UpdateCompanyActionPayload>) {
  try {
    yield put(startFetchingCustomerAction(UPDATE_COMPANY))

    yield call(API.editCompany, payload)

    yield put(setPrimaryModalPageName(null))

    yield put(changeToastAction({ useIntl: true, message: UPDATE_COMPANY_SUCCESS, severity: TOAST_TYPE_SUCCESS }))

    yield put(requestCompaniesListAction())
  } catch (e: any) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingCustomerAction(UPDATE_COMPANY))
  }
}

function* deleteCompanyGenerator({ payload } : ActionPayload<DeleteCompanyActionPayload>) {
  try {
    yield put(startFetchingCustomerAction(DELETE_COMPANY))

    const state: State = yield select()
    const userInfo: Common.UserInfo = yield call(getCurrentUserInfo, state)
    const selectedCompanyId: string = yield call(getSelectedCompanyId, state)
    const defaultUserCompanyId = userInfo.companyId || ''

    if (selectedCompanyId === payload.companyId) {
      yield put(changeCompanyIdAction({
        companyId: defaultUserCompanyId,
        saveToLs: true,
      }))
    }

    yield call(API.deleteCompany, payload)

    yield put(setPrimaryModalPageName(null))

    yield put(changeToastAction({ useIntl: true, message: DELETE_COMPANY_SUCCESS, severity: TOAST_TYPE_SUCCESS }))

    yield put(requestCompaniesListAction())
  } catch (e: any) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingCustomerAction(DELETE_COMPANY))
  }
}

function* createUserGenerator({ payload } : ActionPayload<AddCompanyUsersPayload>) {
  try {
    yield put(startFetchingCustomerAction(CREATE_USER))

    yield call(API.addCompanyUser, payload)

    yield put(setPrimaryModalPageName(null))

    yield put(changeToastAction({ useIntl: true, message: CREATE_USER_SUCCESS, severity: TOAST_TYPE_SUCCESS }))

    yield put(requestCompanyUsersListAction({
      companyId: payload.companyId,
    }))
  } catch (e: any) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingCustomerAction(CREATE_USER))
  }
}

function* updateUserGenerator({ payload } : ActionPayload<EditCompanyUsersPayload>) {
  try {
    yield put(startFetchingCustomerAction(UPDATE_USER))

    yield call(API.editCompanyUser, payload)

    yield put(setPrimaryModalPageName(null))

    const state: State = yield select()
    const userInfo: Common.UserInfo = yield call(getCurrentUserInfo, state)

    if (userInfo.language !== payload.language) {
      yield put(changeUserInfoActionDone({
        ...userInfo,
        language: payload.language,
      }))
    }

    yield put(changeToastAction({ useIntl: true, message: UPDATE_USER_SUCCESS, severity: TOAST_TYPE_SUCCESS }))

    yield put(requestCompanyUsersListAction({
      companyId: payload.companyId,
    }))
  } catch (e: any) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingCustomerAction(UPDATE_USER))
  }
}

function* reassignUserGenerator({ payload } : ActionPayload<ReAssignCompanyUsersPayload>) {
  try {
    yield put(startFetchingCustomerAction(REASSIGN_USER))

    yield call(API.reassignCompanyUser, payload)

    yield put(changeToastAction({ useIntl: true, message: REASSIGN_USER_SUCCESS, severity: TOAST_TYPE_SUCCESS }))

    const state: State = yield select()
    const companyId: string = yield call(getSelectedCompanyId, state)

    yield put(requestCompanyUsersListAction({
      companyId,
    }))
  } catch (e: any) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingCustomerAction(REASSIGN_USER))
  }
}

function* deleteUserGenerator({ payload } : ActionPayload<DeleteCompanyUsersPayload>) {
  try {
    yield put(startFetchingCustomerAction(DELETE_USER))

    yield call(API.deleteCompanyUser, payload)

    yield put(setPrimaryModalPageName(null))

    yield put(changeToastAction({ useIntl: true, message: DELETE_USER_SUCCESS, severity: TOAST_TYPE_SUCCESS }))

    yield put(requestCompanyUsersListAction({
      companyId: payload.companyId,
    }))
  } catch (e: any) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingCustomerAction(DELETE_USER))
  }
}

function* changeUserLanguageGenerator({ payload } : ActionPayload<ChangeUserLanguageActionPayload>) {
  try {
    yield put(startFetchingCustomerAction(CHANGE_USER_LANGUAGE))

    const state: State = yield select()
    const userInfo: Common.UserInfo = yield call(getCurrentUserInfo, state)

    yield call(API.changeUserLanguage, {
      customerId: userInfo.sub,
      language: payload.language,
    })

    yield put(changeUserInfoAction({
      ...userInfo,
      language: payload.language,
    }))
  } catch (e: any) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR, useIntl: true }))
  } finally {
    yield put(stopFetchingCustomerAction(CHANGE_USER_LANGUAGE))
  }
}

export function* watchCloneCompany() {
  yield takeEvery(CLONE_COMPANY, cloneCompanyGenerator)
}

export function* watchCreateCompany() {
  yield takeEvery(CREATE_COMPANY, createCompanyGenerator)
}

export function* watchUpdateCompany() {
  yield takeEvery(UPDATE_COMPANY, updateCompanyGenerator)
}

export function* watchDeleteCompany() {
  yield takeEvery(DELETE_COMPANY, deleteCompanyGenerator)
}

export function* watchCreateUser() {
  yield takeEvery(CREATE_USER, createUserGenerator)
}

export function* watchUpdateUser() {
  yield takeEvery(UPDATE_USER, updateUserGenerator)
}

export function* watchDeleteUser() {
  yield takeEvery(DELETE_USER, deleteUserGenerator)
}

export function* watchReassignUser() {
  yield takeEvery(REASSIGN_USER, reassignUserGenerator)
}

export function* watchChangeUserLanguage() {
  yield takeEvery(CHANGE_USER_LANGUAGE, changeUserLanguageGenerator)
}

export function* watchUserInfoChange() {
  yield takeEvery(CHANGE_USER_INFO, changeUserInfoGenerator)
}

export function* watchCompanyItemRequests() {
  yield takeEvery(REQUEST_COMPANY_ITEM, requestCompanyItemGenerator)
}

export function* watchCompanyIdRequests() {
  yield takeEvery(CHANGE_COMPANY_ID, changeCompanyIdGenerator)
}

export function* watchCompaniesListRequests() {
  yield takeEvery(REQUEST_COMPANIES_LIST, requestCompaniesListGenerator)
}

export function* watchCompanyUsersListRequests() {
  yield takeEvery(REQUEST_COMPANY_USERS_LIST, requestCompanyUsersListGenerator)
}
