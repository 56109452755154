import {
  takeEvery,
  put, call, select,
  take, takeLatest,
} from 'redux-saga/effects'

import { TOAST_TYPE_ERROR } from '@constants/common.constants'
import { parseAndReportErrorResponse } from '@utils/redux.utils'
import { changeToastAction } from '@redux/modules/common/common.actions'
import { ActionPayload, State } from '@redux/modules/types'
import { DATA_GRIDS, DEFAULT_PAGE_SIZE } from '@constants/data-grid.constants'
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid-premium'
import {
  ANALYZE_TABLE_CONFIG_VERSION,
  DEFAULT_ANALYZE_TARGET_COLUMN,
  DEFAULT_ANALYZE_PREDICTION_COLUMN,
} from '@constants/insights.constants'

import {
  createABCTotalsRequestHandler,
  createChartRequestHandler,
  createGridStateChangeHandler,
  createResetViewGenerator,
  createTableRequestHandler,
} from '@utils/insights.utils'

import { getDataGridId } from '@utils/data-grid.utils'
import { createAnalyzeDataGridState } from '@utils/analyze.utils'
import { fetchUseCaseAction } from '@redux/modules/use-case/use-case.actions'
import { RECEIVE_USE_CASE } from '@redux/modules/use-case/use-case.action-types'
import { shortcutOptionToDateRange } from '@utils/moment.utils'
import { DATE_PICKER_SHORTCUT } from '@constants/date.constants'
import { getUseCaseItem } from '@redux/modules/use-case/use-case.selectors'

import {
  getSearchTermFromFilterModel,
  convertMUIAggregationModelToAPIAggregationReq,
  convertMUIPageNumberToAPIPageNumber,
  convertMUIPageSizeToAPIPageSize,
  convertMUISortingModelToAPISortReq,
  prepareAPIFilters,
  convertMUIRowSelectionModelToAPIRowFilterReq,
  convertTimeWindowIntoAPIDateRange,
  prepareAPILogicalOperator,
} from '@redux/modules/hera/hera.utils'

import * as API from '@redux/modules/hera/hera.api'

import {
  REQUEST_ANALYZE_CHART,
  RECEIVE_ANALYZE_CHART,
  RECEIVE_ANALYZE_TABLE,
  REQUEST_ANALYZE_GRID_STATE_CHANGE,
  REQUEST_ANALYZE_TABLE,
  REQUEST_ANALYZE_VIEW,
  RECEIVE_ANALYZE_ABC_TOTALS,
  REQUEST_ANALYZE_ABC_TOTALS,
  RESET_ANALYZE,
  RECEIVE_ANALYZE_GRID_STATE_CHANGE,
} from './analyze.action-types'

import {
  receiveAnalyzeChartAction,
  requestAnalyzeChartAction,
  requestAnalyzeTableAction,
  receiveAnalyzeTableActionDone,
  receiveAnalyzeGridStateChangeAction,
  startFetchingAnalyzeAction,
  stopFetchingAnalyzeAction,
  requestAnalyzeViewAction,
  requestAnalyzeGridStateChangeAction,
  receiveAnalyzeAbcTotalsAction,
  requestAnalyzeAbcTotalsAction,
} from './analyze.actions'

import {
  RequesAnalyzeChartActionPayload,
  RequestAnalyzeTableActionPayload,
  RequestAnalyzeGridStateChangePayload,
  RequestAnalyzeActionPayload,
} from './analyze.types'

import { getAnalyzeTable, getAnalyzeTableState, isFetchingAnalyzeTable } from './analyze.selectors'

const initialGridState = {
  abcFilter: undefined,
  filterModel: {
    items: [],
    logicOperator: 'and',
    quickFilterValues: [''],
  },
  sortModel: [{ field: DEFAULT_ANALYZE_PREDICTION_COLUMN, sort: 'desc' }],
  aggregationModel: {
    [DEFAULT_ANALYZE_TARGET_COLUMN]: 'sum',
    [DEFAULT_ANALYZE_PREDICTION_COLUMN]: 'sum',
  },
  pinnedColumns: {
    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
    right: [DEFAULT_ANALYZE_TARGET_COLUMN, DEFAULT_ANALYZE_PREDICTION_COLUMN],
  },
  paginationModel: {
    pageSize: DEFAULT_PAGE_SIZE,
    page: 0,
  },
  columnVisibilityModel: {},
  internalColumnVisibilityModel: {},
  timeWindow: shortcutOptionToDateRange(DATE_PICKER_SHORTCUT.THIS_YEAR),
  rowSelectionModel: [],
  rowSelectionModelMode: 'exclude',
} as Analyze.AnalyzeGridState

function* requestAnalyzeViewGenerator({ payload }: ActionPayload<RequestAnalyzeActionPayload>) {
  try {
    yield put(startFetchingAnalyzeAction(REQUEST_ANALYZE_VIEW))

    yield put(fetchUseCaseAction({
      useCaseId: payload.useCaseId,
    } as RequestAnalyzeTableActionPayload))

    yield take(RECEIVE_USE_CASE)

    const state: State = yield select()
    const useCase: UseCase.DetailsExtended = yield call(getUseCaseItem, state)
    const useCaseIdToUse = useCase.demandUseCaseId || payload.useCaseId

    const tableId = getDataGridId(DATA_GRIDS.ANALYZE_TABLE, ANALYZE_TABLE_CONFIG_VERSION, useCaseIdToUse)
    const gridStateFromStorage = createAnalyzeDataGridState(tableId, initialGridState)

    yield put(requestAnalyzeAbcTotalsAction({ useCaseId: useCaseIdToUse }))

    yield take(RECEIVE_ANALYZE_ABC_TOTALS)

    yield put(requestAnalyzeTableAction({
      ...gridStateFromStorage,

      useCaseId: useCaseIdToUse,
      initialization: true,
    } as RequestAnalyzeTableActionPayload))

    yield put(requestAnalyzeChartAction({
      ...gridStateFromStorage,

      useCaseId: useCaseIdToUse,
      initialization: true,
    } as RequesAnalyzeChartActionPayload))

    yield take(RECEIVE_ANALYZE_TABLE)

    yield take(RECEIVE_ANALYZE_CHART)
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingAnalyzeAction(REQUEST_ANALYZE_VIEW))
  }
}

const requestAnalyzeGridStateChangeGenerator = createGridStateChangeHandler<
  RequestAnalyzeGridStateChangePayload,
  Analyze.AnalyzeGridState,
  Analyze.AnalyzeTable
>({
  getGridState: getAnalyzeTableState,
  getTableDetails: getAnalyzeTable,
  receiveGridStateChangeAction: receiveAnalyzeGridStateChangeAction,
  requestTableAction: requestAnalyzeTableAction,
  requestChartAction: requestAnalyzeChartAction,
  receiveTableAction: receiveAnalyzeTableActionDone,
})

const requestAnalyzeViewChartGenerator = createChartRequestHandler<
  RequesAnalyzeChartActionPayload,
  Analyze.AnalyzeChartAPIRequest,
  Analyze.AnalyzeChartAPIResponse,
  RequestAnalyzeGridStateChangePayload
>({
  receiveGridStateChangeType: RECEIVE_ANALYZE_GRID_STATE_CHANGE,
  receiveTableActionType: RECEIVE_ANALYZE_TABLE,
  startFetchingAction: () => startFetchingAnalyzeAction(REQUEST_ANALYZE_CHART),
  stopFetchingAction: () => stopFetchingAnalyzeAction(REQUEST_ANALYZE_CHART),
  getTableFetchingState: isFetchingAnalyzeTable,
  requestChartAPI: API.getAnalyzeChart,
  receiveChartAction: receiveAnalyzeChartAction,
  requestGridStateChangeAction: requestAnalyzeGridStateChangeAction,
  convertAPIPayload: (payload, shouldPreselectRows) => ({
    useCaseId: payload.useCaseId,
    groupBy: payload.groupingModel,
    sorting: convertMUISortingModelToAPISortReq(payload.sortModel),
    filters: prepareAPIFilters(payload.filterModel),
    dateRange: convertTimeWindowIntoAPIDateRange(payload.timeWindow),
    logicalOperator: prepareAPILogicalOperator(payload.filterModel),
    aggregations: convertMUIAggregationModelToAPIAggregationReq(payload.aggregationModel),
    rowFilters: convertMUIRowSelectionModelToAPIRowFilterReq(payload.rowSelectionModel, payload.rowSelectionModelMode, shouldPreselectRows),
    abcFilter: payload.abcFilter,
  }),
})

const requestAnalyzeViewTableGenerator = createTableRequestHandler<
  RequestAnalyzeTableActionPayload,
  Analyze.AnalyzeTablePaginatedAPIRequest,
  Analyze.AnalyzeTablePaginatedAPIResponse,
  Analyze.AnalyzeGridState
>({
  tableName: DATA_GRIDS.ANALYZE_TABLE,
  tableVersion: ANALYZE_TABLE_CONFIG_VERSION,

  startFetchingAction: () => startFetchingAnalyzeAction(REQUEST_ANALYZE_TABLE),
  stopFetchingAction: () => stopFetchingAnalyzeAction(REQUEST_ANALYZE_TABLE),
  getInitialGridState: (payload) => initialGridState,
  createGridState: (tableId, finalState) => createAnalyzeDataGridState(tableId, finalState),

  requestTableAPI: API.getAnalyzeTable,
  receiveTableActionDone: receiveAnalyzeTableActionDone,
  retryAction: requestAnalyzeViewAction,
  convertAPIPayload: (payload) => ({
    useCaseId: payload.useCaseId,
    groupBy: payload.groupingModel,
    pageSize: convertMUIPageSizeToAPIPageSize(payload.paginationModel?.pageSize),
    pageNumber: convertMUIPageNumberToAPIPageNumber(payload.paginationModel?.page),
    sorting: convertMUISortingModelToAPISortReq(payload.sortModel),
    searchTerm: getSearchTermFromFilterModel(payload.filterModel),
    filters: prepareAPIFilters(payload.filterModel),
    dateRange: convertTimeWindowIntoAPIDateRange(payload.timeWindow),
    logicalOperator: prepareAPILogicalOperator(payload.filterModel),
    aggregations: convertMUIAggregationModelToAPIAggregationReq(payload.aggregationModel),
    abcFilter: payload.abcFilter,
  }),
})

const requestAnalyzeAbcTotalsGenerator = createABCTotalsRequestHandler({
  startFetchingAction: () => startFetchingAnalyzeAction(REQUEST_ANALYZE_ABC_TOTALS),
  stopFetchingAction: () => stopFetchingAnalyzeAction(REQUEST_ANALYZE_ABC_TOTALS),
  requestABCTotalsAPi: API.getABCTotals,
  receiveABCTotalsAction: receiveAnalyzeAbcTotalsAction,
})

const resetAnalyzeViewGenerator = createResetViewGenerator({
  receiveTableAction: receiveAnalyzeTableActionDone,
  receiveChartAction: receiveAnalyzeChartAction,
})

export function* watchRequestAnalyzeViewTable() {
  yield takeLatest(REQUEST_ANALYZE_TABLE, requestAnalyzeViewTableGenerator)
}

export function* watchResetAnalyze() {
  yield takeEvery(RESET_ANALYZE, resetAnalyzeViewGenerator)
}

export function* watchRequestGridStateChange() {
  yield takeLatest(REQUEST_ANALYZE_GRID_STATE_CHANGE, requestAnalyzeGridStateChangeGenerator)
}

export function* watchRequestAnalyzeViewChart() {
  yield takeLatest(REQUEST_ANALYZE_CHART, requestAnalyzeViewChartGenerator)
}

export function* watchRequestAnalyzeView() {
  yield takeEvery(REQUEST_ANALYZE_VIEW, requestAnalyzeViewGenerator)
}

export function* watchRequestAnalyzeAbcTotals() {
  yield takeEvery(REQUEST_ANALYZE_ABC_TOTALS, requestAnalyzeAbcTotalsGenerator)
}
