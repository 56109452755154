import React from 'react'
import { Box, Typography } from '@mui/material'

export interface ViewInfoComponentProps {
  /**
   * Label to be displayed
   */
  label: string,
  /**
   * Value to be displayed
   */
  value?: string | number,
}

const ViewInfoComponent: React.FC<ViewInfoComponentProps> = ({
  label,
  value,
}) => {
  if (!value) {
    return null
  }

  return (
    <Box
      data-testid={ViewInfoComponent.name}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'center',
        width: 'fit-content',
      }}
    >
      <Typography
        variant='overline'
        noWrap={true}
      >
        {label}
      </Typography>
      <Typography
        variant='body1'
        noWrap={true}
      >
        {value}
      </Typography>
    </Box>
  )
}

export default ViewInfoComponent
