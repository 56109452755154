import { checkApiForError } from '@utils/redux.utils'
import { USE_CASE } from '@constants/api-provider.constants'

import apiProvider from '@redux/api-provider'

import {
  CreateGroupingPayload, CreatePayload,
  FetchGroupingPayload, FetchPayload,
  RemoveGroupingPayload, RemovePayload,
  UpdatePayload,
} from './parameters.types'

export const fetch = ({ parameterId, type, useCaseId } : FetchPayload) => {
  const URL = 'v6/useCase/parameter/get'
  const payload = { parameterId, type, useCaseId }

  return apiProvider.getApi(USE_CASE).post(URL, payload)
    .then(({ data }) => {
      checkApiForError(data)

      return data.data
    })
}

export const create = ({ useCaseId, type, config } : CreatePayload) => {
  const URL = 'v6/useCase/parameter/add'

  return apiProvider.getApi(USE_CASE).post(URL, { useCaseId, type, config }).then(({ data }) => {
    checkApiForError(data)

    return data.data
  })
}
export const update = ({
  parameterId, type,
  useCaseId, config,
} : UpdatePayload) => {
  const URL = 'v6/useCase/parameter/edit'

  return apiProvider.getApi(USE_CASE).post(URL, {
    parameterId, useCaseId, type, config,
  }).then(({ data }) => {
    checkApiForError(data)
  })
}

export const remove = ({ parameterId, useCaseId, type }: RemovePayload) => {
  return apiProvider.getApi(USE_CASE).post('v6/useCase/parameter/delete', { parameterId, useCaseId, type }).then(({ data }) => {
    checkApiForError(data)
  })
}

export const fetchGrouping = ({ groupingAttributeId }: FetchGroupingPayload) => {
  const URL = 'v6/useCase/grouping-attribute/get'
  const payload = { groupingAttributeId }

  return apiProvider.getApi(USE_CASE).post(URL, payload)
    .then(({ data }) => {
      checkApiForError(data)

      return data.data
    })
}

export const createGrouping = ({ useCaseId, name } : CreateGroupingPayload) => {
  const URL = 'v6/useCase/grouping-attribute/add'

  return apiProvider.getApi(USE_CASE).post(URL, { useCaseId, name }).then(({ data }) => {
    checkApiForError(data)

    return data.data
  })
}

export const updateGrouping = (payload: UseCase.GroupingParameter) => {
  const URL = 'v6/useCase/grouping-attribute/edit'

  return apiProvider.getApi(USE_CASE).post(URL, payload).then(({ data }) => {
    checkApiForError(data)
  })
}

export const removeGrouping = ({ groupingAttributeId } : RemoveGroupingPayload) => {
  return apiProvider.getApi(USE_CASE).post('v6/useCase/grouping-attribute/delete', { groupingAttributeId }).then(({ data }) => {
    checkApiForError(data)
  })
}
