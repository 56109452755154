import { handleActions } from 'redux-actions'
import { FilesServiceState } from '@redux/modules/types'

import {
  RECEIVE_FILES_LIST,
  RECEIVE_FILE_PREVIEW,
  RECEIVE_SORTING_CHANGE,
  START_FETCHING_FILES,
  STOP_FETCHING_FILES,
} from './file-service.action-types'

import {
  startFetching,
  stopFetching,
  receiveFilePreview,
  receiveSortingChanges,
  initialState,
  receiveFilesList,
} from './file-service.reducers'

export default handleActions<FilesServiceState, any>({
  [RECEIVE_FILES_LIST]: receiveFilesList,
  [RECEIVE_FILE_PREVIEW]: receiveFilePreview,
  [RECEIVE_SORTING_CHANGE]: receiveSortingChanges,
  [START_FETCHING_FILES]: startFetching,
  [STOP_FETCHING_FILES]: stopFetching,
}, initialState)
