import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  rangePickerRoot: {
    width: '100%',
    marginBottom: theme.spacing(-0.5),
  },
  tradeOffsHeader: {
    color: theme.palette.new.black_d,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    marginBottom: theme.spacing(0.5),
  },
}))

export default useStyles
