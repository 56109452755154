import React, {
  useMemo,
} from 'react'

import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { requestAnalyzeGridStateChangeAction } from '@redux/modules/analyze/analyze.actions'
import { getSelectedLanguage } from '@redux/modules/customer/customer.selectors'
import {
  getAnalyzeAbcTotals, getAnalyzeChart,
  getAnalyzeTable, isFetchingAnalyze,
  isFetchingAnalyzeChart,
} from '@redux/modules/analyze/analyze.selectors'

import DateRangePickerComponent from '@base/dates/DateRangePicker'
import AbcClassificationSelectorComponent from '@components/(features)/insights/abc-classification-selector'
import InsightsFiltersLayoutComponent from '@components/(features)/insights/insights-filters-layout/InsightsFiltersLayout.component'

const AnalyzeFiltersContainer = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const analyzeTable = useSelector((state) => getAnalyzeTable(state))
  const analyzeChart = useSelector((state) => getAnalyzeChart(state))
  const isFetchingAnalyzeChartFlag = useSelector((state) => isFetchingAnalyzeChart(state))
  const isFetchingAnalyzeViewFlag = useSelector((state) => isFetchingAnalyze(state))
  const isFetching = isFetchingAnalyzeChartFlag || isFetchingAnalyzeViewFlag
  const abcTotals = useSelector((state) => getAnalyzeAbcTotals(state))
  const userLanguage = useSelector((state) => getSelectedLanguage(state))

  const {
    gridState,
    targetName,
  } = analyzeTable

  const {
    abcClassification,
  } = analyzeChart

  const timeWindow = useMemo(() => (gridState && gridState.timeWindow), [gridState])

  const handleDatesChange = (value: Common.DatePickerValue) => {
    dispatch(
      requestAnalyzeGridStateChangeAction({
        timeWindow: value,
      }),
    )
  }

  const handleAbcClassificationChange = (value: Hera.APIABCFilter) => {
    dispatch(
      requestAnalyzeGridStateChangeAction({
        abcFilter: value,
      }),
    )
  }

  const leftSideSection = (
    <DateRangePickerComponent
      overline={intl.formatMessage({ id: 'insights.timeWindow' })}
      onChange={handleDatesChange}
      value={timeWindow}
      name='timeWindow'
      disabled={!gridState}
      userLanguage={userLanguage}
    />
  )

  const abcClassificationSection = (
    <AbcClassificationSelectorComponent
      abcClassification={abcClassification}
      abcTotals={abcTotals}
      targetName={targetName}
      gridState={gridState!}
      selectedValue={gridState?.abcFilter}
      loading={isFetching}
      onClick={handleAbcClassificationChange}
    />
  )

  return (
    <InsightsFiltersLayoutComponent
      dataTestId={AnalyzeFiltersContainer.name}
      leftSideSection={leftSideSection}
      abcSection={abcClassificationSection}
    />
  )
}

export default AnalyzeFiltersContainer
