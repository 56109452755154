import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
    border: `1px solid ${theme.palette.new.grey_a}`,
  },
  leftContainer: {
    borderRight: `1px solid ${theme.palette.new.grey_a}`,
  },
  sectionChart: {
    height: theme.spacing(60),
    [theme.breakpoints.down('xl')]: {
      height: theme.spacing(40),
    },
  },
  sectionWrapper: {
    position: 'relative',
  },
  chartHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    height: theme.spacing(8),
    borderBottom: `1px solid ${theme.palette.new.grey_a}`,
  },
  xAxisSelector: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    borderRight: `1px solid ${theme.palette.new.grey_a}`,
    padding: theme.spacing(0, 2),
  },
  yAxisSelector: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: theme.spacing(0, 2),
  },
  optimumContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    height: theme.spacing(5),
    borderTop: `1px solid ${theme.palette.new.grey_a}`,
  },
  toggleContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    borderLeft: `1px solid ${theme.palette.new.grey_a}`,
    padding: theme.spacing(0, 4),
    '& .MuiFormControlLabel-label': {
      fontSize: '12px',
    },
    [theme.breakpoints.down('xl')]: {
      padding: theme.spacing(0, 2),
    },
  },
}))

export default useStyles
