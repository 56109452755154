import throttle from 'lodash.throttle'
import { checkApiForError } from '@utils/redux.utils'
import { USE_CASE } from '@constants/api-provider.constants'
import { EMITTER_TYPES, DEFAULT_THROTTLE_TIMEOUT } from '@constants/emitter.constants'
import eventEmitter from '@utils/emitter.utils'
import apiProvider from '@redux/api-provider'

import {
  EditEvaluationPlotsPayload,
  GetEvaluationPlotDownloadLinkPayload,
  ListEvaluationPlotsPayload,
  RemoveEvaluationPlotPayload,
  UploadEvaluationPlotsPayload,
} from './evaluation-plots.types'

export const getEvaluationPlotDownloadLink = (payload: GetEvaluationPlotDownloadLinkPayload = {} as GetEvaluationPlotDownloadLinkPayload) => {
  const URL = 'v6/evaluationPlot/download-link/get'

  return apiProvider.getApi(USE_CASE).post(URL, payload).then(({ data }) => {
    checkApiForError(data)

    return data.data.downloadLink
  })
}

export const listEvaluationPlots = (payload: ListEvaluationPlotsPayload = {} as ListEvaluationPlotsPayload) => {
  const URL = 'v7/evaluationPlot/list'

  return apiProvider.getApi(USE_CASE).post(URL, payload).then(({ data }) => {
    checkApiForError(data)

    return data.data.evaluationPlots
  })
}

export const listEvaluationPlotsWithId = (payload: ListEvaluationPlotsPayload = {} as ListEvaluationPlotsPayload) => {
  const URL = 'v7/evaluationPlot/list'

  return apiProvider.getApi(USE_CASE).post(URL, payload).then(({ data }) => {
    checkApiForError(data)

    return {
      useCaseId: payload.useCaseId,
      evaluationPlots: data.data.evaluationPlots,
    }
  })
}

export const uploadEvaluationPlots = (payload: UploadEvaluationPlotsPayload = {} as UploadEvaluationPlotsPayload) => {
  const URL = 'v8/evaluationPlot/upload'

  return apiProvider.getApi(USE_CASE).post(URL, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    timeout: 0,
    onUploadProgress: throttle((progressEvent) => {
      eventEmitter.emit(
        EMITTER_TYPES.FILE_UPLOAD_PROGRESS,
        {
          name: payload.fileName,
          total: progressEvent.total,
          loaded: progressEvent.loaded,
        },
      )
    }, DEFAULT_THROTTLE_TIMEOUT),
  }).then(({ data }) => {
    checkApiForError(data)

    const filesDetais = data.data || {}

    return filesDetais
  })
}

export const editEvaluationPlots = (payload: EditEvaluationPlotsPayload = {} as EditEvaluationPlotsPayload) => {
  const URL = 'v7/evaluationPlot/edit'

  return apiProvider.getApi(USE_CASE).post(URL, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    timeout: 0,
    onUploadProgress: throttle((progressEvent) => {
      eventEmitter.emit(
        EMITTER_TYPES.FILE_UPLOAD_PROGRESS,
        {
          name: payload.fileName,
          total: progressEvent.total,
          loaded: progressEvent.loaded,
        },
      )
    }, DEFAULT_THROTTLE_TIMEOUT),
  }).then(({ data }) => {
    checkApiForError(data)

    const filesDetais = data.data || {}

    return filesDetais
  })
}

export const removeEvaluationPlot = (payload: RemoveEvaluationPlotPayload) => {
  const URL = 'v6/evaluationPlot/delete'

  return apiProvider.getApi(USE_CASE).post(URL, payload).then(({ data }) => {
    checkApiForError(data)
  })
}
