import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { getInputsGroupHeight, getInputsGroupWidth } from '@utils/flow.utils'

const useStyles = makeStyles<{ numOfNodes: number }>()((theme: Theme, { numOfNodes }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: getInputsGroupWidth(numOfNodes),
    height: getInputsGroupHeight(numOfNodes),
    background: theme.palette.new.white,
    border: '1px solid',
    borderColor: theme.palette.new.grey_a,
    borderRadius: theme.spacing(0.5),
    position: 'relative',
    cursor: 'default',
  },
  content: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: '100%',
  },
  inputParametersContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    '& div > div': {
      marginRight: theme.spacing(2),
    },
    '& div:last-child': {
      marginRight: 0,
    },
  },
  inputParametersChunk: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
  },
}))

export default useStyles
