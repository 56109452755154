import React from 'react'

import { useSelector } from '@redux/hooks'
import { getBacktestingAbcTotals, getBacktestingTable, isFetchingBacktestingTable } from '@redux/modules/monitor/monitor.selectors'
import { requestBacktestingGridStateChangeAction } from '@redux/modules/monitor/monitor.actions'
import { transformMonitorTableColumns } from '@utils/monitor.utils'

import {
  DEFAULT_BACKTEST_TARGET_COLUMN,
  DEFAULT_BACKTEST_PREDICTION_COLUMN,
  DEFAULT_REL_DEVIATION_COLUMN,
  DEFAULT_ABS_DEVIATION_COLUMN,
} from '@constants/insights.constants'

import InsightsTableComponent from '@components/(features)/insights/insights-table/InsightsTable.component'

const BacktestingTableContainer = () => {
  const backtestingTable = useSelector((state) => getBacktestingTable(state))
  const isFetching = useSelector((state) => isFetchingBacktestingTable(state))
  const abcTotals = useSelector((state) => getBacktestingAbcTotals(state))

  const {
    tableId,
    rows,
    rowCount,
    columns,
    gridState,
  } = backtestingTable

  const alwaysVisibleColumns = [
    DEFAULT_BACKTEST_TARGET_COLUMN,
    DEFAULT_BACKTEST_PREDICTION_COLUMN,
    DEFAULT_ABS_DEVIATION_COLUMN,
    DEFAULT_REL_DEVIATION_COLUMN,
  ]

  return (
    <InsightsTableComponent
      tableId={tableId}
      rows={rows}
      rowCount={rowCount}
      totalRowCount={abcTotals.total}
      columns={columns}
      gridState={gridState}
      isFetching={isFetching}
      requestGridStateChangeAction={requestBacktestingGridStateChangeAction}
      columnTransformer={transformMonitorTableColumns}
      defaultSortingColumn={DEFAULT_BACKTEST_PREDICTION_COLUMN}
      alwaysVisibleColumns={alwaysVisibleColumns}
    />
  )
}

export default BacktestingTableContainer
