import React, { lazy, useState, useTransition } from 'react'
import { useDispatch, useSelector } from '@redux/hooks'
import { Box, Typography, useTheme } from '@mui/material'
import { useIntl } from 'react-intl'
import { v4 as uuidv4 } from 'uuid'

import ChatIcon from '@icons/flow/chat.icon'
import MemoryTooltipComponent from '@base/tooltips/MemoryTooltip'
import TopBarButtonComponent from '@base/topbar/TopBarButton'
import TopBarDividerComponent from '@base/topbar/TopBarDivider'

import { DEFAULT_PADDING } from '@constants/ui.constants'
import { getChatbotState } from '@redux/modules/common/common.selectors'
import { changeChatbotStateAction } from '@redux/modules/common/common.actions'
import { MEMORY_TOOLTIP_IDS } from '@constants/tooltips.constants'
import { setMemoryTooltipState } from '@utils/local-storage.utils'
import { TRACKING_MODULES } from '@utils/tracking.utils'
import { getIsChatUser } from '@redux/modules/customer/customer.selectors'

import useStyles from './ChatSlider.styles'

/* Lazy load, syntax highlighter is pretty big. + not used by every user */
const ChatWithSocratesContainer = lazy(() => import('@containers/application/ChatWithSocrates'))

const ChatSliderContainer: React.FC<{
  onChatWidthChange: (width: number) => void,
  chatWidth: number,
}> = ({
  onChatWidthChange,
  chatWidth,
}) => {
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const intl = useIntl()
  const theme = useTheme()

  const [chatMessage, setChatMessage] = useState('')
  const [sessionId, setSessionId] = useState<string>(uuidv4())
  const [, startTransition] = useTransition()
  const [load, setLoad] = useState(false)

  const chatbotState = useSelector((state) => getChatbotState(state))
  const isChatUser = useSelector((state) => getIsChatUser(state))

  const handleChatIconClick = () => {
    startTransition(() => {
      setLoad(true)

      setSessionId(uuidv4())

      dispatch(changeChatbotStateAction(!chatbotState))

      setMemoryTooltipState(MEMORY_TOOLTIP_IDS.CHAT_BOT)
    })
  }

  const tooltipStyles = {
    width: 'auto',
    maxWidth: 'max-content',
    transform: 'translate(-30px, 5px)',
    borderRadius: theme.spacing(1, 0, 1, 1),
  }

  const buttonStyles = {
    ...(chatbotState ? {
      width: 0,
      padding: 0,
      minWidth: 0,
      marginLeft: 0,
      marginRight: 0,
    } : {
      marginRight: DEFAULT_PADDING.MEDIUM,
      marginLeft: DEFAULT_PADDING.MEDIUM,
    }),
  }

  if (!isChatUser) {
    return null
  }

  return (
    <>
      <TopBarDividerComponent
        mr={false}
      />

      <MemoryTooltipComponent
        id={MEMORY_TOOLTIP_IDS.CHAT_BOT}
        arrow={false}
        open={chatbotState ? false : undefined}
        placement='bottom'
        timeout={0}
        tooltipSx={tooltipStyles}
        title={(
          <Box className={classes.tooltip} onClick={handleChatIconClick}>
            <Typography className={classes.tooltipInvite}>
              {intl.formatMessage({ id: 'chat.invite' })}
            </Typography>
            <Typography className={classes.tooltipSocrates}>
              {intl.formatMessage({ id: 'chat.title' })}
            </Typography>
            <Typography className={classes.tooltipBeta}>
              {intl.formatMessage({ id: 'chat.beta' })}
            </Typography>

            <Box className={classes.tooltipArrow} />
          </Box>
        )}
      >
        <TopBarButtonComponent
          name='chatButton'
          IconComponent={ChatIcon}
          onClick={handleChatIconClick}
          trackingProps={{ moduleName: TRACKING_MODULES.TOP_BAR }}
          label={intl.formatMessage({ id: 'chat.title' })}
          sx={buttonStyles}
        />
      </MemoryTooltipComponent>

      {
        load ? (
          <ChatWithSocratesContainer
            chatMessage={chatMessage}
            dialogOpen={chatbotState}
            handleClose={handleChatIconClick}
            onChatWidthChange={onChatWidthChange}
            onChatMessageChange={setChatMessage}
            chatWidth={chatWidth}
            sessionId={sessionId}
          />
        ) : (
          null
        )
      }
    </>
  )
}

export default ChatSliderContainer
