import React from 'react'
import { Position } from '@xyflow/react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { createId } from '@utils/common.utils'
import { Box } from '@mui/material'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { CONNECT_PARAMETER_CREATION_MODAL_NAME } from '@constants/modals.constants'
import { requestParameterOverviewAction } from '@redux/modules/parameters/parameters.actions'
import { getUseCaseItem } from '@redux/modules/use-case/use-case.selectors'
import { SOURCE_TYPES, TARGET_PARAMETER_PER_ROW, TRIPPLE_CONNECTOR_STYLES } from '@constants/flow.constants'
import { ConnectParameterCreationModalDetails } from '@containers/modals/connect-parameter-creation-modal/ConnectParameterCreationModal.container'

import ReactFlowAddNewButtonComponent from '@components/connect-view/flow/ReactFlowAddNewButton'
import ReactFlowBlockHeaderComponent from '@components/connect-view/flow/ReactFlowBlockHeader'
import ReactFlowParameterBlockComponent, { PARAMETER_BLOCK_TYPES } from '@components/connect-view/flow/ReactFlowParameterBlock'
import ReactFlowHandleComponent from '@components/connect-view/flow/ReactFlowHandle'

import useStyles from './ReactFlowTargetsGroupNode.styles'

export interface ReactFlowTargetsNodeComponentProps {
  /**
   * Data for the node
   */
  data?: {
    /**
     * Number of input types. Determines the number of flow handles
     *
     * @default 0
     */
    numOfInputTypes?: number,
  }
}

const ReactFlowTargetsNodeComponent: React.FC<ReactFlowTargetsNodeComponentProps> = ({ data }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const useCase = useSelector((state) => getUseCaseItem(state))
  const outputParameters = (useCase.outputParameters || [])
  const { numOfInputTypes = 0 } = data || {}
  const numOfNodes = outputParameters.length
  const { classes } = useStyles({ numOfNodes })
  const targetChunks = []
  const flowHandles = []
  const isAddButtonAvailable = Boolean(numOfNodes)

  for (let i = 0; i < outputParameters.length; i += TARGET_PARAMETER_PER_ROW) {
    const chunk = outputParameters.slice(i, i + TARGET_PARAMETER_PER_ROW)
    targetChunks.push(chunk)
  }

  for (let i = 0; i < numOfInputTypes; i += 1) {
    flowHandles.push((
      <ReactFlowHandleComponent
        type='target'
        position={Position.Left}
        id={String(i + 1)}
        key={String(i + 1)}
        style={TRIPPLE_CONNECTOR_STYLES[i]}
      />
    ))
  }

  const onAddButtonClick = () => {
    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: CONNECT_PARAMETER_CREATION_MODAL_NAME,
        modalDetails: {
          parameterType: PARAMETER_BLOCK_TYPES.TARGET,
          returnTo: '',
        } as ConnectParameterCreationModalDetails,
      }),
    )
  }

  const onParameterClick = (parameterItem: UseCase.OutputParameter) => {
    dispatch(
      requestParameterOverviewAction(parameterItem),
    )
  }

  return (
    <Box className={classes.root} data-testid={ReactFlowTargetsNodeComponent.name}>
      {flowHandles}

      <Box className={classes.content}>
        <ReactFlowBlockHeaderComponent
          name={intl.formatMessage({ id: 'connect.block.output' })}
          help={intl.formatMessage({ id: 'connect.block.output.help' })}
          onAddButtonClick={onAddButtonClick}
          isAddButtonAvailable={isAddButtonAvailable}
          white={true}
          parentComponentName={ReactFlowTargetsNodeComponent.name}
        />

        {
          numOfNodes ? (
            <Box className={classes.outputParametersContainer}>
              {
                targetChunks.map((chunk, index) => {
                  return (
                    <Box className={classes.outputParametersChunk} key={createId(index, 'chunk')}>
                      {
                        chunk.map((item: UseCase.OutputParameter, chunkIndex: number) => {
                          const {
                            name,
                            sourceType,
                            unitLabel,
                            modelOutputParameterId,
                          } = item

                          return (
                            <ReactFlowParameterBlockComponent
                              key={createId(index, name, modelOutputParameterId)}
                              name={name}
                              sourceType={sourceType as SOURCE_TYPES}
                              unitLabel={unitLabel}
                              type={PARAMETER_BLOCK_TYPES.TARGET}
                              onClick={() => onParameterClick(item)}
                              dataTestId={`${ReactFlowParameterBlockComponent.name}-${ReactFlowTargetsNodeComponent.name}-${index}`}
                            />
                          )
                        })
                      }
                    </Box>
                  )
                })
              }
            </Box>
          ) : (
            <ReactFlowAddNewButtonComponent
              buttonText={intl.formatMessage({ id: 'connect.block.output.add' })}
              type={PARAMETER_BLOCK_TYPES.TARGET}
              onAddButtonClick={onAddButtonClick}
              parentComponentName={ReactFlowTargetsNodeComponent.name}
            />
          )
        }
      </Box>
    </Box>
  )
}

export default ReactFlowTargetsNodeComponent
