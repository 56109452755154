import React from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@mui/material'

import {
  SOURCE_TYPES, INPUT_TYPES,
  SOURCE_TYPES_TO_ICONS_MAP,
  SOURCE_TYPES_TO_WHITE_ICONS_MAP,
  NO_UNIT_LABEL_PLACEHOLDER,
} from '@constants/flow.constants'

import { trackEvent, TRACKING_ACTIONS } from '@utils/tracking.utils'

import useStyles from './ReactFlowParameterBlock.styles'

export enum PARAMETER_BLOCK_TYPES {
  ACTIVE_INPUT = 'ACTIVE_INPUT',
  PASSIVE_INPUT = 'PASSIVE_INPUT',
  GENERIC_INPUT = 'GENERIC_INPUT',
  TARGET = 'TARGET',
  GROUPING_ATTRIBUTE = 'GROUPING_ATTRIBUTE',
}

export const getParameterBlockByInputType = (inputType: INPUT_TYPES): PARAMETER_BLOCK_TYPES => {
  if (inputType === INPUT_TYPES.PASSIVE) {
    return PARAMETER_BLOCK_TYPES.PASSIVE_INPUT
  }

  if (inputType === INPUT_TYPES.ACTIVE) {
    return PARAMETER_BLOCK_TYPES.ACTIVE_INPUT
  }

  if (inputType === INPUT_TYPES.GENERIC) {
    return PARAMETER_BLOCK_TYPES.GENERIC_INPUT
  }

  if (inputType === INPUT_TYPES.GROUPING) {
    return PARAMETER_BLOCK_TYPES.GROUPING_ATTRIBUTE
  }

  return PARAMETER_BLOCK_TYPES.TARGET
}

export interface ReactFlowParameterBlockComponentProps {
  /**
   * Element's type
   */
  type?: PARAMETER_BLOCK_TYPES,
  /**
   * Element's onClick handler
   */
  onClick?: {
    (e?: React.SyntheticEvent): any,
  },
  /**
   * Title of the element
   */
  name?: string,
  /**
   * Unit label of the element
   */
  unitLabel?: string,
  /**
   * If true, allows to use the element outside of the react-flow
   */
  nonFlowUsage?: boolean,
  /**
   * Element's source type
   */
  sourceType?: SOURCE_TYPES,
  /**
   * List of filter values. Used for grouping attributes
   */
  filterValues?: any[],
  /**
   * Data Test ID for e2e tests
   */
  dataTestId?: string,
}

const ReactFlowParameterBlockComponent: React.FC<ReactFlowParameterBlockComponentProps> = ({
  type = '' as PARAMETER_BLOCK_TYPES,
  onClick,
  name,
  unitLabel = '',
  nonFlowUsage = false,
  sourceType = SOURCE_TYPES.CUSTOM,
  filterValues = [],
  dataTestId = '',
}) => {
  const { classes, cx } = useStyles()
  const intl = useIntl()
  const isTarget = type === PARAMETER_BLOCK_TYPES.TARGET
  const isActiveInput = type === PARAMETER_BLOCK_TYPES.ACTIVE_INPUT
  const IconComponent = SOURCE_TYPES_TO_ICONS_MAP[sourceType]
  const WhiteIconComponent = SOURCE_TYPES_TO_WHITE_ICONS_MAP[sourceType]
  const isInactive = sourceType === SOURCE_TYPES.NO_CONNECTION
  const isGroupingAttribute = type === PARAMETER_BLOCK_TYPES.GROUPING_ATTRIBUTE
  const filterValuesCount = ((filterValues || []).length)
  const shouldDisplayItemsCount = isGroupingAttribute && !isInactive

  const handleOnClick = (e: React.MouseEvent) => {
    trackEvent({
      componentName: 'parameterBlock',
      actionName: TRACKING_ACTIONS.CLICK,
    }, {
      name,
      type,
      sourceType,
    })

    if (onClick) {
      onClick(e)
    }
  }

  return (
    <Box
      data-testid={dataTestId || ReactFlowParameterBlockComponent.name}
      className={cx({
        [classes.inputParameterItem]: [PARAMETER_BLOCK_TYPES.PASSIVE_INPUT, PARAMETER_BLOCK_TYPES.GROUPING_ATTRIBUTE, PARAMETER_BLOCK_TYPES.GENERIC_INPUT].includes(type),
        [classes.activeInputParameterItem]: isActiveInput,
        [classes.outputParameterItem]: isTarget,
        [classes.nonFlowUsage]: nonFlowUsage && isTarget,
        [classes.activeCursor]: Boolean(onClick),
        [classes.isInactive]: isInactive && !isTarget,
        [classes.isTargetInactive]: isInactive && isTarget,
        [classes.isActiveInputInactive]: isInactive && isActiveInput,
      })}
      onClick={handleOnClick}
      role='button'
      tabIndex={0}
    >
      <Typography
        variant='body1'
        className={cx({
          [classes.name]: [
            PARAMETER_BLOCK_TYPES.ACTIVE_INPUT,
            PARAMETER_BLOCK_TYPES.PASSIVE_INPUT,
            PARAMETER_BLOCK_TYPES.GROUPING_ATTRIBUTE,
            PARAMETER_BLOCK_TYPES.GENERIC_INPUT,
          ].includes(type),
          [classes.targetName]: isTarget,
        })}
        title={name}
      >
        {name}
      </Typography>
      <Box
        className={cx(classes.parameterFooter, {
          [classes.activeParameterFooter]: isActiveInput,
          [classes.outputParameterFooter]: isTarget,
        })}
      >
        <Typography
          variant='overline'
          className={cx(classes.footerUnits, {
            [classes.footerUnitsInactive]: isInactive,
          })}
          noWrap={true}
        >
          {isInactive ? intl.formatMessage({ id: 'connect.block.input.inactive' }) : (unitLabel || NO_UNIT_LABEL_PLACEHOLDER)}
        </Typography>
        <Box className={classes.footerIcon}>
          {
            shouldDisplayItemsCount ? (
              <Typography
                variant='overline'
                className={cx(classes.footerFilterItemsCount, {
                  [classes.footerFilterAllItems]: filterValuesCount === 0,
                })}
                noWrap={true}
              >
                {
                  filterValuesCount ?
                    intl.formatMessage({ id: 'connect.block.grouping.items' }, { filterValuesCount }) :
                    intl.formatMessage({ id: 'connect.block.grouping.all_items' })
                }
              </Typography>
            ) : (
              null
            )
          }
          {isTarget ? <WhiteIconComponent /> : <IconComponent /> }
        </Box>
      </Box>
    </Box>
  )
}

export default ReactFlowParameterBlockComponent
