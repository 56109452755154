import { Theme } from '@mui/material'

const styles = ((theme: Theme, {
  expandedColor = '#FB6C4A0D',
  maxDefaultCellWidth = '100px',
}, classnames: any) => ({
  overlayFetching: {
    opacity: 0.6,
    pointerEvents: 'none',
  },
  wrapper: {
    border: `1px solid ${theme.palette.new.grey_b}`,
    borderRadius: theme.spacing(1),
  },
  rootTableWrapper: {
    overflowX: 'auto',
  },
  rootWrapper: {
    display: 'flex',
    flexDirection: 'column',
    fontVariantNumeric: 'tabular-nums',
  },
  rightBorder: {
    borderRight: `1px solid ${theme.palette.new.grey_b}`,
  },
  withHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  withTooltip: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tooltipWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '16px',
    marginRight: theme.spacing(1),
  },
  columnHeader: {
    fontSize: '16px',
    fontWeight: 400,
    color: theme.palette.new.black_d,
    textTransform: 'none',
  },
  columnLabel: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'inherit',
  },
  columnLabelText: {
    fontSize: '18px',
    fontWeight: 500,
    color: theme.palette.new.black_d,
    textTransform: 'none',
  },
  sortingActive: {
    fontSize: '18px',
    fontWeight: 500,
    color: theme.palette.new.black,
    [`& .${classnames.columnLabelText}`]: {
      fontSize: '18px',
      fontWeight: 500,
      color: theme.palette.new.black,
    },
    [`& .${classnames.columnHeader}`]: {
      fontSize: '16px',
      fontWeight: 400,
      color: theme.palette.new.black_d,
    },
  },
  paginationRoot: {
    '&.MuiTablePagination-root': {
      height: theme.spacing(6),
      alignItems: 'flex-end',
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '& .MuiTablePagination-toolbar': {
    },
  },
  root: {
    color: 'inherit',
    '& .MuiTableCell-head': {
      height: theme.spacing(6),
    },
    '& .MuiTableCell-root': {
      height: theme.spacing(6),
      padding: theme.spacing(1.5, 3),
      [theme.breakpoints.down('xl')]: {
        padding: theme.spacing(1.5, 3),
      },
    },
  },
  row: {
    '&[data-hidden-row="true"]:last-child': {
      '& .MuiTableCell-root': {
        borderBottom: 'none',
      },
    },
    '&.MuiTableRow-root.MuiTableRow-hover': {
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
      },
    },
  },
  lastRow: {
    '& .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
  withoutPagination: {
    '&:last-child': {
      '& .MuiTableCell-root': {
        borderBottom: 'none',
      },
    },
  },
  expanded: {
    backgroundColor: expandedColor,
    '&:hover': {
      backgroundColor: `${expandedColor} !important`,
    },
  },
  fixedCell: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: maxDefaultCellWidth,
  },
  tableHead: {
    textTransform: 'uppercase',
  },
  sorting: {
    cursor: 'pointer',
  },
  th: {
  },
  thIcon: {
    fontSize: '24px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '16px',
    },
  },
  thLeft: {},
  trLeft: {},
  thRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  trRight: {},
  firstCol: {
    fontSize: '18px',
    fontWeight: 400,
    color: theme.palette.new.black,
  },
  anyCol: {
    fontSize: '18px',
    fontWeight: 400,
    color: theme.palette.new.black,
  },
  sortingIcons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
  },
  copyIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    '& svg': {
      '&:hover': {
        opacity: '0.6',
      },
    },
  },
  copyIconLeft: {
    justifyContent: 'flex-start',
  },
  onRowClick: {
    cursor: 'pointer',
  },
  progressContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  progressIcon: {
    animation: '$spin 4s linear infinite',
  },
  progressFilename: {
    fontSize: '18px',
    fontWeight: 400,
    color: theme.palette.new.black,
    marginLeft: theme.spacing(1),
  },
  '@keyframes spin': {
    '100%': {
      WebkitTransform: 'rotate(-360deg)',
      transform: 'rotate(-360deg)',
    },
  },
  withHeaderBlock: {
    borderTop: `1px solid ${theme.palette.new.grey_b}`,
  },
}))

export default styles
