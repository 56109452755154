import React, {
  useCallback, useEffect,
  useMemo, useState,
} from 'react'

import { useIntl } from 'react-intl'
import { Drawer, Box, useTheme } from '@mui/material'

import SidePanelRouterPromptComponent from '@base/sidepanel/SidePanelRouterPrompt'
import SidePanelHeaderComponent from '@base/sidepanel/SidePanelHeader'
import { getSidePanelMaxWidth, setSidePanelMaxWidth } from '@utils/local-storage.utils'

export interface SidePanelProps {
  /**
   * if true, the side panel is open
   */
  open?: boolean,
  /**
   * title of the side panel
   */
  title?: string | React.ReactNode,
  /**
   * blocks to be displayed on the right side of the header
   */
  headerRightSideBlocks?: string | React.ReactNode
  /**
   * content of the side panel
   */
  children?: React.ReactNode
  /**
   * function to be called when the side panel is closed
   */
  handleClose?: {
    (): any,
  },
  /**
   * if true, the side panel cannot be closed by clicking the backdrop
   */
  disableBackdropClose?: boolean,
  /**
   * if true, the side panel has unsaved changes
   * Works only if withPrompt is true
   */
  hasUnsavedChanges?: boolean,
  /**
   * if true, the side panel has a prompt when closing and hasUnsavedChanges is true
   */
  withPrompt?: boolean,
  /**
   * custom prompt message to be displayed when closing the side panel
   * Works only if withPrompt is true
   */
  customPromptMessage?: string,
  /**
   * array of allowed redirects when the prompt is active
   * so the prompt will not be displayed when redirecting to these paths
   */
  allowedRedirects?: string[],
  /**
   * if true, the side panel is loading
   */
  loading?: boolean,
  /**
   * if true, the side panel is global, so prompt has to be handled differently
   */
  isGlobalSidePanel?: boolean,
  /**
   * function to reset the global side panel.
   * Works only if isGlobalSidePanel is true and prompt is active
   */
  resetGlobalSidePanel?: {
    (): any,
  },
  /**
   * custom icon to be displayed on the left side of the header
   */
  SidePanelIcon?: React.ElementType,
}

export const SIDE_PANEL_RIGHT_SPACING = 180
export const SIDE_PANEL_MIN_WIDTH = 1100

export const SidePanelComponent: React.FC<SidePanelProps> = ({
  open,
  title,
  headerRightSideBlocks,
  children,
  handleClose,
  resetGlobalSidePanel,
  disableBackdropClose,
  SidePanelIcon,
  customPromptMessage,
  hasUnsavedChanges = false,
  withPrompt = true,
  loading = false,
  isGlobalSidePanel = false,
  allowedRedirects = [],
}) => {
  const intl = useIntl()
  const theme = useTheme()
  const [isResizing, setIsResizing] = useState(false)
  const [maxWidth, setMaxWidth] = useState<number | null>(getSidePanelMaxWidth())
  const isPromptActive = Boolean(withPrompt && open && hasUnsavedChanges)

  const modalTitle = useMemo(() => {
    if (loading) {
      return intl.formatMessage({ id: 'common.loading' })
    }

    return title
  }, [
    intl,
    title,
    loading,
  ])

  const onClose = (e: React.SyntheticEvent, reason: any) => {
    if (disableBackdropClose && (reason === 'backdropClick')) {
      return
    } else if (isPromptActive && ((reason === 'backdropClick') || (reason === 'escapeKeyDown'))) {
      // eslint-disable-next-line
      const answer = confirm(customPromptMessage || intl.formatMessage({ id: 'common.form.leave' }))

      if (answer && handleClose) {
        handleClose()
      } else {
        return
      }
    }

    if (handleClose) {
      handleClose()
    }
  }

  const drawerSx = {
    zIndex: theme.zIndex.drawer + 100,
    '& .MuiDrawer-paper': {
      boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.15)',
    },
  }

  const contentSx = useMemo(() => ({
    minWidth: `${SIDE_PANEL_MIN_WIDTH}px`,
    maxWidth: maxWidth || '100%',
    borderRadius: 'unset',
    width: `calc(100vw - ${SIDE_PANEL_RIGHT_SPACING}px)`,
  }), [maxWidth])

  const resizeLineSx = useMemo(() => ({
    position: 'absolute',
    left: 0,
    height: '100%',
    width: '7px',
    cursor: 'col-resize',
    zIndex: theme.zIndex.drawer + 100,
    ...(isResizing ? {
      opacity: 0.4,
      borderLeft: `7px solid ${theme.palette.new.versatile_violet}`,
    } : {}),
    '&:hover': {
      opacity: 0.4,
      borderLeft: `7px solid ${theme.palette.new.versatile_violet}`,
    },
    '&:active': {
      opacity: 1,
      borderLeft: `7px solid ${theme.palette.new.versatile_violet}`,
    },
  }), [theme, isResizing])

  const handleMouseMove = useCallback((e: MouseEvent) => {
    if (!open) {
      return
    }

    if (!isResizing) {
      return
    }

    const offsetRight = document.body.offsetWidth - (e.clientX - document.body.offsetLeft)
    const offsetLeft = document.body.offsetWidth - offsetRight

    if (offsetRight > SIDE_PANEL_MIN_WIDTH && offsetLeft > SIDE_PANEL_RIGHT_SPACING) {
      setMaxWidth(offsetRight)
    }
  }, [open, isResizing])

  const handleMouseUp = useCallback((e: MouseEvent) => {
    if (!isResizing) {
      return
    }

    if (!open) {
      return
    }

    setSidePanelMaxWidth(maxWidth)

    setIsResizing(false)

    document.body.style.cursor = 'initial'
    document.removeEventListener('mousemove', handleMouseMove)
    document.removeEventListener('mouseup', handleMouseUp)
  }, [open, maxWidth, isResizing, handleMouseMove])

  const handleMouseDown = useCallback((e: any) => {
    if (!open) {
      return
    }

    e.stopPropagation()
    e.preventDefault()

    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
    document.body.style.cursor = 'move'

    setIsResizing(true)
  }, [open, handleMouseMove, handleMouseUp])

  useEffect(() => {
    if (open) {
      setMaxWidth(getSidePanelMaxWidth())
    }
  }, [open])

  useEffect(() => {
    if (open) {
      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUp)
    }

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [open, handleMouseMove, handleMouseUp])

  return (
    <Drawer
      anchor='right'
      open={open}
      elevation={0}
      onClose={onClose}
      data-testid={SidePanelComponent.name}
      disableEnforceFocus={true}
      sx={drawerSx}
    >

      <Box
        sx={resizeLineSx}
        onMouseDown={handleMouseDown}
        role='button'
        aria-label='resize'
      />

      <Box
        sx={contentSx}
      >
        <SidePanelHeaderComponent
          title={modalTitle}
          rightSideBlocks={headerRightSideBlocks}
          SidePanelIcon={SidePanelIcon}
        />

        {children}
      </Box>

      <SidePanelRouterPromptComponent
        resetGlobalSidePanel={resetGlobalSidePanel}
        isGlobalSidePanel={isGlobalSidePanel}
        isPromptActive={isPromptActive}
        customPromptMessage={customPromptMessage}
        allowedRedirects={allowedRedirects}
      />
    </Drawer>
  )
}

export default SidePanelComponent
