import React, {
  useCallback,
  useMemo,
  useState,
} from 'react'

import { useIntl } from 'react-intl'
import { useSelector } from '@redux/hooks'
import { Prompt } from 'react-router-dom'

import DeleteIcon from '@icons/delete.icon'
import DownloadIcon from '@icons/download.icon'

import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { isUploadingArtifacts } from '@redux/modules/artifacts/artifacts.selectors'

import {
  generateActionsColumnDefinition,
  generateDateTimeColumnDefinition,
  generateFileSizeColumnDefinition,
  generateRegularColumnDefinition,
  getSimplifiedStringOperators,
} from '@utils/data-grid-cells.utils'

import DataGridComponent from '@base/datagrid/data-grid'
import DataGridActionButtonComponent from '@base/datagrid/data-grid-action-button'
import TextConfirmationDialogComponent from '@base/dialogs/TextConfirmationDialog'
import ViewIcon from '@icons/view.icon'

import { Box, Card } from '@mui/material'
import { DATA_GRIDS } from '@constants/data-grid.constants'
import { getDataGridId } from '@utils/data-grid.utils'
import { GRID_ACTIONS_COLUMN_TYPE, GridColDef, GridRowParams } from '@mui/x-data-grid-premium'
import { getCsvPreviewButtonLabel, isCsvPreviewDisabled } from '@utils/files.utils'

import useGridInitialState from '@hooks/useGridInitialState.hook'

export interface ExportOverviewComponentProps {
  list: Artifacts.ArtifactItem[],
  handleFileDownload: {
    (record: Artifacts.ArtifactItem): any,
  },
  handleFileDelete: {
    (record: Artifacts.ArtifactItem): any,
  },
  handleFilePreview: {
    (record: Artifacts.ArtifactItem): any,
  },
  isFetching?: boolean,
}

const ExportOverviewComponent: React.FC<ExportOverviewComponentProps> = (props) => {
  const {
    list = [],
    isFetching,
    handleFileDownload,
    handleFileDelete,
    handleFilePreview,
  } = props

  const intl = useIntl()
  const isAdmin = useSelector((state) => getIsAdmin(state))
  const isUploading = useSelector((state) => isUploadingArtifacts(state))

  const [removeFileDialogState, setRemoveFileDialogState] = useState<null | Artifacts.ArtifactItem>(null)
  const tableId = getDataGridId(DATA_GRIDS.EXPORT_TABLE, 2)
  const initialState = useGridInitialState(tableId, {
    pinnedColumns: {
      right: [GRID_ACTIONS_COLUMN_TYPE],
    },
    sorting: {
      sortModel: [{ field: 'updatedAt', sort: 'desc' }],
    },
  })

  const slotProps = {
    toolbar: {
      withExport: false,
    },
  }

  const getActionItems = useCallback((params: GridRowParams<Artifacts.ArtifactItem>) => {
    const actions = [
      (
        <DataGridActionButtonComponent
          name='previewFile'
          icon={<ViewIcon />}
          onClick={() => handleFilePreview(params.row)}
          label={getCsvPreviewButtonLabel(intl, params.row)}
          disabled={isCsvPreviewDisabled(params.row)}
          id={params.id}
        />
      ),
      (
        <DataGridActionButtonComponent
          name='downloadFile'
          icon={<DownloadIcon />}
          onClick={() => handleFileDownload(params.row)}
          label={intl.formatMessage({ id: 'common.tables.actions.download' })}
          id={params.id}
        />
      ),
    ]

    if (isAdmin) {
      actions.push(
        (
          <DataGridActionButtonComponent
            name='removeFile'
            icon={<DeleteIcon />}
            onClick={() => setRemoveFileDialogState(params.row)}
            label={intl.formatMessage({ id: 'common.tables.actions.delete' })}
            id={params.id}
          />
        ),
      )
    }
    return actions
  }, [intl, isAdmin, handleFilePreview, handleFileDownload])

  const columns = useMemo<GridColDef[]>(() => {
    return [
      generateRegularColumnDefinition({
        intl,
        field: 'fileName',
        headerName: intl.formatMessage({ id: 'export.table.fileName' }),
        gridColDefOverrides: {
          filterOperators: getSimplifiedStringOperators(intl),
        },
      }),
      generateDateTimeColumnDefinition({
        intl,
        field: 'updatedAt',
        headerName: intl.formatMessage({ id: 'export.table.uploadDate' }),
      }),
      generateFileSizeColumnDefinition({
        intl,
        field: 'fileSize',
        headerName: intl.formatMessage({ id: 'export.table.fileSize' }),
      }),
      generateActionsColumnDefinition({
        getActionItems,
        numberOfActions: isAdmin ? 3 : 2,
      }),
    ]
  }, [
    intl,
    isAdmin,
    getActionItems,
  ])

  return (
    <Card
      data-testid={ExportOverviewComponent.name}
      elevation={0}
    >
      <DataGridComponent
        key={tableId}
        id={tableId}
        name={DATA_GRIDS.EXPORT_TABLE}
        columns={columns}
        loading={isFetching}
        uploading={isUploading}
        rows={list}
        autoHeight={true}
        autosizeOnMount={true}
        disableVirtualization={true}
        enablePersistence={false}
        getRowId={(row) => row.artifactId}
        initialState={initialState}
        disableRowGrouping={true}
        slotProps={slotProps}
      />

      <Prompt
        when={isUploading}
        message={intl.formatMessage({ id: 'common.dropzone.leave' })}
      />

      <TextConfirmationDialogComponent
        open={Boolean(removeFileDialogState) && isAdmin}
        onClose={() => setRemoveFileDialogState(null)}
        onSubmit={() => handleFileDelete(removeFileDialogState!)}
        confirmationText={removeFileDialogState?.fileName}
        confirmationInputLabel={intl.formatMessage({ id: 'connect.block.data_input.table.removeFileDialog.confirmation' })}
        description={
          intl.formatMessage({ id: 'connect.block.data_input.table.removeFileDialog.content' }, {
            name: <Box component='strong'>{removeFileDialogState?.fileName}</Box>,
          })
        }
      />
    </Card>
  )
}

export default ExportOverviewComponent
