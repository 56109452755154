import React, {
  useLayoutEffect,
} from 'react'

import { useRouteMatch } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'

import PageLayoutContainer from '@containers/application/PageLayout'

import { getUseCaseItem } from '@redux/modules/use-case/use-case.selectors'
import { requestAnalyzeViewAction, resetAnalyzeViewAction } from '@redux/modules/analyze/analyze.actions'

import AnalyzeTableContainer from './analyze-table/AnalyzeTable.container'
import AnalyzeFiltersContainer from './analyze-filters/AnalyzeFilters.container'
import AnalyzeChartContainer from './analyze-chart/AnalyzeChart.container'

const AnalyzeContainer = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const match = useRouteMatch<Common.RouterMatch>()
  const useCase = useSelector((state) => getUseCaseItem(state))
  const useCaseId = match.params.usecase

  useLayoutEffect(() => {
    dispatch(
      requestAnalyzeViewAction({
        useCaseId,
      }),
    )

    return () => {
      dispatch(
        resetAnalyzeViewAction(),
      )
    }
  }, [dispatch, useCaseId])

  return (
    <PageLayoutContainer
      title={intl.formatMessage({ id: 'insights.browser_tab.title' }, { name: useCase.name })}
    >
      <AnalyzeChartContainer />

      <AnalyzeFiltersContainer />

      <AnalyzeTableContainer />
    </PageLayoutContainer>
  )
}

export default AnalyzeContainer
