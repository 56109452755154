import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0),

    [theme.breakpoints.down('xl')]: {
      padding: theme.spacing(0),
    },
  },
  restockQuantity: {
    color: theme.palette.new.versatile_violet,
    fontSize: '18px',
  },
  tableHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(0, 3),
    height: theme.spacing(8),
  },
  tableHeaderTitle: {
    fontWeight: 500,
    fontSize: '30px',
    lineHeight: '40px',
    [theme.breakpoints.down('md')]: {
      fontSize: '22px',
      lineHeight: 1,
    },
  },
  searchBlock: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  searchInput: {
  },
  exportButton: {
    whiteSpace: 'nowrap',
  },
}))

export default useStyles
