import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.palette.new.white,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  gridRoot: {
    display: 'grid',
    background: theme.palette.new.white,
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.new.grey_b}`,
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'repeat(2, 1fr)',
    gridRowGap: theme.spacing(0),
    gridColumnGap: theme.spacing(0),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  currentStockValue: {
    fontWeight: 500,
    fontSize: '48px',
    color: theme.palette.new.black,
    lineHeight: 1,
    marginTop: theme.spacing(1),
  },
  currentStockBlock: {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
  },
}))

export default useStyles
