import React, { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from '@redux/hooks'
import { useRouteMatch } from 'react-router-dom'

import PageLayoutContainer from '@containers/application/PageLayout'
import LiveMonitoringContainer from '@containers/pages/(demand-usecase)/monitor/live-monitoring'
import ActionButtonComponent from '@base/pagebar/ActionButton'
import BacktestingContainer from '@containers/pages/(demand-usecase)/monitor/back-testing'
import ExportIcon from '@icons/export.icon'

import { handleExport } from '@utils/export.utils'
import { EXPORT_AS_PNG } from '@constants/export.constants'
import { getUseCaseItem } from '@redux/modules/use-case/use-case.selectors'
import { MONITOR_LIVE_MONITORING_PATH, MONITOR_BACKTESTING_PATH } from '@constants/routes.constants'
import { setDefaultMonitorTab } from '@utils/local-storage.utils'

const EXPORT_ID = 'monitor'

const MonitorContainer: React.FC = () => {
  const intl = useIntl()
  const match = useRouteMatch<Common.RouterMatch>()

  const useCase = useSelector((state) => getUseCaseItem(state))
  const isLiveMode = match.path === MONITOR_LIVE_MONITORING_PATH

  const handleExportClick = useCallback(() => {
    handleExport({
      type: EXPORT_AS_PNG,
      fileName: intl.formatMessage({ id: 'monitor.live.browser_tab.title' }, { name: useCase?.name }),
      exportId: EXPORT_ID,
    })
  }, [useCase, intl])

  const pageBarRightSideBlocks = useMemo(() => {
    return (
      <ActionButtonComponent
        name='exportAsPng'
        onClick={handleExportClick}
        IconComponent={ExportIcon}
        label={intl.formatMessage({ id: 'common.button.export_png' })}
      />
    )
  }, [
    intl,
    handleExportClick,
  ])

  const tabs = [
    {
      label: intl.formatMessage({ id: 'monitor.tabs.backtesting' }),
      path: MONITOR_BACKTESTING_PATH,
    },
    {
      label: intl.formatMessage({ id: 'monitor.tabs.live' }),
      path: MONITOR_LIVE_MONITORING_PATH,
    },
  ]

  const onTabChange = (tab: Common.TabOption) => {
    setDefaultMonitorTab(tab.path)
  }

  const content = useMemo(() => {
    return isLiveMode ? (
      <LiveMonitoringContainer exportId={EXPORT_ID} />
    ) : (
      <BacktestingContainer exportId={EXPORT_ID} />
    )
  }, [isLiveMode])

  return (
    <PageLayoutContainer
      title={intl.formatMessage({ id: 'monitor.live.browser_tab.title' }, { name: useCase?.name })}
      pageBarRightSideBlocks={pageBarRightSideBlocks}
      tabs={tabs}
      onTabChange={onTabChange}
    >
      {content}
    </PageLayoutContainer>
  )
}

export default MonitorContainer
