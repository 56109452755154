import produce from 'immer'
import { ParametersState, ReducerPayload } from '@redux/modules/types'

export const initialState: ParametersState = {
  isFetching: false,
  isFetchedParameter: false,
  isFetchedGroupingParameter: false,
  fetchingKey: '',
  item: {} as UseCase.ParameterItem,
  groupingItem: {} as UseCase.GroupingParameter,
}

export const receive = (state: ParametersState, action: ReducerPayload<UseCase.ParameterItem>) => {
  const nextState = produce(state, (draftState) => {
    draftState.item = action.payload
    draftState.isFetchedParameter = true
  })

  return nextState
}

export const receiveGrouping = (state: ParametersState, action: ReducerPayload<UseCase.GroupingParameter>) => {
  const nextState = produce(state, (draftState) => {
    draftState.groupingItem = action.payload
    draftState.isFetchedGroupingParameter = true
  })

  return nextState
}

export const startFetching = (state: ParametersState, action: ReducerPayload<string>) => {
  const nextState = produce(state, (draftState) => {
    draftState.isFetching = true
    draftState.fetchingKey = draftState.fetchingKey ? draftState.fetchingKey : action.payload
  })

  return nextState
}

export const stopFetching = (state: ParametersState) => {
  const nextState = produce(state, (draftState) => {
    draftState.fetchingKey = ''
    draftState.isFetching = false
  })

  return nextState
}
