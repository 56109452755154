import React, { useState } from 'react'
import { useRouteMatch } from 'react-router-dom'

import {
  Menu, ListItemIcon, Typography,
  Box, SxProps, Theme, useTheme,
} from '@mui/material'

import { TRACKING_ACTIONS, trackEvent } from '@utils/tracking.utils'
import { createId } from '@utils/common.utils'

import ExportIcon from '@icons/export.icon'
import SelectMenuItemComponent from '@base/menus/SelectMenuItem'
import IconButtonComponent from '@base/buttons/IconButton'
import { DEFAULT_BORDER_RADIUS, DEFAULT_BOX_SHADOW } from '@constants/ui.constants'

export interface ActionsMenuAction {
  name: string,
  icon?: React.ReactNode,
  label: string,
  disabled?: boolean,
  handler?: () => void,
}

export interface ActionsMenuProps {
  sx?: SxProps<Theme>,
  label: string,
  tooltip?: boolean,
  IconComponent?: React.FC<Common.IconProps>,
  actions?: ActionsMenuAction[],
  iconComponentProps?: Common.IconProps,
}

const ActionsMenuComponent: React.FC<ActionsMenuProps> = ({
  actions = [],
  tooltip = true,
  label,
  sx,
  IconComponent = ExportIcon,
  iconComponentProps,
}) => {
  const theme = useTheme()
  const { params } = useRouteMatch<Common.RouterMatch>()
  const [actionMenu, setActionMenu] = useState<Element | null>(null)
  const isActive = Boolean(actionMenu)

  const handleActionIconClick = (e: React.SyntheticEvent) => {
    setActionMenu(e.currentTarget)
  }

  const handleActionClick = (e: React.SyntheticEvent, actionItem: ActionsMenuAction) => {
    e.preventDefault()

    setActionMenu(null)

    trackEvent({
      componentName: actionItem.name,
      actionName: TRACKING_ACTIONS.CLICK,
    }, {
      router: params,
    })

    if (actionItem.handler) {
      actionItem.handler()
    }
  }

  const buttonToRender = (
    <IconButtonComponent
      tooltip={tooltip}
      name='actionMenuComponent'
      IconComponent={IconComponent}
      iconComponentProps={iconComponentProps}
      onClick={(e) => handleActionIconClick(e)}
      label={label}
      color='secondary'
    />
  )

  return (
    <Box
      data-testid={ActionsMenuComponent.name}
    >
      {buttonToRender}

      <Menu
        anchorEl={actionMenu}
        open={isActive}
        onClose={() => setActionMenu(null)}
        sx={{
          '& .MuiBackdrop-root': {
            backgroundColor: 'transparent',
          },
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            sx: {
              transform: 'translate(-15px, -2px) !important',
              borderRadius: DEFAULT_BORDER_RADIUS.MEDIUM,
              boxShadow: DEFAULT_BOX_SHADOW,
              overflow: 'visible',
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                bottom: 'calc(50% - 15px)',
                right: '-5px',
                width: '15px',
                height: '15px',
                bgcolor: theme.palette.new.white,
                boxShadow: '5px -5px 8px rgba(0, 0, 0, 0.1)',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
      >
        {
          actions?.map((actionItem, actionIndex) => {
            return (
              <SelectMenuItemComponent
                key={createId(actionIndex)}
                data-testid={actionItem.name}
                disabled={actionItem.disabled}
                onClick={(e) => handleActionClick(e, actionItem)}
              >
                {
                  actionItem.icon ? (
                    <ListItemIcon
                      sx={{
                        minWidth: `${theme.spacing(4)} !important`,
                      }}
                    >
                      {actionItem.icon}
                    </ListItemIcon>
                  ) : (
                    null
                  )
                }

                <Typography
                  noWrap={true}
                  variant='body1'
                >
                  {actionItem.label}
                </Typography>
              </SelectMenuItemComponent>
            )
          })
        }
      </Menu>
    </Box>
  )
}

export default ActionsMenuComponent
