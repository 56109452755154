import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { IntlShape, useIntl } from 'react-intl'
import get from 'lodash.get'

import { Typography } from '@mui/material'

import {
  DEFAULT_CHARTS_FONT_FAMILY,
} from '@constants/analysis.constants'

import {
  getArrowHead, getCartesianGridProps,
  getTooltipProps,
  getXAxisProps, getXLabelProps,
  getYAxisProps, getYTextProps,
} from '@utils/svg.utils'

import { defaultNumberFormatter, formatNumberWithSuffixUnit } from '@utils/analysis.utils'
import { createId } from '@utils/common.utils'
import ChartTooltipContainerComponent from '@components/charts/ChartTooltipContainer'

import {
  ScatterChart, Scatter,
  XAxis, YAxis, Tooltip,
  ResponsiveContainer,
  Label, CartesianGrid,
  Text,
} from 'recharts'

import palette from '@configuration/theme/theme.palette'
import useStyles from './TradeOffsChart.styles'

const SHADOW_ID = 'filter0_d_4747_19103'
const TOOLTIP_ID = 'DecideTooltip'
const TOOLTIP_PARENT_ID = 'DecideTooltipParent'
const tickFormatter = (value: number) => {
  return defaultNumberFormatter(value)
}

const getFormatterByLabel = (axisLabel?: string, optimizeEnabled?: boolean) => {
  if (!optimizeEnabled) {
    return tickFormatter
  }

  return (value: any) => formatNumberWithSuffixUnit(value)
}

const getLabelByAxisLabel = (intl: IntlShape, axisLabel?: string, optimizeEnabled?: boolean) => {
  if (!optimizeEnabled) {
    return axisLabel
  }

  return axisLabel
}

export interface CustomTooltipProps {
  active?: boolean,
  classes: {
    [key: string]: any,
  },
  payload?: Socrates.TradeOffsDatasetItem[],
  optimizeEnabled?: boolean,
  intl: IntlShape,
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active, payload,
  classes, optimizeEnabled,
  intl,
}) => {
  if (active && payload && payload.length) {
    const DATA: Socrates.TradeOffsDatasetPayload = get(payload[0], 'payload.datasetPayload', {} as Socrates.TradeOffsDatasetPayload)
    const KPIS: Socrates.TradeOffsDatasetKpi[] = get(DATA, 'datasetKpis', [])
    const DESIGN: Socrates.TradeOffsDatasetDesign[] = get(DATA, 'datasetDesign', [])

    return (
      <ChartTooltipContainerComponent>
        <>
          {
            KPIS.map((kpiItem, index) => {
              const formattedValueOptimize = formatNumberWithSuffixUnit(kpiItem.value)

              const value = optimizeEnabled ? formattedValueOptimize : tickFormatter(kpiItem.value)

              return (
                <div className={classes.customTooltipDataSectionItem} key={createId(kpiItem.value, index)}>
                  <span className={classes.customTooltipDataSectionItemKeyKpi}>{kpiItem.label}</span>
                  <span className={classes.customTooltipDataSectionItemValueKpi}>{value}</span>
                </div>
              )
            })
          }
          {
            !optimizeEnabled && DESIGN.map((kpiItem, index) => {
              return (
                <div className={classes.customTooltipDataSectionItem} key={createId(kpiItem.value, index)}>
                  <span className={classes.customTooltipDataSectionItemKey}>{kpiItem.label}</span>
                  <span className={classes.customTooltipDataSectionItemValue}>{tickFormatter(kpiItem.value)}</span>
                </div>
              )
            })
          }
        </>
      </ChartTooltipContainerComponent>
    )
  }

  return null
}

interface CustomizedDotProps {
  cx?: number,
  cy?: number,
  stroke?: string,
  fill?: string,
  strokeWidth?: string,
  payload: Socrates.TradeOffsDatasetItem,
  intl: IntlShape,
  optimizeEnabled?: boolean,
}

const CustomizedDot: React.FC<CustomizedDotProps> = (props) => {
  const {
    cx = 0, cy = 0, stroke,
    fill, strokeWidth,
    payload, intl, optimizeEnabled,
  } = props

  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const name = get(payload, 'name', '')
  const selectedPointIndex = get(payload, 'selectedPointIndex', 0)
  const r = get(payload, 'radius', 3.5)
  const cursor = get(payload, 'cursor', 'pointer')
  const execute = get(payload, 'execute', false)
  const scaleFactor = isHovered ? 1.3 : 1

  if (execute && selectedPointIndex >= 0) {
    return (
      <g id={TOOLTIP_PARENT_ID}>
        <svg
          filter={`url(#${SHADOW_ID})`}
          x={cx - 107}
          y={cy - 95}
          id={TOOLTIP_ID}
        >
          <rect x='10' y='10' width='193' height='53' rx='10' fill='white' />
          <path d='M106.5 73L93.4978 60.4516L119.872 60.8185L106.5 73Z' fill='white' />
          <text
            x='105'
            y='38'
            textAnchor='middle'
            alignmentBaseline='middle'
            stroke={palette.new.pink}
            strokeWidth='0px'
            dy='0'
            fill={palette.new.pink}
            style={{
              fontFamily: DEFAULT_CHARTS_FONT_FAMILY,
              fontSize: '12px',
              textTransform: 'uppercase',
            }}
          >
            {
              intl.formatMessage({ id: optimizeEnabled ? 'trade_offs.details.choosen_strategy' : 'trade_offs.details.decided_to_execute' })
            }
          </text>
        </svg>

        <circle
          cx={cx}
          cy={cy}
          r={r}
          stroke={stroke}
          strokeWidth={strokeWidth}
          fill={palette.new.pink}
          style={{ cursor }}
          name={name}
        />
        <text
          x={cx}
          y={cy}
          textAnchor='middle'
          alignmentBaseline='middle'
          stroke='white'
          strokeWidth='1px'
          dy='.1em'
          style={{
            fontFamily: DEFAULT_CHARTS_FONT_FAMILY,
            fontFeatureSettings: "'tnum' on, 'lnum' on",
            fontSize: '10px',
            cursor,
          }}
        >
          {optimizeEnabled ? '' : (selectedPointIndex + 1)}
        </text>
      </g>
    )
  }

  if (selectedPointIndex >= 0) {
    return (
      <g>
        <circle
          cx={cx}
          cy={cy}
          r={r}
          stroke={stroke}
          strokeWidth={strokeWidth}
          fill={fill}
          style={{ cursor }}
          name={name}
        />
        <text
          x={cx}
          y={cy}
          textAnchor='middle'
          alignmentBaseline='middle'
          stroke='white'
          strokeWidth='1px'
          dy='.1em'
          style={{
            fontFamily: DEFAULT_CHARTS_FONT_FAMILY,
            fontFeatureSettings: "'tnum' on, 'lnum' on",
            fontSize: '10px',
            cursor,
          }}
        >
          {optimizeEnabled ? '' : (selectedPointIndex + 1)}
        </text>
      </g>
    )
  }

  return (
    <circle
      cx={cx}
      cy={cy}
      r={r * scaleFactor}
      stroke={stroke}
      strokeWidth={strokeWidth}
      fill={fill}
      style={{ cursor }}
      name={name}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  )
}

CustomizedDot.propTypes = {
  cx: PropTypes.number,
  cy: PropTypes.number,
  stroke: PropTypes.string,
  fill: PropTypes.string,
  strokeWidth: PropTypes.string,
  payload: PropTypes.any,
  intl: PropTypes.any,
  optimizeEnabled: PropTypes.bool,
}

interface TradeOffsChartComponentProps {
  dataset?: Socrates.TradeOffsDatasetItem[],
  loading?: boolean,
  xAxisLabel?: string,
  yAxisLabel?: string,
  handleSelect: {
    (payload: any): any
  }
  isDefaultConfig?: boolean,
  optimizeEnabled?: boolean,
}

/** Renders Scatter Chart for the Trade Offs */
const TradeOffsChartComponent: React.FC<TradeOffsChartComponentProps> = (props) => {
  const {
    dataset,
    loading,
    xAxisLabel,
    yAxisLabel,
    handleSelect,
    isDefaultConfig,
    optimizeEnabled,
  } = props

  const intl = useIntl()
  const { classes } = useStyles()

  const handlePointClick = (data: {
    payload: Socrates.TradeOffsDatasetItem
  }) => {
    const { payload } = data

    if (payload.datasetPayload.isParetoOptimal && handleSelect) {
      handleSelect({ payload, select: true })
    }
  }

  if (loading) {
    return (
      null
    )
  }

  if (!dataset || (dataset.length === 0)) {
    return (
      <div className={classes.emptyState}>
        <Typography className={classes.emptyStateText}>
          {intl.formatMessage({
            id: isDefaultConfig ? 'trade_offs.chart.config.noData' : 'trade_offs.chart.config.empty',
          })}
        </Typography>
      </div>
    )
  }

  const xLabel = getLabelByAxisLabel(intl, xAxisLabel, optimizeEnabled)
  const yLabel = getLabelByAxisLabel(intl, yAxisLabel, optimizeEnabled)
  const xDomain = ['auto', 'auto']
  const yDomain = ['auto', 'auto']

  return (
    <div className={classes.chartContainer} data-testid={TradeOffsChartComponent.name} id='trade-offs'>
      <ResponsiveContainer width='100%' height='100%'>
        <ScatterChart
          margin={{
            top: 40,
            right: 40,
            left: 40,
            bottom: 50,
          }}
        >
          <defs>
            <filter id={SHADOW_ID} x='0' y='0' width='213' height='83' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
              <feFlood floodOpacity='0' result='BackgroundImageFix' />
              <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
              <feOffset />
              <feGaussianBlur stdDeviation='5' />
              <feComposite in2='hardAlpha' operator='out' />
              <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0' />
              <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_4747_19103' />
              <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_4747_19103' result='shape' />
            </filter>
          </defs>

          <CartesianGrid
            {...getCartesianGridProps(true, true)}
          />

          <Tooltip
            {...getTooltipProps(true, { x: true, y: true })}
            content={<CustomTooltip intl={intl} classes={classes} optimizeEnabled={optimizeEnabled} />}
          />

          <XAxis
            {...getXAxisProps()}
            type='number'
            dataKey='x'
            scale='auto'
            domain={xDomain}
            minTickGap={0}
            interval={0}
            tickMargin={5}
            tickFormatter={getFormatterByLabel(xAxisLabel, optimizeEnabled) as any}
            padding={{ left: 0, right: 40 }}
          >
            <Label
              {...getXLabelProps()}
              position='insideBottomRight'
              style={{ transform: 'translate(15px, 40px)' }}
              value={xLabel}
            />
          </XAxis>

          <YAxis
            {...getYAxisProps()}
            type='number'
            dataKey='y'
            scale='auto'
            domain={yDomain}
            tickFormatter={getFormatterByLabel(yAxisLabel, optimizeEnabled) as any}
          >
            <Label
              content={(
                <Text
                  {...getYTextProps()}
                >
                  {yLabel}
                </Text>
              )}
            />
          </YAxis>

          {getArrowHead()}

          <Scatter
            data={dataset}
            // @ts-ignore-next-line
            shape={<CustomizedDot intl={intl} optimizeEnabled={optimizeEnabled} />}
            onClick={handlePointClick}
            isAnimationActive={false}
          />
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  )
}

TradeOffsChartComponent.propTypes = {
  /** If 'true', loading spinner will be shown */
  loading: PropTypes.bool,
  /** Chart data */
  dataset: PropTypes.any,
  /** If 'true', and there is no data - another message will be displayed  */
  isDefaultConfig: PropTypes.bool,
  /** Handles select of an point on chart */
  handleSelect: PropTypes.any,
  /** X Axis Label */
  xAxisLabel: PropTypes.string,
  /** Y Axis Label */
  yAxisLabel: PropTypes.string,
  /** If 'true', optimize view is enabled */
  optimizeEnabled: PropTypes.bool,
}

TradeOffsChartComponent.defaultProps = {
  dataset: [],
  loading: false,
  isDefaultConfig: true,
}

export default TradeOffsChartComponent
