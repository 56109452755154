import React from 'react'
import { IntlShape } from 'react-intl'
import { GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid-premium'
import { getTableStateFromStorage } from '@utils/data-grid.utils'
import { defaultNumberFormatter } from '@utils/analysis.utils'
import { createInsightsDataGridState } from '@utils/insights.utils'
import { DEFAULT_DATE_PICKER_FORMAT, toUnixTimestamp } from '@utils/moment.utils'
import { TIME_RESOLUTION } from '@constants/date.constants'
import { transformColumnLabel, getAllowedOperators } from '@redux/modules/hera/hera.utils'
import {
  DEFAULT_BACKTEST_TARGET_COLUMN,
  DEFAULT_BACKTEST_PREDICTION_COLUMN,
  DEFAULT_LIVE_MONITORING_TARGET_COLUMN,
  DEFAULT_LIVE_MONITORING_PREDICTION_COLUMN,
  DEFAULT_REL_DEVIATION_COLUMN,
  DEFAULT_ABS_DEVIATION_COLUMN,
} from '@constants/insights.constants'

import DataGridCustomColHeaderComponent from '@base/datagrid/data-grid-custom-col-header'
import IntlFormatBoldComponent from '@base/utils/IntlFormatBold/IntlFormatBold.component'

/**
 * Creates the initial data grid state
 *
 * @param tableId Table id
 *
 * @returns merged table configs
 */
export const createBacktestingDataGridState = (tableId: string, initialState: Monitor.BacktestingGridState) => {
  const tableStateFromStorage = getTableStateFromStorage<Monitor.BacktestingGridState>(tableId)
  const parsedLsState = (tableStateFromStorage?.localStorageState || {}) as Monitor.BacktestingGridState

  const customState: Partial<Monitor.BacktestingGridState> = {
    backtestingFold: parsedLsState?.backtestingFold || initialState.backtestingFold,
  }

  return createInsightsDataGridState<Monitor.BacktestingGridState>(tableId, initialState, customState)
}

/**
 * Creates the initial data grid state
 *
 * @param tableId Table id
 *
 * @returns merged table configs
 */
export const createLiveMonitoringDataGridState = (tableId: string, initialState: Monitor.LiveMonitoringGridState) => {
  const tableStateFromStorage = getTableStateFromStorage<Monitor.LiveMonitoringGridState>(tableId)
  const parsedLsState = (tableStateFromStorage?.localStorageState || {}) as Monitor.LiveMonitoringGridState

  const customState: Partial<Monitor.LiveMonitoringGridState> = {
    liveForecastHorizonOffset: parsedLsState?.liveForecastHorizonOffset || initialState.liveForecastHorizonOffset,
  }

  return createInsightsDataGridState<Monitor.LiveMonitoringGridState>(tableId, initialState, customState)
}

/**
 * Returns the transformed columns with custom header names and filters
 * @param intl React Intl
 * @param columns Columns
 * @returns Columns with custom header names and filters
 */
export const transformMonitorTableColumns = (intl: IntlShape, columns?: Hera.APITableColumn[]) => {
  if (!columns) {
    return []
  }

  return columns.map((column) => {
    if (column.field === DEFAULT_BACKTEST_PREDICTION_COLUMN || column.field === DEFAULT_LIVE_MONITORING_PREDICTION_COLUMN) {
      return {
        ...column,
        headerName: intl.formatMessage({
          id: 'insights.table.predictionColumn',
        }, {
          name: column.headerName,
        }),
        renderHeader: (params: GridColumnHeaderParams) => {
          return (
            <DataGridCustomColHeaderComponent
              params={params}
              subtitle={intl.formatMessage({ id: 'insights.table.sum' })}
            />
          )
        },
      } as GridColDef
    }

    if (column.field === DEFAULT_BACKTEST_TARGET_COLUMN || column.field === DEFAULT_LIVE_MONITORING_TARGET_COLUMN) {
      return {
        ...column,
        headerName: intl.formatMessage({
          id: 'insights.table.targetColumn',
        }, {
          name: column.headerName,
        }),
        renderHeader: (params: GridColumnHeaderParams) => {
          return (
            <DataGridCustomColHeaderComponent
              params={params}
              subtitle={intl.formatMessage({ id: 'insights.table.sum' })}
            />
          )
        },
      } as GridColDef
    }

    if (column.field === DEFAULT_REL_DEVIATION_COLUMN) {
      return {
        ...column,
        headerName: intl.formatMessage({
          id: 'insights.table.relDeviationColumn',
        }),
        renderHeader: (params: GridColumnHeaderParams) => {
          return (
            <DataGridCustomColHeaderComponent
              params={params}
              subtitle={intl.formatMessage({ id: 'insights.table.wape' })}
              tooltip={intl.formatMessage({ id: 'insights.table.wape.help' }, {
                name: <IntlFormatBoldComponent>{intl.formatMessage({ id: 'insights.table.wape.label' })}</IntlFormatBoldComponent>,
              })}
            />
          )
        },
        valueGetter: (params) => {
          if (params.value === null) {
            return null
          }

          return params.value * 100
        },
        valueFormatter(params) {
          if (params.value === null) {
            return intl.formatMessage({ id: 'common.na' })
          }

          return `${defaultNumberFormatter(params.value, {
            intl,
          })}%`
        },
      } as GridColDef
    }

    if (column.field === DEFAULT_ABS_DEVIATION_COLUMN) {
      return {
        ...column,
        headerName: intl.formatMessage({
          id: 'insights.table.absDeviationColumn',
        }),
        renderHeader: (params: GridColumnHeaderParams) => {
          return (
            <DataGridCustomColHeaderComponent
              params={params}
              subtitle={intl.formatMessage({ id: 'insights.table.mae' })}
              tooltip={intl.formatMessage({ id: 'insights.table.mae.help' }, {
                name: <IntlFormatBoldComponent>{intl.formatMessage({ id: 'insights.table.mae.label' })}</IntlFormatBoldComponent>,
              })}
            />
          )
        },
      } as GridColDef
    }

    return column as GridColDef
  }).map((column) => {
    const colCopy = { ...column }

    if ((colCopy.type === 'number') && (column.field !== DEFAULT_REL_DEVIATION_COLUMN)) {
      colCopy.valueFormatter = (params) => {
        if (params.value === null) {
          return intl.formatMessage({ id: 'common.na' })
        }

        if (!params.value) {
          return params.value
        }

        return defaultNumberFormatter(params.value, {
          intl,
        })
      }
    }

    return {
      ...colCopy,
      headerName: transformColumnLabel(column.headerName),
      filterOperators: getAllowedOperators(intl, column.type!),
    }
  })
}

/**
 * Formats the fold date to label
 *
 * @param intl Intl
 * @param fold Fold date
 * @param details Folds details
 *
 * @returns Formatted label
 */
export const convertFoldToLabel = (intl: IntlShape, selectedFoldDetails?: Monitor.MonitorAvailableFolds) => {
  if (!selectedFoldDetails) {
    return ''
  }

  const {
    timeResolution,
    date: foldDate,
    offsetFromToday: fold,
  } = selectedFoldDetails

  const formattedDate = foldDate.format(DEFAULT_DATE_PICKER_FORMAT)
  const intlParams = {
    fold,
    date: formattedDate,
  }

  switch (timeResolution) {
    case TIME_RESOLUTION.WEEKLY:
      return intl.formatMessage({
        id: 'insights.foldFormat.weekly',
      }, intlParams)

    case TIME_RESOLUTION.MONTHLY:
      return intl.formatMessage({
        id: 'insights.foldFormat.monthly',
      }, intlParams)

    case TIME_RESOLUTION.DAILY:
    default:
      return intl.formatMessage({
        id: 'insights.foldFormat.daily',
      }, intlParams)
  }
}

/**
 * Converts backtesting folds to options
 *
 * @param intl React Intl
 * @param backtestingAvailableFolds Available folds details
 * @returns Fold options
 */
export const foldsToOptions = (intl: IntlShape, availableFolds?: Monitor.MonitorAvailableFolds[]) => {
  if (!availableFolds) {
    return []
  }

  return availableFolds.map((foldDetails) => {
    return {
      value: foldDetails.offset,
      label: convertFoldToLabel(intl, foldDetails),
    }
  })
}

/**
 * Converts backtesting folds to chart annotations
 * @param intl React Intl
 * @param selectedFold Selected fold
 * @param backtestingAvailableFolds Available folds details
 * @returns Chart annotations
 */
export const foldsToChartAnnotations = (
  intl: IntlShape,
  selectedFold?: number,
  availableFolds?: Monitor.MonitorAvailableFolds[],
) => {
  if ((selectedFold === undefined) || !availableFolds) {
    return []
  }

  const selectedFoldDetails = availableFolds.find((fold) => fold.offset === selectedFold)

  if (!selectedFoldDetails) {
    return []
  }

  const selectedFoldTimestamp = Number(toUnixTimestamp(selectedFoldDetails.date))

  return [{
    x: selectedFoldTimestamp,
    overline: intl.formatMessage({ id: 'insights.dateOfPrediction' }),
    label: convertFoldToLabel(intl, selectedFoldDetails),
  }]
}
