import React from 'react'
import { Switch, SwitchProps } from '@mui/material'

import useStyles from './DataGridSwitchBase.styles'

export interface DataGridSwitchBaseComponentProps extends SwitchProps {
  forwardedRef?: any
}

export const DataGridSwitchBaseComponent: React.FC<DataGridSwitchBaseComponentProps> = ({
  forwardedRef,
  sx,
  ...props
}) => {
  const { classes } = useStyles()

  return (
    <Switch
      data-testid={DataGridSwitchBaseComponent.name}
      ref={forwardedRef}
      classes={{
        root: classes.root,
        track: classes.switchTrackSucceess,
        switchBase: classes.switchBaseSucceess,
        colorPrimary: classes.switchPrimarySucceess,
      }}
      sx={{
      }}
      {...props}
    />
  )
}

export default React.forwardRef((props, ref) => <DataGridSwitchBaseComponent {...props} forwardedRef={ref} />)
