import React from 'react'
import { Button, ButtonProps, useTheme } from '@mui/material'

export interface DataGridBaseButtonComponentProps extends ButtonProps {
  forwardedRef?: any
}

export const DataGridBaseButtonComponent: React.FC<DataGridBaseButtonComponentProps> = ({
  forwardedRef,
  sx,
  ...props
}) => {
  const theme = useTheme()

  return (
    <Button
      data-testid={DataGridBaseButtonComponent.name}
      ref={forwardedRef}
      sx={{
        ...sx,
        borderRadius: '5px',
        padding: '5px 10px',
        textTransform: 'none',
        fontWeight: 400,
        '&:hover': {
          backgroundColor: theme.palette.new.grey,
        },
        '&.Mui-disabled': {
          color: theme.palette.new.black_d,
        },
        '& svg > path': {
          transitionDuration: '250ms',
        },
      }}
      {...props}
    />
  )
}

export default React.forwardRef((props, ref) => <DataGridBaseButtonComponent {...props} forwardedRef={ref} />)
