import { Edge, Node } from '@xyflow/react'

import {
  DEFAULT_ARROW_CONFIG,
  NODE_TYPES,
} from '@constants/flow.constants'

export const generatePipelineFlowByGraph = (piplineGraph: Pipelines.APIPipelineGraphItem | null) => {
  if (!piplineGraph) {
    return null
  }

  const nodes: Node<Pipelines.PipelineGraphNodeData>[] = []
  const edges: Edge[] = []
  const input = piplineGraph.composite.pipelineId

  const pipelines: {
    [key: string]: Pipelines.APIPipelineGraphItem['children'][0]
  } = {}

  piplineGraph.children.forEach((child) => {
    pipelines[child.pipelineId] = child
  })

  piplineGraph.chain.forEach((layer, layerIndex) => {
    layer.forEach((pipelineId, index) => {
      const pipeline = pipelines[pipelineId]

      if (pipeline) {
        nodes.push({
          id: pipeline.pipelineId,
          type: NODE_TYPES.PIPELINE_ITEM,
          position: { x: 0, y: 0 },
          data: {
            pipelineDetails: pipeline,
            composite: false,
            last: ((index === layer.length - 1) && (layerIndex === piplineGraph.chain.length - 1)),
            step: layerIndex + 1,
          },
        })

        pipeline.dependsOn.forEach((dependency) => {
          edges.push({
            id: `${dependency}-${pipeline.pipelineId}`,
            source: dependency,
            target: pipeline.pipelineId,
            ...DEFAULT_ARROW_CONFIG,
          })
        })
      }
    })
  })

  nodes.push({
    id: piplineGraph.composite.pipelineId,
    type: NODE_TYPES.PIPELINE_ITEM,
    position: { x: 0, y: 0 },
    data: {
      pipelineDetails: piplineGraph.composite,
      composite: true,
      last: nodes.length === 0,
      step: null,
    },
  })

  edges.push({
    id: `${input}-${nodes[0].id}`,
    source: input,
    target: nodes[0].id,
    ...DEFAULT_ARROW_CONFIG,
  })

  return {
    nodes,
    edges,
  }
}
