import React, { useMemo } from 'react'
import moment from 'moment'
import get from 'lodash.get'

import { useIntl } from 'react-intl'
import { Box } from '@mui/material'
import { GridRowSelectionModel } from '@mui/x-data-grid-premium'

import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Label,
  Text,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  ReferenceLine,
} from 'recharts'

import {
  getArrowHead,
  getXAxisProps,
  getYAxisProps,
  getYTextProps,
  getCartesianGridProps,
  getTooltipProps,
  getLineProps,
  getReferenceLabelProps,
  getTooltipFormattedAxis,
  getXLabelProps,
} from '@utils/svg.utils'

import ChartLegendComponent from '@components/charts/ChartLegend'

import { generatePredictionPayloadKey, getColorByIndex, getLineLabel } from '@utils/insights.utils'

import palette from '@configuration/theme/theme.palette'
import { defaultNumberFormatter } from '@utils/analysis.utils'
import { DEFAULT_CHART_TICKS_FORMAT, formatTimestamp, toUnixTimestamp } from '@utils/moment.utils'

import InsightsChartTooltipComponent from '../insights-chart-tooltip/InsightsChartTooltip.component'

export interface InsightsLineChartComponentProps {
  dataset?: Hera.BaseChartDatasetItem[]
  targetName?: string
  targetUnit?: string
  predictionKeyPrefix?: string
  absDeviationKeyPrefix?: string
  lines?: Hera.BaseChartLineItem[]
  annotations?: Hera.BaseChartAnnotationItem[]
  enableTodayLine?: boolean
  hasGroupingEnabled?: boolean
  isFetching?: boolean
  selectedRows?: GridRowSelectionModel
}

const InsightsLineChartComponent: React.FC<InsightsLineChartComponentProps> = ({
  dataset = [],
  lines = [],
  annotations = [],
  targetName = '',
  targetUnit = '',
  predictionKeyPrefix = '',
  absDeviationKeyPrefix = '',
  hasGroupingEnabled = false,
  enableTodayLine = true,
  selectedRows = [],
  isFetching,
}) => {
  const intl = useIntl()
  const xKey = 'date'

  const todayTimestamp = Number(toUnixTimestamp(moment().utc().startOf('day')))
  const firstTimestamp = Number(get(dataset, '[0].date', todayTimestamp))
  const lastTimestamp = Number(get(dataset, `[${dataset.length - 1}].date`, todayTimestamp))

  const chartLines = useMemo(() => {
    const linesList: {
      id: string,
      color: string,
    }[] = []

    lines.forEach((line, index) => {
      if (hasGroupingEnabled) {
        /** Actual line */
        linesList.push({
          id: line.id,
          color: getColorByIndex(index, line.id, {
            isFetching,
            hasGroupingEnabled,
            useColorWay: true,
            useOpacity: true,
            selectedRows,
          }),
        })

        /** Prediction line */
        linesList.push({
          id: generatePredictionPayloadKey(line.id, predictionKeyPrefix),
          color: getColorByIndex(index, line.id, {
            isFetching,
            hasGroupingEnabled,
            useColorWay: true,
            useOpacity: false,
            selectedRows,
          }),
        })
      } else {
        linesList.push({
          id: line.id,
          color: getColorByIndex(index, line.id, {
            isFetching,
            hasGroupingEnabled,
            useColorWay: false,
            useOpacity: false,
            selectedRows,
          }),
        })
      }
    })

    return linesList
  }, [
    lines,
    selectedRows,
    isFetching,
    hasGroupingEnabled,
    predictionKeyPrefix,
  ])

  const legendRows = useMemo(() => {
    const rows: {
      label: string,
      color: string,
    }[] = []

    lines.forEach((line, index) => {
      rows.push({
        label: getLineLabel(intl, line, targetName),
        color: getColorByIndex(index, line.id, {
          isFetching,
          hasGroupingEnabled,
          useColorWay: true,
          useOpacity: false,
          selectedRows,
        }),
      })
    })

    return rows
  }, [intl, lines, isFetching, selectedRows, targetName, hasGroupingEnabled])

  return (
    <Box
      data-testid={InsightsLineChartComponent.name}
      sx={{
        width: '100%',
        height: '100%',
        ...(isFetching ? {
          filter: 'blur(5px)',
        } : {}),
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '392px',
          position: 'relative',
          paddingTop: '20px',
        }}
      >
        <ResponsiveContainer width='100%' height='100%'>
          <LineChart
            data={dataset}
            margin={{
              top: 0,
              right: 5,
              left: 50,
              bottom: 20,
            }}
          >
            <CartesianGrid
              {...getCartesianGridProps(true, true)}
            />

            <Tooltip
              {...getTooltipProps(false, { x: false, y: false })}
              cursor={!isFetching}
              content={(
                <InsightsChartTooltipComponent
                  lines={lines}
                  isFetching={isFetching}
                  hasGroupingEnabled={hasGroupingEnabled}
                  targetName={targetName}
                  selectedRows={selectedRows}
                  predictionKeyPrefix={predictionKeyPrefix}
                  absDeviationKeyPrefix={absDeviationKeyPrefix}
                />
              )}
            />

            {
              chartLines.map((line, index) => {
                return (
                  <Line
                    key={index}
                    {...getLineProps(line.id, line.color)}
                  />
                )
              })
            }

            {
              enableTodayLine ? (
                <ReferenceLine
                  x={todayTimestamp}
                  stroke={palette.new.black}
                  strokeWidth={1}
                >
                  <Label
                    value={intl.formatMessage({ id: 'insights.chart.today' })}
                    {...getReferenceLabelProps()}
                  />
                </ReferenceLine>
              ) : (
                null
              )
            }

            {
              annotations.map((annotation, index) => {
                /**
                 * Calculate edge proximity to determine annotation position.
                 * This calculates the edge position as 10% of the total chart width from the lastTimestamp.
                 */
                const annotationX = Number(annotation.x)
                const isNearRightEdge = annotationX > (lastTimestamp - (0.1 * (lastTimestamp - firstTimestamp)))
                const position = isNearRightEdge ? 'insideTopLeft' : 'insideTopRight'

                return (
                  <ReferenceLine
                    key={index}
                    x={Number(annotation.x)}
                    stroke={palette.new.black}
                    strokeWidth={1}
                    className={position}
                  >
                    {
                      annotation.overline ? (
                        <Label
                          {...getReferenceLabelProps({ position })}
                          value={annotation.overline}
                        />
                      ) : (
                        null
                      )
                    }

                    <Label
                      {...getReferenceLabelProps({ position })}
                      value={annotation.label}
                      fontWeight={500}
                      style={{
                        transform: annotation.overline ? `translate(${isNearRightEdge ? -10 : 10}px, 20px)` : '',
                      }}
                    />
                  </ReferenceLine>
                )
              })
            }

            <XAxis
              {...getXAxisProps()}
              dataKey={xKey}
              minTickGap={100}
              scale='time'
              type='number'
              domain={['auto', 'auto']}
              tickFormatter={(unixTime: number) => {
                return formatTimestamp(unixTime, intl.locale, DEFAULT_CHART_TICKS_FORMAT)
              }}
            >
              <Label
                {...getXLabelProps()}
                value={intl.formatMessage({ id: 'insights.chart.x.title' })}
              />
            </XAxis>

            <YAxis
              {...getYAxisProps()}
              tickFormatter={(value) => String(defaultNumberFormatter(value))}
            >
              <Label
                content={(
                  <Text
                    {...getYTextProps()}
                    style={{ transform: 'translate(100px, 0px)' }}
                  >
                    {
                      getTooltipFormattedAxis(intl, targetName, 'insights.chart.yAxis', targetUnit)
                    }
                  </Text>
                )}
              />
            </YAxis>

            {getArrowHead()}
          </LineChart>
        </ResponsiveContainer>
      </Box>

      <Box
        display='flex'
        flexDirection='row'
        alignItems='center'
        justifyContent='space-between'
        gap={1}
      >
        <ChartLegendComponent
          items={legendRows}
          sx={{
            padding: '0px',
            paddingLeft: '110px',
          }}
        />
      </Box>
    </Box>
  )
}

export default InsightsLineChartComponent
