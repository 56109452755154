import React, { useMemo } from 'react'

import { Box } from '@mui/material'
import { useIntl } from 'react-intl'
import { useRouteMatch } from 'react-router-dom'
import { TRACKING_ACTIONS, trackEvent } from '@utils/tracking.utils'

import AbcClassificationSelectorItemComponent from '@components/(features)/insights/abc-classification-selector-item/AbcClassificationSelectorItem.component'

interface AbcClassificationSelectorContainerProps {
  gridState: Hera.HeraBaseGridState
  abcClassification?: Hera.AbcClassificationApiResponse
  targetName?: string
  abcTotals?: Hera.AbcTotalsApiResponse
  loading?: boolean
  selectedValue?: Hera.APIABCFilter
  onClick?: (value: Hera.APIABCFilter) => void
}

const AbcClassificationSelectorComponent: React.FC<AbcClassificationSelectorContainerProps> = ({
  abcClassification = {
    total: 0,
    a: 0,
    b: 0,
    c: 0,
  },
  abcTotals = {
    total: 0,
    a: 0,
    b: 0,
    c: 0,
  },
  selectedValue,
  targetName,
  gridState,
  loading,
  onClick,
}) => {
  const intl = useIntl()

  const hasFiltersApplied = useMemo(() => {
    if (!gridState || !gridState.filterModel) {
      return false
    }

    const hasFilterItems = gridState.filterModel.items && gridState.filterModel.items.filter((item) => Boolean(item.value)).length > 0

    return hasFilterItems
  }, [gridState])

  const hasRowsSelectionApplied = useMemo(() => {
    if (!gridState || !gridState.filterModel) {
      return false
    }

    const hasRowsSelected = gridState.rowSelectionModel && gridState.rowSelectionModel.length > 0

    return hasRowsSelected
  }, [gridState])

  const { params: { usecase } } = useRouteMatch<Common.RouterMatch>()

  const items = [
    {
      label: intl.formatMessage({ id: 'insights.abc.allItems' }),
      value: 'total' as keyof Hera.AbcClassificationApiResponse,
      selected: selectedValue === undefined,
      tooltip: intl.formatMessage({
        id: 'insights.abc.tooltip',
      }, {
        targetName: (
          <Box component='strong'>{targetName}</Box>
        ),
      }),
    },
    {
      label: intl.formatMessage({ id: 'insights.abc.aItems' }),
      value: 'a' as keyof Hera.AbcClassificationApiResponse,
      selected: selectedValue === 'A',
    },
    {
      label: intl.formatMessage({ id: 'insights.abc.bItems' }),
      value: 'b' as keyof Hera.AbcClassificationApiResponse,
      selected: selectedValue === 'B',
    },
    {
      label: intl.formatMessage({ id: 'insights.abc.cItems' }),
      value: 'c' as keyof Hera.AbcClassificationApiResponse,
      selected: selectedValue === 'C',
    },
  ]

  const handleClick = (value: Hera.APIABCFilter) => {
    trackEvent({
      componentName: 'abcFilter',
      actionName: TRACKING_ACTIONS.CHANGE,
    }, {
      abcFilter: value,
      useCaseId: usecase,
    })

    if (onClick) {
      onClick(value)
    }
  }

  return (
    <Box
      data-testid={AbcClassificationSelectorComponent.name}
      display='flex'
      flexDirection='row'
      justifyContent='space-between'
      width='100%'
      alignItems='center'
      padding='4px 4.25px'
      gap='9px'
    >
      {
        items.map((item) => {
          const val = ((item.value === 'total' || !item.value) ? undefined : item.value.toUpperCase()) as Hera.APIABCFilter

          return (
            <AbcClassificationSelectorItemComponent
              key={item.value}
              label={item.label}
              tooltip={item.tooltip}
              selected={item.selected}
              x={abcClassification[item.value]}
              y={abcTotals[item.value]}
              hasFiltersApplied={hasFiltersApplied}
              hasRowsSelectionApplied={hasRowsSelectionApplied}
              hasABCFilterApplied={selectedValue !== undefined}
              loading={loading}
              disabled={loading}
              onClick={() => handleClick(val)}
            />
          )
        })
      }
    </Box>
  )
}

export default AbcClassificationSelectorComponent
