import React, { useMemo } from 'react'
import { Box } from '@mui/material'
import { useIntl } from 'react-intl'
import { useSelector } from '@redux/hooks'
import { DEFAULT_LIVE_MONITORING_ABS_DEVIATION_FIELD_PREFIX, DEFAULT_LIVE_MONITORING_PREDICTION_FIELD_PREFIX } from '@constants/insights.constants'
import { foldsToChartAnnotations } from '@utils/monitor.utils'

import {
  getLiveMonitoringChart, isFetchingLiveMonitoringChart,
  getLiveMonitoringTable, getLiveMonitoringAvailableForecastHorizons,
} from '@redux/modules/monitor/monitor.selectors'

import InsightsChartComponent from '@components/(features)/insights/insights-chart'

const LiveMonitoringChartContainer = () => {
  const intl = useIntl()
  const liveMonitoringTable = useSelector((state) => getLiveMonitoringTable(state))
  const liveMonitoringChart = useSelector((state) => getLiveMonitoringChart(state))
  const liveMonitoringAvailableForecastHorizons = useSelector((state) => getLiveMonitoringAvailableForecastHorizons(state))
  const isFetching = useSelector((state) => isFetchingLiveMonitoringChart(state))

  const {
    gridState,
  } = liveMonitoringTable

  const {
    dataset,
    lines,
    targetName,
    targetUnit,
  } = liveMonitoringChart

  const annotations: Hera.BaseChartAnnotationItem[] = useMemo(() => {
    if (!gridState) {
      return []
    }

    return foldsToChartAnnotations(intl, gridState.liveForecastHorizonOffset, liveMonitoringAvailableForecastHorizons)
  }, [intl, gridState, liveMonitoringAvailableForecastHorizons])

  return (
    <Box
      data-testid={LiveMonitoringChartContainer.name}
    >
      <InsightsChartComponent
        dataset={dataset}
        lines={lines}
        annotations={annotations}
        gridState={gridState}
        targetName={targetName}
        targetUnit={targetUnit}
        isFetching={isFetching}
        predictionKeyPrefix={DEFAULT_LIVE_MONITORING_PREDICTION_FIELD_PREFIX}
        absDeviationKeyPrefix={DEFAULT_LIVE_MONITORING_ABS_DEVIATION_FIELD_PREFIX}
      />
    </Box>
  )
}

export default LiveMonitoringChartContainer
