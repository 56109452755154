import {
  takeEvery, call,
  put, select, all,
  take,
} from 'redux-saga/effects'

import differenceBy from 'lodash.differenceby'

import { TOAST_TYPE_SUCCESS, TOAST_TYPE_ERROR } from '@constants/common.constants'
import { parseAndReportErrorResponse } from '@utils/redux.utils'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { changeToastAction } from '@redux/modules/common/common.actions'

import { readFileAsText } from '@utils/common.utils'
import { ActionPayload, State } from '@redux/modules/types'
import { getUseCaseItem } from '@redux/modules/use-case/use-case.selectors'
import * as API from './evaluation-plots.api'

import {
  SUBMIT_EVALUATION_PLOTS,
  REQUEST_EVALUATION_PLOTS,
  RECEIVE_EVALUATION_PLOTS,
} from './evaluation-plots.action-types'

import {
  startFetchingEvaluationPlotsAction,
  stopFetchingEvaluationPlotsAction,
  requestEvaluationPlotsAction,
  receiveEvaluationPlotsList,
} from './evaluation-plots.actions'

import {
  ListEvaluationPlotsActionPayload,
  UploadEvaluationPlotsActionPayload,
} from './evaluation-plots.types'

const SUBMIT_EVALUATION_PLOTS_SUCCESS = 'use_cases.files.confirmation.evaluationPlots'

function* fetchEvaluationPlotsGenerator({ payload } : ActionPayload<ListEvaluationPlotsActionPayload>) {
  try {
    yield put(startFetchingEvaluationPlotsAction(REQUEST_EVALUATION_PLOTS))

    const state: State = yield select()
    const useCase: UseCase.DetailsExtended = yield call(getUseCaseItem, state)

    const filesList: EvaluationPlots.EvaluationPlot[] = yield call(API.listEvaluationPlots, {
      useCaseId: (payload?.useCaseId || useCase.useCaseId),
      viewType: payload?.viewType,
    })

    const blobsList: Blob[] = yield all(filesList.map((item) => {
      return call(readFileAsText, item.fileUrl)
    }))

    const fileListWithBlob: EvaluationPlots.EvaluationPlot[] = filesList.map((item, index) => {
      return {
        ...item,
        plotBlob: blobsList[index],
      }
    })

    yield put(receiveEvaluationPlotsList(fileListWithBlob))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))

    yield put(receiveEvaluationPlotsList([]))
  } finally {
    yield put(stopFetchingEvaluationPlotsAction(REQUEST_EVALUATION_PLOTS))
  }
}

function* submitEvaluationPlotsGenerator({ payload }: ActionPayload<UploadEvaluationPlotsActionPayload>) {
  try {
    yield put(startFetchingEvaluationPlotsAction(SUBMIT_EVALUATION_PLOTS))

    const state: State = yield select()
    const useCase: UseCase.DetailsExtended = yield call(getUseCaseItem, state)

    const {
      new: newPayload,
      original: originalPayload,
      viewType,
      showToast = true,
    } = payload

    const newPlots = newPayload.filter((item) => item.newPlot) || []
    const editedPlots = newPayload.filter((item) => !item.newPlot) || []
    const plotsToRemove = differenceBy(originalPayload, newPayload, 'evaluationPlotId') || []

    yield all(newPlots.map((plot) => {
      const data = new FormData()

      data.append('useCaseId', useCase.useCaseId)
      data.append('headline', plot.headline)
      data.append('description', plot.description)
      data.append('plotFile', plot.plotFile)
      data.append('fileType', plot.fileType)
      data.append('viewType', viewType)

      return call(API.uploadEvaluationPlots, data as any)
    }))

    yield all(editedPlots.map((plot) => {
      const data = new FormData()

      data.append('evaluationPlotId', plot.evaluationPlotId)
      data.append('headline', plot.headline)
      data.append('description', plot.description)

      if (plot.plotFile && plot.updatedPlot) {
        data.append('plotFile', plot.plotFile)
        data.append('fileType', plot.fileType)
        data.append('viewType', viewType)
      }

      return call(API.editEvaluationPlots, data as any)
    }))

    yield all(plotsToRemove.map((plot) => {
      return call(API.removeEvaluationPlot, {
        evaluationPlotId: plot.evaluationPlotId,
      })
    }))

    yield put(requestEvaluationPlotsAction({}))

    yield take(RECEIVE_EVALUATION_PLOTS)

    yield put(setPrimaryModalPageName(null))

    if (showToast) {
      yield put(changeToastAction({ useIntl: true, message: SUBMIT_EVALUATION_PLOTS_SUCCESS, severity: TOAST_TYPE_SUCCESS }))
    }
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingEvaluationPlotsAction(SUBMIT_EVALUATION_PLOTS))
  }
}

export function* watchFetchEvaluationPlots() {
  yield takeEvery(REQUEST_EVALUATION_PLOTS, fetchEvaluationPlotsGenerator)
}

export function* watchSubmitEvaluationPlots() {
  yield takeEvery(SUBMIT_EVALUATION_PLOTS, submitEvaluationPlotsGenerator)
}
