import { State } from '@redux/modules/types'

import {
  REQUEST_ARTIFACTS_LIST,
  UPLOAD_ARTIFACT,
  REMOVE_ARTIFACT,
  DOWNLOAD_ARTIFACT,
} from './artifacts.action-types'

export const getUseCaseArtifacts = (state: State) => {
  return state.artifacts.artifactsList
}

export const getUseCaseExecuteArtifacts = (state: State) => {
  return state.artifacts.artifactsList
}

export const isSubmittingArtifacts = (state: State) => {
  const actions = [UPLOAD_ARTIFACT, DOWNLOAD_ARTIFACT, REMOVE_ARTIFACT]

  return state.artifacts.fetchingKeys.some((key) => actions.includes(key))
}

export const isUploadingArtifacts = (state: State) => {
  return state.artifacts.fetchingKeys.includes(UPLOAD_ARTIFACT)
}

export const isDownloadingArtifacts = (state: State) => {
  return state.artifacts.fetchingKeys.includes(DOWNLOAD_ARTIFACT)
}

export const isFetchingArtifacts = (state: State) => {
  return state.artifacts.fetchingKeys.includes(REQUEST_ARTIFACTS_LIST)
}
