import {
  PARETOS_GRADIENT,
  PARETOS_GRADIENT_20,
  DEFAULT_SELECT_BOX_SHADOW,
  DEFAULT_BORDER_RADIUS,
  DEFAULT_BUTTON_FONT_SIZE,
} from '@constants/ui.constants'

import { Theme } from '@mui/material'

/**
 * Generates styles for buttons
 * @param theme MUI theme
 * @param color Button color
 * @param buttonType Button type
 * @param loading Loading state
 * @returns Button styles
 */
export const getButtonsStyles = (
  theme: Theme,
  color: 'primary' | 'secondary' | 'tertiary' | 'highlighted' | 'highlighted-secondary',
  buttonType: 'regular' | 'icon',
  loading?: boolean,
) => {
  return {
    primary: {
      background: loading ? PARETOS_GRADIENT_20 : theme.palette.new.business_black,
      color: theme.palette.new.white,
      outline: loading ? `1px solid ${theme.palette.new.business_black_20}` : 'none',

      '&:hover': {
        opacity: 0.6,
      },
      '&:focus-visible': {
        opacity: 0.6,
      },
      '&:active': {
        opacity: 1,
      },
    },
    secondary: {
      background: loading ? PARETOS_GRADIENT_20 : theme.palette.new.business_black_5,
      color: theme.palette.new.business_black,
      outline: loading ? `1px solid ${theme.palette.new.business_black_20}` : 'none',
      opacity: loading ? 1 : 0.6,

      '&:hover': {
        opacity: 0.8,
        backgroundColor: theme.palette.new.business_black_10,
      },
      '&:focus-visible': {
        opacity: 0.8,
        backgroundColor: theme.palette.new.business_black_10,
      },
      '&:active': {
        opacity: 1,
      },
    },
    tertiary: {
      background: (loading && buttonType === 'regular') ? PARETOS_GRADIENT_20 : theme.palette.new.transparent,
      outline: (loading && buttonType === 'regular') ? `1px solid ${theme.palette.new.business_black_20}` : 'none',
      color: theme.palette.new.business_black,

      '&:hover': {
        backgroundColor: theme.palette.new.business_black_5,
      },
      '&:focus-visible': {
        backgroundColor: theme.palette.new.business_black_5,
      },
      '&:active': {
        backgroundColor: theme.palette.new.business_black_10,
      },
    },
    highlighted: {
      background: loading ? PARETOS_GRADIENT_20 : theme.palette.new.versatile_violet,
      outline: loading ? `1px solid ${theme.palette.new.business_black_20}` : 'none',
      color: theme.palette.new.white,

      '&:hover': {
        backgroundColor: theme.palette.new.versatile_violet,
        opacity: 0.6,
      },
      '&:focus-visible': {
        backgroundColor: theme.palette.new.versatile_violet,
        opacity: 0.6,
      },
      '&:active': {
        backgroundColor: theme.palette.new.versatile_violet,
        opacity: 1,
      },
    },
    'highlighted-secondary': {
      background: loading ? PARETOS_GRADIENT_20 : theme.palette.new.white,
      color: theme.palette.new.business_black,
      outline: `1px solid ${theme.palette.new.business_black_20}`,

      '&:hover': {
        backgroundColor: theme.palette.new.white,
        outline: `1px solid ${theme.palette.new.business_black_60}`,
      },
      '&:focus-visible': {
        backgroundColor: theme.palette.new.white,
        outline: `1px solid ${theme.palette.new.business_black_60}`,
      },
      '&:active': {
        backgroundColor: theme.palette.new.smokey_silver,
        outline: `1px solid ${theme.palette.new.business_black}`,
      },
    },
  }[color || 'primary']
}

/**
 * Generates styles for text with a gradient background
 * @param background Color to use as background
 * @returns Styles for text with a gradient background
 */
export const getTextBackgroundFillStyles = (background = PARETOS_GRADIENT) => {
  return {
    background,
    backgroundClip: 'text',
    textFillColor: 'transparent',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    MozBackgroundClip: 'text',
    MozTextFillColor: 'transparent',
  }
}

/**
 * Generates styles for the end adornment button in the autocomplete component
 * @param theme MUI theme
 * @param withHelp Whether the autocomplete has a help icon
 * @returns Styles for the end adornment button
 */
export const getAutocompleteEndAdornmentButtonStyles = (theme: Theme, withHelp = false) => {
  return {
    position: 'absolute',
    right: withHelp ? '36px' : '12px',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',

    '& .MuiIconButton-root': {
      backgroundColor: theme.palette.new.transparent,
      color: theme.palette.new.business_black,
      opacity: 0.6,
      padding: '4px',

      '&:hover': {
        opacity: 1,
        backgroundColor: theme.palette.new.transparent,
      },
      '&:focus-visible': {
        opacity: 1,
        backgroundColor: theme.palette.new.transparent,
      },
      '&:active': {
        opacity: 1,
        backgroundColor: theme.palette.new.transparent,
      },

      '& .MuiTouchRipple-root': {
        display: 'none',
      },
    },
  }
}

export const getMenuStyles = (theme: Theme, trasformY = '1px') => {
  return {
    '& .MuiPaper-root': {
      outline: `1px solid ${theme.palette.new.business_black_20}`,
      boxShadow: DEFAULT_SELECT_BOX_SHADOW,
      borderRadius: DEFAULT_BORDER_RADIUS.SMALL,
      transform: `translate(0px, ${trasformY}) !important`,
    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent',
    },
    '& .MuiMenu-list': {
      '& .MuiMenuItem-root': {
        borderTop: `1px solid ${theme.palette.new.business_black_20}`,

        '&:first-of-type': {
          borderTop: 'none',
        },
      },
    },
    '& .MuiMenuItem-root': {
      fontSize: DEFAULT_BUTTON_FONT_SIZE,
      '& .MuiTypography-root': {
        fontSize: DEFAULT_BUTTON_FONT_SIZE,
      },

      '& .MuiTouchRipple-root': {
        display: 'none',
      },
      '&:hover': {
        backgroundColor: theme.palette.new.smokey_silver,
      },
      '&:focus-visible': {
        backgroundColor: theme.palette.new.smokey_silver,
      },
      '&:active': {
        backgroundColor: theme.palette.new.business_black_10,
      },
      '&.Mui-selected': {
        fontWeight: 500,
        backgroundColor: theme.palette.new.white,
        color: theme.palette.new.versatile_violet,

        '& .MuiTypography-root': {
          fontWeight: 500,
          color: theme.palette.new.versatile_violet,
        },
        '&:hover': {
          backgroundColor: theme.palette.new.versatile_violet_5,
        },
        '&:focus-visible': {
          backgroundColor: theme.palette.new.versatile_violet_5,
        },
        '&:active': {
          backgroundColor: theme.palette.new.versatile_violet_10,
        },
      },
    },
  }
}
