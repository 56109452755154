import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  rightContainer: {
    backgroundColor: theme.palette.new.grey_d,
  },
  solutionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    height: theme.spacing(8),
    borderBottom: `1px solid ${theme.palette.new.grey_a}`,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  containerTop: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
  },
  containerTopEmpty: {
    display: 'none',
  },
  targetsHeader: {
    display: 'flex',
    width: '100%',
    height: theme.spacing(6),
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '35px',
    alignItems: 'flex-end',
    textTransform: 'uppercase',
    borderBottom: `1px solid ${theme.palette.new.grey_a}`,
    color: theme.palette.new.grey_c,
    padding: theme.spacing(0, 4),
    [theme.breakpoints.down('xl')]: {
      padding: theme.spacing(0, 2),
    },
  },
  targetsList: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    marginBottom: theme.spacing(3),
  },
  targetItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(1.5, 4),
    borderBottom: `1px solid ${theme.palette.new.grey_a}`,
    [theme.breakpoints.down('xl')]: {
      padding: theme.spacing(1.5, 2),
    },
  },
  targetName: {
    fontSize: '16px',
    color: theme.palette.new.black,
    [theme.breakpoints.down('xl')]: {
      fontSize: '14px',
    },
  },
  targetValue: {
    fontSize: '16px',
    color: theme.palette.new.black,
    [theme.breakpoints.down('xl')]: {
      fontSize: '14px',
    },
  },
  configEmpty: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    fontSize: '16px',
    color: theme.palette.new.pink,
    minHeight: '78px',
    padding: theme.spacing(4),
    [theme.breakpoints.down('xl')]: {
      padding: theme.spacing(2),
      fontSize: '14px',
    },
  },
  topLegendContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    padding: theme.spacing(0, 4),
    [theme.breakpoints.down('xl')]: {
      padding: theme.spacing(0, 2),
    },
  },
  topLegendText: {
    fontSize: '20px',
    fontWeight: 500,
    color: theme.palette.new.black,
    [theme.breakpoints.down('xl')]: {
      fontSize: '12px',
    },
  },
  topLegendCircle: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    borderRadius: '100%',
    backgroundColor: theme.palette.new.pink,
    marginRight: theme.spacing(1),
  },
  strategydButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(0, 4),
    marginBottom: theme.spacing(4),
    background: 'transparent',
    [theme.breakpoints.down('xl')]: {
      padding: theme.spacing(0, 2),
      marginBottom: theme.spacing(2),
    },
  },
}))

export default useStyles
