import { handleActions } from 'redux-actions'
import { ChatState } from '@redux/modules/types'

import {
  RECEIVE_CHAT_MESSAGES,
  SEND_CHAT_MESSAGE_DONE,
  START_FETCHING_CHAT,
  STOP_FETCHING_CHAT,
} from './chat.action-types'

import {
  startFetching,
  stopFetching,
  initialState,
  receiveChatMessages,
  sendChatMessageDone,
} from './chat.reducers'

export default handleActions<ChatState, any>({
  [RECEIVE_CHAT_MESSAGES]: receiveChatMessages,
  [SEND_CHAT_MESSAGE_DONE]: sendChatMessageDone,
  [START_FETCHING_CHAT]: startFetching,
  [STOP_FETCHING_CHAT]: stopFetching,
}, initialState)
