import React from 'react'
import { bindActionCreators } from 'redux'
import { useIntl } from 'react-intl'
import { formatNumberWithSuffixUnit } from '@utils/analysis.utils'
import OptimizeRestockDetailsItemComponent from '@components/(features)/optimize/optimize-restock-details-item'
import OptimizeStrategyBlockComponent from '@components/(features)/optimize/optimize-strategy-block'
import { getPredictionOverview, getRangeSelectorType } from '@redux/modules/optimize/optimize.selectors'
import { setTargetSelectorType } from '@redux/modules/optimize/optimize.actions'
import { getSidebarState } from '@redux/modules/common/common.selectors'
import { useSelector, useDispatch } from '@redux/hooks'

import useStyles from './OptimizeRestockSelector.styles'

const OptimizeRestockSelectorComponent: React.FC = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const sidebarState = useSelector((state) => getSidebarState(state))
  const predictionOverview = useSelector((state) => getPredictionOverview(state))
  const selectedType = useSelector((state) => getRangeSelectorType(state))
  const setSelectedType = bindActionCreators(setTargetSelectorType, dispatch)

  const { classes } = useStyles({ sidebarState })

  const {
    totalPredictedReplenishmentCost,
    totalPredictedRevenue,
  } = predictionOverview

  return (
    <div
      className={classes.root}
      data-testid={OptimizeRestockSelectorComponent.name}
    >
      <div className={classes.selectorRoot}>
        <OptimizeStrategyBlockComponent
          selectedType={selectedType}
          setSelectedType={setSelectedType}
        />
      </div>
      <div className={classes.detailsRoot}>
        <OptimizeRestockDetailsItemComponent
          header={intl.formatMessage({ id: 'optimize.selector.replenishmentCost' })}
          subHeader={intl.formatMessage({ id: 'optimize.selector.predicted' })}
          description={intl.formatMessage({ id: 'optimize.selector.replenishmentCost.description' })}
          borderRight={true}
          value={formatNumberWithSuffixUnit(totalPredictedReplenishmentCost)}
        />
        <OptimizeRestockDetailsItemComponent
          header={intl.formatMessage({ id: 'optimize.selector.revenue' })}
          subHeader={intl.formatMessage({ id: 'optimize.selector.predicted' })}
          description={intl.formatMessage({ id: 'optimize.selector.revenue.description' })}
          value={formatNumberWithSuffixUnit(totalPredictedRevenue)}
        />
      </div>
    </div>
  )
}

export default OptimizeRestockSelectorComponent
