import React from 'react'
import { Typography } from '@mui/material'

import useStyles from './OptimizeDrillDownGridItem.styles'

export interface OptimizeDrillDownGridItemComponentProps {
  header: string,
  subHeader?: string,
  value: string | React.ReactNode | React.ReactNode[],
  colorfull?: boolean,
  borderRight?: boolean,
  borderBottom?: boolean,
}

const OptimizeDrillDownGridItemComponent: React.FC<OptimizeDrillDownGridItemComponentProps> = (props) => {
  const {
    header,
    subHeader,
    value,
    colorfull,
    borderRight,
    borderBottom,
  } = props

  const { classes, cx } = useStyles()

  return (
    <div
      className={cx(classes.root, {
        [classes.colorfull]: colorfull,
        [classes.borderRight]: borderRight,
        [classes.borderBottom]: borderBottom,
      })}
      data-testid={OptimizeDrillDownGridItemComponent.name}
    >
      <div className={classes.headerRoot}>
        <Typography className={classes.header}>
          {header}
        </Typography>
        {
          subHeader ? (
            <Typography className={classes.subHeader}>
              {subHeader}
            </Typography>
          ) : (
            null
          )
        }
      </div>
      {
        typeof value === 'string' ? (
          <Typography className={classes.value}>
            {value}
          </Typography>
        ) : (
          value
        )
      }
    </div>
  )
}

export default OptimizeDrillDownGridItemComponent
