import { getTextBackgroundFillStyles } from '@utils/ui.utils'
import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  modalSectionHeaderTitle: {
    fontSize: '30px',
    lineHeight: '35px',
    maxWidth: theme.spacing(80),
    marginBottom: theme.spacing(2),
    ...getTextBackgroundFillStyles(),
  },
  modalSectionHeaderText: {
    fontSize: '18px',
    lineHeight: '20px',
    color: theme.palette.new.black_b,
    maxWidth: theme.spacing(60),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('xl')]: {
      marginBottom: theme.spacing(6),
    },
  },
  blockContainer: {
    marginBottom: theme.spacing(4),
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    '& > div': {
      marginTop: theme.spacing(0),
    },
  },
  connectorLine: {
    zIndex: 5,
  },
  autocompleteContainer: {
    width: '100%',
    marginLeft: theme.spacing(-0.5),
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 10px) scale(1)',
    },
    '& .MuiAutocomplete-inputRoot': {
      padding: '10px 65px 10px 25px',
    },
  },
  spacing: {
    height: theme.spacing(3),
    width: '100%',
  },
  actionBlock: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  analyzeButton: {
    height: theme.spacing(4),
    padding: theme.spacing(1, 2),
    '& .MuiButton-label': {
      fontSize: '18px !important',
      textTransform: 'none',
    },
  },
}))

export default useStyles
