import {
  takeEvery, put,
  take, select, delay,
  cancel, takeLatest, fork,
} from 'redux-saga/effects'

import get from 'lodash.get'

import { Task } from 'redux-saga'

import * as Effects from 'redux-saga/effects'

import { push } from 'redux-first-history'
import { generatePath } from 'react-router-dom'

import { TOAST_TYPE_ERROR, TOAST_TYPE_SUCCESS } from '@constants/common.constants'
import { parseAndReportErrorResponse } from '@utils/redux.utils'
import { changeToastAction } from '@redux/modules/common/common.actions'
import { ActionPayload, State } from '@redux/modules/types'
import { getCompaniesList, getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { REQUEST_COMPANIES_LIST_DONE } from '@redux/modules/customer/customer.action-types'
import { PIPELINE_TYPES } from '@constants/pipelines.constants'
import { AUTO_REFRESH_INTERVAL_IN_MS } from '@constants/files.constants'
import { isArticleAllocationFamilyCheck, isRecommendationFamilyCheck } from '@utils/use-cases.utils'
import { ARTICLE_ALLOCATION_RESULTS_PATH, REPLENISHMENT_RESULTS_PATH } from '@constants/routes.constants'

import * as USE_CASE_API from '@redux/modules/use-case/use-case.api'

import { getUseCaseItem } from '@redux/modules/use-case/use-case.selectors'
import { RECEIVE_USE_CASE } from '@redux/modules/use-case/use-case.action-types'
import { fetchUseCaseAction } from '@redux/modules/use-case/use-case.actions'

import * as API from './pipelines.api'

import {
  DELETE_PIPELINE,
  REQUEST_PIPELINE_OVERVIEW,
  REQUEST_PIPELINES_LIST,
  RECEIVE_PIPELINES_LIST,
  UPDATE_PIPELINE_DELIVERY_SCHEDULE,
  DELETE_PIPELINE_DELIVERY_SCHEDULE,
  SET_PIPELINE_DELIVERS_TO_CUSTOMER_FLAG,
  START_COMPOSITE_PIPELINE,
  STOP_COMPOSITE_PIPELINE,
  REQUEST_COMPOSITE_PIPELINE_STATUS,
  RECEIVE_COMPOSITE_PIPELINE_STATUS,
  COMPOSITE_PIPELINE_STATUS_AUTO_REFRESH_START,
  COMPOSITE_PIPELINE_STATUS_AUTO_REFRESH_STOP,
  TRIGGER_PIPELINE,
  UPDATE_PIPELINE_EXECUTION_SCHEDULE,
  UPDATE_PIPELINE_CONFIGURATION,
  REPORT_PIPELINE_DELIVERY,
} from './pipelines.action-types'

import {
  compositePipelineStatusAutoRefreshStartAction,
  compositePipelineStatusAutoRefreshStopAction,
  startFetchingPipelinesAction,
  stopFetchingPipelinesAction,
  receivePipelineOverviewAction,
  receivePipelinesListAction,
  requestPipelinesListAction,
  receiveCompositePipelineStatusActionDone,
  requestCompositePipelineStatusAction,
} from './pipelines.actions'

import {
  RequestPipelineOverviewActionPayload,
  RequestPipelinesListActionPayload,
  UpdatePipelineDeliveryScheduleActionPayload,
  DeletePipelineDeliveryScheduleActionPayload,
  SetPipelineDeliversToCustomerActionPayload,
  RequestCompositePipelineStatusActionPayload,
  UpdatePipelineExecutionScheduleActionPayload,
  ConfigurePipelineActionPayload,
  PipelineReportDeliveryActionPayload,
} from './pipelines.types'

import { getCompositePipelineStatus, getPipelineById } from './pipelines.selectors'

const DELETE_PIPELINE_SUCCESS = 'pipelines.list.confirmation.delete'
const TRIGGER_PIPELINE_SUCCESS = 'pipelines.list.confirmation.trigger'
const UPDATE_PIPELINE_CONFIGURATION_SUCCESS = 'pipelines.list.confirmation.configuration_updated'
const UPDATE_PIPELINE_EXECUTION_SCHEDULE_SUCCESS = 'pipelines.list.confirmation.execution_updated'
const UPDATE_PIPELINE_DELIVERY_SCHEDULE_SUCCESS = 'pipelines.list.confirmation.delivery_updated'
const REMOVE_PIPELINE_DELIVERY_SCHEDULE_SUCCESS = 'pipelines.list.confirmation.delivery_removed'
const SET_PIPELINE_DELIVERS_TO_CUSTOMER_FLAG_SUCCESS = 'pipelines.list.confirmation.delivers_to_customer_updated'
const START_COMPOSITE_PIPELINE_SUCCESS = 'connect.setup.start_optimization.success'
const STOP_COMPOSITE_PIPELINE_SUCCESS = 'connect.setup.stop_optimization.success'
const RUN_COMPOSITE_PIPELINE_SUCCESS = 'connect.setup.run_optimization.success'
const REPORT_PIPELINE_DELIVERY_SUCCESS = 'pipelines.list.confirmation.delivery_reported'

const PIPELINES_TEMPORAL_DELAY_INTERVAL_MS = 1500
const call: any = Effects.call

function* requestCompositePipelineStatusGenerator({ payload }: ActionPayload<RequestCompositePipelineStatusActionPayload>) {
  try {
    yield put(startFetchingPipelinesAction(REQUEST_COMPOSITE_PIPELINE_STATUS))

    let useAutoRefresh = payload.withAutoRefresh

    if (!useAutoRefresh) {
      const pipelineStatus: Pipelines.APIGetCompositePipelineStatusResponse = yield call(API.fetchCompositePipelineStatus, payload)

      yield put(receiveCompositePipelineStatusActionDone(pipelineStatus))

      if (payload.initialFetch && pipelineStatus.status === 'RUNNING') {
        useAutoRefresh = true
      }
    }

    if (useAutoRefresh) {
      yield put(compositePipelineStatusAutoRefreshStartAction({
        useCaseId: payload.useCaseId,
      }))

      yield take(RECEIVE_COMPOSITE_PIPELINE_STATUS)
    }
  } catch (e) {
    // Ignoring all errors..
    yield put(receiveCompositePipelineStatusActionDone({
      status: null,
      lastSuccessfulRun: null,
      lastRun: null,
    }))
  } finally {
    yield put(stopFetchingPipelinesAction(REQUEST_COMPOSITE_PIPELINE_STATUS))
  }
}

function* startCompositePipelineActionGenerator({ payload }: ActionPayload<Pipelines.APIStartCompositePipelineRequest>) {
  try {
    yield put(startFetchingPipelinesAction(START_COMPOSITE_PIPELINE))

    yield call(API.startCompositePipeline, payload)

    yield put(changeToastAction({ useIntl: true, message: START_COMPOSITE_PIPELINE_SUCCESS, severity: TOAST_TYPE_SUCCESS }))

    yield put(fetchUseCaseAction({ useCaseId: payload.useCaseId, includePipelineStatus: true }))

    yield take(RECEIVE_USE_CASE)

    yield put(setPrimaryModalPageName(null))

    yield put(requestCompositePipelineStatusAction({ useCaseId: payload.useCaseId, withAutoRefresh: true }))

    yield take(RECEIVE_COMPOSITE_PIPELINE_STATUS)
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingPipelinesAction(START_COMPOSITE_PIPELINE))
  }
}

function* stopCompositePipelineActionGenerator({ payload }: ActionPayload<Pipelines.APIStopCompositePipelineRequest>) {
  try {
    yield put(startFetchingPipelinesAction(STOP_COMPOSITE_PIPELINE))

    yield call(API.stopCompositePipeline, payload)

    yield put(changeToastAction({ useIntl: true, message: STOP_COMPOSITE_PIPELINE_SUCCESS, severity: TOAST_TYPE_SUCCESS }))

    yield put(requestCompositePipelineStatusAction({ useCaseId: payload.useCaseId }))

    yield take(RECEIVE_COMPOSITE_PIPELINE_STATUS)
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingPipelinesAction(STOP_COMPOSITE_PIPELINE))
  }
}

function* fetchPipelinesListGenerator({ payload }: ActionPayload<RequestPipelinesListActionPayload>) {
  try {
    if (!payload.silent) {
      yield put(startFetchingPipelinesAction(REQUEST_PIPELINES_LIST))
    }

    const state: State = yield select()
    const isAdmin: boolean = yield call(getIsAdmin, state)
    const companiesList: Customer.CompanyItem[] = yield call(getCompaniesList, state)

    if (!isAdmin) {
      yield put(push('/'))
    } else {
      if (!companiesList.length) {
        yield take(REQUEST_COMPANIES_LIST_DONE)
      }

      const pipelinesList: Pipelines.APIPipelinesListResponseItem[] = yield call(API.getPipelinesList, {
        useCaseId: payload.useCaseId,
      })

      let useCasesList: UseCase.Details[] = []

      try {
        useCasesList = yield call(USE_CASE_API.fetchMany, {
          useCaseIds: [...new Set(pipelinesList.map((pipeline) => pipeline.useCaseId))],
        })
      } catch (e) {
        useCasesList = []
      }

      const latestState: State = yield select()
      const latestCompaniesList: Customer.CompanyItem[] = yield call(getCompaniesList, latestState)

      yield put(receivePipelinesListAction({
        pipelinesList,
        useCasesList,
        companiesList: latestCompaniesList,
      }))
    }
  } catch (e: any) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingPipelinesAction(REQUEST_PIPELINES_LIST))
  }
}

function* requestPipelineOverviewGenerator({ payload }: ActionPayload<RequestPipelineOverviewActionPayload>) {
  try {
    yield put(startFetchingPipelinesAction(REQUEST_PIPELINE_OVERVIEW))

    const state: State = yield select()
    const isAdmin: boolean = yield call(getIsAdmin, state)
    const pipelineDetails: Pipelines.ReduxPipelineItem = yield call(getPipelineById, state, payload.pipelineId)

    if (!isAdmin) {
      yield put(push('/'))
    }

    if (!pipelineDetails) {
      yield put(requestPipelinesListAction({ silent: true }))

      yield take(RECEIVE_PIPELINES_LIST)
    }

    const expectedDeliveryHistory: Pipelines.APIExpectedDeliveryHistoryListResponseItem[] = yield call(API.getExpectedDeliveryHistoryList, {
      pipelineId: payload.pipelineId,
      useCaseId: payload.useCaseId,
    })

    const latestState: State = yield select()
    const latestPipelineDetails: Pipelines.ReduxPipelineItem = yield call(getPipelineById, latestState, payload.pipelineId)
    const demandPipelinesList: Pipelines.APIDemandPipelineListResponse[] | null = (latestPipelineDetails.type === PIPELINE_TYPES.DEMAND_PIPELINE) ?
      yield call(API.getDemandPipelineList) : null

    const pipelineGraphs: Pipelines.APIListPipelineGraphsResponse = yield call(API.listPipeplineGraphs, { pipelineId: payload.pipelineId })

    const pipelineGraph = pipelineGraphs ? pipelineGraphs.pipelines.find((pipeline) => {
      if (latestPipelineDetails.type === PIPELINE_TYPES.COMPOSITE_PIPELINE) {
        return pipeline?.composite?.pipelineId === payload.pipelineId
      }

      return pipeline?.composite?.pipelineId === latestPipelineDetails.memberOf
    }) : null

    const pipelineConfig: Pipelines.APIDemandPipelineListResponse | null = demandPipelinesList ? demandPipelinesList.find((demandPipeline) => {
      return demandPipeline.pipelineId === payload.pipelineId
    }) || null : null

    yield put(receivePipelineOverviewAction({
      pipelineDetails: latestPipelineDetails,
      pipelineConfig,
      pipelineGraph,
      expectedDeliveryHistory,
    }))
  } catch (e: any) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingPipelinesAction(REQUEST_PIPELINE_OVERVIEW))
  }
}

function* updatePipelineExecutionScheduleGenerator({ payload }: ActionPayload<UpdatePipelineExecutionScheduleActionPayload>) {
  try {
    yield put(startFetchingPipelinesAction(UPDATE_PIPELINE_EXECUTION_SCHEDULE))

    yield call(API.updatePipelineExecutionSchedule, {
      pipelineId: payload.pipelineId,
      executionSchedule: payload.executionSchedule,
    })

    yield delay(PIPELINES_TEMPORAL_DELAY_INTERVAL_MS)

    yield put(requestPipelinesListAction({ silent: false }))

    yield take(RECEIVE_PIPELINES_LIST)

    yield put(changeToastAction({ useIntl: true, message: UPDATE_PIPELINE_EXECUTION_SCHEDULE_SUCCESS, severity: TOAST_TYPE_SUCCESS }))

    yield put(setPrimaryModalPageName(null))
  } catch (e: any) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingPipelinesAction(UPDATE_PIPELINE_EXECUTION_SCHEDULE))
  }
}

function* updatePipelineDeliveryScheduleGenerator({ payload }: ActionPayload<UpdatePipelineDeliveryScheduleActionPayload>) {
  try {
    yield put(startFetchingPipelinesAction(UPDATE_PIPELINE_DELIVERY_SCHEDULE))

    yield call(API.updatePipelineDeliverySchedule, {
      pipelineId: payload.pipelineId,
      schedule: payload.schedule,
    })

    yield delay(PIPELINES_TEMPORAL_DELAY_INTERVAL_MS)

    yield put(requestPipelinesListAction({ silent: false }))

    yield take(RECEIVE_PIPELINES_LIST)

    yield put(changeToastAction({ useIntl: true, message: UPDATE_PIPELINE_DELIVERY_SCHEDULE_SUCCESS, severity: TOAST_TYPE_SUCCESS }))

    yield put(setPrimaryModalPageName(null))
  } catch (e: any) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingPipelinesAction(UPDATE_PIPELINE_DELIVERY_SCHEDULE))
  }
}

function* deletePipelineDeliveryScheduleGenerator({ payload }: ActionPayload<DeletePipelineDeliveryScheduleActionPayload>) {
  try {
    yield put(startFetchingPipelinesAction(DELETE_PIPELINE_DELIVERY_SCHEDULE))

    yield call(API.deletePipelineDeliverySchedule, {
      pipelineId: payload.pipelineId,
    })

    yield delay(PIPELINES_TEMPORAL_DELAY_INTERVAL_MS)

    yield put(requestPipelinesListAction({ silent: false }))

    yield take(RECEIVE_PIPELINES_LIST)

    yield put(changeToastAction({ useIntl: true, message: REMOVE_PIPELINE_DELIVERY_SCHEDULE_SUCCESS, severity: TOAST_TYPE_SUCCESS }))

    yield put(setPrimaryModalPageName(null))
  } catch (e: any) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingPipelinesAction(DELETE_PIPELINE_DELIVERY_SCHEDULE))
  }
}

function* updatePipelineConfigurationGenerator({ payload }: ActionPayload<ConfigurePipelineActionPayload>) {
  try {
    yield put(startFetchingPipelinesAction(UPDATE_PIPELINE_CONFIGURATION))

    if (payload.type === PIPELINE_TYPES.DEMAND_PIPELINE) {
      yield call(API.updateDemandPipelineResources, {
        useCaseId: payload.useCaseId,
        memoryMb: payload.memoryMb,
        gpu: payload.gpu,
      })
    }

    if (payload.type === PIPELINE_TYPES.OPTIMIZATION_PIPELINE) {
      yield call(API.updateOptimizationPipelineResources, {
        useCaseId: payload.useCaseId,
        memoryMb: payload.memoryMb,
      })
    }

    if (payload.enabled) {
      yield call(API.enablePipeline, {
        pipelineId: payload.pipelineId,
      })
    } else {
      yield call(API.disablePipeline, {
        pipelineId: payload.pipelineId,
      })
    }

    if (payload.type !== PIPELINE_TYPES.COMPOSITE_PIPELINE) {
      yield call(API.updatePipelineDependsOn, {
        pipelineId: payload.pipelineId,
        memberOf: payload.memberOf,
        dependsOn: payload.dependsOn,
      })
    }

    yield call(API.setPipelineDeliversToCustomer, {
      pipelineId: payload.pipelineId,
      deliversToCustomer: payload.deliversToCustomer,
    })

    yield put(setPrimaryModalPageName(null))

    yield delay(PIPELINES_TEMPORAL_DELAY_INTERVAL_MS)

    yield put(requestPipelinesListAction({ silent: false }))

    yield take(RECEIVE_PIPELINES_LIST)

    yield put(changeToastAction({ useIntl: true, message: UPDATE_PIPELINE_CONFIGURATION_SUCCESS, severity: TOAST_TYPE_SUCCESS }))
  } catch (e: any) {
    const message = parseAndReportErrorResponse(e, payload)
    const additionalDetails = get(e, 'response.data.data.details.problem', '')

    yield put(changeToastAction({ message: `${message}. ${additionalDetails}`, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingPipelinesAction(UPDATE_PIPELINE_CONFIGURATION))
  }
}

function* setPipelineDeliversToCustomerFlagGenerator({ payload }: ActionPayload<SetPipelineDeliversToCustomerActionPayload>) {
  try {
    yield put(startFetchingPipelinesAction(SET_PIPELINE_DELIVERS_TO_CUSTOMER_FLAG))

    yield call(API.setPipelineDeliversToCustomer, {
      pipelineId: payload.pipelineId,
      deliversToCustomer: payload.deliversToCustomer,
    })

    yield delay(PIPELINES_TEMPORAL_DELAY_INTERVAL_MS)

    yield put(requestPipelinesListAction({ silent: false }))

    yield take(RECEIVE_PIPELINES_LIST)

    yield put(changeToastAction({ useIntl: true, message: SET_PIPELINE_DELIVERS_TO_CUSTOMER_FLAG_SUCCESS, severity: TOAST_TYPE_SUCCESS }))

    yield put(setPrimaryModalPageName(null))
  } catch (e: any) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingPipelinesAction(SET_PIPELINE_DELIVERS_TO_CUSTOMER_FLAG))
  }
}

function* compositePipelineStatusAutoRefreshStart({ payload } : ActionPayload<RequestCompositePipelineStatusActionPayload>) {
  while (true) {
    const {
      useCaseId,
    } = payload

    yield put(requestCompositePipelineStatusAction({
      useCaseId,
    }))

    yield take(RECEIVE_COMPOSITE_PIPELINE_STATUS)

    const state: State = yield select()
    const useCase: UseCase.DetailsExtended = yield call(getUseCaseItem, state)
    const compositePipelineStatus: Pipelines.ReduxCompositePipelineStatus = yield call(getCompositePipelineStatus, state)
    const shouldStopAutoRefresh = compositePipelineStatus.status !== 'RUNNING'

    if (shouldStopAutoRefresh) {
      if (isRecommendationFamilyCheck(useCase.family)) {
        yield put(fetchUseCaseAction({ useCaseId: payload.useCaseId, includePipelineStatus: true }))

        yield take(RECEIVE_USE_CASE)

        const isArticleAllocationFamily = isArticleAllocationFamilyCheck(useCase.family)

        yield put(push(generatePath((isArticleAllocationFamily ? ARTICLE_ALLOCATION_RESULTS_PATH : REPLENISHMENT_RESULTS_PATH), { usecase: payload.useCaseId })))

        yield put(changeToastAction({ useIntl: true, message: RUN_COMPOSITE_PIPELINE_SUCCESS, severity: TOAST_TYPE_SUCCESS }))
      }

      yield put(compositePipelineStatusAutoRefreshStopAction({
        useCaseId,
      }))
    }

    yield delay(AUTO_REFRESH_INTERVAL_IN_MS)
  }
}

function* compositePipelineStatusAutoRefreshStop(task: Task<any>) {
  yield cancel(task)
}

function* deletePipelineGenerator(action: ActionPayload<Pipelines.APIPipelineActionRequest>) {
  try {
    yield call(API.deletePipeline, action.payload)

    yield put(setPrimaryModalPageName(null))

    yield put(changeToastAction({ useIntl: true, message: DELETE_PIPELINE_SUCCESS, severity: TOAST_TYPE_SUCCESS }))
  } catch (e: any) {
    const message = parseAndReportErrorResponse(e, action.payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  }
}

function* triggerPipelineGenerator(action: ActionPayload<Pipelines.APIPipelineActionRequest>) {
  try {
    yield call(API.triggerPipeline, action.payload)

    yield put(changeToastAction({ useIntl: true, message: TRIGGER_PIPELINE_SUCCESS, severity: TOAST_TYPE_SUCCESS }))
  } catch (e: any) {
    const message = parseAndReportErrorResponse(e, action.payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  }
}

function* reportPipelineDeliveryGenerator(action: ActionPayload<PipelineReportDeliveryActionPayload>) {
  try {
    yield call(API.reportPipelineDelivery, action.payload)

    yield put(changeToastAction({ useIntl: true, message: REPORT_PIPELINE_DELIVERY_SUCCESS, severity: TOAST_TYPE_SUCCESS }))

    yield put(setPrimaryModalPageName(null))
  } catch (e: any) {
    const message = parseAndReportErrorResponse(e, action.payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  }
}

export function* watchCompositePipelineStatusAutoRefreshStart(): any {
  while (true) {
    const action: any = yield take(COMPOSITE_PIPELINE_STATUS_AUTO_REFRESH_START)
    const workerTask: Task<any> = yield fork(compositePipelineStatusAutoRefreshStart, action)

    yield takeLatest(COMPOSITE_PIPELINE_STATUS_AUTO_REFRESH_STOP, compositePipelineStatusAutoRefreshStop, workerTask)
  }
}

export function* watchFetchPipelinesList() {
  yield takeEvery(REQUEST_PIPELINES_LIST, fetchPipelinesListGenerator)
}

export function* watchUpdatePipelineExecutionSchedule() {
  yield takeEvery(UPDATE_PIPELINE_EXECUTION_SCHEDULE, updatePipelineExecutionScheduleGenerator)
}

export function* watchUpdatePipelineDeliverySchedule() {
  yield takeEvery(UPDATE_PIPELINE_DELIVERY_SCHEDULE, updatePipelineDeliveryScheduleGenerator)
}

export function* watchDeletePipelineDeliverySchedule() {
  yield takeEvery(DELETE_PIPELINE_DELIVERY_SCHEDULE, deletePipelineDeliveryScheduleGenerator)
}

export function* watchRequestPipelineOverview() {
  yield takeEvery(REQUEST_PIPELINE_OVERVIEW, requestPipelineOverviewGenerator)
}

export function* watchSetPipelineDeliversToCustomerFlag() {
  yield takeEvery(SET_PIPELINE_DELIVERS_TO_CUSTOMER_FLAG, setPipelineDeliversToCustomerFlagGenerator)
}

export function* watchRequestCompositePipelineStatus() {
  yield takeEvery(REQUEST_COMPOSITE_PIPELINE_STATUS, requestCompositePipelineStatusGenerator)
}

export function* watchStartCompositePipeline() {
  yield takeEvery(START_COMPOSITE_PIPELINE, startCompositePipelineActionGenerator)
}

export function* watchStopCompositePipeline() {
  yield takeEvery(STOP_COMPOSITE_PIPELINE, stopCompositePipelineActionGenerator)
}

export function* watchTriggerPipeline() {
  yield takeEvery(TRIGGER_PIPELINE, triggerPipelineGenerator)
}

export function* watchDeletePipeline() {
  yield takeEvery(DELETE_PIPELINE, deletePipelineGenerator)
}

export function* watchUpdatePipelineConfiguration() {
  yield takeEvery(UPDATE_PIPELINE_CONFIGURATION, updatePipelineConfigurationGenerator)
}

export function* watchReportPipelineDelivery() {
  yield takeEvery(REPORT_PIPELINE_DELIVERY, reportPipelineDeliveryGenerator)
}
