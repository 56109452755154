import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: theme.spacing(3),
    padding: '7px 0px',

    '& .MuiIconButton-root': {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '&.MuiSwitch-sizeSmall .MuiSwitch-thumb': {
      width: '18px',
      height: '18px',
      boxShadow: 'none',
      border: '1px solid #CCCCCC',
      transform: 'translate(0px, 3px)',
    },
    '&.MuiSwitch-sizeSmall .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translate(12px, 0px)',
    },
    '&.MuiSwitch-sizeSmall .Mui-checked .MuiSwitch-thumb': {
      border: `1px solid ${theme.palette.new.violet}`,
    },
    '&.MuiSwitch-sizeSmall .MuiSwitch-switchBase': {
      padding: 0,
    },
    '& .MuiSwitch-switchBase': {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  switchTrackSucceess: {
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    opacity: '1 !important',
  },
  switchBaseSucceess: {
    color: theme.palette.new.white,
    '&.Mui-disabled': {
      color: theme.palette.new.grey_b,
      '&.Mui-checked + .MuiSwitch-track': {
        opacity: '1 !important',
      },
      '&.Mui-checked': {
        color: theme.palette.new.grey_b,
      },
    },
    '&.Mui-checked': {
      color: theme.palette.new.violet,

      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#F7F7F7',
      border: '1px solid rgba(0, 0, 0, 0.2)',
      opacity: 1,
    },
  },
  switchPrimarySucceess: {
    '&.MuiSwitch-thumb': {
      border: `1px solid ${theme.palette.new.violet}`,
    },
    '&.Mui-checked': {
      color: theme.palette.new.violet,
    },
    '&.Mui-checked + .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: '#F7F7F7',
      border: '1px solid rgba(0, 0, 0, 0.2)',
    },
  },
}))

export default useStyles
