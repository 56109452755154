import React from 'react'
import { Box } from '@mui/material'

import { SOURCE_TYPE_SELECTOR_ID } from '@components/connect-view/parameters/ParameterTemplateSelector'
import ParameterSelectorNoOptionSectionComponent from '@components/connect-view/parameters/ParameterSelectorNoOptionSection'

import useStyles from './ParameterSelectorListBox.styles'

const ParameterSelectorListBoxComponent = React.forwardRef((props: any, ref) => {
  const { children, id, ...rest } = props
  const { classes, cx } = useStyles()
  const isSourceTypeSelector = id?.includes(SOURCE_TYPE_SELECTOR_ID)

  return (
    <Box component='ul' {...rest} id={id} ref={ref} data-testid={ParameterSelectorListBoxComponent.name}>
      {children}
      <Box
        className={cx(classes.noOptionsOption, {
          [classes.noOptionsOptionSourceTypes]: isSourceTypeSelector,
        })}
      >
        <ParameterSelectorNoOptionSectionComponent />
      </Box>
    </Box>
  )
})

export default ParameterSelectorListBoxComponent
