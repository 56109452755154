import React, { useMemo } from 'react'
import get from 'lodash.get'
import { useIntl } from 'react-intl'
import { TooltipProps } from 'recharts'
import { createId } from '@utils/common.utils'
import { Box, Typography } from '@mui/material'
import { DEFAULT_TOOLTIP_DATE_FORMAT, formatTimestamp } from '@utils/moment.utils'
import { GridRowSelectionModel } from '@mui/x-data-grid-premium'
import {
  defaultInsightsTooltipValueFormatter,
  generatePredictionPayloadKey,
  getColorByIndex, getLineLabel,
} from '@utils/insights.utils'

import ChartTooltipComponent, { TooltipRow } from '@components/charts/ChartTooltip/ChartTooltip.component'
import ChartTooltipLegendItemComponent from '@components/charts/ChartTooltipLegendItem'
import ChartTooltipContainerComponent from '@components/charts/ChartTooltipContainer'

export interface TooltipRowsItem {
  label: string,
  valueKey: string,
  predictionKey: string,
  absDeviationKey: string,
  secondaryColor: string,
  primaryColor: string,
  defaultColor: string,
}

export interface InsightsChartRowComponentProps {
  label: string
  colorKey: 'primaryColor' | 'secondaryColor' | 'defaultColor'
  valueKey: keyof TooltipRowsItem
  rows: TooltipRowsItem[]
  data: Hera.BaseChartDatasetItem
  showIntervals?: boolean
}

export const InsightsChartRowComponent: React.FC<InsightsChartRowComponentProps> = ({
  label,
  valueKey,
  colorKey,
  rows,
  data,
  showIntervals = true,
}) => {
  const intl = useIntl()

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='flex-start'
      data-testid={InsightsChartRowComponent.name}
    >
      <Typography
        fontWeight={500}
        textAlign='right'
        variant='body1'
      >
        {label}
      </Typography>

      {
        rows.map((row, index) => {
          const value: number | null = get(data, row[valueKey], null)
          const valueToRender = defaultInsightsTooltipValueFormatter(intl, value, { showIntervals })

          return (
            <Typography
              key={createId(index, valueKey)}
              variant='body1'
              textAlign='right'
              color={row[colorKey]}
            >
              {valueToRender}
            </Typography>
          )
        })
      }
    </Box>
  )
}

export interface InsightsChartTooltipProps extends Omit<TooltipProps<any, any>, 'payload'> {
  active?: boolean
  isFetching?: boolean
  targetName?: string
  predictionKeyPrefix?: string
  absDeviationKeyPrefix?: string
  hasGroupingEnabled?: boolean
  selectedRows?: GridRowSelectionModel
  payload?: Hera.BaseChartDatasetItem[]
  lines: Hera.BaseChartLineItem[]
}

const InsightsChartTooltipComponent: React.FC<InsightsChartTooltipProps> = ({
  active, payload, lines, isFetching,
  selectedRows = [], targetName,
  hasGroupingEnabled = true,
  predictionKeyPrefix = '',
  absDeviationKeyPrefix = '',
}) => {
  const intl = useIntl()
  const sortedRows = useMemo(() => {
    const rows: TooltipRowsItem[] = []

    if (!active || !payload || !payload.length || !lines || !lines.length) {
      return rows
    }

    const data: Hera.BaseChartDatasetItem = get(payload[0], 'payload', {})

    lines.forEach((line, index) => {
      rows.push({
        label: line.label,
        valueKey: line.id,
        predictionKey: generatePredictionPayloadKey(line.id, predictionKeyPrefix),
        absDeviationKey: generatePredictionPayloadKey(line.id, absDeviationKeyPrefix),
        defaultColor: 'black',
        secondaryColor: getColorByIndex(index, line.id, {
          isFetching,
          hasGroupingEnabled,
          useColorWay: true,
          useOpacity: true,
          selectedRows,
        }),
        primaryColor: getColorByIndex(index, line.id, {
          isFetching,
          hasGroupingEnabled,
          useColorWay: true,
          useOpacity: false,
          selectedRows,
        }),
      })
    })

    return rows.sort((a, b) => {
      const aPredictionValue: number = get(data, a.predictionKey, 0) as number
      const bPredictionValue: number = get(data, b.predictionKey, 0) as number
      const aTruthValue: number = get(data, a.valueKey, 0) as number
      const bTruthValue: number = get(data, b.valueKey, 0) as number
      const aName = a.label
      const bName = b.label

      if (bPredictionValue !== aPredictionValue) {
        return bPredictionValue - aPredictionValue
      }

      if (bTruthValue !== aTruthValue) {
        return bTruthValue - aTruthValue
      }

      return aName.localeCompare(bName)
    })
  }, [
    active, payload, lines, isFetching, selectedRows,
    predictionKeyPrefix, absDeviationKeyPrefix, hasGroupingEnabled,
  ])

  /** Use regular tooltip when we do not group. */
  if (!hasGroupingEnabled) {
    const rows = [{
      key: 'date',
      numeric: false,
      label: intl.formatMessage({ id: 'insights.chart.x.title' }),
      valueFormatter: (value: number) => formatTimestamp(value, intl.locale, DEFAULT_TOOLTIP_DATE_FORMAT),
    }] as TooltipRow<Hera.BaseChartDatasetItem>[]

    lines.forEach((line, index) => {
      rows.push({
        key: line.id,
        label: getLineLabel(intl, line, targetName),
        numeric: true,
        legendColor: getColorByIndex(index, line.id, {
          isFetching,
          hasGroupingEnabled,
          useColorWay: false,
          useOpacity: false,
          selectedRows,
        }),
        valueFormatter: (value: number | null) => {
          return defaultInsightsTooltipValueFormatter(intl, value, { showIntervals: true })
        },
      })
    })

    if (absDeviationKeyPrefix) {
      rows.push({
        key: absDeviationKeyPrefix,
        numeric: false,
        label: intl.formatMessage({ id: 'insights.chart.tooltip.absDeviation' }),
        valueFormatter: (value: number | null) => {
          return defaultInsightsTooltipValueFormatter(intl, value, { showIntervals: false })
        },
      })
    }

    return (
      <ChartTooltipComponent<Hera.BaseChartDatasetItem>
        rows={rows}
        sort={true}
        disabled={isFetching}
        active={active}
        payload={payload}
      />
    )
  }

  if (active && !isFetching && payload && sortedRows && sortedRows.length && payload.length) {
    const data: Hera.BaseChartDatasetItem = get(payload[0], 'payload', {})

    return (
      <ChartTooltipContainerComponent
        data-testid={InsightsChartTooltipComponent.name}
      >
        <Box
          display='flex'
          flexDirection='row'
          alignItems='center'
          justifyContent='space-between'
          mb={2}
        >
          <Typography
            fontWeight={500}
          >
            {intl.formatMessage({ id: 'insights.chart.x.title' })}
          </Typography>
          <Typography>
            {formatTimestamp(data.date, intl.locale, DEFAULT_TOOLTIP_DATE_FORMAT)}
          </Typography>
        </Box>

        <Box
          display='flex'
          flexDirection='row'
          justifyContent='space-between'
          alignItems='flex-start'
          gap={2}
        >
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='flex-start'
          >
            <Typography>
              &nbsp;
            </Typography>

            {
              sortedRows.map((row, index) => {
                return (
                  <ChartTooltipLegendItemComponent
                    type='line'
                    color={row.primaryColor}
                    label={row.label}
                    key={createId(index, row.label)}
                  />
                )
              })
            }
          </Box>

          <InsightsChartRowComponent
            label={intl.formatMessage({ id: 'insights.chart.tooltip.actual' })}
            colorKey='secondaryColor'
            valueKey='valueKey'
            rows={sortedRows}
            data={data}
          />

          <InsightsChartRowComponent
            label={intl.formatMessage({ id: 'insights.chart.tooltip.predicted' })}
            colorKey='primaryColor'
            valueKey='predictionKey'
            rows={sortedRows}
            data={data}
          />

          {
            absDeviationKeyPrefix ? (
              <InsightsChartRowComponent
                label={intl.formatMessage({ id: 'insights.chart.tooltip.absDeviation' })}
                colorKey='defaultColor'
                valueKey='absDeviationKey'
                rows={sortedRows}
                data={data}
                showIntervals={false}
              />
            ) : (
              null
            )
          }
        </Box>
      </ChartTooltipContainerComponent>
    )
  }

  return null
}

export default InsightsChartTooltipComponent
