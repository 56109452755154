import React from 'react'
import { useIntl } from 'react-intl'

import {
  Typography, TableBody,
  TableRow, TableCell, Box,
} from '@mui/material'

import useStyles from './NoTableData.styles'

export interface NoTableDataComponentProps {
  className?: string,
  colSpan?: number,
  search?: string,
  customNoDataMessage?: React.ReactElement | React.ReactNode | React.ReactNode[],
}

const NoTableDataComponent: React.FC<NoTableDataComponentProps> = ({
  className, colSpan, search,
  customNoDataMessage,
}) => {
  const { classes, cx } = useStyles()
  const intl = useIntl()
  const dataToRender = customNoDataMessage || (
    <Box className={cx(classes.searchContainer, className)}>
      <Typography className={classes.searchEmptyHeader}>
        {intl.formatMessage({ id: 'common.search_empty_header' })}
      </Typography>

      <Typography className={classes.searchEmptyText}>
        {
          search ? (
            intl.formatMessage({ id: 'common.search_empty_text' }, { search })
          ) : (
            intl.formatMessage({ id: 'common.table_empty_text' })
          )
        }
      </Typography>
    </Box>
  )

  return (
    <TableBody data-testid={NoTableDataComponent.name}>
      <TableRow
        hover={false}
        className={classes.row}
      >
        <TableCell colSpan={colSpan}>
          {dataToRender}
        </TableCell>
      </TableRow>
    </TableBody>
  )
}

export default NoTableDataComponent
