import React, {
  useMemo,
} from 'react'

import { useIntl } from 'react-intl'
import { Box } from '@mui/material'
import { useDispatch, useSelector } from '@redux/hooks'
import { getSelectedLanguage } from '@redux/modules/customer/customer.selectors'

import DateRangeSliderComponent from '@base/dates/DateRangeSlider'
import DateRangePickerComponent from '@base/dates/DateRangePicker'
import AbcClassificationSelectorComponent from '@components/(features)/insights/abc-classification-selector'
import InsightsFiltersLayoutComponent from '@components/(features)/insights/insights-filters-layout/InsightsFiltersLayout.component'

import { foldsToOptions } from '@utils/monitor.utils'
import { requestLiveMonitoringGridStateChangeAction } from '@redux/modules/monitor/monitor.actions'

import {
  getLiveMonitoringChart, isFetchingLiveMonitoringChart,
  getLiveMonitoringTable, getLiveMonitoringAvailableForecastHorizons,
  isFetchingLiveMonitoring, getLiveMonitoringAbcTotals,
} from '@redux/modules/monitor/monitor.selectors'
import { DATE_PICKER_SHORTCUT } from '@constants/date.constants'

const LiveMonitoringFiltersContainer = () => {
  const intl = useIntl()
  const dispatch = useDispatch()

  const liveMonitoringTable = useSelector((state) => getLiveMonitoringTable(state))
  const liveMonitoringChart = useSelector((state) => getLiveMonitoringChart(state))
  const isFetchingLiveMonitoringChartFlag = useSelector((state) => isFetchingLiveMonitoringChart(state))
  const isFetchingLiveMonitoringFlag = useSelector((state) => isFetchingLiveMonitoring(state))
  const isFetching = isFetchingLiveMonitoringChartFlag || isFetchingLiveMonitoringFlag
  const abcTotals = useSelector((state) => getLiveMonitoringAbcTotals(state))
  const liveMonitoringAvailableForecastHorizons = useSelector((state) => getLiveMonitoringAvailableForecastHorizons(state))
  const userLanguage = useSelector((state) => getSelectedLanguage(state))

  const {
    gridState,
    targetName,
  } = liveMonitoringTable

  const {
    abcClassification,
  } = liveMonitoringChart

  const timeWindow = useMemo(() => (gridState && gridState.timeWindow), [gridState])
  const liveMonitoringFold = useMemo(() => (gridState && gridState.liveForecastHorizonOffset), [gridState])
  const liveMonitoringFoldsOptions = useMemo(() => foldsToOptions(intl, liveMonitoringAvailableForecastHorizons), [intl, liveMonitoringAvailableForecastHorizons])

  const handleDatesChange = (value: Common.DatePickerValue) => {
    dispatch(
      requestLiveMonitoringGridStateChangeAction({
        timeWindow: value,
      }),
    )
  }

  const handleViewResultAsOfChange = (value: number | string) => {
    dispatch(
      requestLiveMonitoringGridStateChangeAction({
        liveForecastHorizonOffset: Number(value),
      }),
    )
  }

  const handleAbcClassificationChange = (value: Hera.APIABCFilter) => {
    dispatch(
      requestLiveMonitoringGridStateChangeAction({
        abcFilter: value,
      }),
    )
  }

  const leftSideSection = (
    <Box
      display='flex'
      flexDirection='row'
      alignItems='center'
      gap={2}
    >
      <DateRangePickerComponent
        overline={intl.formatMessage({ id: 'insights.timeFrame' })}
        onChange={handleDatesChange}
        value={timeWindow}
        name='timeWindow'
        disabled={!gridState}
        userLanguage={userLanguage}
        hiddenShortcuts={[DATE_PICKER_SHORTCUT.FROM_TODAY]}
      />

      <DateRangeSliderComponent
        overline={intl.formatMessage({ id: 'insights.viewResultAsOf' })}
        onChange={handleViewResultAsOfChange}
        value={liveMonitoringFold!}
        name='viewResultAsOf'
        loading={isFetchingLiveMonitoringFlag}
        disabled={!gridState}
        options={liveMonitoringFoldsOptions}
        reversed={true}
      />
    </Box>
  )

  const abcClassificationSection = (
    <AbcClassificationSelectorComponent
      abcClassification={abcClassification}
      abcTotals={abcTotals}
      targetName={targetName}
      gridState={gridState!}
      selectedValue={gridState?.abcFilter}
      loading={isFetching}
      onClick={handleAbcClassificationChange}
    />
  )

  return (
    <InsightsFiltersLayoutComponent
      dataTestId={LiveMonitoringFiltersContainer.name}
      leftSideSection={leftSideSection}
      abcSection={abcClassificationSection}
    />
  )
}

export default LiveMonitoringFiltersContainer
