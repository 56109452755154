import React, { ReactNode, useEffect, useState } from 'react'
import { useIntl as useIntlHook } from 'react-intl'

import Snackbar from '@mui/material/Snackbar'
import Alert, { AlertColor } from '@mui/material/Alert'

import {
  Box, Slide, SlideProps,
  styled, useTheme,
} from '@mui/material'

import CloseIcon from '@icons/close.icon'
import AlertInfoIcon from '@icons/alerts/alertInfo.icon'
import AlertSuccessIcon from '@icons/alerts/alertSuccess.icon'
import AlertWarningIcon from '@icons/alerts/alertWarning.icon'
import AlertErrorIcon from '@icons/alerts/alertError.icon'

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import { TOAST_TYPE_ERROR } from '@constants/common.constants'
import { DEFAULT_BUTTON_TRANSITION, DEFAULT_BORDER_RADIUS, DEFAULT_PADDING } from '@constants/ui.constants'

export const SlideTransition = (props: SlideProps) => (
  <Slide {...props} direction='down' />
)

export const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.new.transparent,
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: theme.palette.new.white_60,
  },
}))

export const BOX_SHADOW_MAPPING: {
  [key in AlertColor]: string
} = {
  error: '0px 10px 30px 0px rgba(251, 108, 74, 0.40)',
  success: '0px 10px 30px 0px rgba(0, 192, 77, 0.40)',
  warning: '0px 10px 30px 0px rgba(251, 176, 59, 0.40)',
  info: '0px 10px 30px 0px rgba(81, 215, 230, 0.40)',
}

export const ICON_MAPPING: {
  [key in AlertColor]: ReactNode
} = {
  error: <AlertErrorIcon detailsFill='#fff' />,
  success: <AlertSuccessIcon detailsFill='#fff' />,
  warning: <AlertWarningIcon detailsFill='#fff' />,
  info: <AlertInfoIcon detailsFill='#fff' />,
}

export interface SnackbarComponentProps {
  /**
   * If `true`, the toast is shown.
   */
  open?: boolean,
  /**
   * The number of milliseconds to wait before automatically calling `onClose`.
   */
  autoHideDuration?: number,
  /**
   * Callback fired when the component requests to be closed.
   */
  handleClose?: {
    (): void,
  },
  /**
   * The vertical position of the toast.
   * @default 'bottom'
   */
  verticalPosition?: 'bottom' | 'top',
  /**
   * The horizontal position of the toast.
   * @default 'center'
   */
  horizontalPosition?: 'left' | 'center' | 'right',
  /**
   * The message to be shown in the toast.
   * if `useIntl` is `true`, the message will be formatted using `intl.formatMessage`.
   */
  toastMessage?: string | React.ReactNode,
  /**
   * If `true`, the message will be formatted using `intl.formatMessage`.
   */
  useIntl?: boolean,
  /**
   * The parameters to be used in the `intl.formatMessage`.
   */
  intlParameters?: {
    [key: string]: any,
  },
  /**
   * The severity of the toast.
   * @default 'error'
   */
  severity?: AlertColor,
}

const PROGRESS_INTERVAL = 100
const ANIMATION_DELAY = 300

const SnackbarComponent: React.FC<SnackbarComponentProps> = ({
  open = false,
  autoHideDuration = 6000,
  handleClose,
  verticalPosition = 'top',
  horizontalPosition = 'center',
  toastMessage,
  useIntl = false,
  intlParameters = {},
  severity = TOAST_TYPE_ERROR,
}) => {
  const intl = useIntlHook()
  const theme = useTheme()

  const [progress, setProgress] = useState(100)
  const [isHovered, setIsHovered] = useState(false)
  const [timerStopped, setTimerStopped] = useState(false)

  useEffect(() => {
    if (!open) {
      setProgress(100)
      setIsHovered(false)
      setTimerStopped(false)
    }
  }, [open])

  useEffect(() => {
    if (open && !isHovered && !timerStopped) {
      const interval = PROGRESS_INTERVAL
      const totalDuration = autoHideDuration - ANIMATION_DELAY
      let timePassed = 0

      const timer = setInterval(() => {
        timePassed += interval

        const newProgress = Math.max(100 - (timePassed / totalDuration) * 100, 0)

        setProgress(newProgress)

        if (timePassed >= totalDuration) {
          clearInterval(timer)
        }
      }, interval)

      return () => clearInterval(timer)
    }

    return undefined
  }, [open, autoHideDuration, isHovered, timerStopped])

  const handleMouseEnter = () => {
    setIsHovered(true)
    setTimerStopped(true)
    setProgress(0)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  return (
    <Box
      data-testid={SnackbarComponent.name}
    >
      <Snackbar
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        anchorOrigin={{
          vertical: verticalPosition,
          horizontal: horizontalPosition,
        }}
        TransitionComponent={SlideTransition}
        sx={{
          '&.MuiSnackbar-anchorOriginTopCenter': {
            top: '30px',
          },
        }}
      >
        <Box
          width='100%'
        >
          <Alert
            variant='filled'
            onClose={handleClose}
            severity={severity}
            iconMapping={ICON_MAPPING}
            slots={{
              closeIcon: CloseIcon,
            }}
            slotProps={{
              closeButton: {
                disableRipple: true,
                sx: {
                  p: 0,
                  transition: DEFAULT_BUTTON_TRANSITION,
                  '&:hover': {
                    opacity: 0.6,
                    backgroundColor: theme.palette.new.transparent,
                  },
                  '&:focus-visible': {
                    opacity: 0.6,
                    backgroundColor: theme.palette.new.transparent,
                  },
                  '&:active': {
                    opacity: 1,
                    backgroundColor: theme.palette.new.transparent,
                  },
                },
              },
              closeIcon: {
                detailsFill: theme.palette.new.white,
              } as any,
            }}
            sx={{
              overflow: 'hidden',
              position: 'relative',
              boxShadow: BOX_SHADOW_MAPPING[severity],
              borderRadius: DEFAULT_BORDER_RADIUS.DEFAULT,
              color: theme.palette.new.white,
              p: DEFAULT_PADDING.LARGE,
              gap: '10px',
              fontSize: '16px',
              lineHeight: '125%',
              fontWeight: 500,

              '& .MuiAlert-icon': {
                mr: 0,
                p: 0,
                opacity: 1,
              },
              '& .MuiAlert-message': {
                p: 0,
              },
              '& .MuiAlert-action': {
                p: 0,
                m: 0,
              },
              '&.MuiAlert-filledInfo': {
                backgroundColor: theme.palette.new.talkative_turquoise,
              },
              '&.MuiAlert-filledError': {
                backgroundColor: theme.palette.new.rebellious_red,
              },
              '&.MuiAlert-filledSuccess': {
                backgroundColor: theme.palette.new.generous_green,
              },
              '&.MuiAlert-filledWarning': {
                backgroundColor: theme.palette.new.youthful_yellow,
              },
            }}
          >
            {useIntl ? (
              intl.formatMessage({ id: String(toastMessage) }, intlParameters)
            ) : (
              toastMessage
            )}

            {!isHovered && (
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                }}
              >
                <CustomLinearProgress variant='determinate' value={progress} />
              </Box>
            )}
          </Alert>
        </Box>
      </Snackbar>
    </Box>
  )
}

export default SnackbarComponent
