import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  authBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  authSuccessBlock: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  oauth: {
    color: theme.palette.new.white,
    fontSize: '18px !important',
    fontWeight: '500 !important',
    padding: theme.spacing(0, 2),
    marginRight: theme.spacing(3),
    textTransform: 'initial',
    height: theme.spacing(4),
    '& .MuiButton-label': {
      fontSize: '18px !important',
    },
  },
  oauthError: {
    fontWeight: 500,
    fontSize: '18px',
    color: '#C730BC',
  },
  authSuccessBlockButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  authSuccessButtonContainer: {
    marginLeft: theme.spacing(2),
  },
  oauthSuccess: {
    fontWeight: 500,
    fontSize: '18px',
    color: theme.palette.new.black,
  },
  oauthHidden: {
    display: 'none',
  },
}))

export default useStyles
