import get from 'lodash.get'
import * as Sentry from '@sentry/react'
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { createReduxHistoryContext } from 'redux-first-history'
import { ReducerPayload, State } from '@redux/modules/types'
import { ERROR_CODES_TO_IGNORE, IS_LOCAL_ENV } from '@constants/common.constants'
import { createStoreDispatchProxy } from '@configuration/proxy.config'
import createRootReducer from '@redux/modules'
import { saga } from '@redux/modules/saga'
import { history, historyListener } from '@configuration/history.config'
import produce from 'immer'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose,
    __REDUX_DEVTOOLS_EXTENSION__: typeof compose,
    _env_: any,
  }
}

export const STATUS_FAIL = 'fail'
export const STATUS_CODES = {
  404: 404,
}

/**
 * @function reportEventToSentry Reports error to sentry
 *
 * @param {Object} error Error object
 *
 * @return {void}
 */
export const reportEventToSentry = (error: Error, payload = {}) => {
  const errorCode = get(error, 'code', '')

  if (ERROR_CODES_TO_IGNORE.includes(errorCode)) {
    return
  }

  if (!IS_LOCAL_ENV) {
    Sentry.withScope((scope) => {
      scope.setExtra('data', payload)

      Sentry.captureException(error)
    })
  }
}

/**
 * @function checkApiForError Check API response for error
 *
 * @param {Object} response API response object
 * @param {Boolean} withException if true, will throw an error
 *
 * @return {String} Error message
 */
// eslint-disable-next-line
export const checkApiForError = (response: any, withException = true) => {
  if (response && (response.status === STATUS_FAIL)) {
    const errorMessage = get(response, 'data.description')
    const error = new Error(errorMessage)
    // @ts-ignore
    error.status = 400
    error.message = errorMessage

    if (withException) {
      throw error
    }

    return error
  }
}

/**
 * @function parseAndReportErrorResponse Parses error from Promises
 *
 * @param {Object} error Error object
 *
 * @return {String} Error message
 */
export const parseAndReportErrorResponse = (error: Error | any, payload: any) => {
  reportEventToSentry(error, payload)

  // eslint-disable-next-line
  console.error(error, payload)

  return get(error, 'response.data.data.description') || get(error, 'message') || get(error, 'response.message') || get(error, 'data.message') || get(error, 'response.data.error')
}

/**
 * @function isNotFound Check whether it is 404
 *
 * @param {Object} error Error object
 *
 * @return {Boolean} true, if 404 true
 */
export const isNotFound = (error: Error) => {
  return (get(error, 'response.status') === STATUS_CODES['404'])
}

/**
 * @function initRedux Initialize Redux store and history
 * @returns {Object} Redux store and history
 */
export const initReduxAndRouter = () => {
  const reduxDevToolsMiddleware = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() as any
  const reduxSagaMiddleware = createSagaMiddleware()

  /** Init Router listener */
  history.listen(historyListener)

  /** Init redux history */
  const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history,
  })

  /* Preapare Reducers & Store */
  const applicationReducer = createRootReducer(routerReducer)

  /** Setup Senty Redux integration */
  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    stateTransformer: (state: State) => {
      return {
        analyzeFetchingKeys: state.analyze.fetchingKeys,
        analyzeTable: {
          ...state.analyze.analyzeTable,
          rows: [],
        },
        analyzeChart: {
          ...state.analyze.analyzeChart,
          dataset: [],
        },
        customer: {
          ...state.customer,
          intlMessages: {},
        },
      }
    },
  })

  /** Init redux store */
  const store = (IS_LOCAL_ENV && window.__REDUX_DEVTOOLS_EXTENSION__!) ? (
    createStore(applicationReducer, compose(
      applyMiddleware(
        reduxSagaMiddleware,
        reduxDevToolsMiddleware,
        routerMiddleware,
      ),
    ))
  ) : (
    createStore(applicationReducer, compose(
      applyMiddleware(
        reduxSagaMiddleware,
        routerMiddleware,
      ),
      sentryReduxEnhancer,
    ))
  )

  /** Create redux history */
  const reduxHistory = createReduxHistory(store)

  /* Start Redux Saga */
  reduxSagaMiddleware.run(saga)

  /* Init Proxy Object to expose store.dispatch */
  createStoreDispatchProxy(store)

  return {
    store,
    reduxHistory,
  }
}

/**
 * @function startFetchingAction Start fetching action
 *
 * @param state Current state
 * @param action Action payload
 *
 * @returns {Object} New state
 */
export function startFetchingAction<T extends { fetchingKeys: string[] }>(state: T, action: ReducerPayload<string>) {
  const nextState = produce(state, (draftState) => {
    draftState.fetchingKeys.push(action.payload)
  })

  return nextState
}

/**
 * @function stopFetchingAction Start fetching action
 *
 * @param state Current state
 * @param action Action payload
 *
 * @returns {Object} New state
 */
export function stopFetchingAction<T extends { fetchingKeys: string[] }>(state: T, action: ReducerPayload<string>) {
  const nextState = produce(state, (draftState) => {
    // eslint-disable-next-line
    draftState.fetchingKeys = draftState.fetchingKeys.filter((key) => key !== action.payload)
  })

  return nextState
}

/**
 * @function getInitialState Get initial state of the store for testing
 *
 * @returns {Object} Initial state
 */
export const getDummyInitialState = () => {
  const mockStore = createStore(createRootReducer({} as any))
  const initialState = mockStore.getState()

  return initialState
}
