import { handleActions } from 'redux-actions'
import { ParametersState } from '@redux/modules/types'

import {
  RECEIVE_PARAMETER,
  RECEIVE_GROUPING,
  START_FETCHING_PARAMETER,
  STOP_FETCHING_PARAMETER,
} from './parameters.action-types'

import {
  startFetching,
  stopFetching,
  receive,
  receiveGrouping,
  initialState,
} from './parameters.reducers'

export default handleActions<ParametersState, any>({
  [RECEIVE_PARAMETER]: receive,
  [RECEIVE_GROUPING]: receiveGrouping,
  [START_FETCHING_PARAMETER]: startFetching,
  [STOP_FETCHING_PARAMETER]: stopFetching,
}, initialState)
