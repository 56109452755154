import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { getGroupingAttributesWidth, getGroupingAttributesHeigth } from '@utils/flow.utils'

const useStyles = makeStyles<{ numOfNodes: number }, 'groupingAttributesChunk'>()((theme: Theme, { numOfNodes }, classes) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: getGroupingAttributesWidth(numOfNodes),
    height: getGroupingAttributesHeigth(numOfNodes),
    background: theme.palette.new.white,
    border: '1px solid',
    borderColor: theme.palette.new.grey_a,
    borderRadius: theme.spacing(0.5),
    position: 'relative',
    cursor: 'default',
  },
  content: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: '100%',
  },
  groupingAttributesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    [`& .${classes.groupingAttributesChunk}`]: {
      marginRight: theme.spacing(2),
    },
    '& :last-child': {
      marginRight: 0,
    },
  },
  groupingAttributesChunk: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%',
  },
}))

export default useStyles
