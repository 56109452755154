import theme from '@configuration/theme.config'
import { INSIGHTS_COLOR_WAY } from '@constants/insights.constants'

export enum PIPELINE_VIEW_TYPES {
  USECASE = 'USECASE',
  GLOBAL = 'GLOBAL',
}

export enum PIPELINE_TYPES {
  DEMAND_PIPELINE = 'demand_pipeline',
  ELT_PIPELINE = 'elt_pipeline',
  OPTIMIZATION_PIPELINE = 'optimization_pipeline',
  COMPOSITE_PIPELINE = 'composite_pipeline',
  POSTPROCESSING_PIPELINE = 'postprocessing_pipeline',
}

export enum PIPELINE_METRICS {
  MAE = 'MAE',
  RMSE = 'RMSE',
  MARRE = 'MARRE',
  WAPE = 'WAPE',
  MAPE = 'MAPE',
}

export enum PIPELINE_METRICS_RELATIVE {
  WAPE = 'WAPE',
  MARRE = 'MARRE',
  MAPE = 'MAPE',
}

export enum PIPELINE_METRICS_ABSOLUTE {
  MAE = 'MAE',
  RMSE = 'RMSE',
  BIAS = 'BIAS',
}

export enum PIPELINE_DELIVERY_STATUS {
  FULFILLED = 'FULFILLED',
  MISSED = 'MISSED',
  DELAYED = 'DELAYED',
}

export enum PIPELINE_TIME_RESOLUTION {
  DAILY = '1D',
  WEEKLY = '1W',
}

export const PIPELINE_METRICS_DEFAULT = PIPELINE_METRICS.MAE
export const PIPELINE_METRICS_OPTIONS = Object.values(PIPELINE_METRICS).map((metric) => ({
  label: metric,
  value: metric,
}))

export const PIPELINE_METRICS_RELATIVE_DEFAULT = PIPELINE_METRICS_RELATIVE.WAPE
export const PIPELINE_METRICS_RELATIVE_OPTIONS = Object.values(PIPELINE_METRICS_RELATIVE).map((metric) => ({
  label: metric,
  value: metric,
}))

export const PIPELINE_METRICS_ABSOLUTE_DEFAULT = PIPELINE_METRICS_ABSOLUTE.MAE
export const PIPELINE_METRICS_ABSOLUTE_OPTIONS = Object.values(PIPELINE_METRICS_ABSOLUTE).map((metric) => ({
  label: metric,
  value: metric,
}))

export const PIPELINE_TIME_RESOLUTION_DEFAULT = PIPELINE_TIME_RESOLUTION.DAILY
export const PIPELINE_TIME_RESOLUTION_OPTIONS = Object.values(PIPELINE_TIME_RESOLUTION).map((resolution) => ({
  label: resolution,
  value: resolution,
}))

export const PIPELINE_DELIVERY_STATUS_OPTIONS = Object.values(PIPELINE_DELIVERY_STATUS).map((status) => ({
  label: status,
  value: status,
}))

export const getChipBgColorByStatus = (status: PIPELINE_DELIVERY_STATUS) => {
  switch (status) {
    case PIPELINE_DELIVERY_STATUS.FULFILLED:
      return theme.palette.new.generous_green
    case PIPELINE_DELIVERY_STATUS.MISSED:
      return theme.palette.new.rebellious_red
    case PIPELINE_DELIVERY_STATUS.DELAYED:
      return theme.palette.new.youthful_yellow
    default:
      return theme.palette.new.grey_a
  }
}

export const PIPELINE_MIN_RAM_MB = 1

export const PIPELINES_WITH_RESOURCE_MANAGEMENT = [PIPELINE_TYPES.DEMAND_PIPELINE, PIPELINE_TYPES.OPTIMIZATION_PIPELINE]
export const PIPELINES_WITH_GPU = [PIPELINE_TYPES.DEMAND_PIPELINE]

export const getPipelineTypeBgColor = (value: string) => {
  const type = value as PIPELINE_TYPES

  switch (type) {
    case PIPELINE_TYPES.DEMAND_PIPELINE:
      return `${INSIGHTS_COLOR_WAY[0]}55`
    case PIPELINE_TYPES.COMPOSITE_PIPELINE:
      return `${INSIGHTS_COLOR_WAY[1]}55`
    case PIPELINE_TYPES.ELT_PIPELINE:
      return `${INSIGHTS_COLOR_WAY[2]}55`
    case PIPELINE_TYPES.OPTIMIZATION_PIPELINE:
      return `${INSIGHTS_COLOR_WAY[3]}55`
    case PIPELINE_TYPES.POSTPROCESSING_PIPELINE:
      return `${INSIGHTS_COLOR_WAY[4]}55`
    default:
      return undefined
  }
}
