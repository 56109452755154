import { PARETOS_GRADIENT } from '@constants/ui.constants'
import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

type allowedClassnames = 'header' | 'subHeader' | 'value'

const useStyles = makeStyles<void, allowedClassnames>()((theme: Theme, props, classnames) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minWidth: '260px',
    minHeight: '240px',
    padding: theme.spacing(3, 3),
  },
  borderRight: {
    borderRight: `1px solid ${theme.palette.new.grey_b}`,
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.new.grey_b}`,
  },
  headerRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  header: {
    fontWeight: 500,
    fontSize: '30px',
    lineHeight: '40px',
    color: theme.palette.new.black,
  },
  subHeader: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.new.black,
  },
  value: {
    fontWeight: 500,
    fontSize: '48px',
    color: theme.palette.new.black,
    lineHeight: 1,
  },
  colorfull: {
    background: PARETOS_GRADIENT,
    borderBottomRightRadius: theme.spacing(1),
    [`& .${classnames.header}`]: {
      color: theme.palette.new.white,
    },
    [`& .${classnames.subHeader}`]: {
      color: theme.palette.new.white,
    },
    [`& .${classnames.value}`]: {
      color: theme.palette.new.white,
    },
  },
}))

export default useStyles
