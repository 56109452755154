import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles<void, 'buttonRoot'>()((theme: Theme, props, classes) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(2),

    [`& .${classes.buttonRoot}:first-of-type`]: {
      marginRight: theme.spacing(2),
    },
  },
  iconLeft: {
    transform: 'rotate(90deg)',
  },
  iconRight: {
    transform: 'rotate(-90deg)',
  },
  buttonRoot: {
    padding: '9px',
    borderRadius: '100%',

    '&.Mui-disabled': {
      background: theme.palette.new.grey,

      '& svg': {
        opacity: 0.2,
      },
    },
  },
}))

export default useStyles
