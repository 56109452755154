export const ANALYZE_TABLE_CONFIG_VERSION = 3
export const BACKTESTING_TABLE_CONFIG_VERSION = 3
export const LIVE_MONITORING_TABLE_CONFIG_VERSION = 3

export const INSIGHTS_COLOR_WAY = [
  '#51D7E6',
  '#F96385',
  '#AAA3CC',
  '#FBB03B',
  '#00C0AD',
  '#E026A7',
  '#D7E651',
  '#518DE6',
  '#3BFBB0',
  '#F51400',
  '#96BEF9',
  '#26E05F',
]

export const INSIGHTS_DEFAULT_SELECTED_ROWS_IN_GROUPPING_MODE = 5

export const INSIGHTS_MAX_SELECTED_ROWS_IN_GROUPPING_MODE = 12

export const DEFAULT_BACKTEST_TARGET_COLUMN = '_target'
export const DEFAULT_BACKTEST_PREDICTION_COLUMN = '_backtest_prediction'
export const DEFAULT_BACKTEST_PREDICTION_FIELD_PREFIX = 'backtest_prediction'
export const DEFAULT_BACKTEST_ABS_DEVIATION_FIELD_PREFIX = 'abs_deviation'

export const DEFAULT_REL_DEVIATION_COLUMN = '_rel_deviation'
export const DEFAULT_ABS_DEVIATION_COLUMN = '_abs_deviation'

export const DEFAULT_AGGREGATED_BACKTEST_TARGET_FIELD = 'target'
export const DEFAULT_AGGREGATED_BACKTEST_PREDICTION_FIELD = 'backtest_prediction'

export const DEFAULT_LIVE_MONITORING_TARGET_COLUMN = '_target'
export const DEFAULT_LIVE_MONITORING_PREDICTION_COLUMN = '_prediction'
export const DEFAULT_LIVE_MONITORING_PREDICTION_FIELD_PREFIX = 'prediction'
export const DEFAULT_LIVE_MONITORING_ABS_DEVIATION_FIELD_PREFIX = 'abs_deviation'

export const DEFAULT_AGGREGATED_LIVE_MONITORING_TARGET_FIELD = 'target'
export const DEFAULT_AGGREGATED_LIVE_MONITORING_PREDICTION_FIELD = 'prediction'

export const DEFAULT_ANALYZE_TARGET_COLUMN = '_target'
export const DEFAULT_ANALYZE_PREDICTION_COLUMN = '_prediction'
export const DEFAULT_ANALYZE_PREDICTION_FIELD_PREFIX = 'prediction'
export const DEFAULT_ANALYZE_AGGREGATED_TARGET_FIELD = 'target'
export const DEFAULT_ANALYZE_AGGREGATED_PREDICTION_FIELD = 'prediction'
