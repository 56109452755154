import produce from 'immer'
import sortBy from 'lodash.sortby'

import { ParametersTemplatesState, ReducerPayload } from '@redux/modules/types'

export const initialState: ParametersTemplatesState = {
  isFetching: false,
  isFetchedItem: false,
  isFetchedList: false,
  fetchingKey: '',
  list: [] as UseCase.ParameterTemplateItem[],
  item: {} as UseCase.ParameterTemplateItem,
}

export const receiveOne = (state: ParametersTemplatesState, action: ReducerPayload<UseCase.ParameterTemplateItem>) => {
  const nextState = produce(state, (draftState) => {
    draftState.item = action.payload
    draftState.isFetchedItem = true
  })

  return nextState
}

export const receiveList = (state: ParametersTemplatesState, action: ReducerPayload<UseCase.ParameterTemplateItem[]>) => {
  const nextState = produce(state, (draftState) => {
    draftState.list = sortBy((action.payload || []), 'sourceType', 'name')
    draftState.isFetchedList = true
  })

  return nextState
}

export const startFetching = (state: ParametersTemplatesState, action: ReducerPayload<string>) => {
  const nextState = produce(state, (draftState) => {
    draftState.isFetching = true
    draftState.fetchingKey = draftState.fetchingKey ? draftState.fetchingKey : action.payload
  })

  return nextState
}

export const stopFetching = (state: ParametersTemplatesState) => {
  const nextState = produce(state, (draftState) => {
    draftState.fetchingKey = ''
    draftState.isFetching = false
  })

  return nextState
}
