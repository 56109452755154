import React from 'react'
import { useIntl } from 'react-intl'
import { Typography } from '@mui/material'
import TradeOffIcon from '@icons/tradeOff.icon'
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom'
import { TRADE_OFFS_PATH } from '@constants/routes.constants'
import { OPTIMIZE_RESTOCK_SELECTOR_TYPES } from '@constants/optimize.constants'

import useStyles from './OptimizeStrategySelector.styles'

export interface OptimizeStrategySelectorComponentProps {
  setSelectedType: (type: OPTIMIZE_RESTOCK_SELECTOR_TYPES) => void,
  selectedType: OPTIMIZE_RESTOCK_SELECTOR_TYPES,
}

const OptimizeStrategySelectorComponent: React.FC<OptimizeStrategySelectorComponentProps> = (props) => {
  const {
    setSelectedType,
    selectedType,
  } = props

  const { classes, cx } = useStyles()
  const intl = useIntl()
  const history = useHistory()
  const match = useRouteMatch<Common.RouterMatch>()
  const isTradeOffsSelected = selectedType === OPTIMIZE_RESTOCK_SELECTOR_TYPES.TRADE_OFFS

  const onSelectorItemClick = (type: OPTIMIZE_RESTOCK_SELECTOR_TYPES) => {
    switch (type) {
      case OPTIMIZE_RESTOCK_SELECTOR_TYPES.REPLENISHMENT_COST:
      case OPTIMIZE_RESTOCK_SELECTOR_TYPES.REVENUE:
        setSelectedType(type)
        break

      case OPTIMIZE_RESTOCK_SELECTOR_TYPES.TRADE_OFFS:
      default:
        history.push(generatePath(TRADE_OFFS_PATH, match.params))
    }
  }

  return (
    <div className={classes.selectorContainerRoot} data-testid={OptimizeStrategySelectorComponent.name}>
      <div
        className={cx(classes.selectorItem, {
          [classes.selectorItemSelected]: selectedType === OPTIMIZE_RESTOCK_SELECTOR_TYPES.REPLENISHMENT_COST,
        })}
        onClick={() => onSelectorItemClick(OPTIMIZE_RESTOCK_SELECTOR_TYPES.REPLENISHMENT_COST)}
      >
        <Typography
          className={classes.selectorItemText}
        >
          {intl.formatMessage({ id: 'optimize.selector.replenishmentCost' })}
        </Typography>
      </div>
      <div
        className={cx(classes.selectorItem, {
          [classes.selectorItemSelected]: selectedType === OPTIMIZE_RESTOCK_SELECTOR_TYPES.REVENUE,
        })}
        onClick={() => onSelectorItemClick(OPTIMIZE_RESTOCK_SELECTOR_TYPES.REVENUE)}
      >
        <Typography
          className={classes.selectorItemText}
        >
          {intl.formatMessage({ id: 'optimize.selector.revenue' })}
        </Typography>
      </div>
      <div
        className={cx(classes.selectorItem, {
          [classes.selectorItemSelected]: isTradeOffsSelected,
        })}
        onClick={() => onSelectorItemClick(OPTIMIZE_RESTOCK_SELECTOR_TYPES.TRADE_OFFS)}
      >
        <Typography
          className={classes.selectorItemText}
        >
          <TradeOffIcon detailsFill={isTradeOffsSelected ? 'black' : 'white'} />
          {intl.formatMessage({ id: 'optimize.selector.customTradeOff' })}
        </Typography>
      </div>
    </div>
  )
}

export default OptimizeStrategySelectorComponent
