import React, { useMemo } from 'react'
import { CheckboxProps, Checkbox } from '@mui/material'
import { GridRowGroupingModel, useGridRootProps } from '@mui/x-data-grid-premium'
import { CHECKBOX_BASE_ICONS_STYLES, CHECKBOX_BASE_ICONS_HOVER_STYLES, DEFAULT_BUTTON_TRANSITION } from '@constants/ui.constants'

import IndeterminateSquareIcon from '@icons/checkboxes/indeterminateSquare.icon'
import CheckRoundIcon from '@icons/checkboxes/checkRound.icon'
import CheckedRoundIcon from '@icons/checkboxes/checkedRound.icon'
import CheckSquareIcon from '@icons/checkboxes/checkSquare.icon'
import CheckedSquareIcon from '@icons/checkboxes/checkedSquare.icon'
import InactiveRoundIcon from '@icons/checkboxes/inactiveRound.icon'
import TooltipComponent from '@base/tooltips/Tooltip'

export interface DataGridCheckboxBaseComponentProps extends CheckboxProps {
  forwardedRef?: any
  reversed?: boolean
  disabledHint?: string
  hintPlacement?: 'top' | 'bottom' | 'left' | 'right'
  groupingModel?: GridRowGroupingModel
  dataTestId?: string
}

export const DataGridCheckboxBaseComponent: React.FC<DataGridCheckboxBaseComponentProps> = ({
  forwardedRef,
  reversed,
  disabledHint,
  hintPlacement = 'top',
  sx,
  disabled,
  groupingModel,
  dataTestId,
  ...props
}) => {
  const rootProps = useGridRootProps()

  const iconProps = useMemo(() => {
    return disabled ? {
      width: '18px',
      height: '18px',
      detailsFill: '#CCC',
    } : {
      width: '18px',
      height: '18px',
    }
  }, [disabled])

  const icon = useMemo(() => {
    if (rootProps.disableMultipleRowSelection) {
      if (disabled) {
        return <InactiveRoundIcon {...iconProps} />
      }

      if (reversed) {
        return <CheckedRoundIcon {...iconProps} />
      }

      return <CheckRoundIcon {...iconProps} />
    }

    if (reversed) {
      return <CheckedSquareIcon {...iconProps} />
    }

    return <CheckSquareIcon {...iconProps} />
  }, [rootProps.disableMultipleRowSelection, disabled, iconProps, reversed])

  const checkedIcon = useMemo(() => {
    if (rootProps.disableMultipleRowSelection) {
      if (disabled) {
        return <InactiveRoundIcon {...iconProps} />
      }

      if (reversed) {
        return <CheckRoundIcon {...iconProps} />
      }

      return <CheckedRoundIcon {...iconProps} />
    }

    if (reversed) {
      return <CheckSquareIcon {...iconProps} />
    }

    return <CheckedSquareIcon {...iconProps} />
  }, [rootProps.disableMultipleRowSelection, disabled, iconProps, reversed])

  const checkboxChild = (
    <Checkbox
      data-testid={dataTestId || DataGridCheckboxBaseComponent.name}
      ref={forwardedRef}
      icon={icon}
      checkedIcon={checkedIcon}
      indeterminateIcon={<IndeterminateSquareIcon {...iconProps} />}
      disableRipple={true}
      sx={{
        borderRadius: '8px',
        padding: '5px',
        pointerEvents: disabled ? 'auto !important' : 'auto',
        ...(rootProps.disableMultipleRowSelection && !disabled ? {
          '& .checkRoundIconDot': {
            opacity: 0,
            transition: DEFAULT_BUTTON_TRANSITION,
          },
          '&:hover': {
            '& .checkRoundIconDot': {
              opacity: 1,
            },
          },
        } : {
          ...CHECKBOX_BASE_ICONS_STYLES,
          '&:hover:not(.Mui-disabled)': {
            ...CHECKBOX_BASE_ICONS_HOVER_STYLES,
          },
          '&:focus-within': {
            ...CHECKBOX_BASE_ICONS_HOVER_STYLES,
          },
        }),
      }}
      disabled={disabled}
      {...props}
    />
  )

  if (disabledHint && disabled) {
    return (
      <TooltipComponent
        title={disabledHint}
        placement={hintPlacement}
        describeChild={true}
      >
        {checkboxChild}
      </TooltipComponent>
    )
  }

  return checkboxChild
}

export default React.forwardRef((props, ref) => <DataGridCheckboxBaseComponent {...props} forwardedRef={ref} />)
