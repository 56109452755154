import React from 'react'
import { Typography } from '@mui/material'

import useStyles from './OptimizeValue.styles'

export interface OptimizeRestockDetailsItemComponentProps {
  value?: number | string,
}

const OptimizeValueComponent: React.FC<OptimizeRestockDetailsItemComponentProps> = (props) => {
  const {
    value,
  } = props

  const { classes } = useStyles()

  return (
    <Typography className={classes.value} data-testid={OptimizeValueComponent.name}>
      {value}
    </Typography>
  )
}

export default OptimizeValueComponent
