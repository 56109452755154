import { DEFAULT_BORDER_RADIUS } from '@constants/ui.constants'
import { styled, MenuList } from '@mui/material'

export default styled(MenuList)(({ theme }) => ({
  '& .MuiMenuItem-root': {
    borderTop: `1px solid ${theme.palette.new.business_black_20}`,

    '&:first-of-type': {
      borderTop: 'none',
    },
    '&:last-of-type': {
      borderBottomLeftRadius: DEFAULT_BORDER_RADIUS.SMALL,
      borderBottomRightRadius: DEFAULT_BORDER_RADIUS.SMALL,
    },
  },
}))
