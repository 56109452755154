import { checkApiForError } from '@utils/redux.utils'
import { USE_CASE } from '@constants/api-provider.constants'

import apiProvider from '@redux/api-provider'

import {
  CreatePayload,
  FetchPayload,
  RemovePayload,
  UpdatePayload,
} from './parameters-templates.types'

export const fetch = ({ parameterPresetId } : FetchPayload) => {
  const URL = 'v6/parameter-preset/get'
  const payload = { parameterPresetId }

  return apiProvider.getApi(USE_CASE).post(URL, payload)
    .then(({ data }) => {
      checkApiForError(data)

      return data.data.parameterPreset
    })
}

export const list = () => {
  const URL = 'v6/parameter-preset/list'

  return apiProvider.getApi(USE_CASE).post(URL)
    .then(({ data }) => {
      checkApiForError(data)

      return data.data.parameterPresets
    })
}

export const create = ({
  name,
  dataColumnName, unitLabel,
  sourceType, aggregationFunction,
  description, possiblePresetTypes,
} : CreatePayload) => {
  const URL = 'v6/parameter-preset/add'

  return apiProvider.getApi(USE_CASE).post(URL, {
    name,
    dataColumnName,
    unitLabel,
    sourceType,
    description,
    aggregationFunction,
    possiblePresetTypes,
  }).then(({ data }) => {
    checkApiForError(data)

    return data.data
  })
}

export const update = ({
  parameterPresetId, name,
  dataColumnName, unitLabel,
  description, possiblePresetTypes,
  sourceType, aggregationFunction,
} : UpdatePayload) => {
  const URL = 'v6/parameter-preset/edit'

  return apiProvider.getApi(USE_CASE).post(URL, {
    parameterPresetId,
    name,
    dataColumnName,
    unitLabel,
    sourceType,
    description,
    possiblePresetTypes,
    aggregationFunction,
  }).then(({ data }) => {
    checkApiForError(data)
  })
}

export const remove = ({ parameterPresetId }: RemovePayload) => {
  return apiProvider.getApi(USE_CASE).post('v6/parameter-preset/delete', { parameterPresetId }).then(({ data }) => {
    checkApiForError(data)
  })
}
