import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: '400px',
    padding: theme.spacing(9),
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(5),
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1),
    },
  },
  row: {
    '&:last-child .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
}))

export default useStyles
