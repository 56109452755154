import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    color: theme.palette.new.black,
    height: 1,
    padding: '13px 0',
  },
  thumb: {
    height: 40,
    zIndex: 1,
    width: 19,
    backgroundColor: '#fff',
    borderRadius: 5,
    border: `1px solid ${theme.palette.new.black}`,
    boxShadow: 'none',
    transition: 'none',
    '&.Mui-disabled': {
      height: 40,
      width: 19,
      borderRadius: 5,
      backgroundColor: theme.palette.new.grey_f,
      cursor: 'not-allowed',
      pointerEvents: 'all',
    },
    '&:focus, &:hover, &$active': {
      boxShadow: 'none !important',
    },
    '&.Mui-active': {
      boxShadow: 'none !important',
    },
    '&.Mui-focusVisible': {
      boxShadow: 'none !important',
    },
    '&:before': {
      boxShadow: 'none !important',
    },
    '& .bar': {
      height: 13,
      width: 1,
      backgroundColor: theme.palette.new.black,
      marginLeft: 1.5,
      marginRight: 1.5,
    },
    '&:hover': {
      boxShadow: 'none !important',
    },
  },
  mark: {
    height: 1,
    width: 1,
    backgroundColor: 'transparent',
  },
  markActive: {
    opacity: 1,
    height: 1,
    backgroundColor: theme.palette.new.grey_a,
  },
  active: {},
  track: {
    height: '60px',
    backgroundColor: `${theme.palette.new.versatile_violet}1A`,
    borderBottom: `1px solid ${theme.palette.new.versatile_violet}`,
    zIndex: 1,
    position: 'absolute',
    border: 'none',
    transition: 'none',
    top: '-13px',
    borderRadius: '0px',
    borderLeft: `1px solid ${theme.palette.new.grey_b}`,
    borderRight: `1px solid ${theme.palette.new.grey_b}`,
  },
  rail: {
    color: theme.palette.new.grey_b,
    opacity: 1,
    height: 1,
    top: '16px',
  },
}))

export default useStyles
