import React, { useMemo } from 'react'

import {
  Badge, useTheme,
  Box, InputAdornment,
} from '@mui/material'

import Divider from '@mui/material/Divider'

import {
  DataGridPremiumProps,
  GRID_ACTIONS_COLUMN_TYPE,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridCsvExportMenuItem,
  GridExcelExportMenuItem,
  GridRowGroupingModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridToolbarQuickFilter,
  gridColumnVisibilityModelSelector,
  gridQuickFilterValuesSelector,
  gridRowGroupingModelSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-premium'

import ColumnsIcon from '@icons/columns.icon'
import ExportIcon from '@icons/export.icon'
import SearchIcon from '@icons/search.icon'

import DataGridCustomFiltersToolbarComponent from '@base/datagrid/data-grid-custom-filters-toolbar'
import DataGridCustomGroupingToolbarComponent from '@base/datagrid/data-grid-custom-grouping-toolbar'
import { DEFAULT_DEBOUNCE_INTERVAL } from '@constants/data-grid.constants'

export interface DataGridCustomToolbarComponentProps {
  disabled?: boolean
  disableColumnVisibilityPanelWhenGrouping?: boolean
  withExport?: boolean
  onRowGroupingModelChange?: DataGridPremiumProps['onRowGroupingModelChange']
  groupingMode?: 'server' | 'client'
  groupingModel?: GridRowGroupingModel
  customToolbarChildren?: React.ReactNode
}

export const DataGridCustomToolbarComponent: React.FC<DataGridCustomToolbarComponentProps> = ({
  disabled = false,
  withExport = true,
  disableColumnVisibilityPanelWhenGrouping = false,
  groupingMode = 'client',
  customToolbarChildren = null,
  groupingModel,
  onRowGroupingModelChange,
}) => {
  const theme = useTheme()
  const apiRef = useGridApiContext()
  const internalGroupingModel = useGridSelector(apiRef, gridRowGroupingModelSelector)
  const groupingModelToUse = (groupingMode === 'server' && groupingModel) ? groupingModel : internalGroupingModel
  const columnVisibilityModel = useGridSelector(apiRef, gridColumnVisibilityModelSelector)
  const searchValues = useGridSelector(apiRef, gridQuickFilterValuesSelector)
  const hasSearchValue = searchValues && searchValues.filter((val) => Boolean(val)).length > 0
  const disableColumnVisibility = (disableColumnVisibilityPanelWhenGrouping && groupingModelToUse.length > 0) || disabled
  const hiddenColumnsCount = useMemo(() => {
    if (!columnVisibilityModel) {
      return 0
    }

    const keys = Object.keys(columnVisibilityModel).filter((key) => {
      return ![GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field, GRID_ACTIONS_COLUMN_TYPE].includes(key)
    })

    const values = keys.filter((key) => {
      return !columnVisibilityModel[key]
    })

    return values.length
  }, [columnVisibilityModel])

  return (
    <GridToolbarContainer
      data-testid={DataGridCustomToolbarComponent.name}
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box
        display='flex'
        alignItems='center'
        gap={0.5}
      >
        <GridToolbarColumnsButton
          disabled={disableColumnVisibility}
          startIcon={hiddenColumnsCount ? (
            <Badge
              color='primary'
              badgeContent={hiddenColumnsCount}
            >
              <ColumnsIcon
                detailsFill={disableColumnVisibility ? theme.palette.new.black_d : theme.palette.new.black}
              />
            </Badge>
          ) : (
            <ColumnsIcon
              detailsFill={disableColumnVisibility ? theme.palette.new.black_d : theme.palette.new.black}
            />
          )}
        />

        <DataGridCustomFiltersToolbarComponent
          disabled={disabled}
        />

        <DataGridCustomGroupingToolbarComponent
          disabled={disabled}
          groupingMode={groupingMode}
          onRowGroupingModelChange={onRowGroupingModelChange}
          groupingModel={groupingModelToUse}
        />
      </Box>
      <Box
        display='flex'
        alignItems='center'
        gap={1}
      >
        {customToolbarChildren}

        {
          withExport ? (
            <GridToolbarExportContainer
              startIcon={<ExportIcon />}
              disabled={disabled}
            >
              <GridCsvExportMenuItem options={{}} />
              <Divider />
              <GridExcelExportMenuItem options={{}} />
              {/*
              Not working - https://github.com/mui/mui-x/issues/11885
              <Divider />
              <GridPrintExportMenuItem />
              */}
            </GridToolbarExportContainer>
          ) : (
            null
          )
        }

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '58px',
            width: '250px',
            borderLeft: `1px solid ${theme.palette.new.grey_b}`,
            margin: '-10px 0px',
            padding: '0px 15px',
            ...(hasSearchValue ? {
              background: theme.palette.new.versatile_violet_10,
              color: theme.palette.new.versatile_violet,
            } : {}),
          }}
        >
          <GridToolbarQuickFilter
            disabled={disabled}
            debounceMs={DEFAULT_DEBOUNCE_INTERVAL}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon
                    detailsFill={hasSearchValue ? theme.palette.new.versatile_violet : theme.palette.new.black}
                  />
                </InputAdornment>
              ),
            }}
            sx={{
              paddingBottom: '0 !important',

              '& .MuiTextField-root': {
                borderRadius: '0px',
                border: 'none',
              },
              '& .MuiInputBase-root': {
                padding: '0',
                borderRadius: '0px',
                border: 'none',
                '&:hover': {
                  border: 'none',
                },
                ...(hasSearchValue ? {
                  '& .MuiButtonBase-root': {
                    backgroundColor: 'transparent',
                    '& svg': {
                      '& path': {
                        stroke: theme.palette.new.versatile_violet,
                      },
                    },
                    '&:hover': {
                      opacity: 0.6,
                    },
                  },
                } : {}),
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '& .MuiInputBase-input': {
                padding: '5px 0 5px',
                caretColor: theme.palette.new.carret,
                ...(hasSearchValue ? {
                  color: theme.palette.new.versatile_violet,
                } : {}),
              },
            }}
          />
        </Box>
      </Box>
    </GridToolbarContainer>
  )
}

export default DataGridCustomToolbarComponent
