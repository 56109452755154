import { DEFAULT_BOX_SHADOW } from '@constants/ui.constants'
import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.new.white,
    color: theme.palette.new.black_b,
    boxShadow: DEFAULT_BOX_SHADOW,
    opacity: 1,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1.5, 2.5),
    fontSize: '16px',
    fontWeight: 400,
    letterSpacing: '0.015em',
  },
  arrow: {
    color: theme.palette.new.white,

    '&::before': {
      backgroundColor: theme.palette.new.white,
      boxShadow: DEFAULT_BOX_SHADOW,
    },
  },
}))

export default useStyles
