import React from 'react'
import { Position } from '@xyflow/react'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { Box, Typography, useTheme } from '@mui/material'

import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { TRIPPLE_CONNECTOR_STYLES, INPUT_TYPES } from '@constants/flow.constants'
import { CONNECT_PARAMETER_CREATION_MODAL_NAME } from '@constants/modals.constants'
import { getUseCaseFreezeStatus } from '@redux/modules/use-case/use-case.selectors'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { useSelector } from '@redux/hooks'
import { trackEvent, TRACKING_ACTIONS } from '@utils/tracking.utils'
import { ConnectParameterCreationModalDetails } from '@containers/modals/connect-parameter-creation-modal/ConnectParameterCreationModal.container'

import TooltipComponent from '@base/tooltips/Tooltip'
import ReactFlowHandleComponent from '@components/connect-view/flow/ReactFlowHandle'
import AddFlowIcon from '@icons/flow/addFlow.icon'

import useStyles from './ReactFlowEmptyGroupingNode.styles'

export interface ReactFlowEmptyGroupingNodeComponentProps {
  /**
   * Data for the node
   */
  data?: {
    /**
     * Number of input types, the number of handles will be generated based on this number
     */
    numOfInputTypes?: number,
    /**
     * Number of sources, the number of handles will be generated based on this number
     */
    numOfSources?: number,
  }
}

const ReactFlowEmptyGroupingNodeComponent: React.FC<ReactFlowEmptyGroupingNodeComponentProps> = ({ data }) => {
  const {
    numOfInputTypes = 0,
  } = data || {}

  const { classes, cx } = useStyles()
  const intl = useIntl()
  const theme = useTheme()
  const dispatch = useDispatch()

  const frozen = useSelector((state) => getUseCaseFreezeStatus(state))
  const isAdmin = useSelector((state) => getIsAdmin(state))
  const creationDisabled = !isAdmin && frozen

  const flowHandlesSource = []
  const flowHandlesTarget = []

  const onAddButtonClick = () => {
    trackEvent({
      componentName: 'addGroupingButton',
      actionName: TRACKING_ACTIONS.CLICK,
    })

    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: CONNECT_PARAMETER_CREATION_MODAL_NAME,
        modalDetails: {
          parameterType: INPUT_TYPES.GROUPING,
          returnTo: '',
        } as ConnectParameterCreationModalDetails,
      }),
    )
  }

  for (let i = 0; i < numOfInputTypes; i += 1) {
    flowHandlesSource.push((
      <ReactFlowHandleComponent
        type='source'
        position={Position.Right}
        id={`source_${i + 1}`}
        key={`source_${i + 1}`}
        style={TRIPPLE_CONNECTOR_STYLES[i]}
        invisibleDots={true}
      />
    ))

    flowHandlesTarget.push((
      <ReactFlowHandleComponent
        type='target'
        position={Position.Left}
        id={`target_${i + 1}`}
        key={`target_${i + 1}`}
        style={TRIPPLE_CONNECTOR_STYLES[i]}
        invisibleDots={true}
      />
    ))
  }

  return (
    <Box className={classes.rootWrapper}>
      <Box className={classes.root} data-testid={ReactFlowEmptyGroupingNodeComponent.name}>
        {flowHandlesTarget}

        <TooltipComponent
          describeChild={true}
          title={(
            <Box
              display='flex'
              flexDirection='column'
              gap={1.5}
            >
              <Typography variant='subtitle1'>
                {intl.formatMessage({ id: 'connect.block.grouping.help.title' })}
              </Typography>
              <Typography variant='body1' color={theme.palette.new.black_b}>
                {intl.formatMessage({ id: 'connect.block.grouping.help.text' })}
              </Typography>
            </Box>
          )}
          placement='top'
        >
          <Box
            role='button'
            tabIndex={0}
            className={cx(classes.content, {
              [classes.disabled]: creationDisabled,
            })}
            onClick={creationDisabled ? () => {} : onAddButtonClick}
            data-testid='addGroupingButton'
            aria-label={intl.formatMessage({ id: 'connect.block.grouping.add' })}
          >
            <AddFlowIcon
              detailsFill='black'
              width={22}
              height={22}
            />
          </Box>
        </TooltipComponent>

        {flowHandlesSource}
      </Box>
    </Box>
  )
}

export default ReactFlowEmptyGroupingNodeComponent
