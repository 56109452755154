import React from 'react'
import { useIntl } from 'react-intl'
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom'

import { defaultNumberFormatter } from '@utils/analysis.utils'
import { Card, Typography } from '@mui/material'
import { searchByKeys } from '@utils/list.utils'
import { useDispatch, useSelector } from '@redux/hooks'
import { bindActionCreators } from 'redux'

import { EXPORT_PATH, OPTIMIZE_DRILL_DOWN_PATH } from '@constants/routes.constants'
import { setPaginationConfigAction } from '@redux/modules/common/common.actions'
import { getPaginationConfig } from '@redux/modules/common/common.selectors'
import { isReflectingTargets } from '@redux/modules/optimize/optimize.selectors'

import TableComponent, { ITableColumn } from '@components/_tables/table/Table.component'
import ChipComponent from '@base/chips/Chip'
import { getChipColor } from '@utils/optimize.utils'

import useStyles from './OptimizeOverview.styles'
import OptimizeOverviewHeaderComponent from './OptimizeOverviewHeader.component'

export interface OptimizeOverviewComponentProps {
  list: Optimize.OptimizeItem[],
}

const SEARCH_FIELDS = [
  'sku', 'productName',
  'category', 'region',
]

const OptimizeOverviewComponent: React.FC<OptimizeOverviewComponentProps> = (props) => {
  const {
    list,
  } = props

  const { classes } = useStyles()
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()
  const { params: { usecase } } = useRouteMatch<Common.RouterMatch>()

  const setPaginationConfig = bindActionCreators(setPaginationConfigAction, dispatch)
  const { search } = useSelector((state) => getPaginationConfig(state))
  const isFetching = useSelector((state) => isReflectingTargets(state))

  const handleSearch = (value: string) => {
    setPaginationConfig({
      search: value,
    })
  }

  const tableColumns = [{
    label: intl.formatMessage({ id: 'optimize.table.productName' }),
    key: 'productName',
    alignment: 'left',
    sorting: true,
  }, {
    label: intl.formatMessage({ id: 'optimize.table.category' }),
    key: 'category',
    alignment: 'left',
    sorting: true,
  }, {
    label: intl.formatMessage({ id: 'optimize.table.region' }),
    key: 'region',
    alignment: 'left',
    sorting: true,
  }, {
    header: intl.formatMessage({ id: 'optimize.table.recommended' }),
    label: intl.formatMessage({ id: 'optimize.table.restockQuantity' }),
    key: 'restockQuantity',
    alignment: 'right',
    sorting: true,
    numeric: true,
    valueFormatter: (i: any, record: Optimize.OptimizeItem) => {
      return (
        <Typography
          className={classes.restockQuantity}
        >
          {defaultNumberFormatter(record.restockQuantity)}
        </Typography>
      )
    },
  }, {
    label: intl.formatMessage({ id: 'optimize.table.stockStatus' }),
    key: 'stockStatus',
    alignment: 'right',
    sorting: true,
    valueFormatter: (i: any, record: Optimize.OptimizeItem) => {
      return (
        <ChipComponent
          color={getChipColor(record.stockStatus)}
          label={record.stockStatus}
          textTransform='lowercase'
        />
      )
    },
  }, {
    header: intl.formatMessage({ id: 'optimize.table.current' }),
    label: intl.formatMessage({ id: 'optimize.table.stockNet' }),
    key: 'stockNet',
    alignment: 'right',
    sorting: true,
    numeric: true,
    valueFormatter: (i: any, record: Optimize.OptimizeItem) => {
      return defaultNumberFormatter(record.stockNet)
    },
  }, {
    header: intl.formatMessage({ id: 'optimize.table.current' }),
    label: intl.formatMessage({ id: 'optimize.table.stockNetInEur' }),
    key: 'stockNetInEur',
    alignment: 'right',
    sorting: true,
    numeric: true,
    valueFormatter: (i: any, record: Optimize.OptimizeItem) => {
      return `€${defaultNumberFormatter(record.stockNetInEur)}`
    },
  }, {
    label: intl.formatMessage({ id: 'optimize.table.sku' }),
    key: 'sku',
    alignment: 'right',
    sorting: true,
  }] as ITableColumn[]

  const onRowClick = (e: React.SyntheticEvent, index: any, row: Optimize.OptimizeItem) => {
    history.push(generatePath(OPTIMIZE_DRILL_DOWN_PATH, { usecase, sku: row.sku, region: row.region }))
  }

  const filteredList = searchByKeys(list, SEARCH_FIELDS, search) as Optimize.OptimizeItem[]

  const handleExport = () => {
    history.push(generatePath(EXPORT_PATH, { usecase }))
  }

  const headerBlock = (
    <OptimizeOverviewHeaderComponent
      handleExport={handleExport}
      setSearchTerm={handleSearch}
      searchTerm={search}
    />
  )

  return (
    <Card elevation={0} className={classes.root} data-testid={OptimizeOverviewComponent.name}>
      <TableComponent
        headerBlock={headerBlock}
        tableRows={filteredList}
        tableColumns={tableColumns}
        onRowClick={onRowClick}
        fixedWidth={true}
        withTitles={true}
        resetReduxStateOnUmount={false}
        maxDefaultCellWidth='220px'
        searchTerm={search}
        defaultSortingKey='region'
        indexKey='sku'
        isFetching={isFetching}
      />
    </Card>
  )
}

export default OptimizeOverviewComponent
