import { createAction } from 'redux-actions'

import {
  REQUEST_PARAMETER_OVERVIEW,
  REQUEST_PARAMETER,
  RECEIVE_PARAMETER,

  CREATE_PARAMETER,
  UPDATE_PARAMETER,
  DELETE_PARAMETER,

  REQUEST_GROUPING_OVERVIEW,
  REQUEST_GROUPING,
  RECEIVE_GROUPING,
  CREATE_GROUPING,
  UPDATE_GROUPING,
  DELETE_GROUPING,

  START_FETCHING_PARAMETER,
  STOP_FETCHING_PARAMETER,
} from './parameters.action-types'

import {
  CreateGroupingPayload, CreatePayload,
  FetchGroupingPayload, UpdatePayload,
  RemoveGroupingPayload, RemovePayload,
} from './parameters.types'

export const requestParameterOverviewAction = createAction<UseCase.ParameterItem>(REQUEST_PARAMETER_OVERVIEW)
export const requestParameterAction = createAction<UseCase.ParameterItem>(REQUEST_PARAMETER)
export const receiveParameterActionDone = createAction(RECEIVE_PARAMETER)

export const createParameterAction = createAction<CreatePayload>(CREATE_PARAMETER)
export const updateParameterAction = createAction<UpdatePayload>(UPDATE_PARAMETER)
export const removeParameterAction = createAction<RemovePayload>(DELETE_PARAMETER)

export const requestGroupingOverviewAction = createAction<FetchGroupingPayload>(REQUEST_GROUPING_OVERVIEW)
export const requestGroupingAction = createAction<FetchGroupingPayload>(REQUEST_GROUPING)
export const receiveGroupingActionDone = createAction(RECEIVE_GROUPING)

export const createGroupingAction = createAction<CreateGroupingPayload>(CREATE_GROUPING)
export const updateGroupingAction = createAction<UseCase.GroupingParameter>(UPDATE_GROUPING)
export const removeGroupingAction = createAction<RemoveGroupingPayload>(DELETE_GROUPING)

export const startFetchingParameterAction = createAction(START_FETCHING_PARAMETER)
export const stopFetchingParameterAction = createAction(STOP_FETCHING_PARAMETER)
