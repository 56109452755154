import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import {
  NODES_WIDTHS,
  NODE_TYPES,
  FLOW_ELEMENTS_TRANSITIONS,
} from '@constants/flow.constants'

import { getSourceGroupHeight } from '@utils/flow.utils'

const useStyles = makeStyles<{ numOfSources: number }, 'sourceItem' | 'isMoreItemAvailable'>()((theme: Theme, { numOfSources }, classes) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: NODES_WIDTHS[NODE_TYPES.INPUT],
    height: getSourceGroupHeight(numOfSources),
    background: theme.palette.new.white,
    border: '1px solid',
    borderColor: theme.palette.new.grey_a,
    borderRadius: theme.spacing(0.5),
    alignItems: 'flex-start',
    position: 'relative',
    cursor: 'default',
  },
  subTitle: {
    color: theme.palette.new.grey_c,
    marginLeft: theme.spacing(2),
    marginBottom: '-2px',
  },
  content: {
    width: '100%',
    maxWidth: NODES_WIDTHS[NODE_TYPES.INPUT],
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  newSourceItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    height: theme.spacing(6),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    marginTop: theme.spacing(2),
    cursor: 'pointer',
    background: theme.palette.new.smokey_silver,
    border: '1px solid rgba(0, 0, 0, 0.2)',
    ...FLOW_ELEMENTS_TRANSITIONS,
    '&:hover': {
      background: theme.palette.new.white,
      border: '1px solid rgba(0, 0, 0, 0.4)',
    },
  },
  sourcesContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    marginTop: theme.spacing(2),
    [`& .${classes.sourceItem}`]: {
      border: `1px solid ${theme.palette.new.grey_a}`,
      borderBottom: 'none',
      borderTop: '1px solid transparent',
      ...FLOW_ELEMENTS_TRANSITIONS,

      '&:after': {
        position: 'absolute',
        bottom: '-1px',
        left: 0,
        right: 0,
        width: '100%',
        height: '1px',
        content: '""',
        opacity: 1,
        backgroundColor: theme.palette.new.grey_a,
      },
      '&:first-of-type': {
        borderTop: `1px solid ${theme.palette.new.grey_a}`,
        borderTopLeftRadius: theme.spacing(0.5),
        borderTopRightRadius: theme.spacing(0.5),
      },
      '&:last-child': {
        borderBottom: `1px solid ${theme.palette.new.grey_a}`,
        borderBottomLeftRadius: theme.spacing(0.5),
        borderBottomRightRadius: theme.spacing(0.5),

        '&:after': {
          display: 'none',
        },
      },
    },
    [`& .${classes.isMoreItemAvailable}`]: {
      '&:last-child': {
        borderBottomLeftRadius: `${theme.spacing(0)} !important`,
        borderBottomRightRadius: `${theme.spacing(0)} !important`,
      },
    },
  },
  isMoreItemAvailable: {},
  sourceItem: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    height: theme.spacing(6),
    padding: theme.spacing(2),
    outline: 'none',

    '&:focus-visible': {
      backgroundColor: theme.palette.new.grey,
      border: `1px solid ${theme.palette.new.grey_c}`,
      borderBottom: 'none',
      '&:after': {
        backgroundColor: theme.palette.new.grey_c,
      },
      '&:first-of-type': {
        borderTop: `1px solid ${theme.palette.new.grey_c}`,
      },
      '&:last-child': {
        borderBottom: `1px solid ${theme.palette.new.grey_c}`,
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.new.grey,
      border: `1px solid ${theme.palette.new.grey_c}`,
      borderBottom: 'none',
      '&:after': {
        backgroundColor: theme.palette.new.grey_c,
      },
      '&:first-of-type': {
        borderTop: `1px solid ${theme.palette.new.grey_c}`,
      },
      '&:last-child': {
        borderBottom: `1px solid ${theme.palette.new.grey_c}`,
      },
    },
  },
  sourceMoreItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    position: 'relative',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    height: theme.spacing(6),
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.new.grey_a}`,
    outline: 'none',
    borderRadius: theme.spacing(0.5),
    ...FLOW_ELEMENTS_TRANSITIONS,

    '&:hover': {
      backgroundColor: theme.palette.new.grey,
      border: `1px solid ${theme.palette.new.grey_c}`,
    },
    '&:focus-visible': {
      backgroundColor: theme.palette.new.grey,
      border: `1px solid ${theme.palette.new.grey_c}`,
    },
  },
  moreConnectorLineContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  moreConnectorLine: {
    width: '1px',
    backgroundColor: theme.palette.new.grey_a,
    height: theme.spacing(2),
  },
  moreText: {
    marginLeft: theme.spacing(1.5),
    color: theme.palette.new.black,
  },
  moreCount: {
    letterSpacing: '3px',
  },
  sourceItemMain: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
  },
  newSourceIcon: {
    display: 'flex',
    height: theme.spacing(4),
    width: theme.spacing(4),
    background: theme.palette.new.white,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.spacing(5),
  },
  sourceIcon: {
    display: 'flex',
  },
  sourceName: {
    color: theme.palette.new.black,
    marginLeft: theme.spacing(0.7),
  },
  sourceStatus: {

  },
  sourceItemActive: {
    cursor: 'pointer',
  },
}))

export default useStyles
