import Paper from '@mui/material/Paper'
import isEqual from 'lodash.isequal'

import {
  DataGridPremiumProps,
  GRID_ACTIONS_COLUMN_TYPE,
  GridColDef, GridRowId,
  GridRowParams,
  GridValidRowModel, gridClasses, useGridApiRef,
} from '@mui/x-data-grid-premium'

import { Box, Theme } from '@mui/material'
import { useIntl } from 'react-intl'

import { isLegacyUploadCheck } from '@utils/use-cases.utils'
import { useDispatch } from '@redux/hooks'
import { DATA_GRIDS } from '@constants/data-grid.constants'
import { useRouteMatch } from 'react-router-dom'

import DeleteIcon from '@icons/delete.icon'
import RewindIcon from '@icons/rewind.icon'
import SaveIcon from '@icons/save.icon'

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import DataGridComponent from '@base/datagrid/data-grid'
import ButtonComponent from '@base/buttons/Button'
import DataGridActionButtonComponent from '@base/datagrid/data-grid-action-button'
import useGridInitialState from '@hooks/useGridInitialState.hook'

import { generateActionsColumnDefinition } from '@utils/data-grid-cells.utils'
import { uploadTrainingDataAction } from '@redux/modules/training-files/training-files.actions'
import { closeFilePreviewAction } from '@redux/modules/file-service/file-service.actions'

export interface FileBrowserPreviewTableComponentProps {
  /**
   * Columns definitions
   */
  columns: GridColDef[]
  /**
   * Meta data of parser
   */
  meta: Papa.ParseMeta | null
  /**
   * Data to display
   */
  data: any[]
  /**
   * File preview data
   */
  filePreview: FileService.FilePreview
}

const sx = {
  [`& .${gridClasses.toolbarContainer}`]: {
    borderTop: 'none',
  },
  [`& .${gridClasses.main}`]: {
    borderRadius: '0px',
  },
  [`& .${gridClasses.row}.row--removed`]: {
    backgroundColor: (theme: Theme) => {
      return `${theme.palette.new.rebellious_red}50`
    },
  },
  [`& .${gridClasses.row}.row--edited`]: {
    backgroundColor: (theme: Theme) => {
      return `${theme.palette.new.youthful_yellow}50`
    },
  },
}

const FileBrowserPreviewTableComponent: React.FC<FileBrowserPreviewTableComponentProps> = ({
  columns, data, filePreview, meta,
}) => {
  const intl = useIntl()
  const apiRef = useGridApiRef()
  const dispatch = useDispatch()

  const {
    params: {
      usecase,
      version,
      identifier,
    },
  } = useRouteMatch<Common.RouterMatch>()

  const isLegacyUpload = isLegacyUploadCheck(identifier)
  const initialState = useGridInitialState(DATA_GRIDS.FILE_BROWSER_PREVIEW_TABLE, {
    pinnedColumns: {
      right: [GRID_ACTIONS_COLUMN_TYPE],
    },
    pagination: {
      paginationModel: {
        pageSize: 50,
      },
    },
  })

  const [hasUnsavedRows, setHasUnsavedRows] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const unsavedChangesRef = useRef<{
    unsavedRows: Record<GridRowId, GridValidRowModel>
    rowsBeforeChange: Record<GridRowId, GridValidRowModel>
  }>({
    unsavedRows: {},
    rowsBeforeChange: {},
  })

  const uploadBlob = useCallback((blobData: Blob) => {
    const formData = new FormData()
    const timestamp = new Date().getTime()
    const name = `${(filePreview?.fileName || 'noname.csv')}`

    const file = new File([blobData], name, { type: 'text/csv', lastModified: timestamp })

    if (!isLegacyUpload) {
      formData.append('fileIdentifier', identifier)
      formData.append('version', version)
    }

    formData.append('trainingDataFile', file)
    formData.append('useCaseId', usecase!)

    dispatch(
      uploadTrainingDataAction({
        data: formData,
        meta: {
          fileName: name,
          useCaseId: usecase,
          fileIdentifier: identifier,
          version: version ? Number(version) : undefined,
          fetchList: true,
          showToast: true,
        },
      }),
    )

    dispatch(
      closeFilePreviewAction(),
    )
  }, [
    dispatch,
    isLegacyUpload,
    usecase,
    version,
    identifier,
    filePreview,
  ])

  const saveChanges = useCallback(async () => {
    try {
      setIsSaving(true)

      const rowsToDelete = Object.values(
        unsavedChangesRef.current.unsavedRows,
      ).filter((row) => row._action === 'delete')

      if (rowsToDelete.length > 0) {
        rowsToDelete.forEach((row) => {
          apiRef.current.updateRows([row])
        })
      }

      const dataAsCsv = apiRef.current.getDataAsCsv({
        delimiter: meta?.delimiter || ',',
        utf8WithBom: true,
      })

      const dataBlob = new Blob([dataAsCsv], { type: 'text/csv;charset=utf-8;' })

      uploadBlob(dataBlob)
    } catch (error) {
      setIsSaving(false)
    } finally {
      setHasUnsavedRows(false)

      setIsSaving(false)

      unsavedChangesRef.current = {
        unsavedRows: {},
        rowsBeforeChange: {},
      }
    }
  }, [apiRef, uploadBlob, meta])

  const discardChanges = React.useCallback(() => {
    setHasUnsavedRows(false)

    Object.values(unsavedChangesRef.current.rowsBeforeChange).forEach((row) => {
      apiRef.current.updateRows([row])
    })

    unsavedChangesRef.current = {
      unsavedRows: {},
      rowsBeforeChange: {},
    }
  }, [apiRef])

  const processRowUpdate = React.useCallback<
    NonNullable<DataGridPremiumProps['processRowUpdate']>
  >((newRow, oldRow) => {
    const rowId = newRow.id

    if (isEqual(newRow, oldRow)) {
      return newRow
    }

    unsavedChangesRef.current.unsavedRows[rowId] = newRow

    if (!unsavedChangesRef.current.rowsBeforeChange[rowId]) {
      unsavedChangesRef.current.rowsBeforeChange[rowId] = oldRow
    }

    setHasUnsavedRows(true)

    return newRow
  }, [])

  const getActionItems = useCallback((params: GridRowParams<Pipelines.ReduxPipelineItem>) => {
    const onDeleteClick = () => {
      if (!apiRef.current) {
        return
      }

      unsavedChangesRef.current.unsavedRows[params.id] = {
        ...params.row,
        _action: 'delete',
      }

      if (!unsavedChangesRef.current.rowsBeforeChange[params.id]) {
        unsavedChangesRef.current.rowsBeforeChange[params.id] = params.row
      }

      setHasUnsavedRows(true)

      apiRef.current.updateRows([params.row])
    }

    const onDiscardClick = () => {
      if (!apiRef.current) {
        return
      }

      apiRef.current.updateRows([
        unsavedChangesRef.current.rowsBeforeChange[params.id],
      ])

      delete unsavedChangesRef.current.rowsBeforeChange[params.id]
      delete unsavedChangesRef.current.unsavedRows[params.id]

      setHasUnsavedRows(
        Object.keys(unsavedChangesRef.current.unsavedRows).length > 0,
      )
    }

    return [
      <DataGridActionButtonComponent
        id={params.id}
        name='discardChanges'
        icon={<RewindIcon />}
        label={intl.formatMessage({ id: 'trainingData.actions.discard' })}
        disabled={unsavedChangesRef.current.unsavedRows[params.id] === undefined}
        onClick={onDiscardClick}
      />,
      <DataGridActionButtonComponent
        id={params.id}
        name='deleteRow'
        icon={<DeleteIcon />}
        label={intl.formatMessage({ id: 'trainingData.actions.delete' })}
        onClick={onDeleteClick}
      />,
    ]
  }, [
    intl,
    apiRef,
    unsavedChangesRef,
  ])

  const finalColumns = useMemo<GridColDef[]>(() => {
    return [
      ...columns,
      generateActionsColumnDefinition({
        getActionItems,
        numberOfActions: 3,
      }),
    ]
  }, [columns, getActionItems])

  const [containerMaxHeight, setContainerMaxHeight] = useState(0)
  const tableContainerRef = useRef<HTMLDivElement>(null)

  const handleResize = useCallback(() => {
    if (!tableContainerRef || !tableContainerRef.current) {
      return
    }

    setContainerMaxHeight((tableContainerRef.current.parentElement?.clientHeight || 0))
  }, [tableContainerRef])

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  useEffect(() => {
    if (!tableContainerRef || !tableContainerRef.current) {
      return
    }

    handleResize()
  }, [handleResize])

  const getRowClassName = React.useCallback<
    NonNullable<DataGridPremiumProps['getRowClassName']>
  >(({ id }) => {
    const unsavedRow = unsavedChangesRef.current.unsavedRows[id]

    if (unsavedRow) {
      if (unsavedRow._action === 'delete') {
        return 'row--removed'
      }
      return 'row--edited'
    }

    return ''
  }, [])

  const customToolbarChildren = useMemo(() => {
    const isDisabled = !hasUnsavedRows || isSaving

    return (
      <Box
        display='flex'
        alignItems='center'
        gap={1}
      >
        <ButtonComponent
          color='highlighted'
          name='saveChanges'
          rounded={true}
          StartIconComponent={SaveIcon}
          onClick={saveChanges}
          disabled={isDisabled}
          label={intl.formatMessage({ id: 'trainingData.actions.save' })}
        />
        <ButtonComponent
          color='highlighted-secondary'
          name='discardAll'
          rounded={true}
          StartIconComponent={RewindIcon}
          onClick={discardChanges}
          disabled={isDisabled}
          label={intl.formatMessage({ id: 'trainingData.actions.discardAll' })}
        />
      </Box>
    )
  }, [
    hasUnsavedRows,
    isSaving,
    saveChanges,
    discardChanges,
    intl,
  ])

  const gridSlotConfig = useMemo(() => ({
    toolbar: {
      withExport: false,
      customToolbarChildren,
    },
    pagination: {
      nextIconButtonProps: {
        enableGoToLastPage: true,
      },
      backIconButtonProps: {
        enableGoToFirstPage: true,
      },
    } as any,
  }), [customToolbarChildren])

  return (
    <Paper
      data-testid={FileBrowserPreviewTableComponent.name}
      sx={{ width: '100%', overflow: 'hidden' }}
      ref={tableContainerRef}
      elevation={0}
    >
      <DataGridComponent
        apiRef={apiRef}
        id={DATA_GRIDS.FILE_BROWSER_PREVIEW_TABLE}
        name={DATA_GRIDS.FILE_BROWSER_PREVIEW_TABLE}
        columns={finalColumns}
        rows={data}
        editMode='cell'
        height={`${containerMaxHeight}px`}
        loading={isSaving}
        rounded={false}
        enablePersistence={false}
        disableRowSelectionOnClick={true}
        processRowUpdate={processRowUpdate}
        slotProps={gridSlotConfig}
        initialState={initialState}
        getRowClassName={getRowClassName}
        sx={sx}
      />
    </Paper>
  )
}

export default FileBrowserPreviewTableComponent
