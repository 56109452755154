import React, { SyntheticEvent, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { bindActionCreators } from 'redux'
import { Box, Typography } from '@mui/material'
import { formatDate } from '@utils/moment.utils'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  SidePanelLoadingComponent,
  ModalButtonComponent,
} from '@base/sidepanel/SidePanel'

import { getModalDetails, getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { getSelectedCompanyId } from '@redux/modules/customer/customer.selectors'
import { getParametersTemplatesListBySourceType, isFetching as isFetchingTemplates } from '@redux/modules/parameters-templates/parameters-templates.selectors'
import { requestParametersTemplatesAction } from '@redux/modules/parameters-templates/parameters-templates.actions'
import { getUseCaseItem } from '@redux/modules/use-case/use-case.selectors'
import {
  fetchConnectionsWithDetailsAction,
  modifyTransformationAction,
} from '@redux/modules/hermes/hermes.actions'

import {
  getCompanyConnectionsBySource,
  isFetching as isFetchingHermes,
  isSubmittingHermes,
} from '@redux/modules/hermes/hermes.selectors'

import ChevronIcon from '@icons/flow/chevron.icon'
import ConnectIcon from '@icons/flow/connect.icon'
import EditFlowIcon from '@icons/flow/editFlow.icon'

import { SOURCE_TYPES } from '@constants/flow.constants'
import { createId } from '@utils/common.utils'

import ToggleButtonComponent from '@base/pagebar/ToggleButton'
import ParameterTemplatesTableComponent from '@components/connect-view/parameters/ParameterTemplatesTable'

import { AuthDataSourceModalDetails } from '@containers/modals/auth-data-source-modal/AuthDataSourceModal.container'
import { CONNECT_DATA_SOURCE_MODAL_NAME, AUTH_DATA_SOURCE_MODAL_NAME } from '@constants/modals.constants'

import useStyles from './ConnectDataSourceModal.styles'

export interface ConnectDataSourceModalDetails extends Common.ModalDetails, AuthDataSourceModalDetails {
  icon: React.ElementType
  title: string
  help: string
  sourceId: string
  returnTo: string
  key: SOURCE_TYPES
}

const ConnectDataSourceModalContainer = () => {
  const intl = useIntl()
  const { classes, cx } = useStyles()
  const dispatch = useDispatch()

  const fetchConnectionsWithDetails = bindActionCreators(fetchConnectionsWithDetailsAction, dispatch)
  const updateTransformation = bindActionCreators(modifyTransformationAction, dispatch)
  const requestParametersTemplates = bindActionCreators(requestParametersTemplatesAction, dispatch)

  const companyId = useSelector((state) => getSelectedCompanyId(state))
  const modalDetails = useSelector((state) => getModalDetails<ConnectDataSourceModalDetails>(state))
  const isSubmitting = useSelector((state) => isSubmittingHermes(state))
  const { useCaseId } = useSelector((state) => getUseCaseItem(state))
  const modalPageName = useSelector((state) => getOpenedModal(state))
  const open = modalPageName === CONNECT_DATA_SOURCE_MODAL_NAME

  useEffect(() => {
    if (open) {
      fetchConnectionsWithDetails({ companyId, useCaseId })
      requestParametersTemplates()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const {
    key,
    icon: SidePanelIcon,
    title: sourceName,
    sourceId,
    returnTo,
  } = modalDetails

  const parametersTemplatesList = useSelector((state) => getParametersTemplatesListBySourceType(state, key))
  const connectionsList = useSelector((state) => getCompanyConnectionsBySource(state, sourceId))
  const isConnectionListEmpty = connectionsList.length === 0
  const hasConnectedItems = (connectionsList.filter((item) => item.isConnectedToUseCase) || []).length > 0
  const hasParametersTemplates = (parametersTemplatesList && parametersTemplatesList.length > 0)
  const isFetchingTemplatesList = useSelector((state) => isFetchingTemplates(state))
  const isFetchingHermesConnections = useSelector((state) => isFetchingHermes(state))
  const isFetching = isFetchingTemplatesList || isFetchingHermesConnections

  const handleConnect = () => {
    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: AUTH_DATA_SOURCE_MODAL_NAME,
        modalDetails: {
          ...modalDetails,
          returnTo: CONNECT_DATA_SOURCE_MODAL_NAME,
        } as AuthDataSourceModalDetails,
      }),
    )
  }

  const handleClose = () => {
    dispatch(
      setPrimaryModalPageName(returnTo),
    )
  }

  const handleEditClick = (e: SyntheticEvent, connectionId: string) => {
    e.stopPropagation()

    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: AUTH_DATA_SOURCE_MODAL_NAME,
        modalDetails: {
          ...modalDetails,
          returnTo: CONNECT_DATA_SOURCE_MODAL_NAME,
          connectionId,
        } as AuthDataSourceModalDetails,
      }),
    )
  }

  const handleLinkClick = (e: SyntheticEvent, item: Hermes.ConnectionDetails) => {
    updateTransformation({
      sourceId,
      useCaseId,
      connectionId: item.connectionId,
      companyId,
      toRemove: false,
    })
  }

  const handleUnLinkClick = (e: SyntheticEvent, item: Hermes.ConnectionDetails) => {
    updateTransformation({
      sourceId,
      useCaseId,
      connectionId: item.connectionId,
      companyId,
      toRemove: true,
    })
  }

  const handleToggleClick = (e: SyntheticEvent, isConnectedToUseCase: boolean, item: Hermes.ConnectionDetails) => {
    e.preventDefault()
    e.stopPropagation()

    if (isSubmitting) {
      return
    }

    if (isConnectedToUseCase) {
      handleUnLinkClick(e, item)
    } else {
      handleLinkClick(e, item)
    }
  }

  return (
    <SidePanelComponent
      open={open}
      title={sourceName}
      handleClose={handleClose}
      SidePanelIcon={SidePanelIcon}
      hasUnsavedChanges={isSubmitting}
    >
      <SidePanelLoadingComponent loading={isFetching}>
        <SidePanelCardComponent disableHorizontalSpacing={true}>
          <Box
            className={classes.connectContainer}
            onClick={handleConnect}
          >
            <Box className={classes.connectTextContainer}>
              <ConnectIcon />
              <Typography className={classes.connectText}>
                {intl.formatMessage({ id: 'connect.modal.connect.connect_with' }, { name: sourceName })}
              </Typography>
            </Box>
            <Box className={classes.connectArrow}>
              <ChevronIcon />
            </Box>
          </Box>
          <Box
            className={cx(classes.list, {
              [classes.withTemplates]: hasParametersTemplates,
            })}
          >
            <Box
              className={classes.listItem}
            >
              {
                isConnectionListEmpty ? (
                  null
                ) : (
                  <Typography
                    className={classes.statusField}
                    noWrap={true}
                  >
                    &nbsp;
                  </Typography>
                )
              }
              <Typography
                className={classes.itemName}
                noWrap={true}
              >
                {intl.formatMessage({ id: 'connect.modal.connect.source_name' })}
              </Typography>
              <Typography
                className={cx(classes.itemDate, classes.itemDateHeaderMargin)}
                noWrap={true}
                component='div'
              >
                {intl.formatMessage({ id: 'connect.modal.connect.connection_date' })}
              </Typography>
            </Box>

            {
              isConnectionListEmpty ? (
                <Typography
                  className={classes.emptyList}
                  noWrap={true}
                >
                  {intl.formatMessage({ id: 'connect.modal.connect.empty' })}
                </Typography>
              ) : (
                null
              )
            }

            {
              connectionsList.map((item, index) => {
                const {
                  name, createdAt,
                  connectionId, isConnectedToUseCase,
                } = item
                const date = formatDate(intl, createdAt)

                return (
                  <Box
                    className={cx(classes.listItem, {
                      [classes.listItemConnected]: isConnectedToUseCase,
                    })}
                    key={createId(name, index)}
                  >
                    <Box className={classes.itemStatus}>
                      <ToggleButtonComponent
                        name={createId(name, 'switch')}
                        label=''
                        onChange={(e) => handleToggleClick(e, isConnectedToUseCase, item)}
                        value={isConnectedToUseCase}
                        checked={isConnectedToUseCase}
                        transparent={true}
                      />
                    </Box>
                    <Typography
                      className={cx(classes.itemName, {
                        [classes.itemTextActive]: isConnectedToUseCase,
                      })}
                      noWrap={true}
                      title={name}
                    >
                      {name}
                    </Typography>
                    <Typography
                      className={classes.itemDate}
                      noWrap={true}
                      component='div'
                    >
                      {date}

                      <Box
                        onClick={(e) => handleEditClick(e, connectionId)}
                        className={classes.itemButton}
                      >
                        <EditFlowIcon />
                      </Box>
                    </Typography>
                  </Box>
                )
              })
            }
          </Box>

          {
            hasConnectedItems ? (
              <ParameterTemplatesTableComponent
                list={parametersTemplatesList}
                title={intl.formatMessage({ id: 'connect.modal.connect.connect_with.parameters_list' }, { name: sourceName })}
              />
            ) : (
              null
            )
          }
        </SidePanelCardComponent>

        <SidePanelCardActionsComponent>
          <ModalButtonComponent
            name='connectDataSourceModalCloseButton'
            onClick={handleClose}
            type='back'
            loading={isSubmitting}
            disabled={isSubmitting}
          />
        </SidePanelCardActionsComponent>
      </SidePanelLoadingComponent>
    </SidePanelComponent>
  )
}

export default ConnectDataSourceModalContainer
