import { handleActions } from 'redux-actions'
import { EvaluationPlotsState } from '@redux/modules/types'
import { startFetchingAction, stopFetchingAction } from '@utils/redux.utils'

import {
  RECEIVE_EVALUATION_PLOTS,

  START_FETCHING_EVALUATION_PLOTS,
  STOP_FETCHING_EVALUATION_PLOTS,
} from './evaluation-plots.action-types'

import {
  initialState,
  receiveEvaluationPlots,
} from './evaluation-plots.reducers'

export default handleActions<EvaluationPlotsState, any>({
  [RECEIVE_EVALUATION_PLOTS]: receiveEvaluationPlots,
  [START_FETCHING_EVALUATION_PLOTS]: startFetchingAction<EvaluationPlotsState>,
  [STOP_FETCHING_EVALUATION_PLOTS]: stopFetchingAction<EvaluationPlotsState>,
}, initialState)
