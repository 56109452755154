import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    height: '0px',
    overflow: 'hidden',
  },
  expanded: {
    borderTop: `1px solid ${theme.palette.new.grey_a}`,
    height: '100%',
  },
  chartDetails: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    height: theme.spacing(6),
    padding: theme.spacing(0, 4),
    borderTop: `1px solid ${theme.palette.new.grey_a}`,
    [theme.breakpoints.down('xl')]: {
      padding: theme.spacing(0, 2),
    },
  },
  disabled: {
    display: 'none',
  },
  chartDetailsMessege: {
    fontSize: '16px',
    fontWeight: 500,
    [theme.breakpoints.down('xl')]: {
      fontSize: '14px',
    },
  },
  chartResetMessege: {
    fontSize: '18px',
    lineHeight: '60px',
    cursor: 'pointer',
    color: theme.palette.new.violet,
    textDecoration: 'underline',
    [theme.breakpoints.down('xl')]: {
      fontSize: '12px',
      lineHeight: '14px',
    },
    '&:hover': {
      textDecoration: 'none',
    },
  },
  inputsContainer: {
    marginTop: theme.spacing(-2),
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  chartRanage: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& > :last-child': {
      borderBottom: 'none',
    },
  },
  chartRanageItem: {
    padding: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.new.grey_a}`,
    [theme.breakpoints.down('xl')]: {
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
  },
  chartRanageTitle: {
    fontSize: '16px',
    fontWeight: 500,
    height: theme.spacing(2),
    marginBottom: theme.spacing(3.5),
    marginLeft: theme.spacing(0),
    color: theme.palette.new.black,
    letterSpacing: '0.025em',
    [theme.breakpoints.down('xl')]: {
      fontSize: '14px',
    },
  },
  sliderContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  slider: {},
  sliderRootContainer: {
    display: 'flex',
    position: 'relative',
    height: '64px',
    alignItems: 'center',
    width: '100%',
    margin: theme.spacing(0, 3, 0, 5),
  },
  sliderMinMaxContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    minWidth: theme.spacing(12),
  },
  sliderMinMax: {
    fontSize: '16px',
    maxWidth: theme.spacing(8),
    color: theme.palette.new.black,
  },
  sliderMinMaxUnit: {
    color: theme.palette.new.black_d,
    fontSize: '16px',
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xl')]: {
      fontSize: '14px',
    },
  },
  sliderMin: {
    minWidth: theme.spacing(12),
    justifyContent: 'flex-start',
    '& > :first-of-type': {
      color: theme.palette.new.black,
    },
  },
  sliderMax: {
    minWidth: theme.spacing(12),
    justifyContent: 'flex-end',
    '& > :first-of-type': {
      color: theme.palette.new.black,
    },
  },
}))

export default useStyles
