import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { bindActionCreators } from 'redux'
import { Box, Typography } from '@mui/material'
import { DEFAULT_SUFFIX, formatNumberWithSuffixUnit, getNumberSuffix } from '@utils/analysis.utils'
import OptimizeRestockDetailsItemComponent from '@components/(features)/optimize/optimize-restock-details-item'
import OptimizeStrategySelectorComponent from '@components/(features)/optimize/optimize-strategy-selector'
import OptimizeValueComponent from '@components/(features)/optimize/optimize-value'
import OptimizeSliderFieldComponent from '@components/(features)/optimize/optimize-slider-field'

import { OPTIMIZE_RESTOCK_SELECTOR_TYPES } from '@constants/optimize.constants'
import { useSelector, useDispatch } from '@redux/hooks'
import { getRangeSelectorData } from '@redux/modules/optimize/optimize.selectors'
import { setTargetRevenue, setTargetReplenishmentCost } from '@redux/modules/optimize/optimize.actions'

import useStyles from './OptimizeStrategyBlock.styles'

const SLIDER_STEPS = 10

export interface OptimizeStrategyBlockComponentProps {
  setSelectedType: (type: OPTIMIZE_RESTOCK_SELECTOR_TYPES) => void,
  selectedType: OPTIMIZE_RESTOCK_SELECTOR_TYPES,
}

const OptimizeStrategyBlockComponent: React.FC<OptimizeStrategyBlockComponentProps> = (props) => {
  const {
    setSelectedType,
    selectedType,
  } = props

  const { classes } = useStyles()
  const intl = useIntl()
  const dispatch = useDispatch()
  const rangeSelector = useSelector((state) => getRangeSelectorData(state))

  const {
    targeReplenishmentCostSelected,
    targetRevenueSelected,
    targetRevenueMinMax,
    targeReplenishmentCostMinMax,
  } = rangeSelector

  const setTargetRevenueAction = bindActionCreators(setTargetRevenue, dispatch)
  const setTargetReplenishmentCostAction = bindActionCreators(setTargetReplenishmentCost, dispatch)

  const [replenishmentCostSliderValue, setReplenishmentCostSliderValue] = useState<number>(targeReplenishmentCostSelected)
  const [revenueSliderValue, setRevenueSliderValue] = useState<number>(targetRevenueSelected)
  const formattedReplenishmentCost = formatNumberWithSuffixUnit(targeReplenishmentCostSelected)
  const formattedRevenue = formatNumberWithSuffixUnit(targetRevenueSelected)

  const getTypeBasedValue = () => {
    switch (selectedType) {
      case OPTIMIZE_RESTOCK_SELECTOR_TYPES.REPLENISHMENT_COST:
        return formattedReplenishmentCost
      case OPTIMIZE_RESTOCK_SELECTOR_TYPES.REVENUE:
        return formattedRevenue
      case OPTIMIZE_RESTOCK_SELECTOR_TYPES.TRADE_OFFS:
      default:
        return (
          <Box
            display='flex'
            flexDirection='row'
            alignItems='center'
          >
            <Box
              display='flex'
              flexDirection='column'
              alignItems='flex-start'
              mr={2}
            >
              <Typography className={classes.tradeOffsHeader}>
                {intl.formatMessage({ id: 'optimize.selector.replenishmentCost' })}
              </Typography>

              <OptimizeValueComponent value={formattedReplenishmentCost} />
            </Box>
            <Box
              display='flex'
              flexDirection='column'
              alignItems='flex-start'
            >
              <Typography className={classes.tradeOffsHeader}>
                {intl.formatMessage({ id: 'optimize.selector.revenue' })}
              </Typography>
              <OptimizeValueComponent value={formattedRevenue} />
            </Box>
          </Box>
        )
    }
  }

  const getTypeBasedRangePicker = () => {
    switch (selectedType) {
      case OPTIMIZE_RESTOCK_SELECTOR_TYPES.REPLENISHMENT_COST:
        const targetReplenishmentCostSuffix = getNumberSuffix(targeReplenishmentCostSelected)
        const formatterReplenishmentCost = (value: number) => formatNumberWithSuffixUnit(value, targetReplenishmentCostSuffix, true)
        const tooltipFormatter = (value: number) => formatNumberWithSuffixUnit(
          value,
          targetReplenishmentCostSuffix,
          false,
          intl.formatMessage({ id: 'optimize.selector.eur' }),
        )

        return (
          <div className={classes.rangePickerRoot} key={selectedType}>
            <OptimizeSliderFieldComponent
              value={replenishmentCostSliderValue}
              min={targeReplenishmentCostMinMax[0]}
              max={targeReplenishmentCostMinMax[1]}
              formatter={formatterReplenishmentCost}
              valueLabelFormat={tooltipFormatter}
              disabled={targeReplenishmentCostMinMax[0] === targeReplenishmentCostMinMax[1]}
              unit={intl.formatMessage({ id: 'optimize.selector.eurUnit' }, { suffix: targetReplenishmentCostSuffix?.key || DEFAULT_SUFFIX })}
              marks={true}
              step={((targeReplenishmentCostMinMax[1] - targeReplenishmentCostMinMax[0]) / SLIDER_STEPS)}
              onChange={((e: Event, value: number) => {
                setReplenishmentCostSliderValue(value)
              }) as any}
              onChangeCommitted={((e: Event, value: number) => {
                setTargetReplenishmentCostAction(value)
              }) as any}
            />
          </div>
        )
      case OPTIMIZE_RESTOCK_SELECTOR_TYPES.REVENUE:
        const targetRevenueSuffix = getNumberSuffix(targetRevenueSelected)
        const formatterRevenue = (value: number) => formatNumberWithSuffixUnit(value, targetRevenueSuffix, true)
        const tooltipFormatterR = (value: number) => formatNumberWithSuffixUnit(
          value,
          targetRevenueSuffix,
          false,
          intl.formatMessage({ id: 'optimize.selector.eur' }),
        )

        return (
          <div className={classes.rangePickerRoot} key={selectedType}>
            <OptimizeSliderFieldComponent
              value={revenueSliderValue}
              min={targetRevenueMinMax[0]}
              max={targetRevenueMinMax[1]}
              formatter={formatterRevenue}
              valueLabelFormat={tooltipFormatterR}
              disabled={targetRevenueMinMax[0] === targetRevenueMinMax[1]}
              unit={intl.formatMessage({ id: 'optimize.selector.eurUnit' }, { suffix: targetRevenueSuffix?.key || DEFAULT_SUFFIX })}
              marks={true}
              step={((targetRevenueMinMax[1] - targetRevenueMinMax[0]) / SLIDER_STEPS)}
              onChange={((e: Event, value: number) => {
                setRevenueSliderValue(value)
              }) as any}
              onChangeCommitted={((e: Event, value: number) => {
                setTargetRevenueAction(value)
              }) as any}
            />
          </div>
        )
      case OPTIMIZE_RESTOCK_SELECTOR_TYPES.TRADE_OFFS:
      default:
        return null
    }
  }

  return (
    <OptimizeRestockDetailsItemComponent
      header={intl.formatMessage({ id: 'optimize.selector.restockStrategy' })}
      subHeader={intl.formatMessage({ id: 'optimize.selector.choose' })}
      description={(<OptimizeStrategySelectorComponent selectedType={selectedType} setSelectedType={setSelectedType} />)}
      value={getTypeBasedValue()}
      subValue={getTypeBasedRangePicker()}
      colorless={true}
    />
  )
}

export default OptimizeStrategyBlockComponent
