import React from 'react'
import { useIntl } from 'react-intl'
import get from 'lodash.get'

import { Box, Grid, Typography } from '@mui/material'

import { MAX_SELECTED_POINTS } from '@constants/analysis.constants'
import { defaultNumberFormatter } from '@utils/analysis.utils'
import { createId } from '@utils/common.utils'
import ModalButtonComponent from '@base/buttons/ModalButton'

import useStyles from './TradeOffsPanel.styles'

export type TradeOffsDatasetSolution = (Socrates.TradeOffsDatasetKpi & Socrates.TradeOffsDatasetDesign)

export interface TradeOffsPanelComponentProps {
  tradeOffsComparisonMeta?: Socrates.TradeOffsComparisonMetaItem[],
  selectedPoints: Socrates.TradeOffsDatasetItem[],
  solutionToExecute: number | null,
  handleDownloadSelection: {
    (index: number): any,
  },
  selectTradeOffsPoint(payload: Socrates.SelectedTradeOffsPoint): any,
  setSolutionToExecute: {
    (value: number | null): any,
  },
}

const TradeOffsPanelComponent: React.FC<TradeOffsPanelComponentProps> = (props) => {
  const {
    tradeOffsComparisonMeta = [],
    selectedPoints,
    handleDownloadSelection,
    solutionToExecute,
    setSolutionToExecute,
    selectTradeOffsPoint,
  } = props

  const { classes, cx } = useStyles()
  const intl = useIntl()
  const isPointsSelected = selectedPoints.length > 0
  const numOfPoints = (new Array(MAX_SELECTED_POINTS)).fill('')
  const setSolutionToExecuteHandler = (index: number) => {
    if (isSolutionToExecuteDisabled(index)) {
      return
    }

    if (index === solutionToExecute) {
      setSolutionToExecute(null)
    } else {
      setSolutionToExecute(index)
    }

    selectTradeOffsPoint({
      payload: selectedPoints[index],
      decision: true,
      deselect: (index === solutionToExecute),
    })
  }

  const isSolutionToExecuteActive = (index: number) => {
    return index === solutionToExecute
  }

  const isSolutionToExecuteDisabled = (index: number) => {
    return index + 1 > selectedPoints.length
  }

  const getValueByIndex = (index: number, item: Socrates.TradeOffsComparisonMetaItem) => {
    if (isSolutionToExecuteDisabled(index)) {
      return {
        value: '-',
        original: '-',
      }
    }

    const datasetDesign = get(selectedPoints[index], 'datasetPayload.datasetDesign', [])
    const datasetKpis = get(selectedPoints[index], 'datasetPayload.datasetKpis', [])
    const solutions: TradeOffsDatasetSolution[] = ([] as TradeOffsDatasetSolution[]).concat(datasetDesign).concat(datasetKpis)
    const indexCol = solutions.find((solutionItem) => ((solutionItem.label === item.name) && (solutionItem.kpi === item.isKPI))) || {} as TradeOffsDatasetSolution

    return {
      value: defaultNumberFormatter(indexCol.value),
      original: indexCol.value,
    }
  }

  return (
    <Grid item={true} xs={4} className={classes.rightContainer} data-testid={TradeOffsPanelComponent.name}>
      <div className={classes.solutionHeader}>
        <div className={classes.topLegendContainer}>
          <Typography className={classes.topLegendText}>
            {intl.formatMessage({ id: 'trade_offs.chart.pareto.selected_optimum' })}
          </Typography>
        </div>
        <div />
      </div>

      <div className={classes.comparisonContainer}>
        <div className={classes.comparisonBlock}>

          <div className={classes.comparisonItem}>
            <Typography className={classes.comparisonItemText} />
            {
              numOfPoints.map((item, index) => {
                return (
                  <Typography
                    key={createId(index, 'item-number')}
                    className={cx(classes.comparisonItemNumRow, classes.comparisonItemNum, {
                      [classes.comparisonItemNumActive]: isSolutionToExecuteActive(index),
                    })}
                  >
                    {index + 1}
                  </Typography>
                )
              })
            }
            <Typography className={classes.comparisonItemUnits} />
          </div>

          {
            tradeOffsComparisonMeta?.map((item) => {
              return (
                <div
                  key={item.name}
                  className={cx(classes.comparisonItem, {
                    [classes.comparisonItemKpi]: item.isKPI,
                  })}
                >
                  <Typography
                    className={classes.comparisonItemText}
                    noWrap={true}
                    title={item.name}
                  >
                    {item.name}
                  </Typography>
                  {
                    numOfPoints.map((point, index) => {
                      const values = getValueByIndex(index, item)
                      return (
                        <Typography
                          key={createId(index, 'item-value')}
                          title={String(values.original)}
                          className={cx(classes.comparisonItemValue, classes.comparisonItemNumRow, {
                            [classes.comparisonItemValueKpi]: item.isKPI,
                            [classes.comparisonItemNumActive]: isSolutionToExecuteActive(index) && item.isKPI,
                            [classes.comparisonItemNumActiveNonKpi]: isSolutionToExecuteActive(index) && !item.isKPI,
                          })}
                        >
                          {values.value}
                        </Typography>
                      )
                    })
                  }
                  <Typography className={classes.comparisonItemUnits} />
                </div>
              )
            })
          }
          <div
            className={cx(classes.comparisonItem, classes.comparisonItemDecide, {
              [classes.comparisonItemDecideActive]: isPointsSelected && solutionToExecute === null,
              [classes.comparisonItemDecideSelected]: isPointsSelected && solutionToExecute !== null,
            })}
          >
            <Typography className={classes.comparisonItemText}>
              {intl.formatMessage({ id: 'trade_offs.details.decide' })}
            </Typography>
            {
              numOfPoints.map((point, index) => {
                return (
                  <div
                    key={createId(index, 'item-decide')}
                    className={cx(classes.comparisonItemNumRow, classes.comparisonItemDecideContainer)}
                  >
                    <div
                      onClick={() => setSolutionToExecuteHandler(index)}
                      className={cx(classes.decideItem, {
                        [classes.decideItemActive]: isSolutionToExecuteActive(index),
                        [classes.decideItemDisabled]: isSolutionToExecuteDisabled(index),
                      })}
                    >
                      <div
                        className={classes.decideItemHover}
                      />
                    </div>
                  </div>
                )
              })
            }
            <Typography className={classes.comparisonItemUnits} />
          </div>
        </div>

        <Typography className={classes.configEmpty}>
          {!isPointsSelected ? intl.formatMessage({ id: 'trade_offs.details.empty' }) : null}
        </Typography>

        <Box className={classes.downloadButton}>
          <ModalButtonComponent
            name='downloadDecisionButton'
            onClick={() => handleDownloadSelection(solutionToExecute!)}
            type='submit'
            loading={false}
            disabled={solutionToExecute === null}
            label={intl.formatMessage({ id: 'trade_offs.details.download_decision' })}
          />
        </Box>
      </div>
    </Grid>
  )
}

export default TradeOffsPanelComponent
