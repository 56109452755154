import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    position: 'absolute',
    height: '60px',
    width: '100%',
    top: '-25px',
    zIndex: 1,
  },
}))

export default useStyles
