import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const ELEMENT_HEIGTH = 60

type allowedClassnames = 'summary'

const useStyles = makeStyles<void, allowedClassnames>()((theme: Theme, props, classes) => ({
  root: {
    '&.MuiAccordion-root': {
      '&:before': {
        display: 'none',
      },
    },
    '&.MuiAccordion-root.Mui-expanded': {
      margin: 0,
      [`& .${classes.summary}`]: {
        borderBottom: `1px solid ${theme.palette.new.grey_a} !important`,
        borderBottomLeftRadius: `${theme.spacing(0)} !important`,
        borderBottomRightRadius: `${theme.spacing(0)} !important`,
      },
      '&:hover': {
        [`& .${classes.summary}`]: {
          borderBottom: `1px solid ${theme.palette.new.grey_c} !important`,
        },
      },
      '&:before': {
        opacity: 1,
      },
    },
    '&.MuiAccordion-rounded': {
      borderTop: `1px solid ${theme.palette.new.grey_a}`,
      borderLeft: `1px solid ${theme.palette.new.grey_a}`,
      borderRight: `1px solid ${theme.palette.new.grey_a}`,
      '&:first-of-type': {
        borderTopLeftRadius: theme.spacing(0.5),
        borderTopRightRadius: theme.spacing(0.5),
      },
      '&:last-child': {
        borderBottom: `1px solid ${theme.palette.new.grey_a}`,
        borderBottomLeftRadius: theme.spacing(0.5),
        borderBottomRightRadius: theme.spacing(0.5),
      },
      '&:hover': {
        backgroundColor: theme.palette.new.grey,
        borderTop: `1px solid ${theme.palette.new.grey_c}`,
        borderLeft: `1px solid ${theme.palette.new.grey_c}`,
        borderRight: `1px solid ${theme.palette.new.grey_c}`,
        '&:last-child': {
          borderBottom: `1px solid ${theme.palette.new.grey_c}`,
        },
        '& + .MuiAccordion-rounded': {
          borderTop: `1px solid ${theme.palette.new.grey_c}`,
        },
      },
    },
    '&.Mui-disabled': {
      pointerEvents: 'none',
    },
  },
  container: {
    minHeight: '100%',
    borderRadius: theme.spacing(0.5),
  },
  emptyContainer: {
    height: '100%',
  },
  summary: {
    height: ELEMENT_HEIGTH,
    minHeight: ELEMENT_HEIGTH,
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      transform: 'rotate(-90deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(0deg)',
    },
  },
  summaryHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  summaryTitle: {
    marginLeft: theme.spacing(2),
  },
  statusText: {
    fontWeight: 400,
    fontSize: '16px',
    color: theme.palette.new.grey_c,
    marginRight: theme.spacing(2),
  },
  summaryDetails: {
    display: 'flex',
    borderBottomLeftRadius: theme.spacing(0.5),
    borderBottomRightRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.new.grey,
    padding: theme.spacing(3, 2),
  },
  description: {
    margin: theme.spacing(2, 0),
  },
  descriptionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  fakeInput: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    width: '100%',
    height: theme.spacing(4),
    padding: theme.spacing(1, 1, 1, 2),
    border: `1px solid ${theme.palette.new.grey_a}`,
    borderRadius: theme.spacing(1),
  },
  useCaseId: {
    fontSize: '15px',
    color: theme.palette.new.black,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('xl')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '10px',
    },
  },
  useCaseFloat: {
    color: theme.palette.new.grey_c,
    fontSize: '14px',
    position: 'absolute',
    top: theme.spacing(-1),
    padding: theme.spacing(0, 1),
    left: theme.spacing(2),
    backgroundColor: theme.palette.new.grey,
    [theme.breakpoints.down('xl')]: {
      fontSize: '12px',
      left: theme.spacing(1),
    },
  },
  videoWrapper: {
    width: '100%',
    position: 'relative',
    marginRight: theme.spacing(2.5),
    borderRadius: theme.spacing(1),
    minHeight: '230px',
    background: 'url("/video_placeholder_new.png") no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.down('lg')]: {
      minHeight: '200px',
    },
  },
  videoButton: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    fontSize: '18px',
    height: theme.spacing(4),
    background: 'rgba(0, 0, 0, 0.4)',
    [theme.breakpoints.down('xl')]: {
      '& .MuiButton-label': {
        fontSize: '16px !important',
      },
    },
  },
  videoTitle: {
    position: 'absolute',
    bottom: theme.spacing(1.3),
    left: theme.spacing(3),
    fontSize: '40px',
    fontWeight: 500,
    color: theme.palette.new.white,
    [theme.breakpoints.down('xl')]: {
      fontSize: '30px',
      bottom: theme.spacing(1.7),
    },
  },
  videoType: {
    position: 'absolute',
    top: theme.spacing(2.7),
    left: theme.spacing(10),
    fontSize: '16px',
    fontWeight: 400,
    color: 'rgba(255, 255, 255, 0.6)',
    border: '0.6px solid rgba(255, 255, 255, 0.6)',
    borderRadius: '100px',
    padding: '3px 7px 4px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
      top: theme.spacing(2.5),
      left: theme.spacing(9),
    },
  },
  videoIframe: {
    '& #video_iframe': {
      height: '450px',
    },
  },
  categoryName: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: theme.spacing(1.5),
    marginLeft: theme.spacing(6),
    fontWeight: 500,
    textTransform: 'uppercase',
    letterSpacing: '0.06em',
    color: theme.palette.new.black,
    height: theme.spacing(6),
  },
  categoryNameFirst: {
    height: 'auto',
  },
  emptyState: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(3),
    height: '100%',
  },
  emptyStateHeader: {
    color: theme.palette.new.black,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  emptyStateBody: {
    color: theme.palette.new.grey_h,
    maxWidth: '400px',
    textAlign: 'center',
  },
}))

export default useStyles
