import {
  DataGridPremiumProps,
  GridAggregationModel,
  GridCallbackDetails,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridPaginationModel,
  GridPinnedColumns,
  GridRowGroupingModel,
  GridRowId,
  GridRowSelectionModel,
  GridSortModel,
} from '@mui/x-data-grid-premium'

import { useRouteMatch } from 'react-router-dom'

import {
  trackEvent,
  TRACKING_ACTIONS,
} from '@utils/tracking.utils'

import { DataGridComponentProps } from '@base/datagrid/data-grid/DataGrid.component'

import { useCallback } from 'react'

export interface useDataGridOnChangeTrackingProps {
  name: DataGridComponentProps['name']
  onClipboardCopy?: DataGridPremiumProps['onClipboardCopy']
  onPinnedColumnsChange?: DataGridPremiumProps['onPinnedColumnsChange']
  onRowGroupingModelChange?: DataGridPremiumProps['onRowGroupingModelChange']
  onPaginationModelChange?: DataGridPremiumProps['onPaginationModelChange']
  onSortModelChange?: DataGridPremiumProps['onSortModelChange']
  onFilterModelChange?: DataGridPremiumProps['onFilterModelChange']
  onAggregationModelChange?: DataGridPremiumProps['onAggregationModelChange']
  onColumnVisibilityModelChange?: DataGridPremiumProps['onColumnVisibilityModelChange']
  onRowSelectionModelChange?: DataGridPremiumProps['onRowSelectionModelChange']
  onDetailPanelExpandedRowIdsChange?: DataGridPremiumProps['onDetailPanelExpandedRowIdsChange']
}

export const useDataGridOnChangeTracking = ({
  name,
  onClipboardCopy,
  onPinnedColumnsChange,
  onRowGroupingModelChange,
  onPaginationModelChange,
  onSortModelChange,
  onFilterModelChange,
  onAggregationModelChange,
  onColumnVisibilityModelChange,
  onRowSelectionModelChange,
  onDetailPanelExpandedRowIdsChange,
} : useDataGridOnChangeTrackingProps) => {
  const { params } = useRouteMatch<Common.RouterMatch>()

  const handleTrackEvent = useCallback((action: TRACKING_ACTIONS, model: any) => {
    if (name) {
      trackEvent({
        componentName: name,
        actionName: action,
      }, {
        router: params,
        model,
      })
    }
  }, [
    name,
    params,
  ])

  const handleClipboardCopy = useCallback((p: string, e: any, details: GridCallbackDetails<any>) => {
    if (onClipboardCopy) {
      onClipboardCopy(p, e, details)
    }

    handleTrackEvent(TRACKING_ACTIONS.COPY, null)
  }, [
    onClipboardCopy,
    handleTrackEvent,
  ])

  const handleRowGroupingModelChange = useCallback((model: GridRowGroupingModel, details: GridCallbackDetails<any>) => {
    if (onRowGroupingModelChange) {
      onRowGroupingModelChange(model, details)
    }

    handleTrackEvent(TRACKING_ACTIONS.GROUPING, model)
  }, [onRowGroupingModelChange, handleTrackEvent])

  const handlePaginationModelChange = useCallback((model: GridPaginationModel, details: GridCallbackDetails<any>) => {
    if (onPaginationModelChange) {
      onPaginationModelChange(model, details)
    }

    handleTrackEvent(TRACKING_ACTIONS.PAGINATION, model)
  }, [onPaginationModelChange, handleTrackEvent])

  const handleSortModelChange = useCallback((model: GridSortModel, details: GridCallbackDetails<any>) => {
    if (onSortModelChange) {
      onSortModelChange(model, details)
    }

    handleTrackEvent(TRACKING_ACTIONS.SORTING, model)
  }, [onSortModelChange, handleTrackEvent])

  const handleFilterModelChange = useCallback((model: GridFilterModel, details: GridCallbackDetails<any>) => {
    if (onFilterModelChange) {
      onFilterModelChange(model, details)
    }

    handleTrackEvent(TRACKING_ACTIONS.FILTERING, model)
  }, [onFilterModelChange, handleTrackEvent])

  const handleAggregationModelChange = useCallback((model: GridAggregationModel, details: GridCallbackDetails<any>) => {
    if (onAggregationModelChange) {
      onAggregationModelChange(model, details)
    }

    handleTrackEvent(TRACKING_ACTIONS.AGGREGATION, model)
  }, [onAggregationModelChange, handleTrackEvent])

  const handleRowSelectionModelChange = useCallback((model: GridRowSelectionModel, details: GridCallbackDetails<any>) => {
    if (onRowSelectionModelChange) {
      onRowSelectionModelChange(model, details)
    }

    handleTrackEvent(TRACKING_ACTIONS.ROW_SELECTION, model)
  }, [onRowSelectionModelChange, handleTrackEvent])

  const handlePinnedColumnsChange = useCallback((model: GridPinnedColumns, details: GridCallbackDetails<any>) => {
    if (onPinnedColumnsChange) {
      onPinnedColumnsChange(model, details)
    }

    handleTrackEvent(TRACKING_ACTIONS.COL_PINNING, model)
  }, [
    onPinnedColumnsChange,
    handleTrackEvent,
  ])

  const handleColumnVisibilityModelChange = useCallback((model: GridColumnVisibilityModel, details: GridCallbackDetails<any>) => {
    if (onColumnVisibilityModelChange) {
      onColumnVisibilityModelChange(model, details)
    }

    handleTrackEvent(TRACKING_ACTIONS.COL_VISIBILITY, model)
  }, [
    onColumnVisibilityModelChange,
    handleTrackEvent,
  ])

  const handleDetailPanelExpandedRowIdsChange = useCallback((model: GridRowId[], details: GridCallbackDetails<any>) => {
    if (onDetailPanelExpandedRowIdsChange) {
      onDetailPanelExpandedRowIdsChange(model, details)
    }

    handleTrackEvent(TRACKING_ACTIONS.DETAILS_TOGGLE, model)
  }, [
    onDetailPanelExpandedRowIdsChange,
    handleTrackEvent,
  ])

  return {
    onClipboardCopy: handleClipboardCopy,
    onPinnedColumnsChange: handlePinnedColumnsChange,
    onRowGroupingModelChange: handleRowGroupingModelChange,
    onPaginationModelChange: handlePaginationModelChange,
    onSortModelChange: handleSortModelChange,
    onFilterModelChange: handleFilterModelChange,
    onAggregationModelChange: handleAggregationModelChange,
    onRowSelectionModelChange: handleRowSelectionModelChange,
    onColumnVisibilityModelChange: handleColumnVisibilityModelChange,
    onDetailPanelExpandedRowIdsChange: handleDetailPanelExpandedRowIdsChange,
  }
}
