import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { Box, Typography, useTheme } from '@mui/material'
import { useIntl } from 'react-intl'

import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import { TRACKING_ACTIONS, trackEvent } from '@utils/tracking.utils'

import ChipComponent from '@base/chips/Chip'
import TextFieldComponent from '@base/forms/TextField'
import SelectMenuItemComponent from '@base/menus/SelectMenuItem'
import SelectMenuListComponent from '@base/menus/SelectMenuList'

import ChevronIcon from '@icons/flow/chevron.icon'
import CloseIcon from '@icons/close.icon'

import useStyles from './AutocompleteChipsField.styles'

export interface AutocompleteChipsFieldProps<T> extends Omit<AutocompleteProps<any, true, false, any>, 'renderInput' | 'options'> {

  /**
   * The input name. Same as id if not provided.
   * Some form libraries require a name attribute.
   */
  name: string,
  /**
   * The options to be shown
   */
  options: T[],
  /**
   * onChange callback
   * @param e Event
   * @param selectedValue Selected value
   * @param reason Reason
   */
  handleChangeCallback(e: React.ChangeEvent<any>, selectedValue: T, newValue: T[]): any,
  /**
   * Gets the label of the option
   * @param option Option
   * @returns Label
   */
  getOptionLabel: (option: T) => string,
  /**
   * The label content.
   */
  label: string
  /**
   * If true, the label will be hidden.
   */
  hideLabel?: boolean
  /**
   * The input placeholder.
   */
  placeholder?: string
  /**
   * Input validation errors.
   */
  errors?: string[]
  /**
   * Indicates if the input has been touched.
   */
  touched?: boolean
  /**
   * Help text to be displayed in a tooltip.
   */
  floatingHelp?: string | NonNullable<React.ReactNode>
}

const AutocompleteChipsFieldComponent = <T extends {}> ({
  name,
  value,
  className,
  handleChangeCallback,
  label,
  errors,
  options,
  getOptionLabel,
  disabled,
  floatingHelp,
  freeSolo = false,
  placeholder,
  touched,
  hideLabel = false,
  noOptionsText,
  ...rest
}: AutocompleteChipsFieldProps<T>) => {
  const theme = useTheme()
  const intl = useIntl()
  const { params } = useRouteMatch<Common.RouterMatch>()
  const errorMessages = errors || []
  const hasValues = Boolean(value && value.length > 0)
  const { classes } = useStyles({
    hasValues,
    floatingHelp: Boolean(floatingHelp),
  })

  const handleChange = (e: React.ChangeEvent<any>, newValue: T[], reason: any) => {
    const selectedValue = newValue[newValue.length - 1]

    trackEvent({
      componentName: name,
      actionName: TRACKING_ACTIONS.CHANGE,
    }, {
      selectedValue,
      router: params,
    })

    handleChangeCallback && handleChangeCallback(e, selectedValue, newValue)
  }

  return (
    <Box
      data-testid={AutocompleteChipsFieldComponent.name}
    >
      <Autocomplete
        id={name}
        multiple={true}
        autoHighlight={false}
        autoSelect={false}
        fullWidth={true}
        disablePortal={false}
        disableCloseOnSelect={false}
        freeSolo={freeSolo}
        options={options}
        value={value}
        disabled={disabled}
        filterSelectedOptions={false}
        onChange={handleChange}
        clearIcon={<CloseIcon />}
        popupIcon={<ChevronIcon />}
        noOptionsText={(
          <Typography
            variant='body1'
            px='12px'
            color={theme.palette.new.business_black_40}
          >
            {noOptionsText || intl.formatMessage({ id: 'common.dropdown.noOptions' })}
          </Typography>
        )}
        classes={{
          paper: classes.paper,
          noOptions: classes.noOptions,
          inputRoot: classes.inputRoot,
          endAdornment: classes.endAdornment,
          root: classes.root,
          popper: classes.popper,
        }}
        renderTags={(tagValues, getTagProps) => {
          return tagValues.map((option: T, index) => {
            const {
              onDelete,
              ...tagProps
            } = getTagProps({ index })

            const hasError = Boolean(errorMessages[index])

            return (
              <ChipComponent
                {...tagProps}
                variant='outlined'
                label={(getOptionLabel ? getOptionLabel(option) : option) as string}
                color={hasError ? theme.palette.new.rebellious_red : theme.palette.new.versatile_violet}
                EndIconComponent={CloseIcon}
                onEndIconClick={onDelete}
                disabled={disabled}
                tooltip={hasError ? errorMessages[index] : undefined}
              />
            )
          })
        }}
        ListboxComponent={SelectMenuListComponent}
        renderOption={((props: React.HTMLAttributes<HTMLLIElement>, option: T, { selected } : { selected: boolean }) => {
          return (
            <SelectMenuItemComponent
              {...props}
              selected={selected}
            >
              {getOptionLabel(option)}
            </SelectMenuItemComponent>
          )
        })}
        getOptionLabel={getOptionLabel}
        renderInput={(inputParams) => (
          <TextFieldComponent
            {...inputParams.InputProps}
            inputProps={inputParams.inputProps}
            name={name}
            label={label}
            disabled={disabled}
            hideLabel={hideLabel}
            touched={touched}
            placeholder={disabled ? '' : placeholder}
            floatingHelp={floatingHelp}
          />
        )}
        {...rest}
      />
    </Box>
  )
}

export default AutocompleteChipsFieldComponent
