import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useIntl } from 'react-intl'

import {
  useGridApiContext,
  useGridSelector,
  gridColumnDefinitionsSelector,
  gridClasses,
  DataGridPremiumProps,
  GridRowGroupingModel,
  useGridRootProps,
} from '@mui/x-data-grid-premium'

import DataGridGroupingButtonComponent from '@base/datagrid/data-grid-grouping-button'
import DataGridToolbarSelectionBoxComponent from '@base/datagrid/data-grid-toolbar-selection-box'
import DataGridGroupingPanelComponent from '@base/datagrid/data-grid-grouping-panel'

export interface DataGridCustomGroupingToolbarComponentProps {
  disabled?: boolean,
  onRowGroupingModelChange?: DataGridPremiumProps['onRowGroupingModelChange']
  groupingMode?: 'server' | 'client'
  groupingModel: GridRowGroupingModel
}

export const DataGridCustomGroupingToolbarComponent: React.FC<DataGridCustomGroupingToolbarComponentProps> = ({
  disabled = false,
  groupingMode = 'client',
  onRowGroupingModelChange,
  groupingModel = [],
}) => {
  const intl = useIntl()
  const apiRef = useGridApiContext()
  const rootProps = useGridRootProps()
  const columns = useGridSelector(apiRef, gridColumnDefinitionsSelector)
  const [anchorEl, setAnchorEl] = useState<null | any>(null)
  const [toggleOpen, setToggleOpen] = useState(false)
  const open = Boolean(anchorEl) && toggleOpen
  const id = open ? 'grouping-popover' : undefined

  const readableValues = columns.filter((item) => {
    return groupingModel.includes(item.field)
  }).map((item) => item.headerName || item.field)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setToggleOpen(!toggleOpen)
  }

  useEffect(() => {
    const columnHeadersElement = apiRef.current.rootElementRef?.current?.querySelector(
      `.${gridClasses.columnHeaders}`,
    )

    if (columnHeadersElement) {
      setAnchorEl(columnHeadersElement)
    }
  }, [apiRef])

  const handleClose = () => {
    setToggleOpen(false)
  }

  const onBoxClick = () => {
    setToggleOpen(true)
  }

  const handleClear = () => {
    if (groupingMode === 'client') {
      apiRef.current.setRowGroupingModel([])
    } else if (onRowGroupingModelChange) {
      onRowGroupingModelChange([], {})
    }
  }

  if (rootProps.disableRowGrouping) {
    return null
  }

  return (
    <Box
      data-testid={DataGridCustomGroupingToolbarComponent.name}
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
      }}
    >
      <DataGridGroupingButtonComponent
        disabled={disabled}
        onClick={handleClick}
        groupingModel={groupingModel}
      />

      <DataGridToolbarSelectionBoxComponent
        label={intl.formatMessage({ id: 'common.groupedBy' })}
        values={readableValues}
        onClick={onBoxClick}
        disabled={disabled}
        onClear={handleClear}
      />

      <DataGridGroupingPanelComponent
        id={id}
        disabled={disabled}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onRowGroupingModelChange={onRowGroupingModelChange}
        groupingMode={groupingMode}
        groupingModel={groupingModel}
      />
    </Box>
  )
}

export default DataGridCustomGroupingToolbarComponent
