import { produce } from 'immer'
import get from 'lodash.get'
import maxBy from 'lodash.maxby'
import minBy from 'lodash.minby'
import orderBy from 'lodash.orderby'

import { DEFAULT_SORTER, DESC_SORTING_ORDER, DEFAULT_SORTING_ORDER } from '@constants/filters.constants'
import { TRADE_OFFS_LABEL_REPLENISHMENT_COST } from '@constants/optimize.constants'

import {
  isValueInRange,
  getSelectedPointIndex,
} from '@utils/analysis.utils'

import {
  splitRangeIntoIntervals,
  checkIfValueInRange,
} from '@utils/histoslider.utils'

import {
  DEFAULT_NUMBER_OF_STEPS,
} from '@constants/histoslider.constants'

import { sortByKey } from '@utils/list.utils'
import { setTradeOffsSuboptimumsState, getTradeOffsSuboptimumsState } from '@utils/local-storage.utils'
import { ReducerPayload, SocratesState } from '@redux/modules/types'

import {
  SUB_OPTIMAL_FILL_COLOR,
  SUB_OPTIMAL_SELECTED_RADIUS,
  DEFAULT_SUB_OPTIMAL_RADIUS,
  DEFAULT_SELECTED_RADIUS,
  X_DEFAULT_INDEX,
  Y_DEFAULT_INDEX,
  DEFAULT_SELECTED_COLOR,
  DEFAULT_FILL_COLOR,
  DEFAULT_RADIUS,
  MAX_SELECTED_POINTS,
} from '@constants/analysis.constants'

import { CalculateTradesOffsDatasetReducerPayload, FetchTradeOffsDataReducerPayload } from './socrates.types'

export const initialState: SocratesState = {
  isFetching: false,
  isFetched: false,
  fetchingKey: '',
  projectMeta: {} as Socrates.ProjectMap,
  paretoSolutions: [],
  list: [],
  item: {} as Socrates.ProjectDetails,
  tradeOffsDataSet: [],
  tradeOffsData: {
    kpisOptions: [],
    kpisSlider: {},
    configuration: {
      AXIS: {
        x: '',
        y: '',
      },
      SLIDER: {},
      DEFAULT: true,
      SUBOPTIMAL: {
        enabled: getTradeOffsSuboptimumsState() || false,
      },
    },
    comparisonMeta: [],
  },
  selectedTradeOffsPoints: [],
}

export const receive = (state: SocratesState, action: ReducerPayload<Socrates.ProjectDetails[]>) => {
  const nextState = produce(state, (draftState) => {
    draftState.list = orderBy(action.payload, DEFAULT_SORTER, DEFAULT_SORTING_ORDER) as Socrates.ProjectDetails[]
  })

  return nextState
}

export const receiveOne = (state: SocratesState, action: ReducerPayload<Socrates.ProjectDetails>) => {
  const nextState = produce(state, (draftState) => {
    draftState.item = action.payload
  })

  return nextState
}

export const receiveTradeOffsData = (state: SocratesState, action: ReducerPayload<FetchTradeOffsDataReducerPayload>) => {
  const nextState = produce(state, (draftState) => {
    const { paretoSolutions, projectMeta, optimizeEnabled } = action.payload

    /* Write received data to state */
    draftState.projectMeta = projectMeta
    draftState.paretoSolutions = paretoSolutions

    /* Fill initial configuration state */
    const KPIS = get(projectMeta, 'kpis', []).sort()
    const DESIGN: Socrates.DesignDefinitionExtended[] = sortByKey(get(projectMeta, 'design', []), 'id', DESC_SORTING_ORDER)
    const EMPTY_META: Socrates.TradeOffsComparisonMetaItem[] = []
    const COMPARISON_META: Socrates.TradeOffsComparisonMetaItem[] =
      EMPTY_META.concat(KPIS.map((kpi) => ({ name: kpi, isKPI: true }))).concat(DESIGN.map((design) => ({ name: design.id, isKPI: false })))

    const KPI_OPTIONS_CONFIG: Socrates.KpiOption[] = []
    const KPI_SLIDER_CONFIG: Socrates.KpiSliderConfig = {}

    KPIS.forEach((kpi) => {
      const paretoSolutionsForKpi = paretoSolutions.map((solution) => solution[kpi])
      const maxKpi = maxBy(paretoSolutionsForKpi, (solutionValue) => solutionValue) || 0
      const minKpi = minBy(paretoSolutionsForKpi, (solutionValue) => solutionValue) || 0
      const intervals = splitRangeIntoIntervals(minKpi, maxKpi, DEFAULT_NUMBER_OF_STEPS)
      const marks: Socrates.KpiOptionMark[] = []
      const counts: Socrates.KpiOptionCount[] = []

      intervals.forEach((step, intervalIndex) => {
        const numOfSteps = intervals.length
        const firstStep = step
        const secStep = ((intervalIndex + 1) === numOfSteps) ? step : intervals[intervalIndex + 1]
        const num = paretoSolutionsForKpi.reduce((n, value) => {
          return n + checkIfValueInRange(firstStep, secStep, value)
        }, 0)

        marks.push({
          value: step,
          label: '',
        })

        counts.push({
          firstStep,
          secStep,
          num,
        })
      })

      /* Prepare available option */
      KPI_OPTIONS_CONFIG.push({
        label: kpi,
        value: kpi,
        min: minKpi,
        max: maxKpi,
        marks,
        counts,
      })

      KPI_SLIDER_CONFIG[kpi] = [minKpi, maxKpi]
    })

    const isSingleOutput = KPI_OPTIONS_CONFIG.length === 1
    const yIndex = isSingleOutput ? X_DEFAULT_INDEX : Y_DEFAULT_INDEX
    const getAxes = () => {
      const potentialX = KPI_OPTIONS_CONFIG[X_DEFAULT_INDEX]?.value
      const potentialY = KPI_OPTIONS_CONFIG[yIndex]?.value

      if (!optimizeEnabled) {
        return {
          x: potentialX,
          y: potentialY,
        }
      }

      if (potentialX === TRADE_OFFS_LABEL_REPLENISHMENT_COST) {
        return {
          x: potentialX,
          y: potentialY,
        }
      }

      return {
        x: potentialY,
        y: potentialX,
      }
    }

    const CONFIGURATION = {
      AXIS: getAxes(),
      SLIDER: KPI_SLIDER_CONFIG,
      DEFAULT: true,
      SUBOPTIMAL: {
        enabled: getTradeOffsSuboptimumsState() || false,
      },
    }

    draftState.isFetched = true
    draftState.tradeOffsData = {
      configuration: CONFIGURATION,
      comparisonMeta: COMPARISON_META,
      kpisOptions: KPI_OPTIONS_CONFIG,
      kpisSlider: KPI_SLIDER_CONFIG,
    }
  })

  return nextState
}

export const caclulateTradeOffsDataset = (state: SocratesState, action: ReducerPayload<CalculateTradesOffsDatasetReducerPayload>) => {
  const nextState = produce(state, (draftState) => {
    const {
      projectMeta,
      paretoSolutions,
      tradeOffsData,
    } = action.payload

    const { configuration } = tradeOffsData

    const DATA_SET: Socrates.TradeOffsDatasetItem[] = []
    const X_AXIS_KEY = configuration.AXIS.x
    const Y_AXIS_KEY = configuration.AXIS.y
    const SLIDER_RANGES = configuration.SLIDER
    const KPIS: string[] = get(projectMeta, 'kpis', [])

    paretoSolutions.filter((item) => {
      return item.isParetoOptimal || draftState.tradeOffsData.configuration.SUBOPTIMAL.enabled
    }).forEach((item) => {
      const x = item[X_AXIS_KEY]
      const y = item[Y_AXIS_KEY]
      const isParetoOptimal = (item.isParetoOptimal || false)

      /* Filter values that's are out of range */
      let isAllValuesInRange = true
      for (const [itemKey, sliderRange] of Object.entries(SLIDER_RANGES)) {
        if (isAllValuesInRange) {
          isAllValuesInRange = isValueInRange(item[itemKey], sliderRange)
        }
      }

      const selectedPointIndex = getSelectedPointIndex(draftState.selectedTradeOffsPoints, x, y)
      const isPointSelected = selectedPointIndex >= 0

      const datasetSolutions = Object.keys(item).filter((key) => key !== 'isParetoOptimal').map((key) => ({
        label: key,
        value: item[key],
        kpi: KPIS.includes(key),
        max: Math.max(...paretoSolutions.map((o) => { return o[key] })),
        min: Math.min(...paretoSolutions.map((o) => { return o[key] })),
      })).filter((datasetItem) => (datasetItem.label !== 'name'))

      const datasetDesign = datasetSolutions.filter((datasetItem) => !datasetItem.kpi)
      const datasetKpis = datasetSolutions.filter((datasetItem) => datasetItem.kpi)
      const datasetName = item.name

      const fillColor = isPointSelected ? (DEFAULT_SELECTED_COLOR) : (isParetoOptimal ? DEFAULT_FILL_COLOR : SUB_OPTIMAL_FILL_COLOR)
      const radius = isPointSelected ?
        (isParetoOptimal ? DEFAULT_SELECTED_RADIUS : SUB_OPTIMAL_SELECTED_RADIUS) :
        (isParetoOptimal ? DEFAULT_RADIUS : DEFAULT_SUB_OPTIMAL_RADIUS)

      if (isAllValuesInRange) {
        DATA_SET.push({
          x,
          y,
          xKey: X_AXIS_KEY,
          yKey: Y_AXIS_KEY,
          name: datasetName,
          selectedPointIndex,
          datasetPayload: {
            datasetKpis,
            datasetDesign,
            datasetSolutions,
            datasetName,
            isPointSelected,
            isParetoOptimal,
          },
          radius,
          fill: fillColor,
          stroke: 'white',
          strokeWidth: '1px',
          isParetoOptimal,
          isPointSelected,
          cursor: isParetoOptimal ? 'pointer' : 'default',
          execute: isPointSelected ? draftState.selectedTradeOffsPoints[selectedPointIndex].execute : false,
        })
      }
    })

    /** When rendering SVG, zIndex is defined by order, so, sort order matters */
    draftState.tradeOffsDataSet = sortByKey(sortByKey(sortByKey(DATA_SET, 'isParetoOptimal'), 'isPointSelected'), 'execute')
  })

  return nextState
}

export const changeTradeOffsConfiguration = (state: SocratesState, action: ReducerPayload<Socrates.TradeOffsConfiguration>) => {
  const nextState = produce(state, (draftState) => {
    draftState.tradeOffsData.configuration = {
      AXIS: {
        ...draftState.tradeOffsData.configuration.AXIS,
        ...action.payload.AXIS,
      },
      SLIDER: {
        ...draftState.tradeOffsData.configuration.SLIDER,
        ...action.payload.SLIDER,
      },
      DEFAULT: false,
      SUBOPTIMAL: {
        ...draftState.tradeOffsData.configuration.SUBOPTIMAL,
        ...action.payload.SUBOPTIMAL,
      },
    }

    if (action.payload.SUBOPTIMAL) {
      setTradeOffsSuboptimumsState(action.payload.SUBOPTIMAL.enabled)
    }
  })

  return nextState
}

export const selectTradeOffsPoints = (state: SocratesState, action: ReducerPayload<Socrates.SelectedTradeOffsPoint>) => {
  const nextState = produce(state, (draftState) => {
    const payload = action.payload.payload || {}
    const selection = action.payload.select || false
    const decision = action.payload.decision || false
    const deselect = action.payload.deselect || false

    if (selection) {
      if (draftState.selectedTradeOffsPoints.length > 0) {
        const selectedPointIndex = getSelectedPointIndex(draftState.selectedTradeOffsPoints, payload.x, payload.y)
        const shiftLeft = false

        if (selectedPointIndex === -1) {
          if (draftState.selectedTradeOffsPoints.length === (action.payload.maxItems || MAX_SELECTED_POINTS)) {
            if (shiftLeft) {
              draftState.selectedTradeOffsPoints.shift()

              draftState.selectedTradeOffsPoints.push(payload)
            } else {
              draftState.selectedTradeOffsPoints.pop()

              draftState.selectedTradeOffsPoints.unshift(payload)
            }
          } else {
            draftState.selectedTradeOffsPoints.push(payload)
          }
        } else {
          draftState.selectedTradeOffsPoints.splice(selectedPointIndex, 1)
        }
      } else {
        draftState.selectedTradeOffsPoints.push(payload)
      }
    }

    if (decision) {
      const { name } = payload

      const selectedIndex = draftState.selectedTradeOffsPoints.findIndex((item) => item.name === name)

      draftState.selectedTradeOffsPoints.forEach((item, index) => {
        draftState.selectedTradeOffsPoints[index].execute = false
      })

      if (selectedIndex !== -1 && !deselect) {
        draftState.selectedTradeOffsPoints[selectedIndex].execute = true
      }
    }

    /* Config has changed */
    draftState.tradeOffsData.configuration.DEFAULT = false
  })

  return nextState
}

export const selectTradeOffsPointsReset = (state: SocratesState) => {
  const nextState = produce(state, (draftState) => {
    draftState.selectedTradeOffsPoints = []
  })

  return nextState
}

export const resetTradeOffsConfiguration = (state: SocratesState) => {
  const nextState = produce(state, (draftState) => {
    const isSingleOutput = draftState.tradeOffsData.kpisOptions.length === 1
    const yIndex = isSingleOutput ? X_DEFAULT_INDEX : Y_DEFAULT_INDEX

    draftState.tradeOffsData.configuration = {
      AXIS: {
        x: draftState.tradeOffsData.kpisOptions[X_DEFAULT_INDEX]?.value,
        y: draftState.tradeOffsData.kpisOptions[yIndex]?.value,
      },
      SLIDER: draftState.tradeOffsData.kpisSlider,
      DEFAULT: true,
      SUBOPTIMAL: {
        enabled: false,
      },
    }
  })

  return nextState
}

export const startFetching = (state: SocratesState, action: ReducerPayload<string>) => {
  const nextState = produce(state, (draftState) => {
    draftState.isFetching = true
    draftState.fetchingKey = action.payload
  })

  return nextState
}

export const stopFetching = (state: SocratesState, action: ReducerPayload<string>) => {
  const nextState = produce(state, (draftState) => {
    if (!action.payload) {
      draftState.fetchingKey = ''
      draftState.isFetching = false
    } else if (action.payload === draftState.fetchingKey) {
      draftState.fetchingKey = ''
      draftState.isFetching = false
    }
  })

  return nextState
}
