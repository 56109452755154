import produce from 'immer'
import moment from 'moment'
import orderBy from 'lodash.orderby'

import { ReducerPayload, EvaluationPlotsState } from '@redux/modules/types'
import { toUnixTimestamp } from '@utils/moment.utils'
import { DEFAULT_SORTING_ORDER } from '@constants/filters.constants'

export const initialState: EvaluationPlotsState = {
  fetchingKeys: [],
  evaluationPlots: [],
}

export const receiveEvaluationPlots = (
  state: EvaluationPlotsState,
  action: ReducerPayload<EvaluationPlots.EvaluationPlot[]>,
) => {
  const nextState = produce(state, (draftState) => {
    const data = orderBy(action.payload.map((file) => {
      return {
        ...file,
        timestamp: toUnixTimestamp(moment(file.updatedAt)),
      }
    }), 'timestamp', DEFAULT_SORTING_ORDER) as EvaluationPlots.EvaluationPlot[]

    draftState.evaluationPlots = data
  })

  return nextState
}
