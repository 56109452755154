import React from 'react'

import {
  Box,
  Slider, SliderProps,
  SliderThumb, SliderValueLabelProps,
} from '@mui/material'

import TooltipComponent from '@base/tooltips/Tooltip'
import { SLIDER_TOOLTIP_ANIMATION_TRANSITION_PROPS } from '@constants/tooltips.constants'
import SliderBarChartComponent, { SliderBarCount, SliderBarValue } from '@components/(features)/trade-offs/slider-bar-chart/SliderBarChart.component'
import { defaultNumberFormatter } from '@utils/analysis.utils'

import useStyles from './SliderField.styles'

const ThumbComponent = React.forwardRef((props, ref: any) => {
  const { children, ...other } = props

  return (
    <SliderThumb {...other} ref={ref}>
      {children}
      <Box component='span' className='bar' />
      <Box component='span' className='bar' />
      <Box component='span' className='bar' />
    </SliderThumb>
  )
}) as React.ElementType<React.HTMLAttributes<HTMLSpanElement>>

export interface ValueLabelComponentProps extends SliderValueLabelProps {
  children: React.ReactElement<any, any>,
  open: boolean,
  value: number,
}

const ValueLabelComponent: React.FC<ValueLabelComponentProps> = React.forwardRef((props, ref) => {
  const {
    children,
    open,
    value,
  } = props

  return (
    <TooltipComponent
      open={open}
      describeChild={true}
      enterTouchDelay={0}
      placement='top'
      title={defaultNumberFormatter(value)}
      ref={ref}
      TransitionProps={SLIDER_TOOLTIP_ANIMATION_TRANSITION_PROPS}
    >
      {children}
    </TooltipComponent>
  )
})

export interface SliderFieldComponentProps extends SliderProps {
  className?: string,
  counts?: SliderBarCount[],
  value?: SliderBarValue,
  withBarChart?: boolean,
}

const SliderFieldComponent: React.FC<SliderFieldComponentProps> = ({
  className,
  max = 100,
  min = 0,
  value,
  counts = [],
  withBarChart = true,
  valueLabelDisplay,
  ...props
}) => {
  const { classes } = useStyles()

  return (
    <>
      {
        withBarChart ? (
          <SliderBarChartComponent
            counts={counts}
            value={value}
          />
        ) : (
          null
        )
      }

      <Slider
        data-testid={SliderFieldComponent.name}
        value={value}
        min={min}
        max={max}
        classes={classes}
        components={{
          Thumb: ThumbComponent,
          ValueLabel: ValueLabelComponent,
        }}
        valueLabelDisplay={valueLabelDisplay}
        {...props}
      />
    </>
  )
}

export default SliderFieldComponent
