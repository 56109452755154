import { createAction } from 'redux-actions'

import {
  SUBMIT_EVALUATION_PLOTS,
  REQUEST_EVALUATION_PLOTS,
  RECEIVE_EVALUATION_PLOTS,

  START_FETCHING_EVALUATION_PLOTS,
  STOP_FETCHING_EVALUATION_PLOTS,
} from './evaluation-plots.action-types'

import {
  ListEvaluationPlotsActionPayload,
  UploadEvaluationPlotsActionPayload,
} from './evaluation-plots.types'

export const submitEvaluationPlotsAction = createAction<UploadEvaluationPlotsActionPayload>(SUBMIT_EVALUATION_PLOTS)
export const requestEvaluationPlotsAction = createAction<ListEvaluationPlotsActionPayload>(REQUEST_EVALUATION_PLOTS)
export const receiveEvaluationPlotsList = createAction(RECEIVE_EVALUATION_PLOTS)

export const startFetchingEvaluationPlotsAction = createAction<string>(START_FETCHING_EVALUATION_PLOTS)
export const stopFetchingEvaluationPlotsAction = createAction<string>(STOP_FETCHING_EVALUATION_PLOTS)
