import React, { useMemo } from 'react'
import { Box } from '@mui/material'
import { useIntl } from 'react-intl'
import { useSelector } from '@redux/hooks'
import { DEFAULT_BACKTEST_ABS_DEVIATION_FIELD_PREFIX, DEFAULT_BACKTEST_PREDICTION_FIELD_PREFIX } from '@constants/insights.constants'
import { foldsToChartAnnotations } from '@utils/monitor.utils'

import {
  getBacktestingAvailableFolds, getBacktestingChart,
  getBacktestingTable, isFetchingBacktestingChart,
} from '@redux/modules/monitor/monitor.selectors'

import InsightsChartComponent from '@components/(features)/insights/insights-chart'

const BacktestingChartContainer = () => {
  const intl = useIntl()
  const backtestingTable = useSelector((state) => getBacktestingTable(state))
  const backtestingChart = useSelector((state) => getBacktestingChart(state))
  const backtestingAvailableFolds = useSelector((state) => getBacktestingAvailableFolds(state))
  const isFetching = useSelector((state) => isFetchingBacktestingChart(state))

  const {
    gridState,
  } = backtestingTable

  const {
    dataset,
    lines,
    targetName,
    targetUnit,
  } = backtestingChart

  const annotations: Hera.BaseChartAnnotationItem[] = useMemo(() => {
    if (!gridState) {
      return []
    }

    return foldsToChartAnnotations(intl, gridState.backtestingFold, backtestingAvailableFolds)
  }, [intl, gridState, backtestingAvailableFolds])

  return (
    <Box
      data-testid={BacktestingChartContainer.name}
    >
      <InsightsChartComponent
        dataset={dataset}
        lines={lines}
        annotations={annotations}
        gridState={gridState}
        targetName={targetName}
        targetUnit={targetUnit}
        isFetching={isFetching}
        predictionKeyPrefix={DEFAULT_BACKTEST_PREDICTION_FIELD_PREFIX}
        absDeviationKeyPrefix={DEFAULT_BACKTEST_ABS_DEVIATION_FIELD_PREFIX}
      />
    </Box>
  )
}

export default BacktestingChartContainer
