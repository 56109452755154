import { handleActions } from 'redux-actions'
import { SocratesState } from '@redux/modules/types'

import {
  RECEIVE_SOCRATES_PROJECT,
  RECEIVE_SOCRATES_PROJECTS,
  RECEIVE_TRADE_OFFS_DATA,
  START_FETCHING_ANALYSIS,
  STOP_FETCHING_ANALYSIS,
  CHANGE_TRADE_OFFS_CONFIGURATION_DONE,
  CALCULATE_TRADE_OFFS_DATASET_DONE,
  SELECT_TRADE_OFFS_POINT_DONE,
  SELECT_TRADE_OFFS_POINT_RESET,
  RESET_TRADE_OFFS_CONFIGURATION_DONE,
} from './socrates.action-types'

import {
  receive,
  receiveOne,
  startFetching,
  stopFetching,
  receiveTradeOffsData,
  changeTradeOffsConfiguration,
  caclulateTradeOffsDataset,
  selectTradeOffsPoints,
  selectTradeOffsPointsReset,
  resetTradeOffsConfiguration,
  initialState,
} from './socrates.reducers'

export default handleActions<SocratesState, any>({
  [RECEIVE_SOCRATES_PROJECTS]: receive,
  [RECEIVE_SOCRATES_PROJECT]: receiveOne,
  [RECEIVE_TRADE_OFFS_DATA]: receiveTradeOffsData,
  [START_FETCHING_ANALYSIS]: startFetching,
  [STOP_FETCHING_ANALYSIS]: stopFetching,
  [CHANGE_TRADE_OFFS_CONFIGURATION_DONE]: changeTradeOffsConfiguration,
  [CALCULATE_TRADE_OFFS_DATASET_DONE]: caclulateTradeOffsDataset,
  [RESET_TRADE_OFFS_CONFIGURATION_DONE]: resetTradeOffsConfiguration,
  [SELECT_TRADE_OFFS_POINT_DONE]: selectTradeOffsPoints,
  [SELECT_TRADE_OFFS_POINT_RESET]: selectTradeOffsPointsReset,
}, initialState)
