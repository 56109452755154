import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import {
  NODES_WIDTHS,
  NODES_HEIGHT,
  NODE_TYPES,
  FLOW_ELEMENTS_TRANSITIONS,
} from '@constants/flow.constants'

const useStyles = makeStyles()((theme: Theme) => ({
  rootWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.new.white,
    borderRadius: '100%',
    width: NODES_WIDTHS[NODE_TYPES.EMPTY_GROUPING],
    height: NODES_HEIGHT[NODE_TYPES.EMPTY_GROUPING],
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: theme.spacing(4),
    height: theme.spacing(4),
    background: theme.palette.new.white,
    border: '1px solid',
    borderColor: theme.palette.new.grey_b,
    borderRadius: '100%',
    alignItems: 'flex-start',
    position: 'relative',
    cursor: 'pointer',
    ...FLOW_ELEMENTS_TRANSITIONS,
    '&:hover': {
      background: '#E5E5E5',
      borderColor: '#E5E5E5',
    },
  },
  content: {
    width: theme.spacing(3.8),
    height: theme.spacing(3.8),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    outline: 'none',
  },
  disabled: {
    cursor: 'not-allowed',
  },
}))

export default useStyles
