import React from 'react'

import {
  Box,
  Slider, SliderProps,
  SliderThumb, SliderValueLabelProps,
} from '@mui/material'

import TooltipComponent from '@base/tooltips/Tooltip'
import { SLIDER_TOOLTIP_ANIMATION_TRANSITION_PROPS } from '@constants/tooltips.constants'
import FormattedUnitValueComponent from '@components/(features)/trade-offs/formatted-unit-value'

import useStyles from './OptimizeSliderField.styles'

const ThumbComponent = React.forwardRef((props, ref: any) => {
  const { children, ...other } = props

  return (
    <SliderThumb {...other} ref={ref}>
      {children}
      <Box component='span' className='bar' />
      <Box component='span' className='bar' />
      <Box component='span' className='bar' />
    </SliderThumb>
  )
}) as React.ElementType<React.HTMLAttributes<HTMLSpanElement>>

export interface ValueLabelComponentProps extends SliderValueLabelProps {
  children: React.ReactElement<any, any>,
  open: boolean,
  value: number,
}

const ValueLabelComponent: React.FC<ValueLabelComponentProps> = React.forwardRef((props, ref) => {
  const {
    children,
    open,
    value,
  } = props

  return (
    <TooltipComponent
      open={open}
      describeChild={true}
      enterTouchDelay={0}
      placement='top'
      title={value}
      ref={ref}
      TransitionProps={SLIDER_TOOLTIP_ANIMATION_TRANSITION_PROPS}
    >
      {children}
    </TooltipComponent>
  )
})

export interface SimpleSliderFieldComponentProps extends SliderProps {
  className?: string,
  unit?: string,
  trackColor?: string,
  counts?: any[],
  formatter?: (value: number) => string,
  tooltipFormatter?: (value: number) => string,
  valueLabelFormat?: (value: number) => string,
}

const OptimizeSliderFieldComponent: React.FC<SimpleSliderFieldComponentProps> = ({
  className,
  max = 100,
  min = 0,
  value,
  unit,
  trackColor,
  counts = [],
  valueLabelDisplay = 'auto',
  valueLabelFormat,
  formatter,
  ...props
}) => {
  const { classes } = useStyles({ trackColor })
  const formattedMin = formatter ? formatter(min!) : String(min)
  const formattedMax = formatter ? formatter(max!) : String(max)

  return (
    <Box className={classes.rootContainer}>
      <FormattedUnitValueComponent
        className={classes.min}
        value={formattedMin}
        unit={unit}
      />

      <Slider
        data-testid={OptimizeSliderFieldComponent.name}
        value={value}
        min={min}
        max={max}
        classes={{
          root: classes.root,
          thumb: classes.thumb,
          mark: classes.mark,
          markActive: classes.markActive,
          track: classes.track,
          rail: classes.rail,
        }}
        components={{
          Thumb: ThumbComponent,
          ValueLabel: ValueLabelComponent,
        }}
        valueLabelDisplay={valueLabelDisplay}
        valueLabelFormat={valueLabelFormat}
        {...props}
      />

      <FormattedUnitValueComponent
        className={classes.max}
        value={formattedMax}
        unit={unit}
      />
    </Box>
  )
}

export default OptimizeSliderFieldComponent
