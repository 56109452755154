import React from 'react'

import { Box, Typography, useTheme } from '@mui/material'

import { INPUT_TYPES } from '@constants/flow.constants'
import { PARAMETER_BLOCK_TYPES } from '@components/connect-view/flow/ReactFlowParameterBlock'
import { getUseCaseFreezeStatus } from '@redux/modules/use-case/use-case.selectors'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { trackEvent, TRACKING_ACTIONS } from '@utils/tracking.utils'
import { useSelector } from '@redux/hooks'

import AddFlowIcon from '@icons/flow/addFlow.icon'

import useStyles from './ReactFlowAddNewButton.styles'

export interface ReactFlowAddNewButtonComponentProps {
  /**
   * Button text
   */
  buttonText?: string,
  /**
   * Type of the input
   */
  type?: INPUT_TYPES | PARAMETER_BLOCK_TYPES.TARGET,
  /**
   * On add button click handler
   */
  onAddButtonClick?: {
    (e?: React.SyntheticEvent): any,
  },
  /**
   * Parent component name for testing
   */
  parentComponentName?: string,
}

const ReactFlowAddNewButtonComponent: React.FC<ReactFlowAddNewButtonComponentProps> = ({
  buttonText,
  type,
  onAddButtonClick,
  parentComponentName,
}) => {
  const { classes, cx } = useStyles()
  const theme = useTheme()
  const frozen = useSelector((state) => getUseCaseFreezeStatus(state))
  const isAdmin = useSelector((state) => getIsAdmin(state))
  const creationDisabled = !isAdmin && frozen
  const handleOnClick = (e: React.MouseEvent) => {
    trackEvent({
      componentName: 'addAdditionalNodeButton',
      actionName: TRACKING_ACTIONS.CLICK,
    })

    if (onAddButtonClick) {
      onAddButtonClick(e)
    }
  }

  return (
    <Box
      className={cx(classes.newInputItem, {
        [classes.newTargetItem]: type === PARAMETER_BLOCK_TYPES.TARGET,
        [classes.disabled]: creationDisabled,
      })}
      onClick={creationDisabled ? () => {} : handleOnClick}
      role='button'
      tabIndex={0}
      aria-label={buttonText}
      data-testid={`${ReactFlowAddNewButtonComponent.name}-${parentComponentName}`}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'row',
          gap: '10px',
        }}
      >
        <Box className={classes.buttonIcon}>
          <AddFlowIcon width={22} height={22} />
        </Box>
        <Typography
          variant='button'
          color={type === PARAMETER_BLOCK_TYPES.TARGET ? theme.palette.new.white : theme.palette.new.business_black}
        >
          {buttonText}
        </Typography>
      </Box>
    </Box>
  )
}

export default ReactFlowAddNewButtonComponent
