import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import {
  INPUT_PARAMETER_BLOCK_HEIGHT,
  INPUT_PARAMETER_BLOCK_WIDTHS,
  OUTPUT_PARAMETER_BLOCK_WIDTHS,
  OUTPUT_PARAMETER_BLOCK_HEIGHT,
  FLOW_ELEMENTS_TRANSITIONS,
  FLOW_INACTIVE_ELEMENTS_TRANSITIONS,
} from '@constants/flow.constants'

import { PARETOS_GRADIENT } from '@constants/ui.constants'

type allowedClasses = 'footerUnits' | 'outputParameterItem' | 'name' | 'footerIcon' | 'footerUnitsInactive' | 'targetName'

const useStyles = makeStyles<void, allowedClasses>()((theme: Theme, props, classes) => ({
  inputParameterItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: INPUT_PARAMETER_BLOCK_WIDTHS,
    height: INPUT_PARAMETER_BLOCK_HEIGHT,
    backgroundColor: theme.palette.new.white,
    border: `1px solid ${theme.palette.new.grey_a}`,
    padding: theme.spacing(1.5, 2, 1.5, 1.5),
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    outline: 'none',
    ...FLOW_ELEMENTS_TRANSITIONS,

    '&:hover': {
      border: `1px solid ${theme.palette.new.grey_c}`,
      backgroundColor: theme.palette.new.grey,
    },
    '&:focus-visible': {
      border: `1px solid ${theme.palette.new.grey_c}`,
      backgroundColor: theme.palette.new.grey,
    },
  },
  activeInputParameterItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: INPUT_PARAMETER_BLOCK_WIDTHS,
    height: INPUT_PARAMETER_BLOCK_HEIGHT,
    background: theme.palette.new.white,
    border: `1px solid ${theme.palette.new.versatile_violet}`,
    padding: theme.spacing(1.5, 2, 1.5, 1.5),
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    ...FLOW_ELEMENTS_TRANSITIONS,
    outline: 'none',

    '&:hover': {
      border: `1px solid ${theme.palette.new.versatile_violet}`,
      background: 'rgba(95, 38, 224, 0.2)',
    },
    '&:focus-visible': {
      border: `1px solid ${theme.palette.new.versatile_violet}`,
      background: 'rgba(95, 38, 224, 0.2)',
    },
  },
  outputParameterItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: OUTPUT_PARAMETER_BLOCK_WIDTHS,
    height: OUTPUT_PARAMETER_BLOCK_HEIGHT,
    padding: theme.spacing(1.5, 2, 1.5, 1.5),
    marginTop: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    background: 'rgba(255, 255, 255, 0.4)',
    outline: 'none',

    ...FLOW_ELEMENTS_TRANSITIONS,

    '&:hover': {
      background: 'rgba(255, 255, 255, 0.2)',
    },
    '&:focus-visible': {
      background: 'rgba(255, 255, 255, 0.2)',
    },
  },
  targetName: {
    color: theme.palette.new.white,
    width: OUTPUT_PARAMETER_BLOCK_WIDTHS - 40,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
  },
  name: {
    color: theme.palette.new.black,
    width: INPUT_PARAMETER_BLOCK_WIDTHS - 40,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
    overflow: 'hidden',
  },
  parameterFooter: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: theme.spacing(2),
  },
  activeParameterFooter: {
    [`& .${classes.footerUnits}`]: {
      color: theme.palette.new.violet_a,
    },
  },
  outputParameterFooter: {
    [`& .${classes.footerUnits}`]: {
      color: theme.palette.new.white,
    },
  },
  footerUnits: {
    display: 'block',
    alignItems: 'center',
    letterSpacing: '-0.2px',
    color: theme.palette.new.grey_c,
    width: '130px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  footerFilterItemsCount: {
    display: 'block',
    alignItems: 'flex-end',
    fontWeight: 500,
    letterSpacing: '-0.2px',
    color: theme.palette.new.versatile_violet,
    maxWidth: theme.spacing(6),
    whiteSpace: 'nowrap',
    marginRight: theme.spacing(1),
  },
  footerFilterAllItems: {
    color: theme.palette.new.grey_c,
  },
  footerIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  activeCursor: {
    cursor: 'pointer',
  },
  nonFlowUsage: {
    [`&.${classes.outputParameterItem}`]: {
      background: PARETOS_GRADIENT,
    },
  },
  footerUnitsInactive: {
    color: 'rgba(0, 0, 0, 0.4)',
  },
  isInactive: {
    background: 'rgba(0, 0, 0, 0.03)',
    border: '1px solid rgba(0, 0, 0, 0.2)',

    [`& .${classes.name}`]: {
      ...FLOW_INACTIVE_ELEMENTS_TRANSITIONS,
      opacity: 0.4,
    },
    [`& .${classes.footerIcon}`]: {
      ...FLOW_INACTIVE_ELEMENTS_TRANSITIONS,
      opacity: 0.4,
    },
    [`& .${classes.footerUnitsInactive}`]: {
      ...FLOW_INACTIVE_ELEMENTS_TRANSITIONS,
      color: 'rgba(0, 0, 0, 0.4) !important',
    },
    '&:hover': {
      [`& .${classes.name}`]: {
        opacity: 1,
      },
      [`& .${classes.footerIcon}`]: {
        opacity: 1,
      },
      [`& .${classes.footerUnitsInactive}`]: {
        color: theme.palette.new.grey_c,
      },
    },
  },
  isTargetInactive: {
    background: 'rgba(255, 255, 255, 0.2)',

    [`& .${classes.targetName}`]: {
      ...FLOW_INACTIVE_ELEMENTS_TRANSITIONS,
      opacity: 0.4,
    },
    [`& .${classes.footerIcon}`]: {
      ...FLOW_INACTIVE_ELEMENTS_TRANSITIONS,
      opacity: 0.4,
    },
    [`& .${classes.footerUnitsInactive}`]: {
      ...FLOW_INACTIVE_ELEMENTS_TRANSITIONS,
      color: 'rgba(255, 255, 255, 0.4) !important',
    },
    '&:hover': {
      [`& .${classes.targetName}`]: {
        opacity: 1,
      },
      [`& .${classes.footerIcon}`]: {
        opacity: 1,
      },
      [`& .${classes.footerUnitsInactive}`]: {
        color: `${theme.palette.new.white} !important`,
      },
    },
  },
  isActiveInputInactive: {
    '&:hover': {
      [`& .${classes.footerUnitsInactive}`]: {
        color: `${theme.palette.new.violet_a} !important`,
      },
    },
  },
}))

export default useStyles
