import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

type allowedClassnames = 'selectorItemText'

const useStyles = makeStyles<void, allowedClassnames>()((theme: Theme, props, classnames) => ({
  selectorContainerRoot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    borderRadius: theme.spacing(4),
    marginTop: theme.spacing(0.5),
    width: '100%',
  },
  selectorItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: theme.spacing(4),
    padding: theme.spacing(1, 2),
    cursor: 'pointer',
    '&:hover': {
      [`& .${classnames.selectorItemText}`]: {
        color: 'rgba(255, 255, 255, 0.4)',
      },
    },
  },
  selectorItemSelected: {
    backgroundColor: theme.palette.new.white,
    borderRadius: theme.spacing(5),
    '& svg': {
      color: theme.palette.new.black,
    },
    [`& .${classnames.selectorItemText}`]: {
      color: theme.palette.new.black,
    },
    '&:hover': {
      opacity: 0.6,
      [`& .${classnames.selectorItemText}`]: {
        color: theme.palette.new.black,
      },
    },
  },
  selectorItemText: {
    display: 'flex',
    alignItems: 'center',
    color: 'rgba(255, 255, 255, 0.6)',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '20px',
    '& svg': {
      marginRight: theme.spacing(1),
    },
    '@media (max-width: 1680px) and (min-width:1480px)': {
      fontSize: '16px',
      lineHeight: '18px',
    },
    '@media (max-width: 1480px) and (min-width:1310px)': {
      fontSize: '14px',
      lineHeight: '16px',
    },
    '@media (max-width: 1310px) and (min-width:1200px)': {
      fontSize: '14px',
      lineHeight: '16px',
    },
    '@media (max-width: 780px)': {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
}))

export default useStyles
