import { HERA } from '@constants/api-provider.constants'
import { checkApiForError } from '@utils/redux.utils'
import apiProvider from '@redux/api-provider'
import {
  ReqestConstraintsAnalyzeArtifactActionPayload,
  RequestTopArticlesArtifactActionPayload,
  RequestVisualisationsArtifactActionPayload,
  ReqestKPIItemsArtifactActionPayload,
  ReqestArticlesOverviewDetailsArtifactActionPayload,
  ReqestArticlesOverviewTableArtifactActionPayload,
  ReqestKPIsPerItemArtifactActionPayload,
} from './recommendation-artifacts.types'

export const fetchConstraintsAnalyzeArtifact = ({
  useCaseId,
}: ReqestConstraintsAnalyzeArtifactActionPayload) => {
  return apiProvider
    .getApi(HERA)
    .post('v1/analyze-overview-v1/get', { useCaseId })
    .then(({ data }) => {
      checkApiForError(data)

      const analyzeOverviewValues = data.data.analyzeOverviewValues || {}

      return analyzeOverviewValues as RecommendationArtifacts.ConstraintsAnalyzeArtifact
    })
}

export const fetchTopArticlesArtifact = ({
  useCaseId,
}: RequestTopArticlesArtifactActionPayload) => {
  return apiProvider
    .getApi(HERA)
    .post('v1/top-articles-v1/get', { useCaseId })
    .then(({ data }) => {
      checkApiForError(data)

      const topArticleValues = data.data.topArticleValues || {}

      return topArticleValues as RecommendationArtifacts.TopArticlesArtifact
    })
}

export const fetchVisualisationsArtifact = ({
  useCaseId,
}: RequestVisualisationsArtifactActionPayload) => {
  return apiProvider
    .getApi(HERA)
    .post('v1/visualisation-v1/get', { useCaseId })
    .then(({ data }) => {
      checkApiForError(data)

      const visualisationValues = data.data.visualisationValues || {}

      return visualisationValues as RecommendationArtifacts.VisualisationsArtifact
    })
}

export const fetchKPIItemsArtifact = ({
  useCaseId,
}: ReqestKPIItemsArtifactActionPayload) => {
  return apiProvider
    .getApi(HERA)
    .post('v1/kpi-items-v1/get', { useCaseId })
    .then(({ data }) => {
      checkApiForError(data)

      const kpiItemValues = data.data.kpiItemValues || {}

      return kpiItemValues as RecommendationArtifacts.KPIItemsArtifact
    })
}

export const fetchKPIsPerItemArtifact = ({
  useCaseId,
}: ReqestKPIsPerItemArtifactActionPayload) => {
  return apiProvider
    .getApi(HERA)
    .post('v1/kpis-per-item-v1/get', { useCaseId })
    .then(({ data }) => {
      checkApiForError(data)

      const kpisPerItemValues = data.data.kpisPerItemValues || {}

      return kpisPerItemValues as RecommendationArtifacts.KPIsPerItemArtifact
    })
}

export const fetchArticlesOverviewTableArtifact = ({
  useCaseId,
}: ReqestArticlesOverviewTableArtifactActionPayload) => {
  return apiProvider
    .getApi(HERA)
    .post('v1/article-overview-table-v1/get', { useCaseId })
    .then(({ data }) => {
      checkApiForError(data)

      const articleOverviewValues = data.data.tableValues || {}

      return articleOverviewValues as RecommendationArtifacts.ArticlesOverviewTableArtifact
    })
}

export const fetchArticlesOverviewDetailsArtifact = ({
  useCaseId,
  itemId,
}: ReqestArticlesOverviewDetailsArtifactActionPayload) => {
  return apiProvider
    .getApi(HERA)
    .post('v1/article-overview-details-v1/get', { useCaseId, detailKey: itemId })
    .then(({ data }) => {
      checkApiForError(data)

      const articleOverviewValues = data.data.detailValue || {}

      return articleOverviewValues as RecommendationArtifacts.ArticlesOverviewDetailsArtifact
    })
}
