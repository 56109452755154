import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  value: {
    fontWeight: 400,
    fontSize: '48px',
    lineHeight: '40px',
    color: theme.palette.new.white,
    marginRight: theme.spacing(2),
    minWidth: '175px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('lg')]: {
      minWidth: '145px',
      fontSize: '36px',
      lineHeight: 1,
    },
  },
}))

export default useStyles
