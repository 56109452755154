import {
  Theme, createTheme,
  Palette, ThemeOptions,
} from '@mui/material/styles'

import type {} from '@mui/x-data-grid-premium/themeAugmentation'
import type {} from '@mui/x-date-pickers-pro/themeAugmentation'

import typography from './theme/theme.typography'
import palette, { NewPalette, PaletteOptionsExtended } from './theme/theme.palette'
import defaultDataGridConfig from './theme/data-grid.config'
import defaultDatePickersConfigs from './theme/date-pickers.config'

export interface ThemeOptionsExtended extends ThemeOptions {
  palette: PaletteOptionsExtended
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    new: NewPalette,
    colorWay: {
      [key: string]: string,
    },
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    new: NewPalette,
  }

  interface PaletteOptions {
    new: NewPalette,
  }
}

declare module '@mui/material/styles' {
  export interface Theme {
    palette: Palette
  }
}

const themeOptions: ThemeOptionsExtended = {
  spacing: 10,
  typography,
  palette,
  components: {
    ...defaultDatePickersConfigs,
    MuiDataGrid: defaultDataGridConfig,
    MuiBackdrop: {
      styleOverrides: {
        root: ({ theme }) => ({
          zIndex: theme.zIndex.drawer + 1,
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
        }),
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.MuiAccordion-root': {
            '&:before': {
              display: 'none',
            },
          },
          '&.MuiAccordion-root.Mui-expanded': {
            margin: 0,
            '& .MuiAccordionSummary': {
              borderBottom: `1px solid ${theme.palette.new.grey_a} !important`,
              borderBottomLeftRadius: '0px',
              borderBottomRightRadius: '0px',
            },
            '&:hover': {
              '& .MuiAccordionSummary': {
                borderBottom: `1px solid ${theme.palette.new.grey_c} !important`,
              },
            },
            '&:before': {
              opacity: 1,
            },
          },
          '&.MuiAccordion-rounded': {
            borderTop: `1px solid ${theme.palette.new.grey_a}`,
            borderLeft: `1px solid ${theme.palette.new.grey_a}`,
            borderRight: `1px solid ${theme.palette.new.grey_a}`,
            '&:first-of-type': {
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
            },
            '&:last-child': {
              borderBottom: `1px solid ${theme.palette.new.grey_a}`,
              borderBottomLeftRadius: '10px',
              borderBottomRightRadius: '10px',
            },
            '&:hover': {
              backgroundColor: palette.new.grey,
              borderTop: `1px solid ${theme.palette.new.grey_c}`,
              borderLeft: `1px solid ${theme.palette.new.grey_c}`,
              borderRight: `1px solid ${theme.palette.new.grey_c}`,
              '&:last-child': {
                borderBottom: `1px solid ${theme.palette.new.grey_c}`,
              },
              '& + .MuiAccordion-rounded': {
                borderTop: `1px solid ${theme.palette.new.grey_c}`,
              },
            },
          },
        }),
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          height: '60px',
          minHeight: '60px',
          '& .MuiAccordionSummary-content': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            transform: 'rotate(-90deg)',
          },
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(0deg)',
          },
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
}

const theme: Theme = createTheme(themeOptions)

export default theme
