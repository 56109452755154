export enum AGGREGATION_LEVELS {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  YEARLY = 'YEARLY',
}

export enum TIME_RESOLUTION {
  DAILY = '1D',
  WEEKLY = '1W',
  MONTHLY = '1M',
}

export enum DATE_PICKER_SHORTCUT {
  LAST_WEEK = 'LAST_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  LAST_MONTH = 'LAST_MONTH',
  THIS_YEAR = 'THIS_YEAR',
  LAST_YEAR = 'LAST_YEAR',
  SINCE_INCEPTION = 'SINCE_INCEPTION',
  FROM_TODAY = 'FROM_TODAY',
  CUSTOM = 'CUSTOM',
}

export enum AGGREGATION_TYPES {
  SUM = 'SUM',
  AVERAGE = 'AVERAGE',
}

export enum DEMAND_AGGREGATION_TYPES {
  SUM = 'SUM',
  AVERAGE = 'AVERAGE',
}

export const AGGREGATION_LEVEL_OPTIONS = [{
  labelKey: 'common.aggregation.daily',
  value: AGGREGATION_LEVELS.DAILY,
}, {
  labelKey: 'common.aggregation.weekly',
  value: AGGREGATION_LEVELS.WEEKLY,
}, {
  labelKey: 'common.aggregation.monthly',
  value: AGGREGATION_LEVELS.MONTHLY,
}, {
  labelKey: 'common.aggregation.quarterly',
  value: AGGREGATION_LEVELS.QUARTERLY,
}, {
  labelKey: 'common.aggregation.yearly',
  value: AGGREGATION_LEVELS.YEARLY,
}]

export const TIME_RESOLUTION_OPTIONS = [{
  labelKey: 'common.time_resolution.daily',
  value: TIME_RESOLUTION.DAILY,
}, {
  labelKey: 'common.time_resolution.weekly',
  value: TIME_RESOLUTION.WEEKLY,
}, {
  labelKey: 'common.time_resolution.mothly',
  value: TIME_RESOLUTION.MONTHLY,
}]

export const AGGREGATION_TYPES_OPTIONS = [{
  labelKey: 'common.aggregation.sum',
  value: AGGREGATION_TYPES.SUM,
}, {
  labelKey: 'common.aggregation.average',
  value: AGGREGATION_TYPES.AVERAGE,
}]

export const DEMAND_AGGREGATION_TYPES_OPTIONS = [{
  labelKey: 'common.aggregation.sum',
  value: DEMAND_AGGREGATION_TYPES.SUM,
}, {
  labelKey: 'common.aggregation.average',
  value: DEMAND_AGGREGATION_TYPES.AVERAGE,
}]

export const DEFAULT_AGGREGATION_TYPE = AGGREGATION_TYPES.SUM

export const DEFAULT_AGGREGATION_LEVEL = AGGREGATION_LEVELS.MONTHLY

export const DEFAULT_DEMAND_AGGREGATION_TYPE = DEMAND_AGGREGATION_TYPES.SUM

export const DEFAULT_TIME_RESOLUTION_LEVEL = TIME_RESOLUTION.DAILY

export const DEFAULT_PREDICTION_HORIZON = 7

export const DEFAULT_PREDICTION_EVALUATION_HORIZON = 7

export const DEAFAULT_SHORTCUT = DATE_PICKER_SHORTCUT.SINCE_INCEPTION
