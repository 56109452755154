import React, { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useRouteMatch } from 'react-router-dom'
import { TRACKING_ACTIONS, trackEvent } from '@utils/tracking.utils'

import {
  Box, ClickAwayListener,
  Grow, ListItemText, Popper,
  useTheme,
} from '@mui/material'

import DropdownButtonComponent, { DropdownButtonProps } from '@base/dropdowns/DropdownButton/DropdownButton.component'
import SelectMenuItemComponent from '@base/menus/SelectMenuItem'
import SelectMenuListComponent from '@base/menus/SelectMenuList'

import { getMenuStyles } from '@utils/ui.utils'

export interface DropdownMenuOption {
  label: string
  value: string | number
}

export interface DropdownMenuComponentProps {
  /**
   * Overline text
   */
  overline?: string
  /**
   * Data test id
   */
  dataTestId?: string
  /**
   * Name of the dropdown
   */
  name: string
  /**
   * If 'true', the dropdown will be in loading state
   */
  loading?: boolean
  /**
   * If 'true', the dropdown will be disabled
   */
  disabled?: boolean
  /**
   * If 'true', the dropdown will not allow to unselect the selected option
   */
  forbidUnselected?: boolean
  /**
   * Max height of the dropdown in pixels
   */
  maxHeight?: string
  /**
   * If 'true', the dropdown will allow multiple selection
   */
  multiple?: boolean
  /**
   * Dropdown options
   */
  options?: DropdownMenuOption[]
  /**
   * Selected value
   */
  value?: DropdownMenuOption['value'] | DropdownMenuOption['value'][]
  /**
   * Change handler
   */
  onChange: (value: DropdownMenuOption['value'] | DropdownMenuOption['value'][]) => void
  /**
   * Custom button props
   */
  buttonProps?: Partial<DropdownButtonProps>
}

export const DropdownMenuComponent: React.FC<DropdownMenuComponentProps> = ({
  dataTestId,
  name,
  value,
  disabled,
  forbidUnselected,
  multiple,
  overline,
  loading,
  maxHeight,
  onChange,
  options = [],
  buttonProps,
}) => {
  const intl = useIntl()
  const theme = useTheme()
  const { params } = useRouteMatch<Common.RouterMatch>()

  const anchorRef = React.useRef<HTMLButtonElement>(null)
  const [openMenu, setOpenMenu] = useState(false)

  const handleOpen = () => {
    setOpenMenu(true)
  }

  const handleClose = () => {
    setOpenMenu(false)
  }

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault()

      handleClose()
    } else if (event.key === 'Escape') {
      handleClose()
    }
  }

  const handleOptionClick = (option: DropdownMenuOption) => {
    if (multiple && Array.isArray(value)) {
      const isChecked = value.includes(option.value)
      const newValue = isChecked ? value.filter((item) => item !== option.value) : [...value, option.value]

      onChange(newValue)
    } else {
      onChange(option.value)
    }

    trackEvent({
      componentName: name,
      actionName: TRACKING_ACTIONS.CHANGE,
    }, {
      option,
      router: params,
    })

    handleClose()
  }

  const label = useMemo(() => {
    if (value === undefined || value === null) {
      return intl.formatMessage({ id: 'common.dropdown.loading' })
    }

    if (!options || options.length === 0) {
      return intl.formatMessage({ id: 'common.dropdown.noOptions' })
    }

    if (multiple) {
      if (!Array.isArray(value) || !value || value.length === 0) {
        return intl.formatMessage({ id: 'common.dropdown.select' })
      }

      return intl.formatMessage({ id: 'common.dropdown.nSelected' }, {
        n: value.length,
      })
    }

    const selectedOption = options.find((option) => option.value === value)

    return selectedOption?.label || intl.formatMessage({ id: 'common.dropdown.select' })
  }, [intl, multiple, options, value])

  return (
    <Box
      data-testid={dataTestId || DropdownMenuComponent.name}
    >
      <DropdownButtonComponent
        label={label}
        dataTestId={dataTestId}
        onClick={handleOpen}
        name={name}
        open={openMenu}
        bold={true}
        outlined={true}
        disabled={disabled}
        overline={overline}
        handleRef={anchorRef}
        {...buttonProps}
      />

      <Popper
        open={openMenu}
        anchorEl={anchorRef.current}
        role={undefined}
        placement='bottom-start'
        transition={true}
        sx={{
          ...getMenuStyles(theme, '2px'),
          zIndex: 1000,
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Box
              className='MuiPaper-root'
              sx={{
                minWidth: anchorRef?.current ? anchorRef.current?.clientWidth : undefined,
                backgroundColor: theme.palette.new.white,
                zIndex: 5,
                ...(maxHeight ? {
                  maxHeight,
                  overflow: 'auto',
                } : {}),
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <SelectMenuListComponent
                  autoFocusItem={openMenu}
                  id='dropopdown-menu'
                  aria-labelledby={name}
                  onKeyDown={handleListKeyDown}
                >
                  {
                    options?.map((option) => {
                      const isChecked = Array.isArray(value) ? value.includes(option.value) : value === option.value
                      const isDisabled = forbidUnselected && Array.isArray(value) && isChecked && value.length === 1

                      return (
                        <SelectMenuItemComponent
                          key={option.value}
                          onClick={() => handleOptionClick(option)}
                          disabled={isDisabled}
                          selected={isChecked && !multiple}
                        >
                          <ListItemText primary={option.label} />
                        </SelectMenuItemComponent>
                      )
                    })
                  }
                </SelectMenuListComponent>
              </ClickAwayListener>
            </Box>
          </Grow>
        )}
      </Popper>
    </Box>
  )
}

export default DropdownMenuComponent
