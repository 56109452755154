import React, { Component } from 'react'
import { Dispatch } from 'redux'
import { injectIntl, IntlShape } from 'react-intl'
import { connect } from 'react-redux'
import get from 'lodash.get'
import classNames from 'classnames'
import * as H from 'history'
import { RouteComponentProps, withRouter } from 'react-router-dom'

import {
  Box,
  Card,
  SxProps,
  Table,
  TablePagination,
  Theme,
} from '@mui/material'

import { withStyles } from 'tss-react/mui'
import { State } from '@redux/modules/types'
import TablePaginationActionsComponent from '@components/_tables/table-pagination-actions'
import TableBodyComponent from '@components/_tables/table-body'
import TableHeaderComponent from '@components/_tables/table-header'

import { ASC_SORTING_ORDER, DEFAULT_ROWS_PER_PAGE, DESC_SORTING_ORDER } from '@constants/filters.constants'
import { sortByKey } from '@utils/list.utils'
import { parseIntegerValueFromStr } from '@utils/common.utils'

import { resetPaginationConfigAction, setPaginationConfigAction } from '@redux/modules/common/common.actions'
import { SetPaginationConfigActionPayload } from '@redux/modules/common/common.types'
import { getPaginationConfig } from '@redux/modules/common/common.selectors'

import styles from './Table.styles'

export type SelectedItem = string | number

export type ITableRow = any

export interface ITableColumn {
  label?: string,
  header?: string,
  key: string,
  alignment?: 'left' | 'right',
  sorting?: boolean,
  numeric?: boolean,
  dateTime?: boolean,
  rightBorder?: boolean,
  plainTitle?: boolean,
  width?: string,
  noDataText?: string,
  tooltip?: string,
  valueFormatter?: {
    (i: number, record: ITableRow, column: ITableColumn, props: any): string | number | React.ReactElement | React.ReactNode | React.ReactNode[] | JSX.Element,
  },
  copy?: {
    (e: React.SyntheticEvent, record: ITableRow): any,
  },
}

export interface TableComponentProps<T> {
  id?: string,
  singleColor?: boolean,
  indexKey?: string,
  defaultSortingKey?: string,
  tableRows?: ITableRow[],
  totalCount?: number,
  tableColumns?: ITableColumn[],
  withPagination?: boolean,
  withSorting?: boolean,
  withQueryParams?: boolean,
  overlayFetching?: boolean,
  searchTerm?: string,
  resetReduxStateOnUmount?: boolean,
  withAligment?: boolean,
  withTitles?: boolean,
  isNoDataDueFilters?: boolean,
  isFetching?: boolean,
  paginationConfig?: Common.PaginationConfig,
  getTableActions?: {
    (row: ITableRow, recordIndex: number): Common.TableAction<ITableRow>[],
  },
  fixedWidth?: boolean,
  classes?: any,
  intl: T,
  className?: string,
  WrapperComponent?: any,
  isWrapeerFragment?: boolean,
  originalItemsCount?: number,
  filteredItemsCount?: number,
  maxRows?: number,
  wrapperComponentProps?: any,
  customNoDataMessage?: React.ReactElement | React.ReactNode | React.ReactNode[],
  maxDefaultCellWidth?: string,
  history: H.History,
  location: H.Location,
  headerBlock?: React.ReactElement | React.ReactNode | React.ReactNode[] | null,
  match: any,
  sx?: {
    root?: SxProps<Theme>,
  },
  fetchData?: {
    (paginationConfig: Common.PaginationConfig): any,
  },
  shouldExpandRowCheck?: {
    (i: number, record: ITableRow): any,
  },
  onRowClick?: {
    (e: React.SyntheticEvent, index: any, row: any): any,
  }
  renderExpandedDetails?: {
    (record: any): React.ReactElement | React.ReactNode | React.ReactNode[] | null,
  },
  handleSelectItems?: {
    (args: any): any,
  },
  setPaginationConfig?: {
    (args: SetPaginationConfigActionPayload): any,
  }
  resetPaginationConfig?: {
    (): any,
  }
}

type PathParamsType = {
  usecase: string,
}

type TableComponentInterface = RouteComponentProps<PathParamsType> & TableComponentProps<IntlShape>

export const DEFAULT_INDEX_KEY = 'id'

export class TableComponent extends Component<TableComponentInterface, Common.PaginationConfig> {
  public static defaultProps: any = {
    resetReduxStateOnUmount: true,
    withPagination: true,
    withAligment: true,
    indexKey: DEFAULT_INDEX_KEY,
    WrapperComponent: Card,
    wrapperComponentProps: {},
    paginationConfig: {},
    maxDefaultCellWidth: '100px',
    tableColumns: [],
    tableRows: [],
    headerBlock: null,
    isFetching: false,
    withQueryParams: true,
    fixedWidth: false,
    isWrapeerFragment: false,
    withTitles: false,
    defaultSortingKey: '',
    withSorting: true,
  }

  constructor(props: TableComponentProps<IntlShape>) {
    super(props)

    this.renderTableHead = this.renderTableHead.bind(this)
    this.renderTableBody = this.renderTableBody.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleLimitChange = this.handleLimitChange.bind(this)
    this.getPaginationFrom = this.getPaginationFrom.bind(this)
    this.getPaginationTo = this.getPaginationTo.bind(this)
    this.getPaginatedTableRows = this.getPaginatedTableRows.bind(this)
    this.getColSpan = this.getColSpan.bind(this)
    this.getPaginationConfig = this.getPaginationConfig.bind(this)
    this.setPaginationConfig = this.setPaginationConfig.bind(this)
  }

  componentDidMount(): void {
    const {
      setPaginationConfig,
      defaultSortingKey,
      withQueryParams,
    } = this.props

    const intialConfig = withQueryParams ? this.getPaginationConfig() : {
      sortingKey: defaultSortingKey,
      initial: true,
    }

    setPaginationConfig!(intialConfig)
  }

  componentDidUpdate(prevProps: TableComponentProps<IntlShape>) {
    const {
      searchTerm, withQueryParams,
      resetPaginationConfig, fetchData,
      location, paginationConfig,
    } = this.props

    const {
      location: prevLocation,
      searchTerm: prevSearchTerm,
      paginationConfig: prevPaginationConfig,
    } = prevProps

    if (location.pathname !== prevLocation.pathname) {
      resetPaginationConfig!()
    }

    if (!fetchData && (searchTerm !== prevSearchTerm)) {
      this.setPaginationConfig({
        page: 1,
      })
    }

    const {
      page: prevPage,
      limit: prevLimit,
      search: prevSearch,
      sortingKey: prevSortingKey,
      sortingDirection: prevSortingDirection,
    } = prevPaginationConfig!

    const {
      page,
      limit,
      search,
      sortingKey,
      sortingDirection,
    } = paginationConfig!

    const hasConfigChanged = (
      prevPage !== page ||
      prevLimit !== limit ||
      prevSearch !== search ||
      prevSortingKey !== sortingKey ||
      prevSortingDirection !== sortingDirection
    )

    if (withQueryParams && hasConfigChanged) {
      const nextPaginationConfig = this.reflectPaginationParamsChange()

      if (fetchData) {
        const finalConfig = {
          ...nextPaginationConfig,
        }

        fetchData(finalConfig)
      }
    }
  }

  componentWillUnmount(): void {
    const {
      resetPaginationConfig,
      resetReduxStateOnUmount,
    } = this.props

    if (resetReduxStateOnUmount) {
      resetPaginationConfig!()
    }
  }

  reflectPaginationParamsChange = () => {
    const {
      location, history,
      paginationConfig,
    } = this.props

    const {
      sortingKey,
      sortingDirection,
      search,
      page,
      limit,
    } = paginationConfig!

    const newSearchParams = new URLSearchParams(location.search)
    const nextPaginationConfig = {
      sortingKey,
      sortingDirection,
      search,
      page,
      limit,
    }

    newSearchParams.set('sortingKey', sortingKey)
    newSearchParams.set('sortingDirection', sortingDirection)
    newSearchParams.set('search', encodeURIComponent(search))
    newSearchParams.set('page', page.toString())
    newSearchParams.set('limit', limit.toString())

    history.replace({
      search: newSearchParams.toString(),
    })

    return nextPaginationConfig
  }

  getPaginationParamsFromUrl = (currentState: Common.PaginationConfig, defaultSortingKey?: string) => {
    const {
      location,
    } = this.props

    const searchParams = new URLSearchParams(location.search)

    return {
      sortingKey: searchParams.get('sortingKey') || currentState.sortingKey || defaultSortingKey || '',
      sortingDirection: (searchParams.get('sortingDirection') || currentState.sortingDirection || DESC_SORTING_ORDER) as ListUtils.Direction,
      search: decodeURIComponent(searchParams.get('search') || currentState.search || ''),
      page: searchParams.get('page') ? parseIntegerValueFromStr(searchParams.get('page')) : currentState.page,
      limit: searchParams.get('limit') ? parseIntegerValueFromStr(searchParams.get('limit')) : currentState.limit,
    }
  }

  getPaginationConfig = () => {
    const { defaultSortingKey, paginationConfig } = this.props

    return this.getPaginationParamsFromUrl(paginationConfig!, defaultSortingKey)
  }

  setPaginationConfig = (payload: SetPaginationConfigActionPayload) => {
    const { setPaginationConfig } = this.props

    setPaginationConfig!(payload as Common.PaginationConfig)
  }

  getPaginationFrom = () => {
    const { page, limit } = this.getPaginationConfig()

    return (page - 1) * limit
  }

  getPaginationTo = () => {
    const { page, limit } = this.getPaginationConfig()

    return ((page - 1) * limit) + limit
  }

  getPaginatedTableRows = () => {
    const {
      tableRows = [], tableColumns,
      withPagination, withSorting,
      maxRows, fetchData,
    } = this.props

    const {
      sortingKey,
      sortingDirection,
    } = this.getPaginationConfig()

    if (fetchData) {
      return tableRows
    }

    const column = (withSorting && tableColumns ? (tableColumns.find((col) => col.key === sortingKey) || {}) : {}) as ITableColumn

    const sortedRows = withSorting ? sortByKey(tableRows, sortingKey!, sortingDirection, {
      numeric: column.numeric!,
      date: column.dateTime!,
    }) : tableRows

    const rows = withPagination ? sortedRows.slice(this.getPaginationFrom(), this.getPaginationTo()) : sortedRows

    return maxRows ? rows.slice(0, maxRows) : rows
  }

  getColSpan = () => {
    const { tableColumns = [] } = this.props

    return tableColumns.length
  }

  getTableCellClassName = (isHeader = false) => {
    const { fixedWidth, classes = {} } = this.props

    return classNames({
      [classes.fixedCell]: fixedWidth,
      [classes.tableHead]: isHeader,
    })
  }

  handlePageChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    this.setPaginationConfig({
      page: newPage + 1,
    })
  }

  handleLimitChange = (event: React.ChangeEvent) => {
    const newLimit: number = get(event, 'target.value', 0)

    this.setPaginationConfig({
      limit: newLimit,
      page: 1,
    })
  }

  handleSorting = (key: string) => {
    const { sortingDirection, sortingKey } = this.getPaginationConfig()

    if (sortingKey === key) {
      this.setPaginationConfig({
        sortingDirection: sortingDirection === DESC_SORTING_ORDER ? ASC_SORTING_ORDER : DESC_SORTING_ORDER,
      })
    } else {
      this.setPaginationConfig({
        sortingKey: key,
      })
    }
  }

  renderTableBody = () => {
    const {
      tableRows,
      tableColumns, indexKey = DEFAULT_INDEX_KEY,
      isFetching, singleColor,
      withTitles, searchTerm,
      withAligment, classes = {},
      onRowClick, withPagination,
      customNoDataMessage, shouldExpandRowCheck,
      renderExpandedDetails,
      overlayFetching,
    } = this.props

    const colSpan = this.getColSpan()
    const paginatedTableRows = this.getPaginatedTableRows()
    const tableCellClassName = this.getTableCellClassName()

    return (
      <TableBodyComponent
        colSpan={colSpan}
        isFetching={isFetching}
        withTitles={withTitles}
        withAligment={withAligment}
        singleColor={singleColor}
        withPagination={withPagination}
        tableRows={tableRows}
        paginatedTableRows={paginatedTableRows}
        tableColumns={tableColumns}
        classes={classes}
        indexKey={indexKey}
        searchTerm={searchTerm}
        tableCellClassName={tableCellClassName}
        customNoDataMessage={customNoDataMessage}
        shouldExpandRowCheck={shouldExpandRowCheck}
        renderExpandedDetails={renderExpandedDetails}
        onRowClick={onRowClick}
        overlayFetching={overlayFetching}
      />
    )
  }

  renderTableHead = () => {
    const {
      tableColumns,
      fixedWidth,
      withTitles,
      withAligment,
      isFetching,
      classes = {},
      overlayFetching,
    } = this.props

    const tableCellClassName = this.getTableCellClassName(true)
    const paginationConfig = this.getPaginationConfig()

    return (
      <TableHeaderComponent
        handleSorting={this.handleSorting}
        paginationConfig={paginationConfig}
        tableColumns={tableColumns}
        tableCellClassName={tableCellClassName}
        withTitles={withTitles}
        withAligment={withAligment}
        fixedWidth={fixedWidth}
        classes={classes}
        isFetching={isFetching}
        overlayFetching={overlayFetching}
      />
    )
  }

  render() {
    const {
      id,
      sx,
      tableRows = [],
      withPagination,
      totalCount,
      intl,
      classes = {},
      className,
      WrapperComponent,
      isWrapeerFragment,
      headerBlock = null,
      isFetching,
      overlayFetching,
      wrapperComponentProps,
      fetchData,
    } = this.props

    const {
      page,
      limit,
    } = this.getPaginationConfig()

    const wrapperProps = isWrapeerFragment ? {} : {
      id,
      className: classes.wrapper,
      elevation: 0,
      ...wrapperComponentProps,
    }

    const isOverlayFetching = isFetching && overlayFetching
    const totalCountOfRows = (fetchData ? totalCount : tableRows.length) || 0

    return (
      <Box
        sx={sx?.root}
        className={classNames(classes.rootWrapper, {
          [classes.overlayFetching]: isOverlayFetching,
        })}
      >
        <WrapperComponent
          {...wrapperProps}
        >
          {headerBlock}
          <Box className={classes.rootTableWrapper}>
            <Table
              className={classNames(className, classes.root, {
                [classes.withHeaderBlock]: Boolean(headerBlock),
              })}
              data-testid={TableComponent.name}
            >
              {this.renderTableHead()}
              {this.renderTableBody()}
            </Table>
          </Box>
        </WrapperComponent>
        {
            withPagination ? (
              <TablePagination
                component='div'
                className={classes.paginationRoot}
                count={totalCountOfRows}
                onPageChange={this.handlePageChange}
                onRowsPerPageChange={this.handleLimitChange}
                page={totalCountOfRows === 0 ? 0 : (page - 1)}
                rowsPerPage={limit}
                rowsPerPageOptions={DEFAULT_ROWS_PER_PAGE}
                ActionsComponent={TablePaginationActionsComponent}
                labelRowsPerPage={intl.formatMessage({ id: 'common.tables.pagination.rowsPerPage' })}
                labelDisplayedRows={({ from, to, count }) => {
                  return intl.formatMessage({ id: 'common.tables.pagination.displayed' }, {
                    from,
                    to,
                    count,
                  })
                }}
                SelectProps={{
                  disabled: isFetching,
                }}
                backIconButtonProps={
                  isFetching ? {
                    disabled: isFetching,
                  } : undefined
                }
                nextIconButtonProps={
                  isFetching ? {
                    disabled: isFetching,
                  } : undefined
                }
              />
            ) : (
              null
            )
          }
      </Box>
    )
  }
}

export const mapStateToProps = (state: State) => {
  return {
    paginationConfig: getPaginationConfig(state),
  }
}

export const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    dispatch,
    setPaginationConfig: (payload: SetPaginationConfigActionPayload) => dispatch(setPaginationConfigAction(payload)),
    resetPaginationConfig: () => dispatch(resetPaginationConfigAction()),
  }
}

export const TableComponentWithStyles = withStyles(TableComponent, styles)

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(injectIntl(TableComponentWithStyles)))
