import { handleActions } from 'redux-actions'
import { CommonState } from '../types'

import {
  INCREMENT_UNRESOLVED_REQUESTS_DONE,
  DECREMENT_UNRESOLVED_REQUESTS_DONE,
  RESET_PAGINATION_CONFIG_DONE,
  SET_PAGINATION_CONFIG_DONE,
  CHANGE_SIDEBAR_STATE,
  CHANGE_CHATBOT_STATE,
  CHANGE_TOAST_DONE,
  START_FETCHING_COMMON,
  STOP_FETCHING_COMMON,
} from './common.action-types'

import {
  initialState,
  incrementUnresolvedRequestsReducer,
  decrementUnresolvedRequestsReducer,
  changeSidebarStateReducer,
  changeChatbotStateReducer,
  changeToastReducer,
  changePaginationConfigReducer,
  resetPaginationConfigReducer,
  startFetching,
  stopFetching,
} from './common.reducers'

export default handleActions<CommonState, any>({
  [INCREMENT_UNRESOLVED_REQUESTS_DONE]: incrementUnresolvedRequestsReducer,
  [DECREMENT_UNRESOLVED_REQUESTS_DONE]: decrementUnresolvedRequestsReducer,
  [CHANGE_SIDEBAR_STATE]: changeSidebarStateReducer,
  [CHANGE_CHATBOT_STATE]: changeChatbotStateReducer,
  [CHANGE_TOAST_DONE]: changeToastReducer,
  [RESET_PAGINATION_CONFIG_DONE]: resetPaginationConfigReducer,
  [SET_PAGINATION_CONFIG_DONE]: changePaginationConfigReducer,
  [START_FETCHING_COMMON]: startFetching,
  [STOP_FETCHING_COMMON]: stopFetching,
}, initialState)
