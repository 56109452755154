import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  noOptionsOption: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: theme.spacing(20),
    padding: theme.spacing(0, 3),
    width: '100%',
    height: '100%',
    borderTop: `1px solid ${theme.palette.new.grey_a}`,
  },
  noOptionsOptionSourceTypes: {
    borderTop: 'none',
  },
}))

export default useStyles
