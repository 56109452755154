import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    width: '100%',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.new.grey_b}`,
    height: '480px',
    minWidth: '500px',
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(0),
      marginBottom: theme.spacing(3),
    },
  },
  chartContainer: {
    height: '390px',
    width: '99%',
  },
}))

export default useStyles
