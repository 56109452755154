import React, { useMemo } from 'react'
import { IntlShape } from 'react-intl'
import get from 'lodash.get'
import classNames from 'classnames'

import {
  TableBody,
  TableRow,
  TableCell,
  Collapse,
  Box,
} from '@mui/material'

import palette from '@configuration/theme/theme.palette'
import { createId } from '@utils/common.utils'

import NoTableDataComponent from '@components/_tables/no-table-data'
import TableLoadingComponent from '@components/_tables/table-loading'
import CopyIcon from '@icons/copy.icon'
import { TableComponentProps } from '@components/_tables/table/Table.component'

type ITableRow = any
type ITableBodyPropsFields = 'isFetching' | 'withTitles' | 'withAligment' | 'singleColor' |
                             'withPagination' | 'tableRows' | 'tableColumns' | 'classes' |
                             'searchTerm' | 'customNoDataMessage' | 'overlayFetching' |
                             'shouldExpandRowCheck' | 'renderExpandedDetails' | 'onRowClick'

interface ITableBodyProps extends Pick<TableComponentProps<IntlShape>, ITableBodyPropsFields> {
  colSpan: number
  indexKey: string,
  tableCellClassName?: string,
  paginatedTableRows: ITableRow[]
}

export const TableBodyComponent: React.FC<ITableBodyProps> = (props) => {
  const {
    colSpan,
    isFetching,
    singleColor,
    withAligment,
    withPagination,
    withTitles,
    tableRows,
    indexKey,
    classes: classesProp,
    searchTerm,
    tableColumns,
    tableCellClassName,
    customNoDataMessage,
    paginatedTableRows,
    shouldExpandRowCheck,
    renderExpandedDetails,
    onRowClick,
    overlayFetching,
  } = props

  const classes = useMemo(() => ({
    ...classesProp,
  }), [classesProp])

  const getExpandedRow = (recordIndex: number, record: ITableRow) => {
    const isExpanded = shouldExpandRowCheck!(recordIndex, record)
    const borders = isExpanded ? {} : { borderBottom: 'none' }

    return (
      <TableRow
        key={`row-to-expand-${recordIndex}`}
        data-hidden-row={true}
        className={classes?.row}
      >
        <TableCell
          style={{
            padding: 0,
            height: 'auto',
            ...borders,
          }}
          colSpan={colSpan}
        >
          <Collapse in={isExpanded} timeout='auto' unmountOnExit={true}>
            {renderExpandedDetails!(record)}
          </Collapse>
        </TableCell>
      </TableRow>
    )
  }

  if (isFetching && !overlayFetching) {
    return (
      <TableLoadingComponent
        colSpan={colSpan}
      />
    )
  }

  if (!(tableRows && tableRows.length)) {
    return (
      <NoTableDataComponent
        colSpan={colSpan}
        search={searchTerm}
        customNoDataMessage={customNoDataMessage}
      />
    )
  }

  return (
    <TableBody className={classes.body} data-testid={TableBodyComponent.name}>
      {
        paginatedTableRows.map((record, recordIndex) => {
          const isExpanded = shouldExpandRowCheck ? shouldExpandRowCheck(recordIndex, record) : false
          const expandedContent = renderExpandedDetails ? getExpandedRow(recordIndex, record) : null

          return (
            <React.Fragment key={createId(record[indexKey], recordIndex, 'fragment')}>
              <TableRow
                hover={true}
                key={createId(record[indexKey], recordIndex)}
                onClick={onRowClick ? (e) => onRowClick(e, recordIndex, record) : undefined}
                className={classNames(classes.row, {
                  [classes.onRowClick]: Boolean(onRowClick),
                  [classes.withoutPagination]: !withPagination,
                  [classes.expanded]: Boolean(isExpanded),
                  [classes.lastRow]: (recordIndex === (paginatedTableRows.length - 1)),
                })}
                style={{
                  backgroundColor: (recordIndex % 2 === 0) ? `${palette.new.gainful_grey}0D` : palette.new.white,
                }}
              >
                {
                  tableColumns?.map((column, recordColumnIndex) => {
                    const value = get(record, column.key, '')
                    const plainValue = value || column.noDataText || '-'
                    const columnValue = column.valueFormatter ? (
                      column.valueFormatter(recordIndex, record, column, props)
                    ) : (
                      plainValue
                    )

                    const title = (withTitles && typeof columnValue === 'string') ? { title: (column.plainTitle ? plainValue : columnValue) } : {}
                    const width = column.width ? { width: column.width } : {}

                    return (
                      <TableCell
                        key={createId(record[indexKey], recordColumnIndex)}
                        className={classNames(tableCellClassName, {
                          [classes.firstCol]: (recordColumnIndex === 0) || singleColor,
                          [classes.anyCol]: (recordColumnIndex !== 0) && !singleColor,
                          [classes.trLeft]: withAligment && column.alignment === 'left',
                          [classes.trRight]: withAligment && column.alignment === 'right',
                          [classes.rightBorder]: column.rightBorder,
                        })}
                        align={column.alignment || (withAligment && (recordColumnIndex === 0) ? 'left' : 'right')}
                        {...title}
                        {...width}
                      >
                        {
                          column.copy ? (
                            <Box
                              onClick={(e) => column.copy && column.copy(e, record)}
                              className={classNames(classes.copyIcon, {
                                [classes.copyIconLeft]: withAligment && column.alignment === 'left',
                              })}
                            >
                              <CopyIcon />
                            </Box>
                          ) : (
                            columnValue
                          )
                        }
                      </TableCell>
                    )
                  })
                }
              </TableRow>

              {expandedContent}
            </React.Fragment>
          )
        })
      }
    </TableBody>
  )
}

export default TableBodyComponent
