import React, { SyntheticEvent, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { bindActionCreators } from 'redux'
import { Box, Typography } from '@mui/material'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  SidePanelLoadingComponent,
  ModalButtonComponent,
} from '@base/sidepanel/SidePanel'

import EditFlowIcon from '@icons/flow/editFlow.icon'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'

import {
  SOURCE_TYPES_OPTIONS,
  SOURCE_TYPES_TO_ICONS_MAP,
  SourceTypeOption,
  NO_UNIT_LABEL_PLACEHOLDER,
} from '@constants/flow.constants'

import { createId } from '@utils/common.utils'
import HelpButtonComponent from '@base/buttons/HelpButton'
import AddNodeButtonComponent from '@components/connect-view/view/AddNodeButton'

import { requestParametersTemplatesAction } from '@redux/modules/parameters-templates/parameters-templates.actions'
import { getParametersTemplatesList, isFetching } from '@redux/modules/parameters-templates/parameters-templates.selectors'
import { LIST_PREDIFINED_PARAMETER_MODAL_NAME, MANAGE_PREDIFINED_PARAMETER_MODAL_NAME } from '@constants/modals.constants'
import { ManagePredefinedParametersModalDetails } from '@containers/modals/manage-predefined-parameters-modal/ManagePredefinedParametersModal.container'

import useStyles from './ListPredefinedParametersModal.styles'

const ListPredefinedParametersModalContainer: React.FC = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const { classes, cx } = useStyles()

  const requestParametersTemplates = bindActionCreators(requestParametersTemplatesAction, dispatch)
  const isFetchingList = useSelector((state) => isFetching(state))
  const parametersTemplatesList = useSelector((state) => getParametersTemplatesList(state))
  const isAdmin = useSelector((state) => getIsAdmin(state))
  const modalPageName = useSelector((state) => getOpenedModal(state))

  const open = modalPageName === LIST_PREDIFINED_PARAMETER_MODAL_NAME
  const isParametersTemplatesListEmpty = (!parametersTemplatesList || parametersTemplatesList.length === 0)

  const handleModalClose = () => {
    dispatch(
      setPrimaryModalPageName(''),
    )
  }

  const onAddButtonClick = () => {
    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: MANAGE_PREDIFINED_PARAMETER_MODAL_NAME,
        modalDetails: {
          returnTo: LIST_PREDIFINED_PARAMETER_MODAL_NAME,
        } as ManagePredefinedParametersModalDetails,
      }),
    )
  }

  const onEditButtonClick = (e: SyntheticEvent, parameterPresetId: string) => {
    e.preventDefault()
    e.stopPropagation()

    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: MANAGE_PREDIFINED_PARAMETER_MODAL_NAME,
        modalDetails: {
          returnTo: LIST_PREDIFINED_PARAMETER_MODAL_NAME,
          parameterPresetId,
        } as ManagePredefinedParametersModalDetails,
      }),
    )
  }

  useEffect(() => {
    if (open) {
      requestParametersTemplates()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  if (!isAdmin) {
    return null
  }

  return (
    <SidePanelComponent
      open={open}
      title={intl.formatMessage({ id: 'connect.modal.predifined_parameters' })}
      handleClose={handleModalClose}
      headerRightSideBlocks={(
        <HelpButtonComponent
          tooltip={intl.formatMessage({ id: 'connect.modal.predifined_parameters.help' })}
          name='modalHelpButton'
        />
      )}
    >
      <SidePanelLoadingComponent loading={isFetchingList}>
        <SidePanelCardComponent disableHorizontalSpacing={true}>
          <Box className={classes.header}>
            <Typography className={classes.headerText}>
              {intl.formatMessage({ id: 'connect.modal.predifined_parameters.header' })}
            </Typography>

            <AddNodeButtonComponent
              onClick={onAddButtonClick}
            />
          </Box>
          <Box
            className={classes.list}
          >
            <Box
              className={classes.listItem}
            >
              <Typography
                className={cx(classes.listHeaderItem, classes.listHeaderFirstItem)}
                noWrap={true}
              >
                {intl.formatMessage({ id: 'connect.modal.predifined_parameters.table_header.data_source' })}
              </Typography>
              <Typography
                className={classes.listHeaderItem}
                noWrap={true}
                component='div'
              >
                {intl.formatMessage({ id: 'connect.modal.predifined_parameters.table_header.data_column_name' })}
              </Typography>
              <Typography
                className={classes.listHeaderItem}
                noWrap={true}
                component='div'
              >
                {intl.formatMessage({ id: 'connect.modal.predifined_parameters.table_header.name' })}
              </Typography>
              <Typography
                className={cx(classes.listHeaderItem, classes.listHeaderLastItem)}
                noWrap={true}
                component='div'
              >
                {intl.formatMessage({ id: 'connect.modal.predifined_parameters.table_header.unit' })}
              </Typography>
            </Box>

            {
              isParametersTemplatesListEmpty ? (
                <Typography
                  className={classes.emptyList}
                  noWrap={true}
                >
                  {intl.formatMessage({ id: 'connect.modal.predifined_parameters.empty' })}
                </Typography>
              ) : (
                null
              )
            }

            {
              parametersTemplatesList.map((item, index) => {
                const {
                  name, dataColumnName,
                  sourceType, unitLabel,
                  parameterPresetId,
                } = item

                const dataSourceOption = (SOURCE_TYPES_OPTIONS.find((sourceTypeItem) => sourceTypeItem.value === sourceType) || {}) as SourceTypeOption
                const dataSourceLabelKey = dataSourceOption.labelKey
                const IconComponent = SOURCE_TYPES_TO_ICONS_MAP[dataSourceOption.value]
                const dataSourceName = dataSourceLabelKey ? intl.formatMessage({ id: dataSourceLabelKey }) : ''

                return (
                  <Box
                    className={classes.listItem}
                    onClick={(e) => onEditButtonClick(e, parameterPresetId)}
                    key={createId(name, index)}
                  >
                    <Box className={classes.listItemIcon}>
                      <IconComponent />
                    </Box>
                    <Typography
                      className={classes.listItemValue}
                      noWrap={true}
                      title={dataSourceName}
                    >
                      {dataSourceName}
                    </Typography>
                    <Typography
                      className={classes.listItemValue}
                      noWrap={true}
                      title={dataColumnName || ''}
                    >
                      {dataColumnName}
                    </Typography>
                    <Typography
                      className={classes.listItemValue}
                      noWrap={true}
                      title={name}
                    >
                      {name}
                    </Typography>
                    <Typography
                      className={cx(classes.listItemValue, classes.listItemLast, classes.listItemValueUnit)}
                      noWrap={true}
                      component='div'
                    >
                      {unitLabel || NO_UNIT_LABEL_PLACEHOLDER}

                      <Box
                        onClick={(e) => onEditButtonClick(e, parameterPresetId)}
                        className={classes.listItemButton}
                      >
                        <EditFlowIcon />
                      </Box>
                    </Typography>
                  </Box>
                )
              })
            }
          </Box>
        </SidePanelCardComponent>

        <SidePanelCardActionsComponent>
          <ModalButtonComponent
            name='listPredefinedParametersModalCloseButton'
            onClick={handleModalClose}
            type='back'
          />
        </SidePanelCardActionsComponent>
      </SidePanelLoadingComponent>
    </SidePanelComponent>
  )
}

export default ListPredefinedParametersModalContainer
