import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { getTextBackgroundFillStyles } from '@utils/ui.utils'

const useStyles = makeStyles()((theme: Theme) => ({
  deleteButtonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  modalSectionHeaderTitle: {
    fontSize: '30px',
    lineHeight: '35px',
    ...getTextBackgroundFillStyles('linear-gradient(219.13deg, #8F6BDE 0.5%, #F06DAC 97.79%)'),
    marginBottom: theme.spacing(2),
  },
  modalSectionHeaderText: {
    fontSize: '18px',
    lineHeight: '20px',
    color: theme.palette.new.black_b,
  },
  blockContainer: {
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(-4),
    marginRight: theme.spacing(-4),
  },
  tableHeader: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 4),
    borderBottom: `1px solid ${theme.palette.new.grey_a}`,
    paddingBottom: theme.spacing(2),
  },
  tableRow: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: theme.spacing(6),
    padding: theme.spacing(0, 4),
    borderBottom: `1px solid ${theme.palette.new.grey_a}`,
  },
  categoryRow: {
    backgroundColor: theme.palette.new.grey,
  },
  categoryName: {
    fontWeight: 500,
    color: theme.palette.new.black,
    marginTop: theme.spacing(1.5),
  },
  emptyState: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(4),
  },
  emptyStateHeader: {
    color: theme.palette.new.black,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  emptyStateBody: {
    color: theme.palette.new.grey_h,
    textAlign: 'center',
    maxWidth: '600px',
  },
}))

export default useStyles
