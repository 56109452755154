import { styled, MenuItem } from '@mui/material'

export default styled(MenuItem)(({ theme }) => ({
  fontSize: '16px',
  lineHeight: '125%',
  letterSpacing: '0.24px',
  color: theme.palette.new.business_black,
  padding: '8px 12px',
  gap: '10px',

  '&&': {
    '& .MuiTouchRipple-root': {
      display: 'none',
    },
    '&:hover': {
      backgroundColor: theme.palette.new.smokey_silver,
    },
    '&:focus-visible': {
      backgroundColor: theme.palette.new.smokey_silver,
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.new.smokey_silver,
    },
    '&:active': {
      backgroundColor: theme.palette.new.business_black_10,
    },
    '&.Mui-selected': {
      fontWeight: 500,
      backgroundColor: theme.palette.new.white,
      color: theme.palette.new.versatile_violet,

      '& .MuiTypography-root': {
        fontWeight: 500,
        color: theme.palette.new.versatile_violet,
      },
      '&:hover': {
        backgroundColor: theme.palette.new.versatile_violet_5,
      },
      '&.Mui-focused': {
        backgroundColor: theme.palette.new.versatile_violet_5,
      },
      '&:focus-visible': {
        backgroundColor: theme.palette.new.versatile_violet_5,
      },
      '&:active': {
        backgroundColor: theme.palette.new.versatile_violet_10,
      },
    },
  },
}))
