import { State } from '@redux/modules/types'

import {
  REQUEST_ANALYZE_VIEW,
  REQUEST_ANALYZE_CHART,
  REQUEST_ANALYZE_TABLE,
} from './analyze.action-types'

export const getAnalyzeTable = (state: State) => {
  return state.analyze.analyzeTable
}

export const getAnalyzeChart = (state: State) => {
  return state.analyze.analyzeChart
}

export const getAnalyzeTableState = (state: State) => {
  return state.analyze.analyzeTable?.gridState
}

export const getAnalyzeAbcTotals = (state: State) => {
  return state.analyze.analyzeAbcTotals
}

export const isFetchingAnalyzeTable = (state: State) => {
  return state.analyze.fetchingKeys.includes(REQUEST_ANALYZE_TABLE)
}

export const isFetchingAnalyzeChart = (state: State) => {
  return state.analyze.fetchingKeys.includes(REQUEST_ANALYZE_CHART)
}

export const isFetchingAnalyze = (state: State) => {
  return state.analyze.fetchingKeys.includes(REQUEST_ANALYZE_VIEW)
}
