import React from 'react'
import sortBy from 'lodash.sortby'
import { useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { Position } from '@xyflow/react'
import { Box } from '@mui/material'

import { createId } from '@utils/common.utils'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { CONNECT_PARAMETER_CREATION_MODAL_NAME } from '@constants/modals.constants'
import { requestParameterOverviewAction } from '@redux/modules/parameters/parameters.actions'
import { INPUT_PARAMETER_PER_ROW, INPUT_TYPES, SOURCE_TYPES } from '@constants/flow.constants'
import { ConnectParameterCreationModalDetails } from '@containers/modals/connect-parameter-creation-modal/ConnectParameterCreationModal.container'

import ReactFlowAddNewButtonComponent from '@components/connect-view/flow/ReactFlowAddNewButton'
import ReactFlowBlockHeaderComponent from '@components/connect-view/flow/ReactFlowBlockHeader'
import ReactFlowParameterBlockComponent, { PARAMETER_BLOCK_TYPES } from '@components/connect-view/flow/ReactFlowParameterBlock'
import ReactFlowHandleComponent from '@components/connect-view/flow/ReactFlowHandle'

import useStyles from './ReactFlowGenericInputGroupNode.styles'

export interface ReactFlowGenericInputGroupNodeComponentProps {
  /**
   * Data for the node
   */
  data?: {
    /**
     * Number of nodes
     */
    numOfNodes?: number,
    /**
     * Generic inputs list
     */
    genericInputs?: UseCase.InputParameter[],
  }
}

const ReactFlowGenericInputGroupNodeComponent: React.FC<ReactFlowGenericInputGroupNodeComponentProps> = ({ data }) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { numOfNodes, genericInputs } = (data || {})
  const inputParameters = sortBy((genericInputs || []), 'name')
  const { classes } = useStyles({ numOfNodes: numOfNodes || 0 })
  const inputChunks = []
  const isAddButtonAvailable = Boolean(numOfNodes)

  for (let i = 0; i < inputParameters.length; i += INPUT_PARAMETER_PER_ROW) {
    const chunk = inputParameters.slice(i, i + INPUT_PARAMETER_PER_ROW)
    inputChunks.push(chunk)
  }

  const onAddButtonClick = () => {
    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: CONNECT_PARAMETER_CREATION_MODAL_NAME,
        modalDetails: {
          parameterType: INPUT_TYPES.GENERIC,
          returnTo: '',
        } as ConnectParameterCreationModalDetails,
      }),
    )
  }

  const onParameterClick = (parameterItem: UseCase.InputParameter) => {
    dispatch(
      requestParameterOverviewAction(parameterItem),
    )
  }

  return (
    <Box className={classes.root} data-testid={ReactFlowGenericInputGroupNodeComponent.name}>
      <ReactFlowHandleComponent
        position={Position.Left}
        type='target'
      />

      <Box className={classes.content}>
        <ReactFlowBlockHeaderComponent
          name={intl.formatMessage({ id: 'connect.block.generic_inputs' })}
          help={intl.formatMessage({ id: 'connect.block.generic_inputs.help' })}
          onAddButtonClick={onAddButtonClick}
          isAddButtonAvailable={isAddButtonAvailable}
          parentComponentName={ReactFlowGenericInputGroupNodeComponent.name}
        />

        {
          numOfNodes ? (
            <Box className={classes.inputParametersContainer}>
              {
                inputChunks.map((chunk, index) => {
                  return (
                    <Box className={classes.inputParametersChunk} key={createId(index, 'chunk')}>
                      {
                        chunk.map((item, chunkIndex) => {
                          const {
                            name,
                            sourceType,
                            unitLabel,
                            modelInputParameterId,
                          } = item

                          return (
                            <ReactFlowParameterBlockComponent
                              key={createId(index, name, modelInputParameterId)}
                              name={name}
                              sourceType={sourceType as SOURCE_TYPES}
                              unitLabel={unitLabel}
                              type={PARAMETER_BLOCK_TYPES.GENERIC_INPUT}
                              onClick={() => onParameterClick(item)}
                              dataTestId={`${ReactFlowParameterBlockComponent.name}-${ReactFlowGenericInputGroupNodeComponent.name}-${index}`}
                            />
                          )
                        })
                      }
                    </Box>
                  )
                })
              }
            </Box>
          ) : (
            <ReactFlowAddNewButtonComponent
              buttonText={intl.formatMessage({ id: 'connect.block.generic_inputs.add' })}
              type={INPUT_TYPES.GENERIC}
              onAddButtonClick={onAddButtonClick}
              parentComponentName={ReactFlowGenericInputGroupNodeComponent.name}
            />
          )
        }
      </Box>

      <ReactFlowHandleComponent
        type='source'
        position={Position.Right}
      />
    </Box>
  )
}

export default ReactFlowGenericInputGroupNodeComponent
