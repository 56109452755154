import { SOCRATES } from '@constants/api-provider.constants'
import apiProvider from '@redux/api-provider'
import { checkApiForError } from '@utils/redux.utils'
import {
  FetchProjectActionPayload,
  TrackPayload,
  FetchProjectEvaluationsListPayload,
  FetchEvaluationsDetailstPayload,
} from './socrates.types'

export const fetchAll = () => {
  return apiProvider.getApi(SOCRATES).post('socrates/v3/project/list', {})
    .then(({ data }) => {
      checkApiForError(data)

      return data.data.project_ids
    })
}

export const fetch = ({ id } : FetchProjectActionPayload) => {
  return apiProvider.getApi(SOCRATES).post('socrates/v3/project/show', { projectId: id })
    .then(({ data }) => {
      checkApiForError(data)

      const projectDetais = data.data || {}
      return {
        ...projectDetais,
        name: projectDetais.label,
        targets: Object.keys(projectDetais.target_definition || {}) || [],
      }
    })
}

export const track = ({ id } : TrackPayload) => {
  return apiProvider.getApi(SOCRATES).post('socrates/v3/project/progress/track', { projectId: id })
    .then(({ data }) => {
      checkApiForError(data)
      const projectProgress = data.data || {}

      return {
        ...projectProgress,
        id,
      }
    })
}

export const fetchProjectEvaluationsList = ({ id, onlyOptimal = false } : FetchProjectEvaluationsListPayload) => {
  return apiProvider.getApi(SOCRATES).post('socrates/v3/project/analyze', { projectId: id })
    .then(({ data }) => {
      checkApiForError(data)
      const { evaluations = [] } = data.data || {}

      return evaluations
    })
}

export const fetchEvaluationsDetails = ({ evaluationsList } : FetchEvaluationsDetailstPayload) => {
  return apiProvider.getApi(SOCRATES).post('socrates/v3/evaluations/show', evaluationsList)
    .then(({ data }) => {
      checkApiForError(data)
      const { evaluations = [] } = data.data || {}

      return evaluations || []
    })
}
