import React, { ChangeEvent, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from '@redux/hooks'
import sortBy from 'lodash.sortby'

import {
  SOURCE_TYPES_OPTIONS,
  SOURCE_TYPES_TO_ICONS_MAP,
  SOURCE_TYPES,
  SOURCE_TYPES_CATEGORIES,
  CATEGORIES_TO_LABEL_MAP,
  SOURCE_TYPES_TO_DISABLED_ICONS_MAP,
  POSSIBLE_TEMPLATE_TYPES,
} from '@constants/flow.constants'

import {
  Popper, InputBase,
  Typography, Box,
} from '@mui/material'

import Autocomplete from '@mui/material/Autocomplete'
import DropdownButtonComponent from '@base/dropdowns/DropdownButton'
import ParameterSelectorNoOptionSectionComponent from '@components/connect-view/parameters/ParameterSelectorNoOptionSection'
import ParameterSelectorListBoxComponent from '@components/connect-view/parameters/ParameterSelectorListBox'

import { enrichSourceTypeOptions, SourceTypeOptionExtended } from '@utils/flow.utils'
import { DEFAULT_AGGREGATION_TYPE } from '@constants/date.constants'
import { getParametersTemplatesListWithConstraints } from '@redux/modules/parameters-templates/parameters-templates.selectors'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { getCompanyConnectionsBySource } from '@redux/modules/hermes/hermes.selectors'
import { getUseCaseFileIdentifiers } from '@redux/modules/training-files/training-files.selectors'

import useStyles from './ParameterTemplateSelector.styles'

export const SOURCE_TYPE_SELECTOR_ID = 'source-type-selector'
export const PREDEFINED_INPUT_SELECTOR_ID = 'predefined-input-selector'

export interface AutocompleteParameterTemplateSelectorFieldComponentProps {
  /**
   * Flag to indicate if the component is fetching data
   */
  isFetching?: boolean,
  /**
   * Flag to indicate if the component is disabled
   */
  disabled?: boolean,
  /**
   * Selected source type
   */
  selectedSourceType: SOURCE_TYPES,
  /**
   * Selected input template
   */
  selectedInputTemplate?: string,
  /**
   * Parameter template type
   */
  parameterTemplateType: POSSIBLE_TEMPLATE_TYPES,
  /**
   * Callback to handle source type change
   */
  sourceTypeChangeCb: {
    (e: ChangeEvent, selectedValue: any): any,
  }
  /**
   * Callback to handle predefined input change
   */
  predefinedInputChangeCb: {
    (e: ChangeEvent, selectedValue: any): any,
  }
}

const ParameterTemplateSelectorComponent: React.FC<AutocompleteParameterTemplateSelectorFieldComponentProps> = ({
  isFetching,
  sourceTypeChangeCb,
  predefinedInputChangeCb,
  disabled,
  parameterTemplateType,
  selectedSourceType,
  selectedInputTemplate,
}) => {
  const intl = useIntl()

  const [containerWidth, setContainerWidth] = useState(0)
  const containerRef = useRef<HTMLElement>(null)
  const { classes, cx } = useStyles({ inputWidth: `${containerWidth}px` })

  const [anchorSourceTypeEl, setAnchorSourceTypeEl] = useState(null)
  const [anchorPredefinedInputEl, setAnchorPredefinedInputEl] = useState(null)
  const parametersTemplatesList = useSelector((state) => getParametersTemplatesListWithConstraints(state, selectedSourceType, parameterTemplateType))
  const isAdmin = useSelector((state) => getIsAdmin(state))
  const connectionsList = useSelector((state) => getCompanyConnectionsBySource(state))
  const filesIdentifiers = useSelector((state) => getUseCaseFileIdentifiers(state))
  const fileVersionsCount = filesIdentifiers.length || 0
  const parametersTemplatesListOptions: UseCase.ParameterTemplateItem[] = [{
    name: intl.formatMessage({ id: 'connect.modal.parameter.predefined.custom' }),
    sourceType: '',
    unitLabel: '',
    description: '',
    dataColumnName: '',
    aggregationFunction: DEFAULT_AGGREGATION_TYPE,
    parameterPresetId: '',
    possiblePresetTypes: [
      POSSIBLE_TEMPLATE_TYPES.ACTIVE,
      POSSIBLE_TEMPLATE_TYPES.EXTERNAL,
      POSSIBLE_TEMPLATE_TYPES.PASSIVE,
      POSSIBLE_TEMPLATE_TYPES.TARGET,
    ],
    // @ts-ignore
  }].concat(parametersTemplatesList)

  const allowedOptions: SourceTypeOptionExtended[] = sortBy(SOURCE_TYPES_OPTIONS.filter((item) => {
    if (!item.forbiddenParameterTypes) {
      return true
    }

    return !item.forbiddenParameterTypes.includes(parameterTemplateType)
  }).map((item) => enrichSourceTypeOptions(item, connectionsList, fileVersionsCount, isAdmin)), (option) => {
    const category: SOURCE_TYPES_CATEGORIES = option.category
    return CATEGORIES_TO_LABEL_MAP[category] ? intl.formatMessage({ id: CATEGORIES_TO_LABEL_MAP[category] }) : ''
  })

  const sourceTypeValue = (SOURCE_TYPES_OPTIONS.find((item) => {
    return item.value === selectedSourceType
  }) || null) as SourceTypeOptionExtended

  const inputTemplateValue = parametersTemplatesList.find((item) => {
    return item.parameterPresetId === selectedInputTemplate
  }) || null

  const getSourceTypeOptionLabel = (option: SourceTypeOptionExtended) => {
    return option.labelKey ? intl.formatMessage({ id: option.labelKey }) : ''
  }

  const getPredefinedInputOptionLabel = (option: UseCase.ParameterTemplateItem) => {
    return option.name || ''
  }

  const handleSourceTypeOpen = (event: React.ChangeEvent<any>) => {
    if (disabled) {
      return
    }

    if (containerRef && containerRef.current) {
      setContainerWidth(containerRef.current.clientWidth || 0)
    }

    setAnchorSourceTypeEl(event.currentTarget)
  }

  const handlePredefinedInputOpen = (event: React.ChangeEvent<any>) => {
    if (disabled) {
      return
    }

    if (containerRef && containerRef.current) {
      setContainerWidth(containerRef.current.clientWidth || 0)
    }

    setAnchorPredefinedInputEl(event.currentTarget)
  }

  const handleSourceTypeClose = (event?: React.ChangeEvent<any>, reason?: any) => {
    if (reason !== 'toggleInput') {
      setAnchorSourceTypeEl(null)
    }
  }

  const handlePredefinedInputClose = (event?: React.ChangeEvent<any>, reason?: any) => {
    if (reason !== 'toggleInput') {
      setAnchorPredefinedInputEl(null)
    }
  }

  const handleSourceTypeChange = (e: React.ChangeEvent<any>, newValue?: any, reason?: any) => {
    const selectedValue = newValue[newValue.length - 1]

    if (selectedValue) {
      sourceTypeChangeCb && sourceTypeChangeCb(e, selectedValue)
    }

    handleSourceTypeClose()
  }

  const handlePredefinedInputChange = (e: React.ChangeEvent<any>, newValue?: any, reason?: any) => {
    const selectedValue = newValue[newValue.length - 1]

    if (selectedValue) {
      predefinedInputChangeCb && predefinedInputChangeCb(e, selectedValue)
    }

    handlePredefinedInputClose()
  }

  const getSourceTypeButtonTitle = () => {
    if (sourceTypeValue) {
      return getSourceTypeOptionLabel(sourceTypeValue)
    }

    if (isFetching) {
      return intl.formatMessage({ id: 'connect.modal.parameter.source.empty_selection' })
    }

    return intl.formatMessage({ id: 'connect.modal.parameter.source.empty_selection' })
  }

  const getPredefinedInputButtonTitle = () => {
    if (inputTemplateValue) {
      return getPredefinedInputOptionLabel(inputTemplateValue)
    }

    if (isFetching) {
      return intl.formatMessage({ id: 'connect.modal.parameter.predefined.empty_selection' })
    }

    return intl.formatMessage({ id: 'connect.modal.parameter.predefined.empty_selection' })
  }

  const sourceTypeButtonTitle = getSourceTypeButtonTitle()
  const predefinedInputButtonTitle = getPredefinedInputButtonTitle()
  const sourceTypeOpen = Boolean(anchorSourceTypeEl)
  const predefinedInputOpen = Boolean(anchorPredefinedInputEl)

  return (
    <Box
      className={cx(classes.rootContainer, {
        [classes.disabled]: disabled,
      })}
      data-testid={ParameterTemplateSelectorComponent.name}
      ref={containerRef}
    >
      <DropdownButtonComponent
        name='sourceTypeAutocomplete'
        label={sourceTypeButtonTitle}
        onClick={handleSourceTypeOpen}
        open={sourceTypeOpen}
        disabled={disabled}
      />

      {
        (sourceTypeValue && parametersTemplatesList && parametersTemplatesList.length > 0) ? (
          <Box ml={1}>
            <DropdownButtonComponent
              name='predefinedInputAutocomplete'
              label={predefinedInputButtonTitle}
              onClick={handlePredefinedInputOpen}
              open={predefinedInputOpen}
              disabled={disabled}
            />
          </Box>
        ) : (
          null
        )
      }

      <Popper
        open={sourceTypeOpen}
        anchorEl={anchorSourceTypeEl}
        placement='bottom-start'
        className={classes.popper}
        modifiers={[{
          name: 'flip',
          options: {
            enabled: false,
          },
        }]}
      >
        <Box className={classes.root} data-testid={`${ParameterTemplateSelectorComponent.name}-${SOURCE_TYPE_SELECTOR_ID}`}>
          <Autocomplete
            id={SOURCE_TYPE_SELECTOR_ID}
            open={true}
            multiple={true}
            autoHighlight={true}
            filterSelectedOptions={false}
            autoSelect={false}
            fullWidth={true}
            disablePortal={true}
            disableCloseOnSelect={false}
            value={sourceTypeValue ? [sourceTypeValue] : []}
            renderTags={() => null}
            onClose={handleSourceTypeClose}
            classes={{
              root: classes.rootAutocomplete,
              paper: classes.paper,
              option: classes.option,
              noOptions: cx(classes.option, classes.noOptions),
              popperDisablePortal: classes.popperDisablePortal,
              groupUl: classes.groupUl,
              groupLabel: classes.groupLabel,
            }}
            getOptionDisabled={(option) => {
              return option.isDisabled
            }}
            isOptionEqualToValue={(option, value) => {
              return option.value === value.value
            }}
            onChange={handleSourceTypeChange}
            options={allowedOptions}
            getOptionLabel={getSourceTypeOptionLabel}
            renderInput={(params) => (
              <InputBase
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                autoFocus={true}
                type='search'
                className={classes.inputBase}
                placeholder={intl.formatMessage({ id: 'connect.modal.parameter.source.placeholder' })}
              />
            )}
            ListboxComponent={ParameterSelectorListBoxComponent as any}
            noOptionsText={(
              <ParameterSelectorNoOptionSectionComponent />
            )}
            groupBy={(option) => {
              const category: SOURCE_TYPES_CATEGORIES = option.category

              return CATEGORIES_TO_LABEL_MAP[category] ? intl.formatMessage({ id: CATEGORIES_TO_LABEL_MAP[category] }) : ''
            }}
            renderGroup={(option) => {
              return (
                <Box component='li' key={option.key}>
                  <Box
                    className={cx(classes.groupLabel, {
                      [classes.groupLabelEmpty]: !option.group,
                    })}
                  >
                    {option.group}
                  </Box>
                  <Box component='ul' className={classes.groupUl}>
                    {option.children}
                  </Box>
                </Box>
              )
            }}
            renderOption={((props: React.HTMLAttributes<HTMLLIElement>, option: SourceTypeOptionExtended, { selected } : { selected: boolean }) => {
              const label = option.labelKey ? intl.formatMessage({ id: option.labelKey }) : ''
              const IconComponent = option.isDisabled ? SOURCE_TYPES_TO_DISABLED_ICONS_MAP[option.value] : SOURCE_TYPES_TO_ICONS_MAP[option.value]

              return (
                <Box component='li' {...props}>
                  <Box
                    className={cx(classes.optionContainer, classes.optionContainerDataSources)}
                  >
                    <Box className={cx(classes.optionIcon, classes.optionIconDataSources)}>
                      <IconComponent />
                    </Box>
                    <Typography
                      title={label}
                      className={cx(classes.optionLabel, {
                        [classes.optionLabelSelected]: selected,
                      })}
                    >
                      {label}
                    </Typography>
                  </Box>
                </Box>
              )
            }) as any}
          />
        </Box>
      </Popper>

      <Popper
        open={predefinedInputOpen}
        anchorEl={anchorPredefinedInputEl}
        placement='bottom-start'
        className={cx(classes.popper, classes.popperInput)}
        modifiers={[{
          name: 'flip',
          options: {
            enabled: false,
          },
        }]}
      >
        <Box className={classes.root} data-testid={`${ParameterTemplateSelectorComponent.name}-${PREDEFINED_INPUT_SELECTOR_ID}`}>
          <Autocomplete
            id={PREDEFINED_INPUT_SELECTOR_ID}
            open={true}
            multiple={true}
            autoHighlight={true}
            filterSelectedOptions={false}
            autoSelect={false}
            fullWidth={true}
            disablePortal={true}
            disableCloseOnSelect={false}
            value={inputTemplateValue ? [inputTemplateValue] : []}
            renderTags={() => null}
            onClose={handlePredefinedInputClose}
            classes={{
              root: classes.rootAutocomplete,
              paper: classes.paper,
              option: classes.option,
              noOptions: cx(classes.option, classes.noOptions),
              popperDisablePortal: classes.popperDisablePortal,
            }}
            onChange={handlePredefinedInputChange}
            options={parametersTemplatesListOptions}
            getOptionLabel={getPredefinedInputOptionLabel}
            renderInput={(params) => (
              <InputBase
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                autoFocus={true}
                type='search'
                className={classes.inputBase}
                placeholder={sourceTypeValue ? (
                  intl.formatMessage({ id: 'connect.modal.parameter.predefined.placeholder' }, { name: intl.formatMessage({ id: sourceTypeValue.labelKey }) })
                ) : (
                  ''
                )}
              />
            )}
            ListboxComponent={ParameterSelectorListBoxComponent as any}
            noOptionsText={(
              <ParameterSelectorNoOptionSectionComponent />
            )}
            renderOption={((props: React.HTMLAttributes<HTMLLIElement>, option: UseCase.ParameterTemplateItem, { selected } : { selected: boolean }) => {
              const label = option.name
              const unit = option.unitLabel || ''
              const sourceType = option.sourceType as SOURCE_TYPES
              const IconComponent = sourceType ? SOURCE_TYPES_TO_ICONS_MAP[sourceType] : null

              return (
                <Box component='li' {...props}>
                  <Box
                    className={classes.optionContainer}
                  >
                    <Typography
                      title={label}
                      className={cx(classes.optionLabel, {
                        [classes.optionLabelSelected]: selected,
                      })}
                    >
                      <Box
                        component='span'
                        dangerouslySetInnerHTML={{
                          __html: label,
                        }}
                      />
                    </Typography>
                    <Box className={classes.optionContainerRight}>
                      {
                        (sourceType && IconComponent) ? (
                          <>
                            <Box className={classes.unitLabel}>
                              {unit}
                            </Box>
                            <Box className={classes.optionIcon}>
                              <IconComponent />
                            </Box>
                          </>
                        ) : (
                          null
                        )
                      }
                    </Box>
                  </Box>
                </Box>
              )
            }) as any}
          />
        </Box>
      </Popper>
    </Box>
  )
}

export default ParameterTemplateSelectorComponent
