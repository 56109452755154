import { createAction } from 'redux-actions'
import {
  REQUEST_OPTIMIZE_PAGE_DATA,
  RECEIVE_OPTIMIZE_PAGE_DATA,
  REQUEST_OPTIMIZE_DRILL_DOWN,
  RECEIVE_OPTIMIZE_DRILL_DOWN,
  SET_TARGET_REVENUE,
  SET_TARGET_REVENUEDONE,
  SET_TARGET_REPLENISHMENT_COST,
  SET_TARGET_REPLENISHMENT_COST_DONE,
  SET_STRATEGY_ACTION,
  SET_STRATEGY_ACTION_DONE,
  START_FETCHING_OPTIMIZE,
  STOP_FETCHING_OPTIMIZE,
  SET_TARGET_SELECTOR_TYPE,
  SET_TARGET_SELECTOR_TYPE_DONE,
} from './optimize.action-types'

import {
  SetStrategyPayload,
  RequestOptimizeDataPayload,
  RequestOptimizeDrillDownPayload,
  SetTargetReplenishmentCostPayload,
  SetTargetRevenuePayload,
  SetTargetSelectorTypePayload,
} from './optimize.types'

export const requestOptimizePageData = createAction<RequestOptimizeDataPayload>(REQUEST_OPTIMIZE_PAGE_DATA)
export const receiveOptimizePageData = createAction(RECEIVE_OPTIMIZE_PAGE_DATA)

export const requestOptimizeDrillDown = createAction<RequestOptimizeDrillDownPayload>(REQUEST_OPTIMIZE_DRILL_DOWN)
export const receiveOptimizeDrillDown = createAction(RECEIVE_OPTIMIZE_DRILL_DOWN)

export const setTargetRevenue = createAction<SetTargetRevenuePayload>(SET_TARGET_REVENUE)
export const setTargetRevenueDone = createAction(SET_TARGET_REVENUEDONE)

export const setTargetReplenishmentCost = createAction<SetTargetReplenishmentCostPayload>(SET_TARGET_REPLENISHMENT_COST)
export const setTargetReplenishmentCostDone = createAction(SET_TARGET_REPLENISHMENT_COST_DONE)

export const setStrategyAction = createAction<SetStrategyPayload>(SET_STRATEGY_ACTION)
export const setStrategyActionDone = createAction(SET_STRATEGY_ACTION_DONE)

export const startFetchingOptimizeAction = createAction<string>(START_FETCHING_OPTIMIZE)
export const stopFetchingOptimizeAction = createAction(STOP_FETCHING_OPTIMIZE)

export const setTargetSelectorType = createAction<SetTargetSelectorTypePayload>(SET_TARGET_SELECTOR_TYPE)
export const setTargetSelectorTypeDone = createAction(SET_TARGET_SELECTOR_TYPE_DONE)
