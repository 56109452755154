import { IntlShape } from 'react-intl'
import { isRegularCell } from '@utils/data-grid.utils'
import { GridColDef } from '@mui/x-data-grid-premium'
import {
  EMPTY_CSV_VALUE,
  PROCESSING_STATES,
  FILE_EXTENSION_TO_ICON_MAP,
  MAX_EDIT_FILE_SIZE_IN_BYTES,
  MAX_PREVIEW_FILE_SIZE_IN_BYTES,
  SUPPORTED_PREVIEW_FILE_TYPES,
} from '@constants/files.constants'

import { humanFileSize } from '@utils/common.utils'
import { getAllStringOperators } from './data-grid-cells.utils'

export interface FileRecord {
  fileSizeBytes?: number
  fileName: string
  processingStatus?: PROCESSING_STATES
}

export const getFileExtension = (fileName: string, isDirectory = false) => {
  const parts = fileName.split('.')

  if (isDirectory) {
    return undefined
  }

  if (parts.length === 1) {
    return undefined
  }

  return (parts.pop() || '').toLowerCase()
}

export const getNameByFilePath = (filePath: string) => {
  const pathParts = filePath.split('/')
  const name = pathParts[pathParts.length - 1]

  return name
}

export const getPfpUrl = (bucket: string, path?: string) => {
  if (!bucket || !path) {
    return 'n/a'
  }

  return `pfp://${bucket}/${path}`
}

export const parsePfpPath = (pfpPath: string) => {
  const [bucket, ...pathParts] = pfpPath.replace('pfp://', '').split('/')

  const path = pathParts.join('/')
  const name = getNameByFilePath(path)

  return { bucket, path, name }
}

export const canPreviewFileWithExtension = (fileExtension?: string) => {
  if (!fileExtension) {
    return false
  }

  const typesList = Object.values(SUPPORTED_PREVIEW_FILE_TYPES)

  return (typesList.includes(fileExtension as SUPPORTED_PREVIEW_FILE_TYPES))
}

/**
 * Returns icon for file extension
 * @param fileExtension any file extension
 * @returns icon for file extension
 */
export const getFileIcon = (fileExtension?: string) => {
  if (!fileExtension) {
    return FILE_EXTENSION_TO_ICON_MAP.default
  }

  // @ts-ignore
  return FILE_EXTENSION_TO_ICON_MAP[fileExtension] || FILE_EXTENSION_TO_ICON_MAP.default
}

/**
 * Generates columns for the data grid.
 * @param columns list of columns
 * @param row first row of data
 */
export const generateColumnsDefinitionsFromRow = (
  intl: IntlShape,
  columns?: string[],
  isEditable = false,
) => {
  if (!columns || columns.length === 0) {
    return []
  }

  return columns.map((column) => {
    const colDef = {
      field: column,
      headerName: column,
      type: 'string',
      editable: isEditable,
      width: 200,
      filterOperators: getAllStringOperators(intl),
      cellClassName: (params) => {
        if (!isRegularCell(params)) {
          return ''
        }

        const className = (params.value && params.value !== EMPTY_CSV_VALUE) ? '' : 'preview-empty-cell'

        return className
      },
    } as GridColDef

    return colDef
  })
}

export const isProcessingStatusNotAllowedForCsvPreview = (processingStatus: PROCESSING_STATES) => {
  return [
    PROCESSING_STATES.ERROR,
    PROCESSING_STATES.QUEUED,
    PROCESSING_STATES.RUNNING,
  ].includes(processingStatus)
}

export const isProcessingStatusNotAllowedForCsvEdit = (processingStatus: PROCESSING_STATES) => {
  return [
    PROCESSING_STATES.ERROR,
    PROCESSING_STATES.QUEUED,
    PROCESSING_STATES.RUNNING,
    PROCESSING_STATES.SUCCEEDED,
  ].includes(processingStatus)
}

export const isFileSizeNotAllowedForPreview = (fileSize: number) => {
  return fileSize > MAX_PREVIEW_FILE_SIZE_IN_BYTES
}

export const isFileSizeNotAllowedForEdit = (fileSize: number) => {
  return fileSize > MAX_EDIT_FILE_SIZE_IN_BYTES
}

export const isFileExtNotAllowed = (fileNameWithPath = '') => {
  const fileName = getNameByFilePath(fileNameWithPath)
  const fileExt = getFileExtension(fileName, false)

  return fileExt !== SUPPORTED_PREVIEW_FILE_TYPES.CSV
}

export const getCsvPreviewButtonLabel = (intl: IntlShape, {
  fileSizeBytes,
  fileName,
  processingStatus,
} : {
  fileSizeBytes?: number
  fileName: string
  processingStatus?: PROCESSING_STATES
}) => {
  const formattedFileSize = fileSizeBytes ? humanFileSize(fileSizeBytes, {
    maximumFractionDigits: 2,
  }) : 0

  if (isFileExtNotAllowed(fileName)) {
    return intl.formatMessage({ id: 'common.tables.actions.previewIsNotSupported' })
  }

  if (processingStatus && isProcessingStatusNotAllowedForCsvPreview(processingStatus)) {
    return intl.formatMessage({ id: 'fileManager.preview.dialog.fileStatus' })
  }

  if (isFileSizeNotAllowedForPreview(fileSizeBytes!)) {
    return intl.formatMessage({
      id: 'fileManager.preview.dialog.limit',
    }, {
      actualSize: formattedFileSize,
      limit: humanFileSize(MAX_PREVIEW_FILE_SIZE_IN_BYTES),
    })
  }

  return intl.formatMessage({ id: 'common.tables.actions.preview' })
}

export const getCsvEditButtonLabel = (intl: IntlShape, {
  fileSizeBytes,
  fileName,
  processingStatus,
} : FileRecord) => {
  const formattedFileSize = fileSizeBytes ? humanFileSize(fileSizeBytes, {
    maximumFractionDigits: 2,
  }) : 0

  if (isFileExtNotAllowed(fileName)) {
    return intl.formatMessage({ id: 'fileManager.edit.dialog.notSupported' })
  }

  if (processingStatus && isProcessingStatusNotAllowedForCsvEdit(processingStatus)) {
    return intl.formatMessage({ id: 'fileManager.edit.dialog.fileStatus' })
  }

  if (isFileSizeNotAllowedForEdit(fileSizeBytes!)) {
    return intl.formatMessage({
      id: 'fileManager.edit.dialog.limit',
    }, {
      actualSize: formattedFileSize,
      limit: humanFileSize(MAX_EDIT_FILE_SIZE_IN_BYTES),
    })
  }

  return intl.formatMessage({ id: 'common.tables.actions.edit' })
}

export const isCsvPreviewDisabled = (record: FileRecord) => {
  return (record.processingStatus && isProcessingStatusNotAllowedForCsvPreview(record.processingStatus)) ||
          isFileSizeNotAllowedForPreview(record.fileSizeBytes!) ||
          isFileExtNotAllowed(record.fileName)
}

export const isCsvEditDisabled = (record: FileRecord) => {
  return (record.processingStatus && isProcessingStatusNotAllowedForCsvEdit(record.processingStatus)) ||
          isFileSizeNotAllowedForEdit(record.fileSizeBytes!) ||
          isFileExtNotAllowed(record.fileName)
}
