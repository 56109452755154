import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { DEFAULT_FILL_COLOR, SUB_OPTIMAL_FILL_COLOR, DEFAULT_SELECTED_COLOR } from '@constants/analysis.constants'

const useStyles = makeStyles()((theme: Theme) => ({
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginRight: theme.spacing(3),
  },
  legendItemIndicatorOptimum: {
    width: theme.spacing(1.4),
    height: theme.spacing(1.4),
    marginRight: theme.spacing(1),
    borderRadius: '100%',
    backgroundColor: DEFAULT_FILL_COLOR,
  },
  legendItemIndicatorSubOptimum: {
    width: theme.spacing(0.8),
    height: theme.spacing(0.8),
    marginRight: theme.spacing(1),
    borderRadius: '100%',
    backgroundColor: SUB_OPTIMAL_FILL_COLOR,
  },
  legendItemIndicatorSelectedOptimum: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    fontSize: '12px',
    fontFeatureSettings: "'tnum' on, 'lnum' on",
    color: theme.palette.new.white,
    minWidth: theme.spacing(2),
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: theme.spacing(1),
    borderRadius: '100%',
    backgroundColor: DEFAULT_SELECTED_COLOR,
  },
  legendItemIndicatorOptimumToExecute: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    fontSize: '12px',
    fontFeatureSettings: "'tnum' on, 'lnum' on",
    color: theme.palette.new.white,
    minWidth: theme.spacing(2),
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: theme.spacing(1),
    borderRadius: '100%',
    background: theme.palette.new.pink,
  },
  legendItemIndicatorText: {
    color: theme.palette.new.black,
    fontSize: '12px',
  },
  legendItemDisabled: {
    opacity: 0.4,
  },
  legendContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    padding: theme.spacing(0, 4),
    [theme.breakpoints.down('xl')]: {
      padding: theme.spacing(0, 2),
    },
  },
}))

export default useStyles
