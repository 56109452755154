import produce from 'immer'
import orderBy from 'lodash.orderby'

import { FilesServiceState, ReducerPayload } from '@redux/modules/types'
import { getNameByFilePath, getFileExtension } from '@utils/files.utils'

import {
  FILE_BROWSER_DEFAULT_SORTING_KEY, FILE_BROWSER_DEFAULT_SORTING_ORDER,
  FILE_BROWSER_SORTING_OPTIONS, FILE_BROWSER_SORTING_ORDER_OPTIONS,
} from '@constants/files.constants'

import { fileListToTree } from './file-service.utils'

export const initialState: FilesServiceState = {
  isFetching: false,
  fetchingKey: '',
  filePreview: null,
  filesList: [],
  browserTree: null,
  sortingKey: FILE_BROWSER_DEFAULT_SORTING_KEY,
  sortingOrder: FILE_BROWSER_DEFAULT_SORTING_ORDER,
}

export const receiveSortingChanges = (
  state: FilesServiceState,
  action: ReducerPayload<{
    sortingKey: FILE_BROWSER_SORTING_OPTIONS,
    sortingOrder: FILE_BROWSER_SORTING_ORDER_OPTIONS
  }>,
) => {
  const nextState = produce(state, (draftState) => {
    draftState.sortingKey = action.payload.sortingKey
    draftState.sortingOrder = action.payload.sortingOrder

    const sortedFilesList = orderBy(draftState.filesList, [draftState.sortingKey], draftState.sortingOrder) as FileService.ReduxFileItem[]

    draftState.filesList = sortedFilesList
    draftState.browserTree = fileListToTree(sortedFilesList, draftState.sortingKey, draftState.sortingOrder)
  })

  return nextState
}

export const receiveFilesList = (state: FilesServiceState, action: ReducerPayload<FileService.FileItem[]>) => {
  const nextState = produce(state, (draftState) => {
    const itemsList = action.payload.map((fileItem, index) => {
      const name = getNameByFilePath(fileItem.fileKey)
      return {
        path: fileItem.fileKey,
        sizeInBytes: fileItem.metadata.sizeInBytes,
        createdAt: fileItem.metadata.createdAt,
        fileExtension: getFileExtension(name, false),
        name,
      }
    })

    const sortedFilesList = orderBy(itemsList, [draftState.sortingKey], draftState.sortingOrder) as FileService.ReduxFileItem[]

    draftState.filesList = sortedFilesList
    draftState.browserTree = fileListToTree(sortedFilesList, draftState.sortingKey, draftState.sortingOrder)
  })

  return nextState
}

export const receiveFilePreview = (state: FilesServiceState, action: ReducerPayload<FileService.FilePreview>) => {
  const nextState = produce(state, (draftState) => {
    draftState.filePreview = action.payload
  })

  return nextState
}

export const startFetching = (state: FilesServiceState, action: ReducerPayload<string>) => {
  const nextState = produce(state, (draftState) => {
    draftState.isFetching = true
    draftState.fetchingKey = (!draftState.fetchingKey) ? action.payload : draftState.fetchingKey
  })

  return nextState
}

export const stopFetching = (state: FilesServiceState, action: ReducerPayload<string>) => {
  const nextState = produce(state, (draftState) => {
    if (draftState.fetchingKey === action.payload) {
      draftState.fetchingKey = ''
      draftState.isFetching = false
    }
  })

  return nextState
}
