import React, {
  useCallback,
  useMemo,
} from 'react'

import { Box, Typography, useTheme } from '@mui/material'
import { useIntl } from 'react-intl'
import { PARETOS_GRADIENT } from '@constants/ui.constants'
import { defaultNumberFormatter } from '@utils/analysis.utils'
import InfoButtonComponent from '@base/buttons/InfoButton'

export interface AbcClassificationSelectorItemComponentProps {
  label: string
  tooltip?: React.ReactNode
  x?: number
  y?: number
  selected?: boolean
  loading?: boolean
  disabled?: boolean
  hasFiltersApplied?: boolean,
  hasRowsSelectionApplied?: boolean,
  hasABCFilterApplied?: boolean,
  onClick?: () => void
}

const AbcClassificationSelectorItemComponent: React.FC<AbcClassificationSelectorItemComponentProps> = ({
  selected,
  loading,
  disabled,
  label,
  tooltip,
  hasFiltersApplied,
  hasRowsSelectionApplied,
  hasABCFilterApplied,
  onClick,
  x = 0,
  y = 0,
}) => {
  const intl = useIntl()
  const theme = useTheme()
  const formattedX = defaultNumberFormatter(x)
  const formattedY = defaultNumberFormatter(y)
  const numbersColor = selected ? theme.palette.new.white : theme.palette.new.black
  const numbersStyles = useMemo(() => ({
    fontSize: '12px',
    lineHeight: '12px',
    fontWeight: 400,
    opacity: 0.6,
    color: numbersColor,
  }), [numbersColor])

  const handleClick = useCallback(() => {
    if (disabled) {
      return
    }

    if (!selected && onClick) {
      onClick()
    }
  }, [selected, disabled, onClick])

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault()

      handleClick()
    }
  }

  const content = useMemo(() => {
    if ((hasFiltersApplied && hasRowsSelectionApplied) || ((!hasFiltersApplied && hasRowsSelectionApplied) && (x !== y))) {
      return intl.formatMessage({ id: 'insights.abc.countConstructor' }, {
        countStr: (
          <Typography
            component='span'
            sx={{
              ...numbersStyles,
              fontWeight: 500,
              opacity: selected ? 1 : 0.6,
            }}
          >
            {formattedX}
          </Typography>
        ),
        totalStr: (
          <Typography
            component='span'
            sx={numbersStyles}
          >
            {intl.formatMessage({ id: 'insights.abc.totalStr' }, { total: formattedY })}
          </Typography>
        ),
        pcsStr: (
          <Typography
            component='span'
            sx={{
              ...numbersStyles,
              letterSpacing: 0.05,
            }}
          >
            {intl.formatMessage({ id: 'insights.abc.pcsStr' })}
          </Typography>
        ),
      })
    }

    return intl.formatMessage({ id: 'insights.abc.countConstructorNoFilters' }, {
      countStr: (
        <Typography
          component='span'
          sx={{
            ...numbersStyles,
            fontWeight: 500,
            opacity: selected ? 1 : 0.6,
          }}
        >
          {hasABCFilterApplied && !hasFiltersApplied ? formattedY : formattedX}
        </Typography>
      ),
      pcsStr: (
        <Typography
          component='span'
          sx={{
            ...numbersStyles,
            letterSpacing: 0.05,
          }}
        >
          {intl.formatMessage({ id: 'insights.abc.pcsStr' })}
        </Typography>
      ),
    })
  }, [
    intl, formattedX, formattedY, selected, numbersStyles,
    x, y, hasABCFilterApplied, hasFiltersApplied, hasRowsSelectionApplied,
  ])

  return (
    <Box
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      role='button'
      tabIndex={0}
      data-testid={AbcClassificationSelectorItemComponent.name}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        borderRadius: '7px',
        padding: theme.spacing(1),
        cursor: (selected || disabled) ? 'default' : 'pointer',
        outline: 'none',
        background: selected ? PARETOS_GRADIENT : `${theme.palette.new.white}99`,
        width: '100%',
        height: '100%',
        minHeight: '82px',
        maxHeight: '82px',

        '&:hover': {
          background: selected ? PARETOS_GRADIENT : theme.palette.new.white,
        },
        '&:focus-visible': {
          background: selected ? PARETOS_GRADIENT : theme.palette.new.white,
        },
      }}
    >
      <Box
        display='flex'
        flexDirection='row'
        justifyContent='space-between'
        alignItems='center'
        width='100%'
      >
        <Typography
          fontSize='16px'
          lineHeight='16px'
          fontWeight={500}
          letterSpacing={-0.02}
          sx={{
            color: selected ? theme.palette.new.white : theme.palette.new.black,
          }}
        >
          {label}
        </Typography>

        {
          tooltip ? (
            <InfoButtonComponent
              name='abcSelectorTooltip'
              tooltip={tooltip}
              IconComponentProps={{
                detailsFill: selected ? theme.palette.new.white : theme.palette.new.black,
              }}
              contentSx={{
                display: 'inline-block',
              }}
            />
          ) : (
            <Box />
          )
        }
      </Box>

      <Box
        component='span'
        lineHeight='12px'
        sx={{
          ...(loading ? { filter: 'blur(5px)' } : {}),
        }}
      >
        {content}
      </Box>
    </Box>
  )
}

export default AbcClassificationSelectorItemComponent
