import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  handle: {

  },
  handleLeft: {
    '&.react-flow__handle': {
      border: 'none',
      width: theme.spacing(1),
      height: theme.spacing(1),
      backgroundColor: 'transparent',
    },
  },
  handleRight: {
    '&.react-flow__handle': {
      border: 'none',
      right: theme.spacing(0),
      width: theme.spacing(1),
      height: theme.spacing(1),
      backgroundColor: theme.palette.new.grey_c,
    },
  },
  invisibleDots: {
    '&.react-flow__handle': {
      backgroundColor: 'transparent',
    },
  },
}))

export default useStyles
