import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

type allowedClasses = 'itemName' | 'itemDate' | 'itemType'

const useStyles = makeStyles<void, allowedClasses>()((theme: Theme, props, classes) => ({
  itemsListWrapper: {
    position: 'relative',
    width: '100%',
  },
  itemsList: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(0),
    width: 'calc(100% + 80px)',
    position: 'absolute',
    left: '-40px',
    top: '-20px',

    '&:last-child': {
      borderBottom: `1px solid ${theme.palette.new.grey_a}`,
    },
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: theme.spacing(6),
    padding: theme.spacing(0, 4),
    borderTop: `1px solid ${theme.palette.new.grey_a}`,
    '&:hover': {
      backgroundColor: theme.palette.new.grey_d,
    },
    '&:first-of-type': {
      borderTop: 'none',
      [`& .${classes.itemName}`]: {
        color: theme.palette.new.black,
      },
      [`& .${classes.itemDate}`]: {
        color: theme.palette.new.black,
      },
      [`& .${classes.itemType}`]: {
        color: theme.palette.new.black,
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  itemName: {
    fontSize: '18px',
    color: theme.palette.new.black,
    width: '400px',
  },
  itemType: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',
    color: theme.palette.new.grey_c,
  },
  itemDate: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',
    color: theme.palette.new.grey_c,
  },
  itemsListEmpty: {
    display: 'none',
  },
  itemButton: {
    display: 'flex',
    cursor: 'pointer',
    marginLeft: theme.spacing(3),
    padding: theme.spacing(0),
    '& svg': {
      '&:hover': {
        opacity: 0.6,
      },
    },
  },
  itemButtonDisabled: {
    cursor: 'not-allowed',
  },
  itemDateHeaderMargin: {
    marginRight: theme.spacing(5),
  },
}))

export default useStyles
