import { IntlShape } from 'react-intl'
import { Edge, Node, Position } from '@xyflow/react'

import {
  NODE_TYPES,
  NODES_HEIGHT,
  NODES_WIDTHS,
  MAX_SOURCES_TO_SHOW,
  INPUT_SOURCE_BLOCK_HEIGHT,
  INPUT_SOURCE_BLOCK_HEIGHT_MARGIN,
  OUTPUT_PARAMETER_BLOCK_WIDTHS,
  OUTPUT_PARAMETER_BLOCK_HEIGHT,
  OUTPUT_PARAMETER_BLOCK_HEIGHT_MARGIN,
  OUTPUT_PARAMETER_BLOCK_WIDTHS_MARGIN,
  GROUPING_ATTRIBUTES_BLOCK_HEIGHT,
  GROUPING_ATTRIBUTES_BLOCK_WIDTHS,
  GROUPING_ATTRIBUTES_BLOCK_WIDTHS_MARGIN,
  GROUPING_ATTRIBUTES_BLOCK_HEIGHT_MARGIN,
  GROUPING_ATTRIBUTES_PER_ROW,
  INPUT_PARAMETER_BLOCK_HEIGHT,
  INPUT_PARAMETER_BLOCK_WIDTHS,
  INPUT_PARAMETER_BLOCK_WIDTHS_MARGIN,
  INPUT_PARAMETER_BLOCK_HEIGHT_MARGIN,
  INPUT_PARAMETER_PER_ROW,
  TARGET_PARAMETER_PER_ROW,
  SOURCE_TYPES_TO_ICONS_MAP,
  SOURCE_TYPES_OPTIONS,
  CATEGORIES_TO_LABEL_MAP,
  SOURCE_TYPES,
  SourceTypeOption,
  NodeTypeValues,
} from '@constants/flow.constants'

import {
  AUTH_DATA_SOURCE_MODAL_NAME,
} from '@constants/modals.constants'

export const DIRECTIONS = {
  LR: 'LR',
  TB: 'TB',
}

export const getMinimumItemCount = (numOfItems?: number): number => {
  return (!numOfItems || (numOfItems <= 1)) ? 1 : numOfItems
}

export const getTargetsGroupHeigth = (numOfNodes?: number): number => {
  const minNodesPresent = getMinimumItemCount(numOfNodes)
  const numOfNodesWrapped = (minNodesPresent > TARGET_PARAMETER_PER_ROW) ? TARGET_PARAMETER_PER_ROW : minNodesPresent

  return (NODES_HEIGHT[NODE_TYPES.TARGETS_GROUP] + (numOfNodesWrapped * OUTPUT_PARAMETER_BLOCK_HEIGHT) + ((numOfNodesWrapped - 1) * OUTPUT_PARAMETER_BLOCK_HEIGHT_MARGIN))
}

export const getTargetsGroupWidth = (numOfNodes?: number): number => {
  const minNodesPresent = getMinimumItemCount(numOfNodes)
  const numberOfCols = Math.ceil(minNodesPresent / TARGET_PARAMETER_PER_ROW)

  return (NODES_WIDTHS[NODE_TYPES.TARGETS_GROUP] + ((numberOfCols - 1) * OUTPUT_PARAMETER_BLOCK_WIDTHS) + ((numberOfCols - 1) * OUTPUT_PARAMETER_BLOCK_WIDTHS_MARGIN))
}

export const getGroupingAttributesHeigth = (numOfNodes?: number): number => {
  const minNodesPresent = getMinimumItemCount(numOfNodes)
  const numOfNodesWrapped = (minNodesPresent > GROUPING_ATTRIBUTES_PER_ROW) ? GROUPING_ATTRIBUTES_PER_ROW : minNodesPresent

  return (NODES_HEIGHT[NODE_TYPES.TARGETS_GROUP] + (numOfNodesWrapped * GROUPING_ATTRIBUTES_BLOCK_HEIGHT) + ((numOfNodesWrapped - 1) * GROUPING_ATTRIBUTES_BLOCK_HEIGHT_MARGIN))
}

export const getGroupingAttributesWidth = (numOfNodes?: number): number => {
  const minNodesPresent = getMinimumItemCount(numOfNodes)
  const numberOfCols = Math.ceil(minNodesPresent / GROUPING_ATTRIBUTES_PER_ROW)

  return (NODES_WIDTHS[NODE_TYPES.TARGETS_GROUP] + ((numberOfCols - 1) * GROUPING_ATTRIBUTES_BLOCK_WIDTHS) + ((numberOfCols - 1) * GROUPING_ATTRIBUTES_BLOCK_WIDTHS_MARGIN))
}

export const getInputsGroupHeight = (numOfNodes?: number): number => {
  const minNodesPresent = getMinimumItemCount(numOfNodes)
  const numberOfRows = Math.ceil(minNodesPresent / INPUT_PARAMETER_PER_ROW)

  return (NODES_HEIGHT[NODE_TYPES.INPUTS_GROUP] + (numberOfRows * INPUT_PARAMETER_BLOCK_HEIGHT) + ((numberOfRows - 1) * INPUT_PARAMETER_BLOCK_HEIGHT_MARGIN))
}

export const getInputsGroupWidth = (numOfNodes?: number): number => {
  const minNodesPresent = getMinimumItemCount(numOfNodes)
  const numOfNodesWrapped = (minNodesPresent > INPUT_PARAMETER_PER_ROW) ? INPUT_PARAMETER_PER_ROW : minNodesPresent

  return (NODES_WIDTHS[NODE_TYPES.INPUTS_GROUP] + (numOfNodesWrapped * INPUT_PARAMETER_BLOCK_WIDTHS) + ((numOfNodesWrapped - 1) * INPUT_PARAMETER_BLOCK_WIDTHS_MARGIN))
}

export const getSourceGroupHeight = (numOfSources?: number): number => {
  /**
   * In order to follow the design, we show only MAX_SOURCES_TO_SHOW blocks.
   * When there more source blocks we show - More block which has unusual height.
   */
  const minSourcesPresent = getMinimumItemCount(numOfSources)
  const shouldShowShowMoreBlock = (minSourcesPresent > MAX_SOURCES_TO_SHOW)
  const numOfSourcesSplitted = shouldShowShowMoreBlock ? (MAX_SOURCES_TO_SHOW + 2) : minSourcesPresent
  const heightDifference = shouldShowShowMoreBlock ? -38 : 0

  return (NODES_HEIGHT[NODE_TYPES.INPUT] + ((numOfSourcesSplitted + 1) * INPUT_SOURCE_BLOCK_HEIGHT) + INPUT_SOURCE_BLOCK_HEIGHT_MARGIN) + heightDifference
}

export function getLayoutedElements<T extends Common.ReactFlowElementData>(
  dagre: any,
  dagreGraph: any,
  nodes: Node<T>[],
  edges: Edge[],
  direction = DIRECTIONS.LR,
) {
  const isHorizontal = direction === DIRECTIONS.LR

  dagreGraph.setGraph({ rankdir: direction })

  const maxNumOfNodes = Math.max(...nodes.filter((element) => {
    return [NODE_TYPES.ACTIVE_INPUTS_GROUP, NODE_TYPES.GENERIC_INPUTS_GROUP, NODE_TYPES.INPUTS_GROUP].includes(element.type as any)
  }).map((element) => {
    const elData = element.data as UseCase.ConnectOverviewElementData

    return elData.numOfNodes!
  }))

  nodes.forEach((el) => {
    const connectViewData = el.data as UseCase.ConnectOverviewElementData

    switch (el.type) {
      case NODE_TYPES.INPUT:
        dagreGraph.setNode(el.id, { width: NODES_WIDTHS[el.type], height: getSourceGroupHeight(connectViewData.numOfSources) })
        break
      case NODE_TYPES.ACTIVE_INPUTS_GROUP:
      case NODE_TYPES.GENERIC_INPUTS_GROUP:
      case NODE_TYPES.INPUTS_GROUP:
        dagreGraph.setNode(el.id, { width: getInputsGroupWidth(connectViewData.numOfNodes), height: getInputsGroupHeight(connectViewData.numOfNodes) })
        break
      case NODE_TYPES.TARGETS_GROUP:
        dagreGraph.setNode(el.id, { width: getTargetsGroupWidth(connectViewData.numOfNodes), height: getTargetsGroupHeigth(connectViewData.numOfNodes) })
        break
      case NODE_TYPES.GROUPING_ATTRIBUTES:
        dagreGraph.setNode(el.id, { width: getGroupingAttributesWidth(connectViewData.numOfNodes), height: getGroupingAttributesHeigth(connectViewData.numOfNodes) })
        break
      default:
        dagreGraph.setNode(el.id, { width: NODES_WIDTHS[el.type as NodeTypeValues], height: NODES_HEIGHT[el.type as NodeTypeValues] })
        break
    }
  })

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target)
  })

  dagre.layout(dagreGraph)

  const layoutedNodes = nodes.map((node) => {
    const el = { ...node }
    const connectViewData = el.data as UseCase.ConnectOverviewElementData

    const nodeWithPosition = dagreGraph.node(el.id)
    const defaultHeight = 200

    let nodeY = nodeWithPosition.y
    let nodeX = nodeWithPosition.x + Math.random() / 1000

    el.targetPosition = (isHorizontal ? 'left' : 'top') as Position
    el.sourcePosition = (isHorizontal ? 'right' : 'bottom') as Position

    switch (el.type) {
      case NODE_TYPES.INPUT:
        nodeX = nodeWithPosition.x + 20
        nodeY = nodeWithPosition.y - (getSourceGroupHeight(connectViewData.numOfSources) / 2) + 100
        break
      case NODE_TYPES.EMPTY_GROUPING:
        nodeX = nodeWithPosition.x + 180
        nodeY = nodeWithPosition.y + ((defaultHeight - NODES_HEIGHT[el.type]) / 2)
        break
      case NODE_TYPES.ACTIVE_INPUTS_GROUP:
      case NODE_TYPES.GENERIC_INPUTS_GROUP:
      case NODE_TYPES.INPUTS_GROUP:
        nodeX = nodeWithPosition.x - (getInputsGroupWidth(maxNumOfNodes) / 2) + 240
        nodeY = nodeWithPosition.y - (getInputsGroupHeight(connectViewData.numOfNodes) / 2) + 100
        break
      case NODE_TYPES.TARGETS_GROUP:
        nodeX = nodeWithPosition.x - (getTargetsGroupWidth(connectViewData.numOfNodes) / 2) + 265
        nodeY = nodeWithPosition.y - (getTargetsGroupHeigth(connectViewData.numOfNodes) / 2) + 100
        break
      case NODE_TYPES.GROUPING_ATTRIBUTES:
        nodeX = nodeWithPosition.x - (getGroupingAttributesWidth(connectViewData.numOfNodes) / 2) + 230
        nodeY = nodeWithPosition.y - (getGroupingAttributesHeigth(connectViewData.numOfNodes) / 2) + 100
        break
      default:
        nodeX = nodeWithPosition.x + 140
        nodeY = nodeWithPosition.y + ((defaultHeight - NODES_HEIGHT[el.type as NodeTypeValues]) / 2)
        break
    }

    // unfortunately we need this little hack to pass a slighltiy different position
    // in order to notify react flow about the change
    el.position = {
      x: nodeX,
      y: nodeY,
    }

    return el
  })

  return {
    nodes: layoutedNodes,
    edges,
  }
}

export interface MappedSources {
  sourceId?: string,
  key: string,
  title: string,
  description: string,
  help: string,
  statusText: string | null,
  icon?: any,
  documentationLink?: string,
  videoLink?: string,
  videoHeight?: string,
  category: string,
  categoryLabel: string,
  disabled: boolean,
  onChange?: any,
}

export const matchSourcesToApi = (
  intl: IntlShape,
  isAdmin: boolean,
  {
    connectionsList = [],
    fileVersionsCount = 0,
    useCaseFileIdentifiersCount = 0,
    handleCustomSourceClick,
    handleStaticSourceClick,
  } : {
    connectionsList: Hermes.ConnectionDetails[],
    fileVersionsCount: number,
    useCaseFileIdentifiersCount: number,
    handleCustomSourceClick: Function,
    handleStaticSourceClick: Function,
  },
): MappedSources[] => {
  return SOURCE_TYPES_OPTIONS.filter((item) => !item.hidden).map((item) => {
    const {
      helpKey,
      labelKey,
      descriptionKey,
      documentationLink,
      videoLink,
      value,
      sourceId,
      modalName,
      videoHeight,
      category,
    } = item

    const isCustomFiles = value === SOURCE_TYPES.CUSTOM
    const isParetos = value === SOURCE_TYPES.PARETOS
    const isHermesConnector = Boolean(sourceId)
    const activeConnectionsBySourceList = isHermesConnector ?
      connectionsList.filter((connection) => (connection.isConnectedToUseCase && (connection.sourceId === sourceId))) :
      []
    const allConnectionsBySourceList = isHermesConnector ?
      connectionsList.filter((connection) => (connection.sourceId === sourceId)) :
      []

    const allConnectionsCount = allConnectionsBySourceList.length
    const activeConnectionsCount = activeConnectionsBySourceList.length

    const customOverrite = isCustomFiles ? {
      statusText: intl.formatMessage({ id: 'connect.modal.data_sources.custom_files' }, { fileVersionsCount }),
    } : {}

    const hermesOverrite = isHermesConnector ? {
      statusText: activeConnectionsCount ? (
        intl.formatMessage({ id: 'connect.modal.data_sources.connections_count' }, {
          connectionsCount: activeConnectionsCount,
        })
      ) : (
        null
      ),
      sourceId,
    } : {}

    const sourceItems = {
      key: value,
      title: labelKey ? intl.formatMessage({ id: labelKey }) : '',
      description: descriptionKey ? intl.formatMessage({ id: descriptionKey }) : '',
      help: helpKey ? intl.formatMessage({ id: helpKey }) : '',
      statusText: '',
      icon: SOURCE_TYPES_TO_ICONS_MAP[value],
      documentationLink,
      videoLink,
      videoHeight,
      category,
      disabled: (useCaseFileIdentifiersCount === 0 && isCustomFiles && !isAdmin),
      categoryLabel: CATEGORIES_TO_LABEL_MAP[category] ? intl.formatMessage({ id: CATEGORIES_TO_LABEL_MAP[category] }) : '',
      ...customOverrite,
      ...hermesOverrite,
    }

    const isDymamicConnector = (isCustomFiles || isHermesConnector || isParetos)
    const onChangeHandler = isDymamicConnector ? (
      () => {
        if (isHermesConnector && allConnectionsCount === 0) {
          return handleCustomSourceClick(AUTH_DATA_SOURCE_MODAL_NAME, sourceItems)
        }

        return handleCustomSourceClick(modalName, sourceItems)
      }
    ) : (
      handleStaticSourceClick(value)
    )

    return {
      ...sourceItems,
      onChange: onChangeHandler,
    }
  })
}

export interface SourceTypeOptionExtended extends SourceTypeOption {
  isDisabled: boolean,
}

export const enrichSourceTypeOptions = (
  item: SourceTypeOption,
  connectionsList: Hermes.ConnectionDetails[],
  trainingFilesCount: number,
  isAdmin: boolean,
): SourceTypeOptionExtended => {
  const isNoConnection = item.value === SOURCE_TYPES.NO_CONNECTION
  const isFileUploadConnected = (item.value === SOURCE_TYPES.CUSTOM) && trainingFilesCount > 0
  const isHermesConnected = (connectionsList.filter((hermesItem) => {
    return ((hermesItem.sourceId === item.sourceId) && hermesItem.isConnectedToUseCase)
  }) || []).length > 0

  return {
    isDisabled: !(isNoConnection || isFileUploadConnected || isHermesConnected) && !isAdmin,
    ...item,
  }
}
