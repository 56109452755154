import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import { Grid, Card } from '@mui/material'

import ToggleButtonComponent from '@base/pagebar/ToggleButton'
import DropdownMenuComponent from '@base/dropdowns/DropdownMenu'

import TradeOffsChartComponent from '@components/(features)/trade-offs/trade-offs-chart'
import TradeOffsConfigurationComponent from '@components/(features)/trade-offs/configuration'
import { MAX_SELECTED_POINTS } from '@constants/analysis.constants'
import TradeOffsLegendComponent from '@components/(features)/trade-offs/trade-offs-legend'
import TradeOffsPanelComponent from '@components/(features)/trade-offs/trade-offs-panel'
import TradeOffsOptimizePanelComponent from '@components/(features)/trade-offs/trade-offs-optimize-panel'
import useStyles from './TradeOffsLayout.styles'

export interface TradeOffsLayoutComponentProps {
  exportId?: string,
  dataSet?: Socrates.TradeOffsDatasetItem[],
  configuration: Socrates.TradeOffsConfiguration,
  kpisOptions: Socrates.KpiOption[],
  isDefaultConfig?: boolean,
  optimizeEnabled?: boolean,
  selectedPoints: Socrates.TradeOffsDatasetItem[],
  showSuboptimalSolutions?: boolean,
  tradeOffsComparisonMeta?: Socrates.TradeOffsComparisonMetaItem[],
  selectTradeOffsPoint(payload: Socrates.SelectedTradeOffsPoint): any,
  handleConfigurationChange: Socrates.HandleConfigurationChange,
  handleResetClick(): any,
  handleDownloadSelection: {
    (index: number): any,
  },
}

const TradeOffsLayoutComponent: React.FC<TradeOffsLayoutComponentProps> = (props) => {
  const {
    exportId,
    dataSet,
    configuration = {} as Socrates.TradeOffsConfiguration,
    isDefaultConfig,
    selectTradeOffsPoint,
    kpisOptions,
    selectedPoints,
    handleConfigurationChange,
    handleResetClick,
    showSuboptimalSolutions,
    tradeOffsComparisonMeta,
    handleDownloadSelection,
    optimizeEnabled,
  } = props

  const { classes } = useStyles()
  const intl = useIntl()
  const [solutionToExecute, setSolutionToExecute] = useState<number | null>(null)

  const handleSelect = (data: {
    payload: Socrates.TradeOffsDatasetItem,
  }) => {
    if (optimizeEnabled) {
      selectTradeOffsPoint({
        payload: {
          ...data.payload,
        },
        select: true,
        decision: true,
        deselect: false,
        maxItems: 1,
      })

      setSolutionToExecute(0)
    } else {
      selectTradeOffsPoint({
        payload: {
          ...data.payload,
        },
        select: true,
        decision: true,
        deselect: true,
        maxItems: MAX_SELECTED_POINTS,
      })

      setSolutionToExecute(null)
    }
  }

  const handleReset = () => {
    handleResetClick()
    setSolutionToExecute(null)
  }

  return (
    <Card id={exportId} elevation={0} className={classes.root} data-testid={TradeOffsLayoutComponent.name}>
      <Grid container={true} spacing={0}>
        <Grid item={true} xs={8} className={classes.leftContainer}>
          <div className={classes.chartHeader}>
            <div className={classes.xAxisSelector}>
              <DropdownMenuComponent
                name='xAxisSelector'
                overline={intl.formatMessage({ id: 'trade_offs.chart.pareto.x' })}
                options={kpisOptions}
                value={configuration.AXIS.x}
                onChange={(value) => handleConfigurationChange('AXIS', 'x', value as any)}
              />
            </div>
            <div className={classes.yAxisSelector}>
              <DropdownMenuComponent
                name='xAxisSelector'
                overline={intl.formatMessage({ id: 'trade_offs.chart.pareto.y' })}
                options={kpisOptions}
                value={configuration.AXIS.y}
                onChange={(value) => handleConfigurationChange('AXIS', 'y', value as any)}
              />
            </div>
          </div>
          <div className={classes.sectionChart}>
            <TradeOffsChartComponent
              dataset={dataSet}
              isDefaultConfig={isDefaultConfig}
              handleSelect={handleSelect}
              xAxisLabel={configuration.AXIS.x}
              yAxisLabel={configuration.AXIS.y}
              optimizeEnabled={optimizeEnabled}
            />
          </div>
          <div className={classes.optimumContainer}>
            <TradeOffsLegendComponent
              optimizeEnabled={optimizeEnabled}
              showSuboptimalSolutions={showSuboptimalSolutions}
            />
            <div className={classes.toggleContainer}>
              <ToggleButtonComponent
                label={intl.formatMessage({ id: 'trade_offs.chart.pareto.toggle' })}
                onChange={() => handleConfigurationChange('SUBOPTIMAL', 'enabled', (!showSuboptimalSolutions) as any)}
                name='showSuboptimalSolutions'
                value={showSuboptimalSolutions}
                checked={showSuboptimalSolutions}
                transparent={true}
              />
            </div>
          </div>
          <TradeOffsConfigurationComponent
            kpisOptions={kpisOptions}
            configuration={configuration}
            handleChange={handleConfigurationChange}
            optimizeEnabled={optimizeEnabled}
            handleReset={handleReset}
          />
        </Grid>
        {
          optimizeEnabled ? (
            <TradeOffsOptimizePanelComponent
              selectedPoints={selectedPoints}
              solutionToExecute={solutionToExecute}
            />
          ) : (
            <TradeOffsPanelComponent
              tradeOffsComparisonMeta={tradeOffsComparisonMeta}
              selectedPoints={selectedPoints}
              handleDownloadSelection={handleDownloadSelection}
              solutionToExecute={solutionToExecute}
              setSolutionToExecute={setSolutionToExecute}
              selectTradeOffsPoint={selectTradeOffsPoint}
            />
          )
        }
      </Grid>
    </Card>
  )
}

TradeOffsLayoutComponent.propTypes = {
  /** ID of container */
  exportId: PropTypes.string,
  /** KPI options */
  kpisOptions: PropTypes.any,
  /** List of selected poits */
  selectedPoints: PropTypes.any,
  /** Chart dataSet */
  dataSet: PropTypes.array,
  /** Config object */
  configuration: PropTypes.any,
  /** handle select of trade offs point */
  selectTradeOffsPoint: PropTypes.any,
  /** if 'true', default config is used */
  isDefaultConfig: PropTypes.bool,
  /** Handle configuration change */
  handleConfigurationChange: PropTypes.any,
  /** Handle reset click */
  handleResetClick: PropTypes.any,
  /** if 'true', suboptimal solutions are shown */
  showSuboptimalSolutions: PropTypes.bool,
  /** Trade-offs configuration data object */
  tradeOffsComparisonMeta: PropTypes.array,
  /** Handles download selected point */
  handleDownloadSelection: PropTypes.any,
  /** If true, indicates that optimize view is enabled  */
  optimizeEnabled: PropTypes.bool,
}

TradeOffsLayoutComponent.defaultProps = {
  exportId: '',
  kpisOptions: [],
  selectedPoints: [],
  dataSet: [],
  tradeOffsComparisonMeta: [],
}

export default TradeOffsLayoutComponent
