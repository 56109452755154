import React from 'react'
import { IntlShape } from 'react-intl'
import classNames from 'classnames'

import {
  TableRow,
  TableCell,
  Typography,
  TableHead,
  Box,
} from '@mui/material'

import { createId } from '@utils/common.utils'
import { ASC_SORTING_ORDER } from '@constants/filters.constants'

import ArrowUpIcon from '@icons/arrowUp.icon'
import ArrowDownIcon from '@icons/arrowDown.icon'
import InfoButtonComponent from '@base/buttons/InfoButton'

import { TableComponentProps } from '@components/_tables/table/Table.component'

type ITableBodyPropsFields = 'withTitles' | 'withAligment' | 'fixedWidth' | 'tableColumns' | 'classes' | 'isFetching' | 'overlayFetching'

interface ITableHeaderProps extends Pick<TableComponentProps<IntlShape>, ITableBodyPropsFields> {
  tableCellClassName?: string,
  handleSorting: (key: string) => void
  anyColHasHeader?: boolean
  paginationConfig: Common.PaginationConfig
}

export const TableHeaderComponent: React.FC<ITableHeaderProps> = (props) => {
  const {
    withAligment,
    withTitles,
    fixedWidth,
    classes: classesProp,
    tableColumns,
    isFetching,
    tableCellClassName,
    handleSorting,
    paginationConfig,
  } = props

  const classes = {
    ...classesProp,
  }

  const {
    sortingDirection,
    sortingKey,
  } = paginationConfig

  const upSortingOpacity = sortingDirection === ASC_SORTING_ORDER ? '1' : '0.2'
  const downSortingOpacity = sortingDirection === ASC_SORTING_ORDER ? '0.2' : '1'
  const anyColHasHeader = tableColumns?.some((column) => column.header)

  return (
    <TableHead data-testid={TableHeaderComponent.name}>
      <TableRow>
        {
          tableColumns?.map((column, index) => {
            const tableRowWidth = (fixedWidth && column.width) ? { width: column.width } : {}
            const tableRowTitle = (withTitles && column.label) ? { title: column.label } : {}
            const isSortingActive = column.sorting && (column.key === sortingKey)

            return (
              <TableCell
                key={createId(column.key, index)}
                className={classNames(tableCellClassName, {
                  [classes.sorting]: column.sorting,
                  [classes.sortingActive]: isSortingActive,
                  [classes.rightBorder]: column.rightBorder,
                })}
                align={column.alignment || (withAligment && (index === 0) ? 'left' : 'right')}
                onClick={(column.sorting && !isFetching) ? () => handleSorting(column.key) : undefined}
                {...tableRowTitle}
                {...tableRowWidth}
              >
                <Box
                  className={classNames(classes.th, {
                    [classes.thLeft]: withAligment && column.alignment === 'left',
                    [classes.thRight]: withAligment && column.alignment === 'right',
                    [classes.sortingActive]: isSortingActive,
                    [classes.withTooltip]: Boolean(column.tooltip),
                  })}
                >
                  {
                    column.tooltip ? (
                      <InfoButtonComponent
                        name='tableHeaderTooltip'
                        tooltip={column.tooltip}
                        placement='top'
                        sx={{ mr: 1 }}
                      />
                    ) : (
                      null
                    )
                  }
                  <Box
                    className={classNames({
                      [classes.withHeader]: Boolean(column.header),
                    })}
                  >
                    {
                      column.header ? (
                        <Typography
                          className={classNames(classes.columnHeader, {
                            [classes.sortingActive]: isSortingActive,
                          })}
                        >
                          {column.header}
                        </Typography>
                      ) : (
                        anyColHasHeader ? 'ㅤ' : null
                      )
                    }
                    <Box component='span' className={classes.columnLabel}>
                      <Typography className={classes.columnLabelText}>
                        {column.label}
                      </Typography>

                      {
                        column.sorting ? (
                          <Box className={classes.sortingIcons}>
                            <ArrowUpIcon opacity={isSortingActive ? upSortingOpacity : '0.2'} />
                            <ArrowDownIcon opacity={isSortingActive ? downSortingOpacity : '0.2'} />
                          </Box>
                        ) : (
                          null
                        )
                      }
                    </Box>
                  </Box>
                </Box>
              </TableCell>
            )
          })
        }
      </TableRow>
    </TableHead>
  )
}

export default TableHeaderComponent
