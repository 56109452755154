import React, {
  useMemo,
} from 'react'

import { useIntl } from 'react-intl'
import { Box, Typography, useTheme } from '@mui/material'

import InsightsLineChartComponent from '@components/(features)/insights/insights-line-chart'
import LoadingFallbackComponent from '@base/loading/LoadingFallback'
import { DEFAULT_BORDER_RADIUS } from '@constants/ui.constants'

export interface InsightsChartComponentProps {
  gridState?: Hera.HeraBaseGridState
  isFetching?: boolean
  dataset: Hera.BaseChartDatasetItem[]
  lines: Hera.BaseChartLineItem[]
  annotations?: Hera.BaseChartAnnotationItem[]
  predictionKeyPrefix: string
  absDeviationKeyPrefix?: string
  targetName: string
  targetUnit: string
}

export const INSIGHT_CHART_HEIGHT = '460px'

const InsightsChartComponent: React.FC<InsightsChartComponentProps> = ({
  gridState,
  isFetching,
  dataset = [],
  lines = [],
  annotations = [],
  targetName,
  targetUnit,
  predictionKeyPrefix,
  absDeviationKeyPrefix,
}) => {
  const intl = useIntl()
  const theme = useTheme()

  const hasGroupingEnabled = useMemo(() => {
    return gridState && gridState.groupingModel && gridState.groupingModel.length > 0
  }, [gridState])

  const selectedRows = useMemo(() => {
    return hasGroupingEnabled && gridState ? (gridState.rowSelectionModel || []) : []
  }, [gridState, hasGroupingEnabled])

  const hasNoSelection = useMemo(() => {
    return ((gridState?.rowSelectionModel || []).length === 0) && (gridState?.rowSelectionModelMode === 'include')
  }, [gridState])

  const disableTodayLine = useMemo(() => {
    if (annotations && annotations.length > 0) {
      return true
    }

    return gridState && gridState.timeWindow && gridState.timeWindow[0] !== null && gridState.timeWindow[1] === null
  }, [gridState, annotations])

  const containerStyles = {
    display: 'flex',
    flexDirection: 'column',
    padding: 2,
    width: '100%',
    minHeight: INSIGHT_CHART_HEIGHT,
    borderRadius: '7px',
    border: `1px solid ${theme.palette.new.grey_b}`,
    marginBottom: theme.spacing(2),
  }

  const warpperStypes = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
  }

  if (hasNoSelection && !isFetching) {
    return (
      <Box
        data-testid={InsightsChartComponent.name}
        sx={{
          ...containerStyles,
          ...warpperStypes,
        }}
      >
        <Typography
          variant='h6'
        >
          {intl.formatMessage({ id: 'insights.chart.noSelection.title' })}
        </Typography>
        <Typography>
          {intl.formatMessage({ id: 'insights.chart.noSelection.text' })}
        </Typography>
      </Box>
    )
  }

  if ((!dataset || dataset.length === 0) && !isFetching && gridState) {
    return (
      <Box
        data-testid={InsightsChartComponent.name}
        sx={{
          ...containerStyles,
          ...warpperStypes,
        }}
      >
        <Typography
          variant='h6'
        >
          {intl.formatMessage({ id: 'insights.chart.noData' })}
        </Typography>
      </Box>
    )
  }

  if ((isFetching || !gridState) && ((lines.length === 0) || (!dataset || dataset.length === 0))) {
    return (
      <Box
        data-testid={InsightsChartComponent.name}
        sx={{
          width: '100%',
          height: INSIGHT_CHART_HEIGHT,
          borderRadius: DEFAULT_BORDER_RADIUS.DEFAULT,
          border: `1px solid ${theme.palette.new.grey_b}`,
          marginBottom: theme.spacing(2),
          overflow: 'hidden',
        }}
      >
        <LoadingFallbackComponent fluid={true} size='medium' />
      </Box>
    )
  }

  return (
    <Box
      data-testid={InsightsChartComponent.name}
      sx={containerStyles}
    >
      <InsightsLineChartComponent
        dataset={dataset}
        lines={lines}
        targetName={targetName}
        targetUnit={targetUnit}
        hasGroupingEnabled={hasGroupingEnabled}
        isFetching={isFetching}
        enableTodayLine={!disableTodayLine}
        selectedRows={selectedRows}
        annotations={annotations}
        predictionKeyPrefix={predictionKeyPrefix}
        absDeviationKeyPrefix={absDeviationKeyPrefix}
      />
    </Box>
  )
}

export default InsightsChartComponent
