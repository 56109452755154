import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { Location } from 'history'

export const ERR_CANCELED_CODE = 'ERR_CANCELED'
export const REQUEST_CANCELED_BY_USER = 'Canceled by user'
export const CANCELED_REQUEST_ERROR = 'Canceled request via changing route'
export const CANCEL_SIGNAL = 'signal'

let controller = new AbortController()
let prevPathname = window.location.pathname || null
let prevParams = window.location.search || null

/**
 * Filters list to skip cancellation interceptor.
 * List item is a function returning Boolean to indicate,
 * that request shouldn't be attached with CancelToken
 *
 * @type {(function(*): (boolean|*))[]}
 */
const unCancelableFilterList = [
  (req: AxiosRequestConfig) => req?.url?.includes('/useCase/show'),
  (req: AxiosRequestConfig) => req?.url?.includes('/useCase/get'),
  (req: AxiosRequestConfig) => req?.url?.includes('/composite-pipeline/run-status'),
]

/**
 * @function isRequestCancellable Check if request can be cancelled
 *
 * @param {Object} req Axios request
 *
 * @return {Boolean} Checks whether request is cancellable
 */
export const isRequestCancellable = (req: AxiosRequestConfig) => {
  /* TODO: remove false when this feature is required */
  return false && !unCancelableFilterList.some((fn) => {
    return fn(req)
  })
}

/**
 * @function isReqCanceled Check if request is cancelled
 *
 * @param {Object} req Axios request
 *
 * @return {Boolean} Checks whether request is cancelled
 */
export const isReqCanceled = (req: AxiosRequestConfig) => {
  return req[CANCEL_SIGNAL] && req[CANCEL_SIGNAL].aborted
}

/**
 * @function cancelPendingRequests Cancel requests if browserHistory location changes
 *
 * @param {String} pathname Location's pathname
 * @param {String} search Location's search
 *
 * @return {Void}
 */
export const cancelPendingRequests = ({ pathname, search }: Location) => {
  if (prevPathname !== pathname || pathname === undefined || prevParams !== search) {
    controller.abort(CANCELED_REQUEST_ERROR)
    // @ts-ignore
    // eslint-disable-next-line
    controller = new AbortController()
  }

  prevPathname = pathname
  prevParams = search
}

/**
 * @function interceptRequestForCancellation Intercept request
 *
 * @param {Object} axiosInstance Axios Instance
 *
 * @return {Void}
 */
export const interceptRequestForCancellation = (axiosInstance: AxiosInstance = axios) => {
  axiosInstance.interceptors.request.use((req) => {
    return isRequestCancellable(req) && !isReqCanceled(req) ? {
      ...req,
      signal: controller.signal,
    } : req
  })
}
