export const START_FETCHING_ANALYZE = 'START_FETCHING_ANALYZE'
export const STOP_FETCHING_ANALYZE = 'STOP_FETCHING_ANALYZE'

export const REQUEST_ANALYZE_VIEW = 'REQUEST_ANALYZE_VIEW'
export const RESET_ANALYZE = 'RESET_ANALYZE'

export const REQUEST_ANALYZE_TABLE = 'REQUEST_ANALYZE_TABLE'
export const RECEIVE_ANALYZE_TABLE = 'RECEIVE_ANALYZE_TABLE'

export const REQUEST_ANALYZE_CHART = 'REQUEST_ANALYZE_CHART'
export const RECEIVE_ANALYZE_CHART = 'RECEIVE_ANALYZE_CHART'

export const REQUEST_ANALYZE_ABC_TOTALS = 'REQUEST_ANALYZE_ABC_TOTALS'
export const RECEIVE_ANALYZE_ABC_TOTALS = 'RECEIVE_ANALYZE_ABC_TOTALS'

export const REQUEST_ANALYZE_GRID_STATE_CHANGE = 'REQUEST_ANALYZE_GRID_STATE_CHANGE'
export const RECEIVE_ANALYZE_GRID_STATE_CHANGE = 'RECEIVE_ANALYZE_GRID_STATE_CHANGE'
