import React from 'react'
import { useIntl } from 'react-intl'
import { Typography } from '@mui/material'

import ButtonComponent from '@base/buttons/Button'
import SearchFieldComponent from '@base/forms/SearchField'

import useStyles from './OptimizeOverview.styles'

export interface OptimizeOverviewHeaderComponentProps {
  setSearchTerm: (searchTerm: string) => void,
  searchTerm: string,
  handleExport: () => void,
}

const OptimizeOverviewHeaderComponent: React.FC<OptimizeOverviewHeaderComponentProps> = (props) => {
  const {
    setSearchTerm,
    searchTerm,
    handleExport,
  } = props

  const { classes } = useStyles()
  const intl = useIntl()

  return (
    <div className={classes.tableHeader}>
      <Typography
        variant='h4'
      >
        {intl.formatMessage({ id: 'optimize.table.restockRecommendations' })}
      </Typography>

      <div className={classes.searchBlock}>
        <SearchFieldComponent
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          label={null}
          fullWidth={false}
        />
        <ButtonComponent
          name='exportButton'
          onClick={handleExport}
          disabled={false}
          color='highlighted'
          rounded={true}
          label={intl.formatMessage({ id: 'optimize.table.export' })}
        />
      </div>
    </div>
  )
}

export default OptimizeOverviewHeaderComponent
