import { getTextBackgroundFillStyles } from '@utils/ui.utils'
import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  modalSectionHeaderTitle: {
    fontSize: '30px',
    lineHeight: '35px',
    maxWidth: theme.spacing(50),
    marginBottom: theme.spacing(2),
    ...getTextBackgroundFillStyles(),
  },
  modalSectionHeaderText: {
    fontSize: '18px',
    lineHeight: '20px',
    color: theme.palette.new.black_b,
    maxWidth: theme.spacing(60),
    marginBottom: theme.spacing(6),
  },
  blockContainer: {
    marginBottom: theme.spacing(6),
    position: 'relative',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    '& > div': {
      marginTop: theme.spacing(0),
    },
  },
  connectorLine: {
    zIndex: 5,
  },
  autocompleteContainer: {
    width: '100%',
  },
}))

export default useStyles
