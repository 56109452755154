import produce from 'immer'
import { OptimizeState, ReducerPayload } from '@redux/modules/types'
import {
  REPLENISHMENT_COST_MEDIUM,
  REPLENISHMENT_COST_MIN_MAX,
  OPTIMIZE_RESTOCK_SELECTOR_TYPES,
  REVENUE_MEDIUM,
  REVENUE_MIN_MAX,
} from '@constants/optimize.constants'

import {
  SetStrategyPayload,
  SetTargetRevenuePayload,
  SetTargetReplenishmentCostPayload,
  PageDataReducerPayload,
} from './optimize.types'

export const initialState: OptimizeState = {
  isFetching: false,
  isFetchedList: false,
  isFetchedDrillDown: false,
  fetchingKey: '',
  drillDownData: {
    meta: {} as Optimize.OptimizeDrillDownItemMeta,
    data: [],
  } as Optimize.OptimizeDrillDownItem,
  tableData: [] as Optimize.OptimizeTableData,
  rangeSelector: {
    targetSelectorType: OPTIMIZE_RESTOCK_SELECTOR_TYPES.REVENUE,
    targetRevenueSelected: REVENUE_MEDIUM,
    targetRevenueMinMax: REVENUE_MIN_MAX,
    targeReplenishmentCostSelected: REPLENISHMENT_COST_MEDIUM,
    targeReplenishmentCostMinMax: REPLENISHMENT_COST_MIN_MAX,
  } as Optimize.OptimizeRangeSelectorData,
  predictionOverview: {} as Optimize.PredictionOverview,
}

export const receiveOptimizePageData = (state: OptimizeState, action: ReducerPayload<PageDataReducerPayload>) => {
  const nextState = produce(state, (draftState) => {
    draftState.predictionOverview = {
      ...action.payload.predictionOverview,
    }

    draftState.rangeSelector = {
      ...draftState.rangeSelector,
      ...action.payload.rangeSelector,
    }

    draftState.isFetchedList = true

    draftState.tableData = [
      ...action.payload.tableData,
    ]
  })

  return nextState
}

export const receiveOptimizeDrillDownData = (state: OptimizeState, action: ReducerPayload<Optimize.OptimizeDrillDownItem>) => {
  const nextState = produce(state, (draftState) => {
    draftState.drillDownData.meta = {
      ...action.payload.meta,
    }
    draftState.drillDownData.data = [
      ...action.payload.data,
    ]

    draftState.isFetchedDrillDown = true
  })

  return nextState
}

export const setTargetReplenishmentCostData = (state: OptimizeState, action: ReducerPayload<SetTargetReplenishmentCostPayload>) => {
  const nextState = produce(state, (draftState) => {
    draftState.rangeSelector.targeReplenishmentCostSelectedRange = [action.payload, action.payload]
    draftState.rangeSelector.targeReplenishmentCostSelected = action.payload
  })

  return nextState
}

export const setTargetRevenueData = (state: OptimizeState, action: ReducerPayload<SetTargetRevenuePayload>) => {
  const nextState = produce(state, (draftState) => {
    draftState.rangeSelector.targetRevenueSelectedRange = [action.payload, action.payload]
    draftState.rangeSelector.targetRevenueSelected = action.payload
  })

  return nextState
}

export const setStrategyData = (state: OptimizeState, action: ReducerPayload<SetStrategyPayload>) => {
  const nextState = produce(state, (draftState) => {
    draftState.rangeSelector.targetRevenueSelectedRange = [action.payload.targetRevenue, action.payload.targetRevenue]
    draftState.rangeSelector.targetRevenueSelected = action.payload.targetRevenue
    draftState.rangeSelector.targeReplenishmentCostSelectedRange = [action.payload.targetReplenishmentCost, action.payload.targetReplenishmentCost]
    draftState.rangeSelector.targeReplenishmentCostSelected = action.payload.targetReplenishmentCost
    draftState.rangeSelector.targetSelectorType = OPTIMIZE_RESTOCK_SELECTOR_TYPES.TRADE_OFFS
  })

  return nextState
}

export const setTargetSelectorType = (state: OptimizeState, action: ReducerPayload<OPTIMIZE_RESTOCK_SELECTOR_TYPES>) => {
  const nextState = produce(state, (draftState) => {
    draftState.rangeSelector.targetSelectorType = action.payload
  })

  return nextState
}

export const startFetching = (state: OptimizeState, action: ReducerPayload<string>) => {
  const nextState = produce(state, (draftState) => {
    draftState.isFetching = true
    draftState.fetchingKey = (!draftState.fetchingKey) ? action.payload : draftState.fetchingKey
  })

  return nextState
}

export const stopFetching = (state: OptimizeState, action: ReducerPayload<string>) => {
  const nextState = produce(state, (draftState) => {
    if (draftState.fetchingKey === action.payload) {
      draftState.fetchingKey = ''
      draftState.isFetching = false
    }
  })

  return nextState
}
