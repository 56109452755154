import React from 'react'
import { useSelector } from '@redux/hooks'
import { isFetchingBacktesting, getBacktestingChart, isFetchingBacktestingChart } from '@redux/modules/monitor/monitor.selectors'
import InsightsKpisComponent from '@components/(features)/insights/insights-kpis'

const BacktestingKpisContainer = () => {
  const backtestingChart = useSelector((state) => getBacktestingChart(state))
  const isFetchingBacktestingChartFlag = useSelector((state) => isFetchingBacktestingChart(state))
  const isFetchingBacktestingFlag = useSelector((state) => isFetchingBacktesting(state))

  const {
    targetName,
    overallNumbers = {
      absDeviation: 0,
      relDeviation: 0,
      target: 0,
      backtestPrediction: 0,
    },
  } = backtestingChart

  return (
    <InsightsKpisComponent
      loading={isFetchingBacktestingFlag}
      loadingValues={isFetchingBacktestingChartFlag}
      targetName={targetName}
      absDeviation={overallNumbers.absDeviation}
      relDeviation={overallNumbers.relDeviation}
      target={overallNumbers.target}
      prediction={overallNumbers.backtestPrediction}
    />
  )
}

export default BacktestingKpisContainer
