import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { useFormik } from 'formik'
import { Box, Typography } from '@mui/material'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  SidePanelLoadingComponent,
  ModalButtonComponent,
} from '@base/sidepanel/SidePanel'

import {
  FormLayoutContainer,
  FormLayoutItem,
  FormLayoutItemsContainer,
} from '@base/forms/FormLayout'

import TextFieldComponent from '@base/forms/TextField'
import SelectFieldComponent from '@base/forms/SelectField'
import ButtonComponent from '@base/buttons/Button'
import DeleteIcon from '@icons/delete.icon'

import { CreateArtifactsMappingsPayload } from '@redux/modules/use-case/use-case.types'
import { formatDate } from '@utils/moment.utils'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { DEFAULT_USE_CASE_ARTIFACTS_TYPE, USE_CASE_ARTIFACTS_TYPES_OPTIONS_LIST } from '@constants/use-cases.constants'
import { getModalDetails, getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { USE_CASE_ARTIFACTS_MAPPING_MODAL_NAME } from '@constants/modals.constants'

import {
  requestArtifactsMappingsAction,
  createArtifactsMappingsAction,
  deleteArtifactsMappingsAction,
} from '@redux/modules/use-case/use-case.actions'

import {
  getFullArtifactMappings, isDeletingArtifactUseCase,
  isFetchingUseCaseArtifactMappings, isSubmittingUseCase,
} from '@redux/modules/use-case/use-case.selectors'

import useStyles from './UseCaseArtifactMappingsModal.styles'
import validation from './UseCaseArtifactMappingsModal.validations'

export interface UseCaseArtifactMappingsModalDetails extends Common.ModalDetails {
  useCaseId: string
  useCaseName: string
}

export interface UseCaseArtifactMappingsModalContainerProps {}

const UseCaseArtifactMappingsModalContainer: React.FC<UseCaseArtifactMappingsModalContainerProps> = () => {
  const intl = useIntl()
  const { classes, cx } = useStyles()
  const dispatch = useDispatch()

  const artifactMappings = useSelector((state) => getFullArtifactMappings(state))
  const isFetching = useSelector((state) => isFetchingUseCaseArtifactMappings(state))
  const isSubmitting = useSelector((state) => isSubmittingUseCase(state))
  const isDeleting = useSelector((state) => isDeletingArtifactUseCase(state))
  const modalDetails = useSelector((state) => getModalDetails<UseCaseArtifactMappingsModalDetails>(state))
  const modalPageName = useSelector((state) => getOpenedModal(state))

  const open = modalPageName === USE_CASE_ARTIFACTS_MAPPING_MODAL_NAME
  const usecaseId = modalDetails.useCaseId
  const usecaseName = modalDetails.useCaseName

  const initialValues: CreateArtifactsMappingsPayload = {
    artifactType: DEFAULT_USE_CASE_ARTIFACTS_TYPE,
    useCaseId: usecaseId,
  } as CreateArtifactsMappingsPayload

  useEffect(() => {
    if (open && usecaseId) {
      dispatch(
        requestArtifactsMappingsAction({
          useCaseId: usecaseId,
        }),
      )
    }
  }, [dispatch, usecaseId, open])

  const handleCloseAction = (toggleModal = true) => {
    if (toggleModal) {
      dispatch(
        setPrimaryModalPageName(modalDetails.returnTo),
      )
    }
  }

  const handleSubmitAction = (values: CreateArtifactsMappingsPayload) => {
    const payload: CreateArtifactsMappingsPayload = {
      ...initialValues,
      ...values,
    }

    dispatch(
      createArtifactsMappingsAction(payload),
    )
  }

  const handleDeleteAction = (artifactId: string) => {
    dispatch(
      deleteArtifactsMappingsAction({
        useCaseId: usecaseId!,
        artifactId,
      }),
    )
  }

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    isValid,
    touched,
    values,
    dirty,
  } = useFormik({
    initialValues,
    onSubmit: handleSubmitAction,
    enableReinitialize: true,
    validationSchema: validation(intl),
  })

  useEffect(() => {
    if (!open) {
      resetForm()
    }
  }, [open, resetForm])

  return (
    <SidePanelComponent
      open={open}
      title={intl.formatMessage({ id: 'use_cases.artifacts.configuration' })}
      handleClose={handleCloseAction}
      hasUnsavedChanges={dirty || isSubmitting}
    >
      <SidePanelLoadingComponent loading={isFetching || !artifactMappings}>
        <Box component='form' onSubmit={handleSubmit}>
          <SidePanelCardComponent>
            <FormLayoutContainer>
              <FormLayoutItemsContainer
                title={intl.formatMessage({ id: 'use_cases.artifacts.general' })}
              >
                <FormLayoutItem xs={6}>
                  <TextFieldComponent
                    name='useCaseId'
                    value={usecaseId}
                    label={intl.formatMessage({ id: 'use_cases.form.id.title' })}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={true}
                  />
                </FormLayoutItem>
                <FormLayoutItem xs={6}>
                  <TextFieldComponent
                    name='name'
                    value={usecaseName}
                    label={intl.formatMessage({ id: 'use_cases.form.name.title' })}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={true}
                  />
                </FormLayoutItem>
              </FormLayoutItemsContainer>

              <FormLayoutItemsContainer
                title={intl.formatMessage({ id: 'use_cases.artifacts.create' })}
              >
                <FormLayoutItem xs={5}>
                  <TextFieldComponent
                    name='artifactId'
                    touched={touched.artifactId}
                    errors={errors.artifactId}
                    value={values.artifactId}
                    label={intl.formatMessage({ id: 'use_cases.artifacts.artifactId' })}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                </FormLayoutItem>
                <FormLayoutItem xs={5}>
                  <SelectFieldComponent
                    name='artifactType'
                    value={values.artifactType}
                    label={intl.formatMessage({ id: 'use_cases.artifacts.artifactType' })}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled={false}
                    labelKey='label'
                    options={USE_CASE_ARTIFACTS_TYPES_OPTIONS_LIST}
                  />
                </FormLayoutItem>
                <FormLayoutItem xs={2}>
                  <Box
                    display='flex'
                    height='100%'
                    alignItems='center'
                    mt='9px'
                  >
                    <ButtonComponent
                      name='addArtifactButton'
                      color='primary'
                      onClick={(e) => handleSubmitAction(values)}
                      loading={isSubmitting || isDeleting}
                      disabled={!isValid || !dirty}
                      label={intl.formatMessage({ id: 'use_cases.artifacts.add' })}
                    />
                  </Box>
                </FormLayoutItem>
              </FormLayoutItemsContainer>

              <Box
                className={classes.itemsListWrapper}
              >
                <Box
                  className={cx(classes.itemsList, {
                    [classes.itemsListEmpty]: !artifactMappings.length,
                  })}
                >
                  <Box
                    className={classes.listItem}
                  >
                    <Typography
                      className={classes.itemName}
                      noWrap={true}
                    >
                      {intl.formatMessage({ id: 'use_cases.artifacts.artifactId' })}
                    </Typography>
                    <Typography
                      className={classes.itemType}
                      noWrap={true}
                    >
                      {intl.formatMessage({ id: 'use_cases.artifacts.artifactType' })}
                    </Typography>
                    <Typography
                      className={cx(classes.itemDate, classes.itemDateHeaderMargin)}
                      noWrap={true}
                      component='div'
                    >
                      {intl.formatMessage({ id: 'use_cases.artifacts.createdAt' })}
                    </Typography>
                  </Box>
                  {
                    artifactMappings.map((artifact, index) => {
                      const artifactId = artifact.id
                      const artifactType = artifact.type
                      const date = formatDate(intl, artifact.createdAt)

                      return (
                        <Box
                          className={classes.listItem}
                          key={artifactId}
                        >
                          <Typography
                            className={classes.itemName}
                            noWrap={true}
                            title={artifactId}
                          >
                            {artifactId}
                          </Typography>
                          <Typography
                            className={classes.itemType}
                            noWrap={true}
                            title={artifactType}
                          >
                            {artifactType}
                          </Typography>
                          <Typography
                            className={classes.itemDate}
                            noWrap={true}
                            component='div'
                          >
                            {date}

                            <Box
                              onClick={isSubmitting || isDeleting ? undefined : () => handleDeleteAction(artifactId)}
                              className={cx(classes.itemButton, {
                                [classes.itemButtonDisabled]: isSubmitting || isDeleting,
                              })}
                            >
                              <DeleteIcon />
                            </Box>
                          </Typography>
                        </Box>
                      )
                    })
                  }
                </Box>
              </Box>
            </FormLayoutContainer>
          </SidePanelCardComponent>

          <SidePanelCardActionsComponent>
            <ModalButtonComponent
              name='useCaseArtifactMappingsModalCloseButton'
              onClick={() => handleCloseAction()}
              type='back'
            />
          </SidePanelCardActionsComponent>
        </Box>
      </SidePanelLoadingComponent>
    </SidePanelComponent>
  )
}

export default UseCaseArtifactMappingsModalContainer
