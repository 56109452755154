import produce from 'immer'
import parser from 'cron-parser'
import cronstrue from 'cronstrue'

import { PipelinesState, ReducerPayload } from '@redux/modules/types'
import { generatePipelineFlowByGraph } from './pipelines.utils'

export const initialState: PipelinesState = {
  pipelinesList: [],
  pipelineOverview: {
    pipelineConfig: null,
    pipelineGraph: null,
    pipelineDetails: {} as Pipelines.ReduxPipelineItem,
    expectedDeliveryHistory: [],
  } as Pipelines.ReduxPipelineOverviewItem,
  compositePipelineStatus: {
    lastSuccessfulRun: null,
    lastRun: null,
    status: null,
  },
  fetchingKeys: [],
}

export const receivePipelinesList = (state: PipelinesState, action: ReducerPayload<{
  pipelinesList: Pipelines.APIPipelinesListResponseItem[],
  useCasesList: UseCase.Details[],
  companiesList: Customer.CompanyItem[],
}>) => {
  const nextState = produce(state, (draftState) => {
    const useCaseMap = action.payload.useCasesList.reduce((acc, useCase) => {
      acc[useCase.useCaseId] = useCase
      return acc
    }, {} as { [key: string]: UseCase.Details })

    const companiesMap = action.payload.companiesList.reduce((acc, company) => {
      acc[company.companyId] = company
      return acc
    }, {} as { [key: string]: Customer.CompanyItem })

    draftState.pipelinesList = action.payload.pipelinesList.map((pipelineItem) => {
      const executionScheduleCronInterval = pipelineItem.executionSchedule ? parser.parseExpression(pipelineItem.executionSchedule) : null
      const deliveryScheduleCronInterval = pipelineItem.deliverySchedule ? parser.parseExpression(pipelineItem.deliverySchedule) : null

      return {
        ...pipelineItem,
        useCaseName: useCaseMap[pipelineItem.useCaseId]?.name,
        companyId: useCaseMap[pipelineItem.useCaseId]?.companyId,
        companyName: companiesMap[useCaseMap[pipelineItem.useCaseId]?.companyId]?.name,
        executionScheduleDate: cronstrue.toString(pipelineItem.executionSchedule),
        nextExecutionDate: executionScheduleCronInterval ? executionScheduleCronInterval.next().toDate() : null,
        deliveryScheduleDate: pipelineItem.deliverySchedule ? cronstrue.toString(pipelineItem.deliverySchedule) : null,
        nextDeliveryDate: deliveryScheduleCronInterval ? deliveryScheduleCronInterval.next().toDate() : null,
        lastDeliveryStatus: pipelineItem.latestExpectedDelivery ? pipelineItem.latestExpectedDelivery.status : null,
        lastDeliveryDate: pipelineItem.latestDelivery ? new Date(pipelineItem.latestDelivery.updatedAt) : null,
      }
    })
  })

  return nextState
}

export const receivePipelineOverview = (state: PipelinesState, action: ReducerPayload<{
  pipelineConfig: Pipelines.DemandConfig | null,
  pipelineGraph: Pipelines.APIPipelineGraphItem | null,
  pipelineDetails: Pipelines.ReduxPipelineItem,
  expectedDeliveryHistory: Pipelines.ReduxExpectedDeliveryHistoryItem[],
}>) => {
  const nextState = produce(state, (draftState) => {
    draftState.pipelineOverview = {
      pipelineConfig: action.payload.pipelineConfig,
      pipelineDetails: action.payload.pipelineDetails,
      pipelineGraph: generatePipelineFlowByGraph(action.payload.pipelineGraph),
      expectedDeliveryHistory: action.payload.expectedDeliveryHistory.map((expectedDelivery) => {
        const deliveryDate = new Date(expectedDelivery.delivery)
        const deliveryScheduleSnapshotCronInterval = expectedDelivery.deliveryScheduleSnapshot ? parser.parseExpression(expectedDelivery.deliveryScheduleSnapshot, {
          currentDate: deliveryDate,
          iterator: false,
        }) : null

        return {
          ...expectedDelivery,
          deliveryScheduleSnapshotDate: cronstrue.toString(expectedDelivery.deliveryScheduleSnapshot),
          deliveryScheduleSnapshotFullDate: deliveryScheduleSnapshotCronInterval ? deliveryScheduleSnapshotCronInterval.next().toDate() : null,
        }
      }),
    }
  })

  return nextState
}

export const receiveCompositePipelineStatus = (state: PipelinesState, action: ReducerPayload<Pipelines.APIGetCompositePipelineStatusResponse>) => {
  const nextState = produce(state, (draftState) => {
    draftState.compositePipelineStatus = action.payload
  })

  return nextState
}
