import {
  takeEvery,
  call,
  put,
  take,
  select,
} from 'redux-saga/effects'

import { push } from 'redux-first-history'

import { TOAST_TYPE_ERROR } from '@constants/common.constants'
import { parseAndReportErrorResponse } from '@utils/redux.utils'
import { generatePath } from 'react-router-dom'

import { changeToastAction } from '@redux/modules/common/common.actions'
import { ActionPayload, State } from '@redux/modules/types'
import { fetchUseCaseAction } from '@redux/modules/use-case/use-case.actions'
import { RECEIVE_USE_CASE } from '@redux/modules/use-case/use-case.action-types'
import { OPTIMIZE_PATH } from '@constants/routes.constants'
import { roundToNearestRange } from '@utils/analysis.utils'

import {
  REPLENISHMENT_COST_MEDIUM,
  REVENUE_MEDIUM,
  OPTIMIZE_RESTOCK_SELECTOR_TYPES,
  RANGE_SELECTOR_REPLENISHMENT_COST_TO_REVENUE,
  RANGE_SELECTOR_REVENUE_TO_REPLENISHMENT_COST,
  REPLENISHMENT_COST_RANGES,
  REVENUERANGES,
} from '@constants/optimize.constants'

import * as API from './optimize.api'

import {
  SET_TARGET_SELECTOR_TYPE,
  REQUEST_OPTIMIZE_PAGE_DATA,
  REQUEST_OPTIMIZE_DRILL_DOWN,
  SET_TARGET_REVENUE,
  SET_TARGET_REPLENISHMENT_COST,
  SET_STRATEGY_ACTION,
  RECEIVE_OPTIMIZE_PAGE_DATA,
} from './optimize.action-types'

import {
  setTargetSelectorTypeDone,
  receiveOptimizePageData,
  receiveOptimizeDrillDown,
  setTargetRevenueDone,
  setTargetReplenishmentCostDone,
  stopFetchingOptimizeAction,
  startFetchingOptimizeAction,
  setStrategyActionDone,
  requestOptimizePageData,
} from './optimize.actions'

import {
  PageDataReducerPayload,
  SetStrategyPayload,
  RequestOptimizeDataPayload,
  RequestOptimizeDrillDownPayload,
  SetTargetReplenishmentCostPayload,
  SetTargetRevenuePayload,
  SetTargetSelectorTypePayload,
} from './optimize.types'

import { getRangeSelectorData, getRangeSelectorType, getTableData } from './optimize.selectors'
import { getUseCaseItem } from '../use-case/use-case.selectors'

function* fetchOptimizePageDataGenerator({ payload } : ActionPayload<RequestOptimizeDataPayload>) {
  try {
    yield put(startFetchingOptimizeAction(REQUEST_OPTIMIZE_PAGE_DATA))

    if (payload.fetchUseCase) {
      yield put(fetchUseCaseAction({ useCaseId: payload.useCaseId }))

      yield take(RECEIVE_USE_CASE)
    }

    const state: State = yield select()
    const tableDataFromState: Optimize.OptimizeTableData = yield call(getTableData, state)
    const rangeSelectorData: Optimize.OptimizeRangeSelectorData = yield call(getRangeSelectorData, state)
    const rangeSelectorType: OPTIMIZE_RESTOCK_SELECTOR_TYPES = yield call(getRangeSelectorType, state)
    const tableData: Optimize.OptimizeItem[] = (tableDataFromState.length > 0 && !payload.reflectTargets) ? tableDataFromState : yield call(API.fetchOptimizeTableData, {
      ...payload,
      rangeSelectorData,
    })

    const getPredictionOverview = () => {
      switch (rangeSelectorType) {
        case OPTIMIZE_RESTOCK_SELECTOR_TYPES.REPLENISHMENT_COST:
          const roundedNetStock = roundToNearestRange(String(rangeSelectorData.targeReplenishmentCostSelected), REPLENISHMENT_COST_RANGES)
          // @ts-ignore
          const totalPredictedRevenue = RANGE_SELECTOR_REPLENISHMENT_COST_TO_REVENUE[roundedNetStock] || REVENUE_MEDIUM

          return {
            totalPredictedReplenishmentCost: rangeSelectorData.targeReplenishmentCostSelected,
            totalPredictedRevenue,
          }
        case OPTIMIZE_RESTOCK_SELECTOR_TYPES.REVENUE:
          const roundedReplenishmentCost = roundToNearestRange(String(rangeSelectorData.targetRevenueSelected), REVENUERANGES)
          // @ts-ignore
          const totalPredictedReplenishmentCost = RANGE_SELECTOR_REVENUE_TO_REPLENISHMENT_COST[roundedReplenishmentCost] || REPLENISHMENT_COST_MEDIUM

          return {
            totalPredictedReplenishmentCost,
            totalPredictedRevenue: rangeSelectorData.targetRevenueSelected,
          }
        default:
          return {
            totalPredictedReplenishmentCost: rangeSelectorData.targeReplenishmentCostSelected,
            totalPredictedRevenue: rangeSelectorData.targetRevenueSelected,
          }
      }
    }

    const data = {
      predictionOverview: getPredictionOverview(),
      tableData,
    } as PageDataReducerPayload

    yield put(receiveOptimizePageData(data))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingOptimizeAction(REQUEST_OPTIMIZE_PAGE_DATA))
  }
}

function* fetchOptimizeDrillDownGenerator({ payload } : ActionPayload<RequestOptimizeDrillDownPayload>) {
  try {
    yield put(startFetchingOptimizeAction(REQUEST_OPTIMIZE_DRILL_DOWN))

    if (payload.fetchUseCase) {
      yield put(fetchUseCaseAction({ useCaseId: payload.useCaseId }))

      yield take(RECEIVE_USE_CASE)
    }

    const state: State = yield select()
    const tableData: Optimize.OptimizeTableData = yield call(getTableData, state)

    const data: Optimize.OptimizeDrillDownItem[] = yield call(API.fetchOptimizeDrillDown, {
      ...payload,
      tableData,
    })

    yield put(receiveOptimizeDrillDown(data))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingOptimizeAction(REQUEST_OPTIMIZE_DRILL_DOWN))
  }
}

function* setTargetRevenueGenerator({ payload } : ActionPayload<SetTargetRevenuePayload>) {
  try {
    yield put(startFetchingOptimizeAction(SET_TARGET_REVENUE))

    yield put(setTargetRevenueDone(payload))

    const state: State = yield select()
    const useCase: UseCase.DetailsExtended = yield call(getUseCaseItem, state)

    yield put(requestOptimizePageData({
      useCaseId: useCase.useCaseId,
      reflectTargets: true,
    }))

    yield take(RECEIVE_OPTIMIZE_PAGE_DATA)
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingOptimizeAction(SET_TARGET_REVENUE))
  }
}

function* setTargetReplenishmentCostGenerator({ payload } : ActionPayload<SetTargetReplenishmentCostPayload>) {
  try {
    yield put(startFetchingOptimizeAction(SET_TARGET_REPLENISHMENT_COST))

    yield put(setTargetReplenishmentCostDone(payload))

    const state: State = yield select()
    const useCase: UseCase.DetailsExtended = yield call(getUseCaseItem, state)

    yield put(requestOptimizePageData({
      useCaseId: useCase.useCaseId,
      reflectTargets: true,
    }))

    yield take(RECEIVE_OPTIMIZE_PAGE_DATA)
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingOptimizeAction(SET_TARGET_REPLENISHMENT_COST))
  }
}

function* setStrategyActionGenerator({ payload } : ActionPayload<SetStrategyPayload>) {
  try {
    yield put(setStrategyActionDone(payload))

    yield put(push(generatePath(OPTIMIZE_PATH, { usecase: payload.useCaseId })))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  }
}
function* setTargetSelectorTypeGenerator({ payload } : ActionPayload<SetTargetSelectorTypePayload>) {
  try {
    yield put(setTargetSelectorTypeDone(payload))

    const state: State = yield select()
    const useCase: UseCase.DetailsExtended = yield call(getUseCaseItem, state)

    yield put(requestOptimizePageData({
      useCaseId: useCase.useCaseId,
      reflectTargets: true,
    }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  }
}

export function* watchRequestOptimizePageData() {
  yield takeEvery(REQUEST_OPTIMIZE_PAGE_DATA, fetchOptimizePageDataGenerator)
}

export function* watchRequestOptimizeDrillDown() {
  yield takeEvery(REQUEST_OPTIMIZE_DRILL_DOWN, fetchOptimizeDrillDownGenerator)
}

export function* watchSetTargetRevenue() {
  yield takeEvery(SET_TARGET_REVENUE, setTargetRevenueGenerator)
}

export function* watchSetTargetNetStock() {
  yield takeEvery(SET_TARGET_REPLENISHMENT_COST, setTargetReplenishmentCostGenerator)
}

export function* watchSetStrategyAction() {
  yield takeEvery(SET_STRATEGY_ACTION, setStrategyActionGenerator)
}

export function* watchSetTargetSelectorType() {
  yield takeEvery(SET_TARGET_SELECTOR_TYPE, setTargetSelectorTypeGenerator)
}
