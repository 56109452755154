import moment from 'moment'
import { IntlShape } from 'react-intl'
import { transformDatePickerValues } from '@utils/moment.utils'
import { DEFAULT_PAGE_SIZE } from '@constants/data-grid.constants'

import {
  GridColType,
  getGridNumericOperators,
  getGridStringOperators,
  GridFilterModel,
  GridAggregationModel,
  GridSortModel,
  GridRowSelectionModel,
  GridFilterOperator,
  GridFilterInputValue,
  GridLogicOperator,
} from '@mui/x-data-grid-premium'

import { filterEmptyFilterItems } from '@utils/data-grid.utils'

/**
 * Converts the API field type to MUI Data Grid field type
 * @param fieldType API field type
 * @returns MUI Data Grid field type
 */
export const convertAPIFieldTypeToMUIFieldType = (fieldType: Hera.APITableColumnType) => {
  switch (fieldType) {
    case 'TEXT':
      return 'string'
    case 'NUMBER':
      return 'number'
    case 'BOOLEAN':
      return 'boolean'
    case 'FLOAT':
      return 'number'
    case 'DATE':
      return 'date'
    default:
      return 'string'
  }
}

/**
 * Converts the MUI Data Grid operator to API operator
 * @param operator MUI Data Grid operator
 * @returns API operator
 */
export const convertMUIOperatorToAPIOperator = (operator: string) => {
  switch (operator) {
    case 'equals':
      return 'EQ'
    case '>':
      return 'GT'
    case '<':
      return 'LT'
    case '>=':
      return 'GTE'
    case '<=':
      return 'LTE'
    case 'isEmpty':
      return 'NULL'
    case 'isNotEmpty':
      return 'NOT_NULL'
    case 'contains':
      return 'CONTAINS'
    case 'startsWith':
      return 'STARTS_WITH'
    case 'doesNotContain':
      return 'NOT_CONTAINS'
    case 'endsWith':
      return 'ENDS_WITH'
    default:
      return 'EQ'
  }
}

/**
 * Converts the MUI Data Grid aggregation function to API aggregation function
 * @param functionName MUI Data Grid aggregation function
 * @returns API aggregation function
 */
export const convertMUIAggregationFunctionToAPIAggregationFunction = (functionName: string) => {
  switch (functionName) {
    case 'sum':
      return 'SUM'
    case 'avg':
      return 'AVG'
    case 'min':
      return 'MIN'
    case 'max':
      return 'MAX'
    default:
      return 'SUM'
  }
}

/**
 * Converts the API sorting model to MUI Data Grid sorting model
 *
 * @param sorting API sorting model
 * @returns MUI Data Grid sorting model
 */
export const convertAPISortReqToMUISortingModel = (sorting: Hera.APITableSortingReqDefinition) => {
  return [{
    field: sorting.column,
    sort: sorting.order.toLowerCase(),
  }] as GridSortModel
}

/**
 * Converts the MUI Data Grid sorting model to API sorting model
 * @param sortModel MUI Data Grid sorting model
 * @returns API sorting model
 */
export const convertMUISortingModelToAPISortReq = (sortModel?: GridSortModel) => {
  if (!sortModel || sortModel.length === 0) {
    return undefined
  }

  return {
    column: sortModel[0].field,
    order: sortModel[0].sort!.toUpperCase() as 'ASC' | 'DESC',
  } as Hera.APITableSortingReqDefinition
}

/**
 * Converts the MUI Data Grid filter model to API filter model
 * @param filterModel MUI Data Grid filter model
 * @returns API filter model
 */
export const convertMUIFilterModelToAPIFilterReq = (filterModel?: GridFilterModel) => {
  if (!filterModel || !filterModel.items || filterModel.items.length === 0) {
    return undefined
  }

  return filterModel.items.filter(filterEmptyFilterItems).map((item) => {
    return {
      column: item.field,
      operator: convertMUIOperatorToAPIOperator(item.operator),
      value: item.value || null,
    } as Hera.APITableFiltersReqDefinition
  })
}

/**
 * Converts the MUI Data Grid aggregation model to API aggregation model
 * @param aggregationModel MUI Data Grid aggregation model
 * @returns API aggregation model
 */
export const convertMUIAggregationModelToAPIAggregationReq = (aggregationModel?: GridAggregationModel) => {
  if (!aggregationModel) {
    return undefined
  }

  return Object.keys(aggregationModel).map((column) => {
    return {
      column,
      function: convertMUIAggregationFunctionToAPIAggregationFunction(aggregationModel[column]),
    } as Hera.APITableAggregationsReqDefinition
  })
}

/**
 * Gets the search term from the filter model
 * @param filterModel MUI Data Grid filter model
 * @returns search term
 */
export const getSearchTermFromFilterModel = (filterModel?: GridFilterModel) => {
  if (!filterModel || !filterModel.quickFilterValues || filterModel.quickFilterValues.length === 0) {
    return undefined
  }

  const searchTerm = filterModel.quickFilterValues.join(' ')

  if (searchTerm.trim() === '') {
    return undefined
  }

  return searchTerm
}

/**
 * Converts the MUI Data Grid page number to API page number
 * @param pageNumber MUI Data Grid page number
 * @returns API page number
 */
export const convertMUIPageNumberToAPIPageNumber = (pageNumber?: number) => {
  return (pageNumber || 0) + 1
}

/**
 * Converts the MUI Data Grid page size to API page size
 * @param pageSize MUI Data Grid page size
 * @returns API page size
 */
export const convertMUIPageSizeToAPIPageSize = (pageSize?: number) => {
  return pageSize || DEFAULT_PAGE_SIZE
}

/**
 * Returns the allowed operators for the column.
 *
 * @param column Column
 *
 * @returns Allowed operators for the column
 */
export const getAllowedOperators = (intl: IntlShape, type: GridColType): GridFilterOperator<any, string | number | null, any>[] => {
  if (type === 'number') {
    return getGridNumericOperators().filter((operator) => {
      switch (operator.value) {
        case '=':
        case '>':
        case '<':
        case '>=':
        case '<=':
        case 'isEmpty':
        case 'isNotEmpty':
          return true
        default:
          return false
      }
    })
  }

  const stringOperators = getGridStringOperators()
  const containsOperator = stringOperators.find((operator) => operator.value === 'contains')!
  const otherStringOperators = stringOperators.filter((operator) => {
    switch (operator.value) {
      case 'equals':
      case 'startsWith':
      case 'endsWith':
      case 'isEmpty':
      case 'isNotEmpty':
        return true
      default:
        return false
    }
  })

  return [
    containsOperator,
    {
      label: intl.formatMessage({ id: 'common.tables.doesNotContain' }),
      value: 'doesNotContain',
      InputComponent: GridFilterInputValue,
      getApplyFilterFn: () => {
        /* server-side only */
        return null
      },
    },
    ...otherStringOperators,
  ]
}

/**
 * Converts the time window into API date range
 * @param timeWindow Time window
 * @returns API date range object
 */
export const convertTimeWindowIntoAPIDateRange = (timeWindow?: Common.DatePickerValue) => {
  if (timeWindow) {
    const dates = transformDatePickerValues(timeWindow)

    if (!dates || (!dates.from && !dates.to)) {
      return null
    }

    return {
      from: dates.from,
      to: dates.to,
    } as Hera.APIDateRangeReqDefinition
  }

  return null
}

/**
 * Prepares the API filters
 *
 * @param filterModel Filter model
 * @param timeWindow Time window
 *
 * @returns API filters
 */
export const prepareAPIFilters = (filterModel?: GridFilterModel) => {
  const filters: Hera.APITableFiltersReqDefinition[] = []
  const tableFilters = convertMUIFilterModelToAPIFilterReq(filterModel)

  if (tableFilters && tableFilters.length > 0) {
    filters.push(...tableFilters)
  }

  if (filters.length === 0) {
    return undefined
  }

  return filters
}

/**
 * Prepares the API logic operator
 * @param filterModel Filter model
 * @returns API logic operator
 */
export const prepareAPILogicalOperator = (filterModel?: GridFilterModel): Hera.APILogicalOperator => {
  if (!filterModel) {
    return 'AND'
  }

  return filterModel.logicOperator === GridLogicOperator.And ? 'AND' : 'OR'
}

/**
 * Parses the time window from the local/session storage
 *
 * @param timeWindow time window
 *
 * @returns parsed time window
 */
export const parseStoredTimeWindow = (timeWindow?: [string | null, string | null]) => {
  if (!timeWindow) {
    return null
  }

  const from = timeWindow[0] ? moment(timeWindow[0] as string).utc() : null
  const to = timeWindow[1] ? moment(timeWindow[1] as string).utc() : null

  return [from, to] as Common.DatePickerValue
}

/**
 * Transforms the column label
 *
 * @param label Column label
 *
 * @returns Transformed column label (replaces underscores with spaces and capitalizes each word)
 */
export const transformColumnLabel = (label?: string) => {
  const columnNameWithoutUnderscores = (label || '').toLowerCase().replace(/_/g, ' ')
  const capitalizedColumnName = columnNameWithoutUnderscores.split(' ').map((word) => {
    return word.charAt(0).toUpperCase() + word.substring(1)
  }).join(' ')

  return capitalizedColumnName
}

/**
 * Converts the MUI Data Grid row selection model to API row filter request
 * @param rowSelectionModel MUI Data Grid row selection model
 * @returns API row filter request
 */
export const convertMUIRowSelectionModelToAPIRowFilterReq = (
  rowSelectionModel?: GridRowSelectionModel,
  mode: Hera.RowSelectionModelMode = 'include',
  preselectRows = false,
): Hera.APIChartRowFilters => {
  if (!rowSelectionModel) {
    return {
      include: [],
      exclude: [],
    }
  }

  const rows = rowSelectionModel.map((row) => String(row))

  if (mode === 'exclude') {
    return {
      include: null,
      exclude: rows,
    }
  }

  if (mode === 'include' && preselectRows) {
    return {
      include: null,
      exclude: [],
    }
  }

  return {
    include: rows,
    exclude: [],
  }
}
