import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { Position } from '@xyflow/react'
import { useRouteMatch } from 'react-router-dom'

import {
  SOURCE_TYPES_TO_ICONS_MAP,
  TRIPPLE_CONNECTOR_STYLES,
  SOURCE_TYPES, SOURCE_TYPES_OPTIONS,
  MAX_SOURCES_TO_SHOW,
  SourceTypeOption,
} from '@constants/flow.constants'

import { Box, Typography } from '@mui/material'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { getUseCaseFileIdentifiers } from '@redux/modules/training-files/training-files.selectors'
import { createId } from '@utils/common.utils'
import { USE_CASE_DATA_TYPES_MODAL_NAME, DATA_SOURCES_MODAL_NAME } from '@constants/modals.constants'
import { getFileIdentifiersCount } from '@utils/use-cases.utils'
import { DataSourcesWidgetModalDetails } from '@containers/modals/data-sources-modal/data-sources-widget/DataSourcesWidget.component'
import { DataSourceConnectedModalDetails } from '@containers/modals/data-source-connected-modal/DataSourceConnectedModal.container'
import { TRACKING_ACTIONS, trackEvent } from '@utils/tracking.utils'

import ReactFlowBlockHeaderComponent from '@components/connect-view/flow/ReactFlowBlockHeader'
import AddFlowIcon from '@icons/flow/addFlow.icon'
import ReactFlowHandleComponent from '@components/connect-view/flow/ReactFlowHandle'

import useStyles from './ReactFlowInputNode.styles'

export interface ReactFlowInputNodeComponentProps {
  /**
   * Data for the node
   */
  data?: {
    /**
     * Number of input types
     */
    numOfInputTypes?: number,
    /**
     * Unique sources to be displayed
     */
    uniqueSources?: string[]
    /**
     * Number of sources
     */
    numOfSources?: number,
  }
}

const ReactFlowInputNodeComponent: React.FC<ReactFlowInputNodeComponentProps> = ({ data }) => {
  const {
    numOfInputTypes = 0,
    uniqueSources = [],
    numOfSources = 0,
  } = data || {}
  const { classes, cx } = useStyles({ numOfSources })
  const intl = useIntl()
  const dispatch = useDispatch()
  const { params: { usecase } } = useRouteMatch<Common.RouterMatch>()
  const isAdmin = useSelector((state) => getIsAdmin(state))
  const fileIdentifiersList = useSelector((state) => getUseCaseFileIdentifiers(state))
  const isUploadDisabled = getFileIdentifiersCount(fileIdentifiersList, true) && !isAdmin
  const hiddenSourcesCount = numOfSources - MAX_SOURCES_TO_SHOW
  const flowHandles = []

  const onUploadButtonClick = () => {
    trackEvent({
      componentName: 'uploadDataSourceShortcutButton',
      actionName: TRACKING_ACTIONS.CLICK,
    }, {
      useCaseId: usecase,
    })

    dispatch(
      setPrimaryModalPageName(USE_CASE_DATA_TYPES_MODAL_NAME),
    )
  }

  const onAddButtonClick = () => {
    trackEvent({
      componentName: 'addDataSourceButton',
      actionName: TRACKING_ACTIONS.CLICK,
    }, {
      useCaseId: usecase,
    })

    dispatch(
      setPrimaryModalPageName(DATA_SOURCES_MODAL_NAME),
    )
  }

  const onStaticSourceClick = (key: string) => {
    trackEvent({
      componentName: 'mockDataSourceShortcutButton',
      actionName: TRACKING_ACTIONS.CLICK,
    }, {
      useCaseId: usecase,
      dataSourceType: key,
    })

    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: DATA_SOURCES_MODAL_NAME,
        modalDetails: {
          panelToOpen: key,
          returnTo: '',
        } as DataSourcesWidgetModalDetails,
      }),
    )
  }

  const onSourceClick = (sourceItem: any, modalName: string) => {
    trackEvent({
      componentName: 'dataSourceShortcutButton',
      actionName: TRACKING_ACTIONS.CLICK,
    }, {
      useCaseId: usecase,
      dataSourceType: sourceItem?.key,
    })

    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: modalName,
        modalDetails: {
          ...sourceItem,
          returnTo: '',
        } as DataSourceConnectedModalDetails,
      }),
    )
  }

  for (let i = 0; i < numOfInputTypes; i += 1) {
    const style = (numOfInputTypes === 1) ? TRIPPLE_CONNECTOR_STYLES[1] : TRIPPLE_CONNECTOR_STYLES[i]

    flowHandles.push((
      <ReactFlowHandleComponent
        type='source'
        position={Position.Right}
        id={String(i + 1)}
        key={String(i + 1)}
        style={style}
      />
    ))
  }

  const soucesItems = uniqueSources.map((key) => {
    const sourceDetails = SOURCE_TYPES_OPTIONS.find((item) => item.value === key) || ({} as SourceTypeOption)
    const {
      sourceId,
      value,
      labelKey,
      descriptionKey,
      documentationLink,
      videoLink,
      videoHeight,
      helpKey,
      modalName,
    } = sourceDetails

    const isCustomFiles = key === SOURCE_TYPES.CUSTOM
    const isParetos = key === SOURCE_TYPES.PARETOS
    const isHermesConnected = Boolean(sourceId)

    const sourceItems = {
      key: value,
      title: labelKey ? intl.formatMessage({ id: labelKey }) : '',
      description: descriptionKey ? intl.formatMessage({ id: descriptionKey }) : '',
      help: helpKey ? intl.formatMessage({ id: helpKey }) : '',
      status: null,
      subTitle: null,
      icon: SOURCE_TYPES_TO_ICONS_MAP[value],
      documentationLink,
      videoLink,
      videoHeight,
      sourceId,
      onClick: () => onStaticSourceClick(value),
    }

    const customOverrite = isCustomFiles ? {
      subTitle: (
        <Typography variant='caption' className={classes.subTitle}>
          {intl.formatMessage({ id: 'connect.block.data_input.sources.custom_files' }, {
            count: getFileIdentifiersCount(fileIdentifiersList),
          })}
        </Typography>
      ),
      onClick: () => onUploadButtonClick(),
      disabled: isUploadDisabled,
    } : {}

    const hermesOverrite = (isHermesConnected || isParetos) ? {
      onClick: () => onSourceClick(sourceItems, modalName!),
    } : {}

    return {
      ...sourceItems,
      ...customOverrite,
      ...hermesOverrite,
    }
  })

  const isDataSouceConnected = soucesItems.length > 0
  const isMoreItemAvailable = hiddenSourcesCount > 0

  return (
    <Box className={classes.root} data-testid={ReactFlowInputNodeComponent.name}>
      <Box className={classes.content}>
        <ReactFlowBlockHeaderComponent
          name={intl.formatMessage({ id: 'connect.block.data_input' })}
          help={intl.formatMessage({ id: 'connect.block.data_input.help' })}
          onAddButtonClick={onAddButtonClick}
          isAddButtonAvailable={isDataSouceConnected}
          parentComponentName={ReactFlowInputNodeComponent.name}
          ignoreFrozen={true}
        />

        {
          !isDataSouceConnected ? (
            <Box
              className={classes.newSourceItem}
              onClick={onAddButtonClick}
              data-testid='newSource'
            >
              <Box className={classes.sourceItemMain}>
                <Box className={classes.newSourceIcon}>
                  <AddFlowIcon width={22} height={22} />
                </Box>
                <Typography variant='body1' className={classes.sourceName}>
                  {intl.formatMessage({ id: 'connect.block.data_input.new' })}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box className={classes.sourcesContainer}>
              {
                soucesItems.map((item, index) => {
                  const {
                    onClick,
                    icon: IconComponent,
                    title,
                    status,
                    subTitle,
                  } = item

                  return (
                    <Box
                      className={cx(classes.sourceItem, {
                        [classes.sourceItemActive]: Boolean(onClick),
                        [classes.isMoreItemAvailable]: isMoreItemAvailable,
                      })}
                      key={createId(title, index)}
                      onClick={onClick}
                      role='button'
                      tabIndex={0}
                      data-testid={`source-${index}`}
                    >
                      <Box className={classes.sourceItemMain}>
                        <Box className={classes.sourceIcon}>
                          <IconComponent />
                        </Box>
                        <Typography variant='body1' className={classes.sourceName}>
                          {title}
                        </Typography>
                        {
                          subTitle ? (
                            <>
                              {subTitle}
                            </>
                          ) : (
                            null
                          )
                        }
                      </Box>
                      {
                        status ? (
                          <Box className={classes.sourceStatus}>
                            {status}
                          </Box>
                        ) : (
                          null
                        )
                      }
                    </Box>
                  )
                })
              }
            </Box>
          )
        }

        {
          (isMoreItemAvailable && isDataSouceConnected) ? (
            <>
              <Box className={classes.moreConnectorLineContainer}>
                <Box
                  className={classes.moreConnectorLine}
                />
              </Box>
              <Box
                className={cx(classes.sourceMoreItem, {
                  [classes.sourceItemActive]: true,
                })}
                onClick={onAddButtonClick}
                data-testid='moreSources'
                role='button'
                tabIndex={0}
              >
                <Typography variant='body1' className={classes.moreCount}>
                  {`+${hiddenSourcesCount}`}
                </Typography>
                <Typography variant='body1' className={classes.moreText}>
                  {intl.formatMessage({ id: 'connect.block.data_input.more' })}
                </Typography>
              </Box>
            </>
          ) : (
            null
          )
        }
      </Box>

      {flowHandles}
    </Box>
  )
}

export default ReactFlowInputNodeComponent
