import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

type allowedClasses = 'optionLabel' | 'optionIcon'
const textWidth = '580px'

const useStyles = makeStyles<{ inputWidth: string, }, allowedClasses>()((theme: Theme, { inputWidth }, classes) => {
  const errorPadding = 20

  return {
    rootContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      width: '100%',
      borderRadius: theme.spacing(1),
      padding: theme.spacing(1, 2),
      height: theme.spacing(6),
      border: `1px solid ${theme.palette.new.grey_a}`,
    },
    disabled: {
      cursor: 'not-allowed',
      background: theme.palette.new.grey,
    },
    root: {
      minWidth: inputWidth,
    },
    popper: {
      minWidth: inputWidth,
      backgroundColor: theme.palette.new.white,
      border: `1px solid ${theme.palette.new.grey_a}`,
      borderBottomLeftRadius: theme.spacing(1),
      borderBottomRightRadius: theme.spacing(1),
      filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.15))',
      zIndex: 100000,
      marginTop: '-50px !important',
      marginLeft: '-21px !important',
    },
    popperDisablePortal: {
      minWidth: inputWidth,
      position: 'relative !important' as any,
      transform: 'none !important',
    },
    paper: {
      minWidth: inputWidth,
      backgroundColor: theme.palette.new.white,
      boxShadow: 'none',
      margin: theme.spacing(0),
      borderRadius: theme.spacing(0),
      borderBottomLeftRadius: theme.spacing(1),
      borderBottomRightRadius: theme.spacing(1),
      '& .MuiAutocomplete-listbox': {
        maxHeight: '50vh',
      },
    },
    option: {
      height: theme.spacing(6),
      padding: `${theme.spacing(0, 3)} !important`,
      borderTop: `1px solid ${theme.palette.new.grey_a}`,
      '&:first-of-type': {
        borderTop: 'none',
      },
      '&:hover': {
        backgroundColor: theme.palette.new.grey_d,
      },
      '&[aria-selected="true"]': {
        backgroundColor: theme.palette.new.white,
        [`& .${classes.optionLabel}`]: {
          color: theme.palette.new.violet,
        },
        '&:hover': {
          backgroundColor: theme.palette.new.grey_d,
        },
      },
      '&[data-focus="true"]': {
        backgroundColor: theme.palette.new.grey_d,
      },
      '&[aria-disabled="true"]': {
        opacity: 1,
        [`& .${classes.optionLabel}`]: {
          color: 'rgba(0, 0, 0, 0.4)',
        },
        [`& .${classes.optionIcon}`]: {
          opacity: 0.4,
        },
      },
    },

    optionContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    optionLabel: {
      fontSize: '18px',
      color: theme.palette.new.black,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      textTransform: 'none',
      maxWidth: textWidth,
    },
    optionLabelSelected: {
      fontSize: '18px',
      color: theme.palette.new.violet,
    },
    optionIcon: {
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(2),
    },
    optionLabelEmpty: {
      display: 'flex',
      alignItems: 'center',
      fontSize: '18px',
      color: theme.palette.new.black,
      height: theme.spacing(6),
    },
    rootAutocomplete: {
      borderBottom: `1px solid ${theme.palette.new.grey_a}`,
      height: theme.spacing(6),
    },
    groupUl: {
      '&:last-child': {
        borderBottom: `1px solid ${theme.palette.new.grey_a}`,
      },
    },
    groupLabel: {
      height: theme.spacing(6),
      padding: theme.spacing(0, 7),
      display: 'flex',
      alignItems: 'flex-end',
      color: theme.palette.new.black,
      fontWeight: '500',
      fontSize: '11px',
      lineHeight: '40px',
      letterSpacing: '0.06em',
      borderBottom: `1px solid ${theme.palette.new.grey_a}`,
      textTransform: 'uppercase',
      position: 'sticky',
      top: '0px',
      width: '100%',
      zIndex: 10,
      backgroundColor: theme.palette.new.white,
    },
    inputBase: {
      fontSize: '18px',
      color: theme.palette.new.black,
      height: theme.spacing(6),
      padding: theme.spacing(0, 3),
      width: '100%',
      '& ::-webkit-search-cancel-button': {
        height: '18px',
        width: '18px',
        borderRadius: 0,
        background: 'url("/close.svg") no-repeat 50% 50%',
        WebkitAppearance: 'none',
        cursor: 'pointer',
        opacity: 1,
      },
    },
    groupLabelEmpty: {
      display: 'none',
    },
    additionalIcon: {
      height: '18px',
      cursor: 'pointer',
    },
    endAdornment: {
      position: 'absolute',
      left: `${(inputWidth + errorPadding)}px`,
    },
    helpIcon: {
      height: '18px',
      cursor: 'pointer',
    },
  }
})

export default useStyles
