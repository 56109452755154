import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { getTargetsGroupWidth, getTargetsGroupHeigth } from '@utils/flow.utils'
import { PARETOS_GRADIENT } from '@constants/ui.constants'

const useStyles = makeStyles<{ numOfNodes: number }, 'outputParametersChunk'>()((theme: Theme, { numOfNodes }, classes) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: getTargetsGroupWidth(numOfNodes),
    height: getTargetsGroupHeigth(numOfNodes),
    background: PARETOS_GRADIENT,
    border: `1px solid ${theme.palette.new.grey_b}`,
    borderRadius: theme.spacing(0.5),
    position: 'relative',
    cursor: 'default',
  },
  content: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: '100%',
  },
  outputParametersContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    [`& .${classes.outputParametersChunk}`]: {
      marginRight: theme.spacing(2),
    },
    '& :last-child': {
      marginRight: 0,
    },
  },
  outputParametersChunk: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%',
  },
}))

export default useStyles
