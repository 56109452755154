import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

type allowedClasses = 'listItem'

const useStyles = makeStyles<void, allowedClasses>()((theme: Theme, props, classes) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(4),
  },
  headerText: {
    fontWeight: 500,
    fontSize: '24px',
    display: 'flex',
    alignItems: 'center',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(0),
    width: '100%',
    [`& .${classes.listItem}:last-child`]: {
      borderBottom: `1px solid ${theme.palette.new.grey_a}`,
    },
  },
  emptyList: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderTop: `1px solid ${theme.palette.new.grey_a}`,
    borderBottom: `1px solid ${theme.palette.new.grey_a}`,
    color: theme.palette.new.grey_c,
    width: '100%',
    height: theme.spacing(6),
    padding: theme.spacing(0, 4),
  },
  listItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    width: '100%',
    height: theme.spacing(6),
    padding: theme.spacing(0, 4),
    borderTop: `1px solid ${theme.palette.new.grey_a}`,
    '&:hover': {
      backgroundColor: theme.palette.new.grey_d,
    },
    '&:first-of-type': {
      borderTop: 'none',
      cursor: 'default',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  listHeaderItem: {
    fontSize: '18px',
    color: theme.palette.new.grey_c,
    width: '100%',
    paddingRight: theme.spacing(1),
  },
  listHeaderFirstItem: {
    marginLeft: theme.spacing(4),
  },
  listHeaderLastItem: {
    marginRight: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  listItemIcon: {
    minWidth: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  listItemValue: {
    fontSize: '18px',
    color: theme.palette.new.black,
    width: '100%',
    paddingRight: theme.spacing(1),
  },
  listItemValueUnit: {
    fontSize: '13px',
    color: theme.palette.new.grey_c,
    paddingRight: theme.spacing(0),
  },
  listItemButton: {
    display: 'flex',
    cursor: 'pointer',
    marginLeft: theme.spacing(3),
    padding: theme.spacing(0),
    '& svg': {
      '&:hover': {
        opacity: 0.6,
      },
    },
  },
  listItemLast: {
    display: 'flex',
    alignItems: 'center',
    minWidth: theme.spacing(15),
    justifyContent: 'space-between',
    textTransform: 'uppercase',
  },
}))

export default useStyles
