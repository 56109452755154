import { createAction } from 'redux-actions'

import {
  REQUEST_TRADE_OFFS_BY_USE_CASE,
  REQUEST_SOCRATES_PROJECTS,
  RECEIVE_SOCRATES_PROJECTS,
  REQUEST_SOCRATES_PROJECT,
  RECEIVE_SOCRATES_PROJECT,
  REQUEST_TRADE_OFFS_DATA,
  RECEIVE_TRADE_OFFS_DATA,
  START_FETCHING_ANALYSIS,
  STOP_FETCHING_ANALYSIS,
  CHANGE_TRADE_OFFS_CONFIGURATION,
  CHANGE_TRADE_OFFS_CONFIGURATION_DONE,
  CALCULATE_TRADE_OFFS_DATASET,
  CALCULATE_TRADE_OFFS_DATASET_DONE,
  SELECT_TRADE_OFFS_POINT,
  RESET_TRADE_OFFS_CONFIGURATION,
  RESET_TRADE_OFFS_CONFIGURATION_DONE,
  SELECT_TRADE_OFFS_POINT_RESET,
  SELECT_TRADE_OFFS_POINT_DONE,
} from './socrates.action-types'

import {
  FetchProjectActionPayload,
  CalculateTradesOffsDatasetActionPayload,
  FetchTradeOffsDataActionPayload,
  RequestTradeOffsByUseCaseActionPayload,
} from './socrates.types'

export const fetchAllProjectsAction = createAction<void>(REQUEST_SOCRATES_PROJECTS)
export const receiveProjectsActionDone = createAction(RECEIVE_SOCRATES_PROJECTS)

export const fetchProjectAction = createAction<FetchProjectActionPayload>(REQUEST_SOCRATES_PROJECT)
export const receiveProjectActionDone = createAction(RECEIVE_SOCRATES_PROJECT)

export const requestTradeOffsByUseCaseAction = createAction<RequestTradeOffsByUseCaseActionPayload>(REQUEST_TRADE_OFFS_BY_USE_CASE)

export const fetchTradeOffsDataAction = createAction<FetchTradeOffsDataActionPayload>(REQUEST_TRADE_OFFS_DATA)
export const receiveTradeOffsDataActionDone = createAction(RECEIVE_TRADE_OFFS_DATA)

export const startFetchingAnalysisAction = createAction<string>(START_FETCHING_ANALYSIS)
export const stopFetchingAnalysisAction = createAction(STOP_FETCHING_ANALYSIS)

export const resetTradeOffsConfigurationAction = createAction<void>(RESET_TRADE_OFFS_CONFIGURATION)
export const resetTradeOffsConfigurationActionDone = createAction(RESET_TRADE_OFFS_CONFIGURATION_DONE)

export const changeTradeOffsConfigurationAction = createAction<Socrates.TradeOffsConfiguration>(CHANGE_TRADE_OFFS_CONFIGURATION)
export const changeTradeOffsConfigurationActionDone = createAction(CHANGE_TRADE_OFFS_CONFIGURATION_DONE)

export const calculateTradesOffsDatasetAction = createAction<CalculateTradesOffsDatasetActionPayload>(CALCULATE_TRADE_OFFS_DATASET)
export const calculateTradesOffsDatasetActionDone = createAction(CALCULATE_TRADE_OFFS_DATASET_DONE)

export const selectTradeOffsPointAction = createAction<Socrates.SelectedTradeOffsPoint>(SELECT_TRADE_OFFS_POINT)
export const selectTradeOffsPointResetAction = createAction<void>(SELECT_TRADE_OFFS_POINT_RESET)
export const selectTradeOffsPointActionDone = createAction(SELECT_TRADE_OFFS_POINT_DONE)
