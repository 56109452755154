import React from 'react'

import { useSelector } from '@redux/hooks'
import { getAnalyzeAbcTotals, getAnalyzeTable, isFetchingAnalyzeTable } from '@redux/modules/analyze/analyze.selectors'
import { requestAnalyzeGridStateChangeAction } from '@redux/modules/analyze/analyze.actions'

import {
  DEFAULT_ANALYZE_PREDICTION_COLUMN,
  DEFAULT_ANALYZE_TARGET_COLUMN,
} from '@constants/insights.constants'

import {
  transformAnalyzeTableColumns,
  analyzeAggregationModelTransformer,
} from '@utils/analyze.utils'

import InsightsTableComponent from '@components/(features)/insights/insights-table/InsightsTable.component'

const AnalyzeTableContainer = () => {
  const analyzeTable = useSelector((state) => getAnalyzeTable(state))
  const isFetching = useSelector((state) => isFetchingAnalyzeTable(state))
  const abcTotals = useSelector((state) => getAnalyzeAbcTotals(state))

  const {
    tableId,
    rows,
    rowCount,
    columns,
    gridState,
  } = analyzeTable

  const alwaysVisibleColumns = [
    DEFAULT_ANALYZE_TARGET_COLUMN,
    DEFAULT_ANALYZE_PREDICTION_COLUMN,
  ]

  return (
    <InsightsTableComponent
      tableId={tableId}
      rows={rows}
      rowCount={rowCount}
      totalRowCount={abcTotals.total}
      columns={columns}
      gridState={gridState}
      isFetching={isFetching}
      requestGridStateChangeAction={requestAnalyzeGridStateChangeAction}
      columnTransformer={transformAnalyzeTableColumns}
      aggregationModelTransformer={analyzeAggregationModelTransformer}
      defaultSortingColumn={DEFAULT_ANALYZE_PREDICTION_COLUMN}
      alwaysVisibleColumns={alwaysVisibleColumns}
    />
  )
}

export default AnalyzeTableContainer
