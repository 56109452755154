import React from 'react'
import { Handle, HandleProps, Position } from '@xyflow/react'

import useStyles from './ReactFlowHandle.styles'

export interface ReactFlowHandleComponentProps extends HandleProps {
  /**
   * Position of the handle
   */
  position: Position,
  /**
   * Whether the dots should be invisible
   */
  invisibleDots?: boolean,
  /**
   * Additional styles
   */
  style?: any,
}

const ReactFlowHandleComponent: React.FC<ReactFlowHandleComponentProps> = ({
  position,
  invisibleDots = false,
  ...rest
}) => {
  const { classes, cx } = useStyles()

  if (!position) {
    return null
  }

  return (
    <Handle
      className={cx(classes.handle, {
        [classes.handleLeft]: position === Position.Left,
        [classes.handleRight]: position !== Position.Left,
        [classes.invisibleDots]: invisibleDots,
      })}
      data-testid={ReactFlowHandleComponent.name}
      position={position}
      isConnectable={false}
      {...rest}
    />
  )
}

export default ReactFlowHandleComponent
