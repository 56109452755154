import produce from 'immer'
import { ChatState, ReducerPayload } from '@redux/modules/types'

export const initialState: ChatState = {
  isFetching: false,
  isFetched: false,
  fetchingKey: '',
  messages: [],
}

export const receiveChatMessages = (state: ChatState, action: ReducerPayload<Chat.ChatMessages>) => {
  const nextState = produce(state, (draftState) => {
    draftState.messages = action.payload
    draftState.isFetched = true
  })

  return nextState
}

export const sendChatMessageDone = (state: ChatState, action: ReducerPayload<Chat.ChatMessage>) => {
  const nextState = produce(state, (draftState) => {
    draftState.messages.push(action.payload)
  })

  return nextState
}

export const startFetching = (state: ChatState, action: ReducerPayload<string>) => {
  const nextState = produce(state, (draftState) => {
    draftState.isFetching = true
    draftState.fetchingKey = (!draftState.fetchingKey) ? action.payload : draftState.fetchingKey
  })

  return nextState
}

export const stopFetching = (state: ChatState, action: ReducerPayload<string>) => {
  const nextState = produce(state, (draftState) => {
    if (draftState.fetchingKey === action.payload) {
      draftState.fetchingKey = ''
      draftState.isFetching = false
    }
  })

  return nextState
}
