import { COCKPIT_BUILD_ENV } from '@constants/env-replacements.constants'

export const CANCELED_REQUEST_ERROR = 'Canceled request via changing route'
export const CANCELED_ERROR_CODE = 'ERR_CANCELED'
export const ERROR_CODES_TO_IGNORE = [CANCELED_ERROR_CODE]

export const TOAST_MAX_LENGHT = 250
export const TOAST_TYPE_INFO = 'info'
export const TOAST_TYPE_WARNING = 'warning'
export const TOAST_TYPE_ERROR = 'error'
export const TOAST_TYPE_SUCCESS = 'success'
export const TOAST_TYPE_NONE = TOAST_TYPE_INFO

export const SENTRY_DNS = 'https://3158cef7ca834e8ead66c6eb28871220@o1034873.ingest.sentry.io/6020805'

export const IS_LOCAL_ENV = process.env.NODE_ENV === 'development'
export const IS_DEV_ENV = COCKPIT_BUILD_ENV === 'dev'
export const ENV_TYPE = IS_DEV_ENV ? 'development' : 'production'
export const UPLOAD_DOCUMENTATION_URL = IS_DEV_ENV ? 'https://docs.dev.paretos.io/training_data_upload/' : 'https://docs.paretos.io/training_data_upload/'
