import React from 'react'

import { v4 } from 'uuid'

import {
  GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD,
  GridColDef,
} from '@mui/x-data-grid-premium'

import {
  DEFAULT_DATA_GRID_STATE,
  DEFAULT_BOOLEAN_COL_MIN_WIDTH,
} from '@constants/data-grid.constants'

import { formatDateString } from '@utils/moment.utils'
import { defaultNumberFormatter } from '@utils/analysis.utils'
import { ChartConfig } from '@utils/svg.utils'

import DataGridBooleanCellComponent from '@base/datagrid/data-grid-cells/data-grid-boolean-cell'

/**
 * Get axis formatter by type
 * @param type category or date
 * @returns formatter function
 */
export const getAxisFormatterByType = (type: 'category' | 'date' | 'number' | 'float', chartConfig?: ChartConfig, options?: Intl.NumberFormatOptions) => {
  if (type === 'category') {
    return (value: string) => {
      if (chartConfig && chartConfig.rotateXLabels && chartConfig.maxXLabelsHeight) {
        const maxAllowedSymbols = Math.floor(chartConfig.maxXLabelsHeight / 10)
        const shouldTruncate = value.length > maxAllowedSymbols

        return shouldTruncate ? `${value.slice(0, maxAllowedSymbols)}...` : value
      }

      return value
    }
  }

  if (type === 'number' || type === 'float') {
    return (value: string) => {
      return defaultNumberFormatter(value, {
        numberFormatOptions: options,
        float: (type === 'float'),
      })
    }
  }

  return (value: string) => {
    return formatDateString(value)
  }
}

/**
 * Get chart data point keys based on the legend
 *
 * @param {RecommendationArtifacts.VisualisationItem[]} items
 * @param {String} actualValueKey
 * @param {Boolean} comparisonMode
 *
 * @returns {string[]} chart keys which are used to draw bars/lines/tooltips
 */
export const getChartDataPointKeys = ({
  items,
  type,
  actualValueKeys,
  comparisonMode,
} : {
  items?: RecommendationArtifacts.VisualisationItem[],
  type?: RecommendationArtifacts.VisualisationTypes,
  actualValueKeys?: string[],
  comparisonMode?: boolean,
}) => {
  const keys = items || []

  return keys.filter((item) => {
    if (!comparisonMode) {
      return actualValueKeys ? !actualValueKeys.includes(item.dataKey) : true
    }

    return true
  }).map((item) => {
    return {
      ...item,
      type: (type === 'bar' || type === 'line') ? type : item.type,
    }
  })
}

/**
 * Replaces the column name if it is a grouping column.
 *
 * @param configuration Grid Configuration
 * @param column Column name
 *
 * @returns The actual column name or the constant GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD which is used for grouping columns.
 */
export const groupingColumnsReplacer = (configuration: RecommendationArtifacts.DataGridConfiguration, column: string) => {
  if (configuration.rowGroupingFields && configuration.rowGroupingFields.includes(column)) {
    return GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD
  }

  return column
}

export const getInitialConfigFromSchema = (configuration?: RecommendationArtifacts.DataGridConfiguration) => {
  const finalConfig = {
    ...DEFAULT_DATA_GRID_STATE,
  } as RecommendationArtifacts.GridInitialState

  if (!configuration) {
    return undefined
  }

  if (configuration.rowGroupingFields && configuration.rowGroupingFields.length > 0) {
    const columnVisibilityModel = configuration.rowGroupingFields.reduce((acc, field) => {
      acc[field] = false

      return acc
    }, {} as Record<string, boolean>)

    finalConfig.columns = {
      ...finalConfig.columns,
      columnVisibilityModel,
    }

    finalConfig.rowGrouping = {
      ...finalConfig.rowGrouping,
      model: configuration.rowGroupingFields,
    }
  }

  if (
    (configuration.pinnedLeftColumns && configuration.pinnedLeftColumns.length > 0) ||
    (configuration.pinnedRightColumns && configuration.pinnedRightColumns.length > 0)
  ) {
    const pinnedLeftColumns = (configuration.pinnedLeftColumns || []).map((column) => groupingColumnsReplacer(configuration, column))
    const pinnedRightColumns = (configuration.pinnedRightColumns || []).map((column) => groupingColumnsReplacer(configuration, column))

    finalConfig.pinnedColumns = {
      ...finalConfig.pinnedColumns,
      left: pinnedLeftColumns,
      right: pinnedRightColumns,
    }
  }

  if (configuration.aggregationFields && configuration.aggregationFields.length > 0) {
    const aggregationModel = configuration.aggregationFields.reduce((acc, field) => {
      acc[field.field] = field.aggregationFunction

      return acc
    }, {} as Record<string, string>)

    finalConfig.aggregation = {
      ...finalConfig.aggregation,
      model: {
        ...finalConfig.aggregation?.model,
        ...aggregationModel,
      },
    }
  }

  if (configuration.sorting && configuration.sorting.length > 0) {
    finalConfig.sorting = {
      ...finalConfig.sorting,
      sortModel: (configuration.sorting || []).map((sort) => {
        return {
          field: groupingColumnsReplacer(configuration, sort.field),
          sort: sort.sort,
        }
      }),
    }
  }

  return finalConfig
}

/**
 * Transforms the rows to the format required by the data grid.
 * Used for dynamic tables.
 *
 * @param columns Columns
 * @param rows Rows to transform
 *
 * @returns Transformed rows
 */
export const getTransformedRows = (
  columns: RecommendationArtifacts.DataGridColumnSchema[],
  rows: RecommendationArtifacts.DataGridRowSchema[],
) => {
  const dateTimeFields = columns.filter((column) => column.type === 'dateTime').map((column) => column.field)

  return rows.map((row) => {
    const uuid = v4()
    const rowCopy = { ...row }

    dateTimeFields.forEach((field) => {
      rowCopy[field] = new Date(row[field] as string)
    })

    return {
      ...rowCopy,
      id: uuid,
    }
  }) as RecommendationArtifacts.DataGridRowSchema[]
}

/**
 * Get columns config from dynamic table schema
 *
 * @param columns DataGrid columns schema
 *
 * @returns DataGrid columns config
 */
export const getColumnsConfigFromSchema = (columns?: RecommendationArtifacts.DataGridColumnSchema[]) => {
  if (!columns || !columns.length) {
    return []
  }

  return columns.map((col) => {
    const colCopy = { ...col } as GridColDef

    if (col.type === 'boolean') {
      colCopy.minWidth = DEFAULT_BOOLEAN_COL_MIN_WIDTH
      colCopy.align = 'left'
      colCopy.headerAlign = 'left'
      colCopy.renderCell = (params) => <DataGridBooleanCellComponent params={params} booleanLabels={col.booleanLabels} />
    }

    if (col.type === 'number' && !colCopy.align) {
      colCopy.align = 'right'
    }

    if (col.type === 'number' || col.type === 'float') {
      colCopy.valueFormatter = (params) => {
        if (!params.value) {
          return params.value
        }

        return defaultNumberFormatter(params.value, {
          float: col.type === 'float',
          numberFormatOptions: col.formattingOptions,
        })
      }
    }

    return {
      ...colCopy,
      width: col.minWidth,
      headerAlign: col.align || colCopy.align,
      aggregable: col.aggregable !== false,
      headerClassName: col.cellColor,
      cellClassName: col.cellColor,
    }
  }) as GridColDef[]
}
