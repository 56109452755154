import React from 'react'

import {
  SxProps,
  Typography,
  Theme,
  Box,
} from '@mui/material'

export interface SidePanelSectionHeaderProps {
  /**
   * content of the card
   */
  title: React.ReactNode
  /**
   * if true, the container is hidden
   */
  hidden?: boolean
  /**
   * variant of the typography
   * @default 'subtitle1'
   */
  variant?: 'subtitle1' | 'subtitle2' | 'h5' | 'h6' | 'body1' | 'body2'
  /**
   * custom styles
   */
  sx?: SxProps<Theme>
}

export const SidePanelSectionHeaderComponent: React.FC<SidePanelSectionHeaderProps> = ({
  title,
  hidden,
  sx,
  variant = 'subtitle1',
}) => {
  if (hidden) {
    return null
  }

  return (
    <Box
      data-testid={SidePanelSectionHeaderComponent.name}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        ...sx,
      }}
    >
      <Typography
        variant={variant}
      >
        {title}
      </Typography>
    </Box>
  )
}

export default SidePanelSectionHeaderComponent
