import React from 'react'
import { SwitchProps, Switch } from '@mui/material'
import { TRACKING_ACTIONS, trackEvent } from '@utils/tracking.utils'
import { useRouteMatch } from 'react-router-dom'

import useStyles from './SwitchBase.styles'

export interface SwitchBaseComponentProps extends SwitchProps {
  /**
   * Name of the radio
   */
  name: string
  /**
   * if true, the radio will be checked
   */
  checked?: boolean
  /**
   * if true, the radio will be disabled
   */
  disabled?: boolean
  /**
   * Ref to be forwarded to the Checkbox component
   */
  forwardedRef?: any
  /**
   * Callback fired when the state is changed
   */
  onChange?: (event: React.ChangeEvent<any>, checked: boolean) => void
}

export const SwitchBaseComponent: React.FC<SwitchBaseComponentProps> = ({
  checked,
  forwardedRef,
  name,
  disabled,
  onChange,
  ...props
}) => {
  const { classes } = useStyles()
  const { params } = useRouteMatch<Common.RouterMatch>()

  const onChangeHandler = (event: React.ChangeEvent<any>, checkedValue: boolean) => {
    trackEvent({
      componentName: name,
      actionName: TRACKING_ACTIONS.CHANGE,
    }, {
      checked,
      router: params,
    })

    if (onChange) {
      onChange(event, checkedValue)
    }
  }

  return (
    <Switch
      data-testid={name || SwitchBaseComponent.name}
      ref={forwardedRef}
      checked={checked}
      disableRipple={true}
      name={name}
      disabled={disabled}
      onChange={onChangeHandler}
      size='small'
      classes={{
        root: classes.root,
        track: classes.switchTrackSucceess,
        switchBase: classes.switchBaseSucceess,
        colorPrimary: classes.switchPrimarySucceess,
      }}
      {...props}
    />
  )
}

export default React.forwardRef((props: SwitchBaseComponentProps, ref) => <SwitchBaseComponent {...props} forwardedRef={ref} />)
