import { DEFAULT_BORDER_RADIUS, DEFAULT_SELECT_BOX_SHADOW } from '@constants/ui.constants'
import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles<void>()((theme: Theme) => {
  const width = '380px'

  return {
    root: {
      minWidth: width,
    },
    popper: {
      minWidth: width,
      backgroundColor: theme.palette.new.white,
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
      borderBottomRightRadius: DEFAULT_BORDER_RADIUS.SMALL,
      borderBottomLeftRadius: DEFAULT_BORDER_RADIUS.SMALL,
      boxShadow: DEFAULT_SELECT_BOX_SHADOW,
      zIndex: 100000,
      marginTop: '-49px !important',
      marginLeft: '-13px !important',
    },
    popperDisablePortal: {
      minWidth: width,
      position: 'relative !important' as any,
      transform: 'none !important',
    },
    paper: {
      minWidth: width,
      border: `1px solid ${theme.palette.new.business_black_20}`,
      borderTop: 'none',
      boxShadow: 'none',
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
      borderBottomRightRadius: DEFAULT_BORDER_RADIUS.SMALL,
      borderBottomLeftRadius: DEFAULT_BORDER_RADIUS.SMALL,
      '& .MuiAutocomplete-listbox': {
        maxHeight: '70vh',
      },
    },
    rootAutocomplete: {
      borderBottom: 'none',
      marginTop: '1px',
      height: theme.spacing(6),
    },
    noOptions: {
      backgroundColor: theme.palette.new.smokey_silver,
      padding: theme.spacing(0),
      height: '36px',
      display: 'flex',
      alignItems: 'center',
    },
  }
})

export default useStyles
