import React from 'react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'

import {
  FormControl,
  InputLabel, Select,
  BaseSelectProps, styled,
  useTheme,
} from '@mui/material'

import { getMenuStyles } from '@utils/ui.utils'

import ChevronIcon from '@icons/flow/chevron.icon'
import InputBaseComponent from '@base/forms/InputBase'
import SelectMenuItemComponent from '@base/menus/SelectMenuItem'

const SelectFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: '200px',

  transition: theme.transitions.create([
    'border-color',
    'background-color',
    'box-shadow',
  ]),

  '& .MuiSelect-select.MuiInputBase-input': {
    minHeight: '18px',
    height: '18px',
    lineHeight: '18px',
  },
  '& .MuiInputBase-root.Mui-focused:not(.Mui-error):has(.MuiSelect-select[aria-expanded="true"])': {
    borderColor: theme.palette.new.business_black,
  },
  '& .MuiInputBase-root.Mui-focused.Mui-error:has(.MuiSelect-select[aria-expanded="true"])': {
    borderColor: theme.palette.new.rebellious_red,
  },
  '& .MuiSelect-icon': {
    right: '12px',
  },
}))

const SelectLabel = styled(InputLabel)(({ theme }) => ({
  color: theme.palette.new.business_black_60,
  fontSize: '0.75rem',
  lineHeight: '125%',
  letterSpacing: '0.24px',
  fontWeight: 400,
  transform: 'none',
  marginLeft: '10px',
}))

export interface SimpleSelectOption {
  [key: string]: any,
}

export interface SelectFieldProps extends BaseSelectProps {
  /**
   * The input id. Same as name.
   */
  id?: string,
  /**
   * The input name. Same as id if not provided.
   * Some form libraries require a name attribute.
   */
  name?: string,
  /**
   * The label content.
   */
  label: string
  /**
   * Key for the value of the option
   */
  valueKey?: string,
  /**
   * Key for the label of the option
   */
  labelKey?: string,
  /**
   * If true, react-intl will be used to format the label
   * @default false
   */
  useIntlForLabel?: boolean,
  /**
   * If true, an empty option will be shown
   * @default false
   */
  allowEmpty?: boolean,
  /**
   * The options to be shown
   */
  options?: SimpleSelectOption
  /**
   * If provided, the input will be full width.
   * @default true
   */
  fullWidth?: boolean
  /**
   * If provided, the input will be disabled.
   */
  disabled?: boolean
  /**
   * If true, the label will be hidden.
   * @default false
   */
  hideLabel?: boolean
}

const SelectFieldComponent: React.FC<SelectFieldProps> = ({
  disabled,
  valueKey = 'value',
  labelKey = 'label',
  options = [],
  fullWidth = true,
  label,
  id,
  name,
  useIntlForLabel = false,
  allowEmpty = false,
  hideLabel = false,
  ...rest
}) => {
  const identifier = id || name
  const intl = useIntl()
  const theme = useTheme()

  const menuProps = {
    sx: getMenuStyles(theme),
  }

  return (
    <SelectFormControl
      variant='standard'
      fullWidth={fullWidth}
      data-testid={SelectFieldComponent.name}
      className={classNames({
        disabled,
      })}
    >
      {
        hideLabel ? (
          null
        ) : (
          <SelectLabel shrink={true} htmlFor={identifier}>
            {label}
          </SelectLabel>
        )
      }

      <Select
        variant='standard'
        fullWidth={fullWidth}
        disabled={disabled}
        id={identifier}
        name={name}
        label={label}
        input={<InputBaseComponent />}
        IconComponent={ChevronIcon}
        MenuProps={menuProps}
        aria-label={hideLabel ? label : undefined}
        {...rest}
      >
        {
          allowEmpty ? (
            <SelectMenuItemComponent
              value=''
            >
              {intl.formatMessage({ id: 'common.none' })}
            </SelectMenuItemComponent>
          ) : (
            null
          )
        }

        {
          options?.map((optionItem: SimpleSelectOption) => {
            return (
              <SelectMenuItemComponent
                key={optionItem[valueKey]}
                value={optionItem[valueKey]}
              >
                {
                  useIntlForLabel ? intl.formatMessage({ id: optionItem[labelKey] }) : optionItem[labelKey]
                }
              </SelectMenuItemComponent>
            )
          })
        }
      </Select>
    </SelectFormControl>
  )
}

export default SelectFieldComponent
