import moment from 'moment'
import { EVALUATION_PLOT_FILE_TYPES } from '@constants/analysis.constants'
import { getReactIntlLocaleByShortCode } from '@decorators/ReactIntl.decorator'
import { FULL_LOCALES, SHORT_LOCALES } from '@constants/locales.constants'
import { IntlShape } from 'react-intl'

export type formattedNumber = string | number
export type comparisonResults = 1 | 0 | -1
export interface SuffixMap {
  key: keyof Suffixes,
  value: number,
}

export interface Suffixes {
  M: number,
  B: number,
  T: number,
}

export const DEFAULT_SUFFIX = 'M' as keyof Suffixes
export const SUFFIXES: Suffixes = {
  M: 6,
  B: 9,
  T: 12,
}

/**
 * @function isValueInRange Checks whether value is in the provided range
 *
 * @param {Number} value Value in range 1:0
 * @param {Array} range Array of [min, max] values in which value can be presented
 *
 * @return {Boolean} true, if value in range
 */
export const isValueInRange = (value: number, range?: number[]): boolean => {
  if (!range) {
    return true
  }

  const min = range[0]
  const max = range[1]

  return (value <= max) && (value >= min)
}

/**
 * @function getSelectedPointIndex Checks whether value is in the provided range
 *
 * @param {Array} dataset Dataset array
 * @param {Number} x X value
 * @param {Number} y Y value
 *
 * @return {Number} Index of element in dataset
 */
export const getSelectedPointIndex = (
  dataset: object[],
  x: number,
  y: number,
) => {
  if (!dataset || dataset.length === 0) {
    return -1
  }

  return dataset.findIndex((el: {
    [key: string]: any
  }) => {
    return (el.x === x) && (el.y === y)
  })
}

/**
 * @function normalizeValue Normalizes value in %
 *
 * @param {Number} value Value to normalize
 * @param {Number} min Value minimum
 * @param {Number} max Value maximum
 *
 * @return {Number} Normalized value
 */
export const normalizeValue = (
  value: number,
  min: number = 0,
  max: number = 100,
): number => {
  if ((value === min) && (value === max)) {
    return 100
  }

  return ((value - min) * 100) / (max - min)
}

/**
 * @function getEvaluationPlotFileType Returns evaluation plot file type
 *
 * @param {File} file File
 *
 * @return {string} one of EVALUATION_PLOT_FILE_TYPES
 */
export const getEvaluationPlotFileType = (file?: File): EVALUATION_PLOT_FILE_TYPES => {
  if (!file) {
    return EVALUATION_PLOT_FILE_TYPES.SVG
  }
  const fileType = file.type === 'application/json' ? EVALUATION_PLOT_FILE_TYPES.JSON : EVALUATION_PLOT_FILE_TYPES.SVG

  return fileType
}

/**
 * @function defaultNumberFormatter Returns formatted number
 *
 * @param {Number | String} value value to format
 * @param {Object} options Intl.NumberFormatOptions & locale
 *
 * @return {Number | String} Returns formatted number
 */
export const defaultNumberFormatter = (value: number | string | null, options?: {
  float?: boolean,
  numberFormatOptions?: Intl.NumberFormatOptions,
  intl?: IntlShape,
}): string => {
  const num = Number(value)
  const {
    numberFormatOptions,
    intl,
    float,
  } = options || {}

  const momentLocale = moment.locale() as SHORT_LOCALES
  const momentFullLocale = getReactIntlLocaleByShortCode(momentLocale)
  const finalLocale = intl ? (intl.locale as FULL_LOCALES) : momentFullLocale

  return isNaN(num) ? String(value) : num.toLocaleString(finalLocale, {
    maximumFractionDigits: float ? 2 : 0,
    minimumFractionDigits: 0,
    ...numberFormatOptions,
  })
}

/**
 * @function getNumberSuffix Returns formatted number
 *
 * @param {Number | String} value value to format
 *
 * @return {String} Returns number suffix
 */
export const getNumberSuffix = (value: number | string): SuffixMap | null => {
  const num = Number(value)

  if (isNaN(num)) {
    return null
  }

  let suffix = DEFAULT_SUFFIX

  for (const key of Object.keys(SUFFIXES)) {
    const keyToUse = key as keyof Suffixes
    if (num >= Math.pow(10, SUFFIXES[keyToUse])) {
      suffix = keyToUse
    } else {
      break
    }
  }

  return {
    key: suffix,
    value: SUFFIXES[suffix],
  }
}

/**
 * @function formatNumberWithSuffixUnit Returns formatted number
 *
 * @param {Number | String} value value to format
 * @param {String} customSuffix custom suffix
 * @param {String} unit unit to format
 *
 * @return {String} Returns formatted number
 */
export const formatNumberWithSuffixUnit = (value: number | string, customSuffix?: SuffixMap | null, numberOnly = false, unit = '€') => {
  const num = Number(value)

  if (isNaN(num)) {
    return String(value)
  }

  const suffix = customSuffix || getNumberSuffix(num)

  if (!suffix) {
    return String(value)
  }

  const formattedValue = defaultNumberFormatter((num / Math.pow(10, suffix.value)), {
    numberFormatOptions: {
      maximumFractionDigits: 1,
      minimumFractionDigits: 1,
    },
  })

  return numberOnly ? String(formattedValue) : `${formattedValue} ${suffix.key}${unit}`
}

/**
 * @function roundToNearestRange Returns rounded value to nearest range
 *
 * @param {String} value value to round
 * @param {Number[]} range range to round to
 *
 * @return {Number} Returns rounded value
 */
export const roundToNearestRange = (value: string, range: string[]) => {
  const parsedValue = parseFloat(value)
  const roundedValues = range.map(Number)

  const closestValue = roundedValues.reduce((prev, curr) => {
    return (Math.abs(curr - parsedValue) < Math.abs(prev - parsedValue) ? curr : prev)
  })

  return closestValue
}

/**
 * @function formatNumberWithSuffix Returns formatted number
 *
 * @param {Number | String} value value to format
 *
 * @return {String} Returns formatted number
 */
export const formatNumberWithSuffix = (value?: number | string) => {
  if (value === undefined) {
    return 'n/a'
  }

  const num = Number(value)

  if (isNaN(num)) {
    return 'n/a'
  }
  const suffixes = ['', 'k', 'M', 'B', 'T']
  const order = Math.floor(Math.log10(Math.abs(num)) / 3)

  const formattedNumber = defaultNumberFormatter((num / Math.pow(10, order * 3)), {
    numberFormatOptions: {
      maximumFractionDigits: 1,
      minimumFractionDigits: 0,
    },
  })

  if (!suffixes[order]) {
    return formattedNumber
  }

  return formattedNumber + suffixes[order]
}
