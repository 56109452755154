import React from 'react'
import { Typography } from '@mui/material'
import OptimizeValueComponent from '@components/(features)/optimize/optimize-value'

import useStyles from './OptimizeRestockDetailsItem.styles'

export interface OptimizeRestockDetailsItemComponentProps {
  header: string,
  subHeader?: string,
  description?: React.ReactNode | React.ReactNode[] | null,
  value?: number | string | React.ReactNode | React.ReactNode[] | null,
  subValue?: React.ReactNode | React.ReactNode[] | null,
  borderRight?: boolean,
  colorless?: boolean,
}

const OptimizeRestockDetailsItemComponent: React.FC<OptimizeRestockDetailsItemComponentProps> = (props) => {
  const {
    header,
    subHeader,
    value,
    subValue,
    borderRight,
    colorless,
    description,
  } = props

  const { classes, cx } = useStyles()

  return (
    <div
      className={cx(classes.root, {
        [classes.borderRight]: borderRight,
        [classes.colorless]: colorless,
      })}
      data-testid={OptimizeRestockDetailsItemComponent.name}
    >
      <div className={classes.headerRoot}>
        {
          subHeader ? (
            <Typography className={classes.subHeader}>
              {subHeader}
            </Typography>
          ) : (
            null
          )
        }
        <Typography className={classes.header}>
          {header}
        </Typography>
        {
          description && typeof description === 'string' ? (
            <Typography className={classes.description}>
              {description}
            </Typography>
          ) : (
            null
          )
        }
        {
          description && typeof description !== 'string' ? description : null
        }
      </div>
      <div className={classes.valueRoot}>
        {
          (typeof value === 'number' || typeof value === 'string') ? (
            <>
              <OptimizeValueComponent value={value} />

              {
                (typeof subValue === 'number' || typeof subValue === 'string') ? (
                  <Typography className={classes.subValue}>
                    {subValue}
                  </Typography>
                ) : (
                  subValue
                )
              }
            </>
          ) : (
            value
          )
        }
      </div>
    </div>
  )
}

export default OptimizeRestockDetailsItemComponent
