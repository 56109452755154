import { DEFAULT_BORDER_RADIUS, DEFAULT_SELECT_BOX_SHADOW } from '@constants/ui.constants'
import { Theme } from '@mui/material'
import { getAutocompleteEndAdornmentButtonStyles } from '@utils/ui.utils'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles<{ floatingHelp: boolean }>()((theme: Theme, { floatingHelp }) => {
  return {
    popper: {
      marginTop: '1px !important',
      marginBottom: '1px !important',
    },
    paper: {
      outline: `1px solid ${theme.palette.new.business_black_20}`,
      boxShadow: DEFAULT_SELECT_BOX_SHADOW,
      borderRadius: DEFAULT_BORDER_RADIUS.SMALL,
    },
    noOptions: {
      backgroundColor: theme.palette.new.smokey_silver,
      padding: theme.spacing(0),
      height: '36px',
      display: 'flex',
      alignItems: 'center',
    },
    inputRoot: {
      gap: '5px',
      paddingRight: `${floatingHelp ? '80px' : '40px'} !important`,

      '&.MuiInputBase-root': {
        minHeight: '36px',
      },
    },
    endAdornment: getAutocompleteEndAdornmentButtonStyles(theme, floatingHelp) as any,
    root: {
      '&.MuiAutocomplete-root.Mui-focused.Mui-expanded': {
        '& .MuiInputBase-root.Mui-focused:not(.Mui-error)': {
          borderColor: theme.palette.new.business_black,
        },
        '& .MuiInputBase-root.Mui-focused.Mui-error': {
          borderColor: theme.palette.new.rebellious_red,
        },
      },
    },
  }
})

export default useStyles
