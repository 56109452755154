import { createAction } from 'redux-actions'

import {
  REQUEST_PARAMETERS_TEMPLATES,
  RECEIVE_PARAMETERS_TEMPLATES,

  REQUEST_PARAMETER_TEMPLATE,
  RECEIVE_PARAMETER_TEMPLATE,

  CREATE_PARAMETERS_TEMPLATES,
  UPDATE_PARAMETERS_TEMPLATES,
  DELETE_PARAMETERS_TEMPLATES,

  START_FETCHING_PARAMETERS_TEMPLATES,
  STOP_FETCHING_PARAMETERS_TEMPLATES,
} from './parameters-templates.action-types'

import {
  FetchPayload,
  CreatePayload,
  UpdatePayload,
  RemovePayload,
} from './parameters-templates.types'

export const requestParametersTemplatesAction = createAction(REQUEST_PARAMETERS_TEMPLATES)
export const receiveParametersTemplatesActionDone = createAction(RECEIVE_PARAMETERS_TEMPLATES)

export const requestParameterTemplateAction = createAction<FetchPayload>(REQUEST_PARAMETER_TEMPLATE)
export const receiveParameterTemplateActionDone = createAction(RECEIVE_PARAMETER_TEMPLATE)

export const createParameterTemplateAction = createAction<CreatePayload>(CREATE_PARAMETERS_TEMPLATES)
export const updateParameterTemplateAction = createAction<UpdatePayload>(UPDATE_PARAMETERS_TEMPLATES)
export const removeParameterTemplateAction = createAction<RemovePayload>(DELETE_PARAMETERS_TEMPLATES)

export const startFetchingParametersTemplatesAction = createAction<string>(START_FETCHING_PARAMETERS_TEMPLATES)
export const stopFetchingParametersTemplatesAction = createAction(STOP_FETCHING_PARAMETERS_TEMPLATES)
