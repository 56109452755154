import {
  takeLatest,
  put, call,
} from 'redux-saga/effects'

import { TOAST_TYPE_ERROR } from '@constants/common.constants'
import { isNotFound, parseAndReportErrorResponse } from '@utils/redux.utils'
import { changeToastAction } from '@redux/modules/common/common.actions'
import { ActionPayload } from '@redux/modules/types'

import * as API from './recommendation-artifacts.api'

import {
  REQUEST_TOP_ARTICLES_ARTIFACT,
  REQUEST_CONSTRAINTS_ANALYZE_ARTIFACT,
  REQUEST_KPI_ITEMS_ARTIFACT,
  REQUEST_VISUALISATIONS_ARTIFACT,
  REQUEST_ARTICLES_OVERVIEW_TABLE_ARTIFACT,
  REQUEST_ARTICLES_OVERVIEW_DETAILS_ARTIFACT,
  REQUEST_KPIS_PER_ITEM_ARTIFACT,
} from './recommendation-artifacts.action-types'

import {
  receiveTopArticlesArtifactActionDone,
  receiveKPIItemsActionArtifactDone,
  receiveConstraintsAnalyzeArtifactActionDone,
  receiveVisualisationsArtifactActionDone,
  receiveArticlesOverviewArtifactActionDone,
  receiveKPIsPerItemArtifactActionDone,
  receiveArticlesOverviewDetailsArtifactActionDone,
  requestArticlesOverviewDetailsArtifactAction,
  startFetchingRecommendationArtifacts,
  stopFetchingRecommendationArtifacts,
  selectArticlesOverviewDetailsItemAction,
} from './recommendation-artifacts.actions'

import {
  RequestVisualisationsArtifactActionPayload,
  RequestTopArticlesArtifactActionPayload,
  ReqestKPIItemsArtifactActionPayload,
  ReqestConstraintsAnalyzeArtifactActionPayload,
  ReqestArticlesOverviewTableArtifactActionPayload,
  ReqestArticlesOverviewDetailsArtifactActionPayload,
  ReqestKPIsPerItemArtifactActionPayload,
} from './recommendation-artifacts.types'

import { initialState } from './recommendation-artifacts.reducers'

function* requestConstraintsAnalyzeGenerator({ payload }: ActionPayload<ReqestConstraintsAnalyzeArtifactActionPayload>) {
  try {
    yield put(startFetchingRecommendationArtifacts(REQUEST_CONSTRAINTS_ANALYZE_ARTIFACT))

    const data: RecommendationArtifacts.ConstraintsAnalyzeArtifact = yield call(API.fetchConstraintsAnalyzeArtifact, payload)

    yield put(receiveConstraintsAnalyzeArtifactActionDone(data))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    const isNotFoundError = isNotFound(e as any)

    if (!isNotFoundError) {
      yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
    }

    yield put(receiveConstraintsAnalyzeArtifactActionDone({
      rows: [],
      columns: [],
      gridConfiguration: {},
    }))
  } finally {
    yield put(stopFetchingRecommendationArtifacts(REQUEST_CONSTRAINTS_ANALYZE_ARTIFACT))
  }
}

function* requestTopArticlesArtifactGenerator({ payload }: ActionPayload<RequestTopArticlesArtifactActionPayload>) {
  try {
    yield put(startFetchingRecommendationArtifacts(REQUEST_TOP_ARTICLES_ARTIFACT))

    const data: RecommendationArtifacts.TopArticlesArtifact = yield call(API.fetchTopArticlesArtifact, payload)

    yield put(receiveTopArticlesArtifactActionDone(data))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    const isNotFoundError = isNotFound(e as any)

    if (!isNotFoundError) {
      yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
    }

    yield put(receiveTopArticlesArtifactActionDone({
      tables: [],
      metaData: {
        topOptions: [],
        defaultTopOption: 0,
        tablesPerRow: 0,
        totalCount: 0,
      },
    }))
  } finally {
    yield put(stopFetchingRecommendationArtifacts(REQUEST_TOP_ARTICLES_ARTIFACT))
  }
}

function* requestKPIItemsArtifactGenerator({ payload }: ActionPayload<ReqestKPIItemsArtifactActionPayload>) {
  try {
    yield put(startFetchingRecommendationArtifacts(REQUEST_KPI_ITEMS_ARTIFACT))

    const data: RecommendationArtifacts.KPIItemsArtifact = yield call(API.fetchKPIItemsArtifact, payload)

    yield put(receiveKPIItemsActionArtifactDone(data))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    const isNotFoundError = isNotFound(e as any)

    if (!isNotFoundError) {
      yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
    }

    yield put(receiveKPIItemsActionArtifactDone(initialState.KPIItemsArtifact))
  } finally {
    yield put(stopFetchingRecommendationArtifacts(REQUEST_KPI_ITEMS_ARTIFACT))
  }
}

function* requestVisualisationsArtifactGenerator({ payload }: ActionPayload<RequestVisualisationsArtifactActionPayload>) {
  try {
    yield put(startFetchingRecommendationArtifacts(REQUEST_VISUALISATIONS_ARTIFACT))

    const data: RecommendationArtifacts.VisualisationsArtifact = yield call(API.fetchVisualisationsArtifact, payload)

    yield put(receiveVisualisationsArtifactActionDone(data))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    const isNotFoundError = isNotFound(e as any)

    if (!isNotFoundError) {
      yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
    }

    yield put(receiveVisualisationsArtifactActionDone({ visualisations: [] }))
  } finally {
    yield put(stopFetchingRecommendationArtifacts(REQUEST_VISUALISATIONS_ARTIFACT))
  }
}

function* requestArticlesOverviewTableArtifactGenerator({ payload }: ActionPayload<ReqestArticlesOverviewTableArtifactActionPayload>) {
  try {
    yield put(startFetchingRecommendationArtifacts(REQUEST_ARTICLES_OVERVIEW_TABLE_ARTIFACT))

    const data: RecommendationArtifacts.ArticlesOverviewTableArtifact = yield call(API.fetchArticlesOverviewTableArtifact, payload)

    if (data.metaData.defaultSelection || data.table.rows.length > 0) {
      const firstRowItemId = data.table.rows[0][data.metaData.rowIdentifierKey]
      const itemId = data.metaData.defaultSelection || firstRowItemId

      yield put(requestArticlesOverviewDetailsArtifactAction({
        useCaseId: payload.useCaseId,
        itemId: String(itemId),
      }))
    }

    yield put(receiveArticlesOverviewArtifactActionDone(data))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    const isNotFoundError = isNotFound(e as any)

    if (!isNotFoundError) {
      yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
    }

    yield put(receiveArticlesOverviewArtifactActionDone({
      table: {
        columns: [],
        rows: [],
        gridInitialState: undefined,
        metaData: {
          paretosValueColumnNames: [],
          actualValueColumnNames: [],
        },
      },
      metaData: {
        paretosValueKeys: [],
        actualValueKeys: [],
        data: [],
      },
    }))
  } finally {
    yield put(stopFetchingRecommendationArtifacts(REQUEST_ARTICLES_OVERVIEW_TABLE_ARTIFACT))
  }
}

function* requestArticlesOverviewDetailsArtifactGenerator({ payload }: ActionPayload<ReqestArticlesOverviewDetailsArtifactActionPayload>) {
  try {
    yield put(startFetchingRecommendationArtifacts(REQUEST_ARTICLES_OVERVIEW_DETAILS_ARTIFACT))

    yield put(selectArticlesOverviewDetailsItemAction(payload.itemId))

    const data: RecommendationArtifacts.ArticlesOverviewDetailsArtifact = yield call(API.fetchArticlesOverviewDetailsArtifact, payload)

    yield put(receiveArticlesOverviewDetailsArtifactActionDone({
      itemId: payload.itemId,
      kpiItems: data.kpiItems,
      visualisations: data.visualisations,
      breakdown: data.breakdown,
    }))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    const isNotFoundError = isNotFound(e as any)

    if (!isNotFoundError) {
      yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
    }

    yield put(receiveArticlesOverviewDetailsArtifactActionDone(initialState.articlesOverviewDetailsArtifact))
  } finally {
    yield put(stopFetchingRecommendationArtifacts(REQUEST_ARTICLES_OVERVIEW_DETAILS_ARTIFACT))
  }
}

function* requestKPIsPerItemArtifactGenerator({ payload }: ActionPayload<ReqestKPIsPerItemArtifactActionPayload>) {
  try {
    yield put(startFetchingRecommendationArtifacts(REQUEST_KPIS_PER_ITEM_ARTIFACT))

    const data: RecommendationArtifacts.KPIsPerItemArtifact = yield call(API.fetchKPIsPerItemArtifact, payload)

    yield put(receiveKPIsPerItemArtifactActionDone(data))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    const isNotFoundError = isNotFound(e as any)

    if (!isNotFoundError) {
      yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
    }

    yield put(receiveKPIsPerItemArtifactActionDone(initialState.KPIsPerItemArtifact))
  } finally {
    yield put(stopFetchingRecommendationArtifacts(REQUEST_KPIS_PER_ITEM_ARTIFACT))
  }
}

export function* watchRequestArticlesOverviewTableArtifact() {
  yield takeLatest(REQUEST_ARTICLES_OVERVIEW_TABLE_ARTIFACT, requestArticlesOverviewTableArtifactGenerator)
}

export function* watchRequestArticlesOverviewDetailsArtifact() {
  yield takeLatest(REQUEST_ARTICLES_OVERVIEW_DETAILS_ARTIFACT, requestArticlesOverviewDetailsArtifactGenerator)
}

export function* watchRequestrequestKPIItemsArtifact() {
  yield takeLatest(REQUEST_KPI_ITEMS_ARTIFACT, requestKPIItemsArtifactGenerator)
}

export function* watchRequestConstraintsAnalyzeArtifact() {
  yield takeLatest(REQUEST_CONSTRAINTS_ANALYZE_ARTIFACT, requestConstraintsAnalyzeGenerator)
}

export function* watchRequestTopArticlesArtifact() {
  yield takeLatest(REQUEST_TOP_ARTICLES_ARTIFACT, requestTopArticlesArtifactGenerator)
}

export function* watchRequestVisualisationsArtifact() {
  yield takeLatest(REQUEST_VISUALISATIONS_ARTIFACT, requestVisualisationsArtifactGenerator)
}

export function* watchRequestKPIsPerItemArtifact() {
  yield takeLatest(REQUEST_KPIS_PER_ITEM_ARTIFACT, requestKPIsPerItemArtifactGenerator)
}
