import { IntlShape } from 'react-intl'

import {
  PROCESSING_STATES, MAX_FILE_NAME_LENGTH,
} from '@constants/files.constants'

export const isProcessingStatusNotAllowedForCsvPreview = (processingStatus: PROCESSING_STATES) => {
  return [
    PROCESSING_STATES.ERROR,
    PROCESSING_STATES.QUEUED,
    PROCESSING_STATES.RUNNING,
  ].includes(processingStatus)
}

export const isProcessingStatusNotAllowedForCsvEdit = (processingStatus: PROCESSING_STATES) => {
  return [
    PROCESSING_STATES.ERROR,
    PROCESSING_STATES.QUEUED,
    PROCESSING_STATES.RUNNING,
    PROCESSING_STATES.SUCCEEDED,
  ].includes(processingStatus)
}

export const isActionButtonDisabled = (isUploading: boolean, record: TrainingFiles.TrainingFileItem) => {
  return isUploading || [PROCESSING_STATES.QUEUED, PROCESSING_STATES.RUNNING].includes(record.processingStatus)
}

/**
 * Returns label key for file processing status
 * @param status file processing status
 * @returns label key
 */
export const getFileProcessingLabelKey = (status: PROCESSING_STATES) => {
  switch (status) {
    case PROCESSING_STATES.ERROR:
      return 'connect.data_input.modal.dropzone.status.worflow_error'
    case PROCESSING_STATES.FAILED:
      return 'connect.data_input.modal.dropzone.status.error'
    case PROCESSING_STATES.NO_PROCESSING:
      return 'connect.data_input.modal.dropzone.status.transferred'
    case PROCESSING_STATES.SUCCEEDED:
      return 'connect.data_input.modal.dropzone.status.processed'
    case PROCESSING_STATES.QUEUED:
      return 'connect.data_input.modal.dropzone.status.queued'
    case PROCESSING_STATES.RUNNING:
    default:
      return 'connect.data_input.modal.dropzone.status.processing'
  }
}

/**
 * Returns processing status options
 * @param intl intl object
 * @returns processing status options
 */
export const getProcessingStatusOptions = (intl: IntlShape) => {
  return [
    PROCESSING_STATES.ERROR,
    PROCESSING_STATES.FAILED,
    PROCESSING_STATES.NO_PROCESSING,
    PROCESSING_STATES.QUEUED,
    PROCESSING_STATES.RUNNING,
    PROCESSING_STATES.SUCCEEDED,
  ].map((status) => {
    return {
      value: status,
      label: intl.formatMessage({ id: getFileProcessingLabelKey(status) }),
    }
  })
}

/**
 * Validates file name length
 * @param file File to validate
 * @param maxLength Maximum length of the file name
 * @returns if 'true' file name is valid
 */
export const isFileNameLengthValid = (fileName: string, maxLength = MAX_FILE_NAME_LENGTH) => {
  if (fileName.length > maxLength) {
    return false
  }

  return true
}
