import { DateRange } from '@mui/x-date-pickers-pro'
import moment, { Moment } from 'moment'
import { IntlShape } from 'react-intl'
import { deDE, enUS } from '@mui/x-date-pickers-pro/locales'
import { DATE_PICKER_SHORTCUT } from '@constants/date.constants'
import { SHORT_LOCALES } from '@constants/locales.constants'
import { shortcutOptionToDateRange } from '@utils/moment.utils'

export const SHORTCUT_OPTIONS = [{
  labelKey: 'common.time_window.from_today',
  value: DATE_PICKER_SHORTCUT.FROM_TODAY,
}, {
  labelKey: 'common.time_window.last_week',
  value: DATE_PICKER_SHORTCUT.LAST_WEEK,
}, {
  labelKey: 'common.time_window.this_month',
  value: DATE_PICKER_SHORTCUT.THIS_MONTH,
}, {
  labelKey: 'common.time_window.last_month',
  value: DATE_PICKER_SHORTCUT.LAST_MONTH,
}, {
  labelKey: 'common.time_window.this_year',
  value: DATE_PICKER_SHORTCUT.THIS_YEAR,
}, {
  labelKey: 'common.time_window.last_year',
  value: DATE_PICKER_SHORTCUT.LAST_YEAR,
}, {
  labelKey: 'common.time_window.since_inception',
  value: DATE_PICKER_SHORTCUT.SINCE_INCEPTION,
}]

/**
 * @function dateRangeToShortcutOption - Convert date range to time window option
 *
 * @param dateRange - Date range
 *
 * @returns {DATE_PICKER_SHORTCUT} Time window option
 */
export const dateRangeToShortcutOption = (dateRange: DateRange<Moment>) => {
  const [from, to] = dateRange

  if (from === null && to === null) {
    return DATE_PICKER_SHORTCUT.SINCE_INCEPTION
  }

  if (from && from.isSame(moment().utc().startOf('day')) && to === null) {
    return DATE_PICKER_SHORTCUT.FROM_TODAY
  }

  if (from !== null && to !== null) {
    if (from.isSame(moment().utc().subtract(7, 'days').startOf('week'), 'day') && to.isSame(moment().utc().subtract(7, 'days').endOf('week'), 'day')) {
      return DATE_PICKER_SHORTCUT.LAST_WEEK
    }

    if (from.isSame(moment().utc().startOf('month'), 'day') && to.isSame(moment().utc().endOf('month'), 'day')) {
      return DATE_PICKER_SHORTCUT.THIS_MONTH
    }

    if (from.isSame(moment().utc().subtract(1, 'month').startOf('month'), 'day') && to.isSame(moment().utc().subtract(1, 'month').endOf('month'), 'day')) {
      return DATE_PICKER_SHORTCUT.LAST_MONTH
    }

    if (from.isSame(moment().utc().startOf('year'), 'day') && to.isSame(moment().utc().endOf('year'), 'day')) {
      return DATE_PICKER_SHORTCUT.THIS_YEAR
    }

    if (from.isSame(moment().utc().subtract(1, 'year').startOf('year'), 'day') && to.isSame(moment().utc().subtract(1, 'year').endOf('year'), 'day')) {
      return DATE_PICKER_SHORTCUT.LAST_YEAR
    }
  }

  return DATE_PICKER_SHORTCUT.CUSTOM
}

/**
 * @function getShortcutsItems - Get shortcuts items
 *
 * @param intl React Intl
 * @param resetValues Values used to reset the date range
 *
 * @returns {Common.PickersShortcutsItem<DateRange<Moment>>[]} Shortcuts items
 */
export const getShortcutsItems = (intl: IntlShape, hiddenOptions?: DATE_PICKER_SHORTCUT[]) => {
  return SHORTCUT_OPTIONS.filter((option) => {
    return !hiddenOptions?.includes(option.value)
  }).map((option) => {
    return {
      id: option.value,
      label: intl.formatMessage({ id: option.labelKey }),
      getValue: () => {
        return shortcutOptionToDateRange(option.value)
      },
    }
  }) as Common.PickersShortcutsItem<DateRange<Moment>>[]
}

/**
 * @function getDatePickerLocales - Get date picker locales
 *
 * @param userLanguage user language
 * @returns {Record<string, string>} Locale text
 */
export const getDatePickerLocales = (userLanguage: SHORT_LOCALES) => {
  switch (userLanguage) {
    case SHORT_LOCALES.en:
      return enUS.components.MuiLocalizationProvider.defaultProps.localeText
    case SHORT_LOCALES.de:
      return deDE.components.MuiLocalizationProvider.defaultProps.localeText
    default:
      return enUS.components.MuiLocalizationProvider.defaultProps.localeText
  }
}

/**
 * @function getShortcutsAvailability - Get shortcuts availability
 *
 * @param minDate - Minimum date
 * @param maxDate - Maximum date
 *
 * @returns Available shortcuts
 */
export const getShortcutsAvailability = (minDate?: Moment, maxDate?: Moment) => {
  const availability: {
    [key: string]: boolean;
  } = {
    [DATE_PICKER_SHORTCUT.SINCE_INCEPTION]: true,
  }

  if (!minDate || !maxDate) {
    return {
      [DATE_PICKER_SHORTCUT.FROM_TODAY]: true,
      [DATE_PICKER_SHORTCUT.LAST_WEEK]: true,
      [DATE_PICKER_SHORTCUT.LAST_MONTH]: true,
      [DATE_PICKER_SHORTCUT.LAST_YEAR]: true,
      [DATE_PICKER_SHORTCUT.THIS_MONTH]: true,
      [DATE_PICKER_SHORTCUT.THIS_YEAR]: true,
      [DATE_PICKER_SHORTCUT.SINCE_INCEPTION]: true,
    }
  }

  const isEndOfLastWeekAvailable = moment()
    .subtract(1, 'week')
    .endOf('isoWeek')
    .isSameOrBefore(maxDate, 'week')

  const isStartOfLastWeekAvailable = moment()
    .subtract(1, 'week')
    .startOf('isoWeek')
    .isSameOrAfter(minDate, 'week')

  availability[DATE_PICKER_SHORTCUT.LAST_WEEK] = isEndOfLastWeekAvailable && isStartOfLastWeekAvailable

  const isEndOfThisMonthAvailable = moment()
    .endOf('month')
    .isSameOrBefore(maxDate, 'month')
  const isStartOfThisMonthAvailable = moment()
    .startOf('month')
    .isSameOrAfter(minDate, 'month')

  availability[DATE_PICKER_SHORTCUT.THIS_MONTH] = isEndOfThisMonthAvailable && isStartOfThisMonthAvailable

  const isEndOfLastMonthAvailable = moment()
    .subtract(1, 'month')
    .endOf('month')
    .isSameOrBefore(maxDate, 'month')
  const isStartOfLastMonthAvailable = moment()
    .subtract(1, 'month')
    .startOf('month')
    .isSameOrAfter(minDate, 'month')

  availability[DATE_PICKER_SHORTCUT.LAST_MONTH] = isStartOfLastMonthAvailable && isEndOfLastMonthAvailable

  const isEndOfThisYearAvailable = moment()
    .endOf('year')
    .isSameOrBefore(maxDate, 'year')
  const isStartOfThisYearAvailable = moment()
    .startOf('year')
    .isSameOrAfter(minDate, 'year')

  availability[DATE_PICKER_SHORTCUT.THIS_YEAR] = isEndOfThisYearAvailable && isStartOfThisYearAvailable

  const isEndOfLastYearAvailable = moment()
    .subtract(1, 'year')
    .endOf('year')
    .isSameOrBefore(maxDate, 'year')
  const isStartOfLastYearAvailable = moment()
    .subtract(1, 'year')
    .startOf('year')
    .isSameOrAfter(minDate, 'year')

  availability[DATE_PICKER_SHORTCUT.LAST_YEAR] = isStartOfLastYearAvailable && isEndOfLastYearAvailable

  availability[DATE_PICKER_SHORTCUT.FROM_TODAY] = true

  return availability
}
