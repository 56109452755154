import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { FLOW_ELEMENTS_TRANSITIONS } from '@constants/flow.constants'

const useStyles = makeStyles<void, 'buttonIcon'>()((theme: Theme, props, classes) => ({
  newInputItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    height: theme.spacing(10),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    marginTop: theme.spacing(2),
    cursor: 'pointer',
    background: theme.palette.new.smokey_silver,
    border: '1px solid rgba(0, 0, 0, 0.2)',
    outline: 'none',
    ...FLOW_ELEMENTS_TRANSITIONS,
    '&:hover': {
      background: theme.palette.new.white,
      border: '1px solid rgba(0, 0, 0, 0.4)',
    },
    '&:focus-visible': {
      background: theme.palette.new.white,
      border: '1px solid rgba(0, 0, 0, 0.4)',
    },
  },
  newTargetItem: {
    background: 'rgba(255, 255, 255, 0.4)',
    border: '1px solid transparent',
    [`& .${classes.buttonIcon}`]: {
      ...FLOW_ELEMENTS_TRANSITIONS,
      background: 'rgba(255, 255, 255, 0.4)',
    },
    '&:hover': {
      background: 'rgba(255, 255, 255, 0.6)',
      border: '1px solid transparent',

      [`& .${classes.buttonIcon}`]: {
        background: 'transparent',
      },
    },
  },
  buttonIcon: {
    display: 'flex',
    height: theme.spacing(4),
    width: theme.spacing(4),
    background: theme.palette.new.white,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.spacing(5),
  },
  disabled: {
    cursor: 'not-allowed',
  },
}))

export default useStyles
