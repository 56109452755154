import React from 'react'
import { Box, IconButton } from '@mui/material'

import ChevronIcon from '@icons/flow/chevron.icon'

import useStyles from './TablePaginationActions.styles'

export interface TablePaginationActionsProps {
  count: number,
  onPageChange: (event: any, page: number) => void,
  page: number,
  rowsPerPage: number,
}

const TablePaginationActionsComponent: React.FC<TablePaginationActionsProps> = ({
  count,
  onPageChange,
  page,
  rowsPerPage,
}) => {
  const { classes } = useStyles()

  const handleBackButtonClick = (event: any) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event: any) => {
    onPageChange(event, page + 1)
  }

  return (
    <Box className={classes.root} data-testid={TablePaginationActionsComponent.name}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
        className={classes.buttonRoot}
        size='large'
      >
        <ChevronIcon className={classes.iconLeft} />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
        className={classes.buttonRoot}
        size='large'
      >
        <ChevronIcon className={classes.iconRight} />
      </IconButton>
    </Box>
  )
}

export default TablePaginationActionsComponent
