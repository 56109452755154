import React, {
  useState, useMemo,
  useEffect, SyntheticEvent,
} from 'react'

import groupBy from 'lodash.groupby'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import {
  Accordion, AccordionSummary,
  AccordionDetails, Typography,
  Box,
} from '@mui/material'

import { sortByKey } from '@utils/list.utils'
import { copyToClipboard } from '@utils/common.utils'
import { getUseCaseItem } from '@redux/modules/use-case/use-case.selectors'
import { getUseCaseFileIdentifiers } from '@redux/modules/training-files/training-files.selectors'
import { getCompanyConnectionsBySource } from '@redux/modules/hermes/hermes.selectors'
import { getModalDetails } from '@redux/modules/modal-manager/modal-manager.selectors'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'
import { DATA_SOURCES_MODAL_NAME } from '@constants/modals.constants'
import { DESC_SORTING_ORDER } from '@constants/filters.constants'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { matchSourcesToApi, MappedSources } from '@utils/flow.utils'
import { SOURCE_TYPES_CATEGORIES, CATEGORIES_TO_LABEL_MAP } from '@constants/flow.constants'
import { getFileIdentifiersCount } from '@utils/use-cases.utils'
import { AuthDataSourceModalDetails } from '@containers/modals/auth-data-source-modal/AuthDataSourceModal.container'
import { TRACKING_ACTIONS, trackEvent } from '@utils/tracking.utils'

import ChevronIcon from '@icons/flow/chevron.icon'
import CopyIcon from '@icons/flow/copy.icon'
import ButtonComponent from '@base/buttons/Button'
import VideoBlockComponent from '@components/connectors/VideoBlock'
import InlineButtonComponent from '@base/buttons/InlineButton'

import useStyles from './DataSourcesWidget.styles'

export interface DataSourcesWidgetModalDetails extends Common.ModalDetails {
  panelToOpen: string
}

export interface DataSourcesWidgetComponentProps {
  searchValue?: string,
  setSearchValue?: {
    (value: string): any,
  }
}

const DataSourcesWidgetComponent: React.FC<DataSourcesWidgetComponentProps> = ({ searchValue = '', setSearchValue }) => {
  const { classes, cx } = useStyles()
  const intl = useIntl()
  const dispatch = useDispatch()

  const useCase = useSelector((state) => getUseCaseItem(state))
  const { useCaseId } = useCase || ''
  const filesIdentifiers = useSelector((state) => getUseCaseFileIdentifiers(state))
  const useCaseFileIdentifiers = useSelector((state) => getUseCaseFileIdentifiers(state))
  const connectionsList = useSelector((state) => getCompanyConnectionsBySource(state))
  const { panelToOpen } = useSelector((state) => getModalDetails<DataSourcesWidgetModalDetails>(state))
  const isAdmin = useSelector((state) => getIsAdmin(state))
  const fileVersionsCount = getFileIdentifiersCount(filesIdentifiers)
  const useCaseFileIdentifiersCount = useCaseFileIdentifiers.length || 0
  const categories = Object.values(SOURCE_TYPES_CATEGORIES)

  const [expanded, setExpanded] = useState<null | boolean | string>(null)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    trackEvent({
      componentName: 'mockDataSourcesListItem',
      actionName: TRACKING_ACTIONS.CLICK,
    }, {
      useCaseId,
      dataSourceType: panel,
    })

    setExpanded(newExpanded ? panel : false)
  }

  const handleCustom = (modalName: string, newModalDetails: AuthDataSourceModalDetails) => {
    if (setSearchValue) {
      setSearchValue('')
    }

    trackEvent({
      componentName: 'dataSourcesListItem',
      actionName: TRACKING_ACTIONS.CLICK,
    }, {
      useCaseId,
      dataSourceType: newModalDetails.key,
    })

    dispatch(
      setPrimaryModalPageName({
        primaryModalPage: modalName,
        modalDetails: {
          ...newModalDetails,
          returnTo: DATA_SOURCES_MODAL_NAME,
        } as AuthDataSourceModalDetails,
      }),
    )
  }

  const filterBySearch = (item: MappedSources) => {
    const name = item.title.toLowerCase()
    const searchTerm = searchValue?.toLowerCase()

    if (!searchValue) {
      return true
    }

    return name.includes(searchTerm)
  }

  const sourcesListByCategory = useMemo(() => {
    const sourcesList = matchSourcesToApi(
      intl,
      isAdmin,
      {
        connectionsList,
        fileVersionsCount,
        useCaseFileIdentifiersCount,
        handleCustomSourceClick: handleCustom,
        handleStaticSourceClick: handleChange,
      },
    )

    return groupBy(sourcesList, (option) => option.category)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionsList, fileVersionsCount, useCaseFileIdentifiersCount])

  useEffect(() => {
    if (panelToOpen) {
      handleChange(panelToOpen)({} as React.SyntheticEvent, true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelToOpen])

  const onCopyButtonClick = (e: SyntheticEvent) => copyToClipboard({
    e, text: useCaseId, intl,
  })

  const handleRedirectClick = (dataSourceType: string, link?: string) => {
    trackEvent({
      componentName: 'dataSourceDocumentationLink',
      actionName: TRACKING_ACTIONS.NAVIGATE,
    }, {
      useCaseId,
      dataSourceType,
    })

    if (link) {
      window.open(link, '_blank')
    }
  }

  const listToRender = categories.map((category, categoryIndex) => {
    const list = sourcesListByCategory[category] as MappedSources[]
    const items = list ? (
      sortByKey<MappedSources[]>(list, 'title', DESC_SORTING_ORDER).filter(filterBySearch)
    ) : []

    if (!items || items.length === 0) {
      return null
    }

    return (
      <Box key={category}>
        <Box>
          <Typography
            variant='overline'
            className={cx(classes.categoryName, {
              [classes.categoryNameFirst]: categoryIndex === 0,
            })}
          >
            {intl.formatMessage({ id: CATEGORIES_TO_LABEL_MAP[category] })}
          </Typography>
        </Box>
        <Box>
          {
            items.map((item, index) => {
              const {
                key,
                title,
                description,
                documentationLink,
                videoLink,
                onChange,
                videoHeight,
                statusText,
                disabled,
                icon: IconComponent,
              } = item
              const isExpanded = (expanded === key)

              return (
                <Accordion
                  key={key}
                  elevation={0}
                  expanded={isExpanded}
                  onChange={onChange}
                  disabled={disabled}
                  className={classes.root}
                >
                  <AccordionSummary
                    aria-controls={key}
                    id={key}
                    className={classes.summary}
                    expandIcon={<ChevronIcon />}
                  >
                    <div className={classes.summaryHeader}>
                      <IconComponent />
                      <Typography variant='body1' className={classes.summaryTitle}>{title}</Typography>
                    </div>
                    {
                      statusText ? (
                        <Typography className={classes.statusText}>{statusText}</Typography>
                      ) : (
                        null
                      )
                    }
                  </AccordionSummary>
                  <AccordionDetails className={classes.summaryDetails}>
                    <VideoBlockComponent
                      documentationLink={documentationLink}
                      videoLink={videoLink}
                      title={title}
                      videoHeight={videoHeight}
                    />
                    <Box className={classes.descriptionWrapper}>
                      <Box className={classes.fakeInput}>
                        <Typography variant='body2'>
                          {useCaseId}
                        </Typography>
                        <InlineButtonComponent
                          name='copyButton'
                          iconOnly={true}
                          label={intl.formatMessage({ id: 'common.button.copy' })}
                          onClick={onCopyButtonClick}
                          StartIconComponent={CopyIcon}
                        />
                        <Typography variant='body2' className={classes.useCaseFloat}>
                          {intl.formatMessage({ id: 'connect.modal.use_case_id' })}
                        </Typography>
                      </Box>

                      <Typography variant='body2' className={classes.description}>
                        {description}
                      </Typography>
                      <ButtonComponent
                        name='documentationButton'
                        color='primary'
                        onClick={() => handleRedirectClick(key, documentationLink)}
                        label={intl.formatMessage({ id: 'connect.modal.data_sources.documentation' })}
                      />
                    </Box>
                  </AccordionDetails>
                </Accordion>
              )
            })
          }
        </Box>
      </Box>
    )
  })

  const isEmptyList = listToRender.filter((item) => item !== null).length === 0

  return (
    <Box
      className={cx(classes.container, {
        [classes.emptyContainer]: isEmptyList,
      })}
      data-testid={DataSourcesWidgetComponent.name}
    >
      {
        isEmptyList ? (
          <Box className={classes.emptyState}>
            <Typography variant='h4' className={classes.emptyStateHeader}>
              {intl.formatMessage({ id: 'connect.modal.data_sources.search_empty_title' })}
            </Typography>
            <Typography variant='subtitle1' className={classes.emptyStateBody}>
              {intl.formatMessage({ id: 'connect.modal.data_sources.search_empty_body' })}
            </Typography>
          </Box>
        ) : (
          listToRender
        )
      }
    </Box>
  )
}

export default DataSourcesWidgetComponent
