import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

type allowedClasses = 'comparisonItemText' | 'decideItemHover' | 'decideItemDisabled'

const useStyles = makeStyles<void, allowedClasses>()((theme: Theme, props, classes) => ({
  rightContainer: {
    backgroundColor: theme.palette.new.grey_d,
  },
  solutionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    height: theme.spacing(8),
    borderBottom: `1px solid ${theme.palette.new.grey_a}`,
  },
  configHeadlineValue: {
    fontSize: '16px',
    color: theme.palette.new.black,
    [theme.breakpoints.down('xl')]: {
      fontSize: '12px',
    },
  },
  configFirstItem: {
    borderTop: 'none',
  },
  configEmpty: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
    maxWidth: theme.spacing(36),
    fontSize: '14px',
    color: theme.palette.new.pink,
    minHeight: '78px',
    padding: theme.spacing(4),
    [theme.breakpoints.down('xl')]: {
      padding: theme.spacing(2),
    },
  },
  comparisonContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  comparisonBlock: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: '1px',
  },
  comparisonItemText: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.6)',
    width: '100%',
    minWidth: theme.spacing(20),
    [theme.breakpoints.down('xl')]: {
      minWidth: theme.spacing(15),
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
      minWidth: theme.spacing(10),
    },
  },
  comparisonItemNum: {
    color: theme.palette.new.black,
    textAlign: 'right',
    fontFeatureSettings: "'tnum' on, 'lnum' on, 'ss07' on",
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
    },
  },
  comparisonItemNumRow: {
    width: '100%',
    minWidth: theme.spacing(7),
  },
  comparisonItemUnits: {
    fontSize: '12px',
    color: 'rgba(0, 0, 0, 0.4)',
    minWidth: theme.spacing(7),
    fontFeatureSettings: "'tnum' on, 'lnum' on, 'ss07' on",
    textAlign: 'right',
  },
  comparisonItemValue: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.6)',
    textAlign: 'right',
    fontFeatureSettings: "'tnum' on, 'lnum' on, 'ss07' on",
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
    },
  },
  comparisonItemValueKpi: {
    color: theme.palette.new.black,
  },
  comparisonItemNumActive: {
    fontWeight: 500,
    color: theme.palette.new.pink,
  },
  comparisonItemNumActiveNonKpi: {
    color: theme.palette.new.black,
  },
  comparisonItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(0, 4),
    height: theme.spacing(5),
    borderBottom: `1px solid ${theme.palette.new.grey_a}`,
    [theme.breakpoints.down('xl')]: {
      padding: theme.spacing(0, 2),
    },
  },
  comparisonItemKpi: {
    [`& .${classes.comparisonItemText}`]: {
      fontWeight: 500,
      color: theme.palette.new.black,
    },
  },
  comparisonItemDecide: {
    backgroundColor: '#E5E5E5',
    [`& .${classes.comparisonItemText}`]: {
      fontWeight: 500,
      color: theme.palette.new.black,
    },
  },
  downloadButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(0, 4),
    marginBottom: theme.spacing(4),
    background: 'transparent',
    [theme.breakpoints.down('xl')]: {
      padding: theme.spacing(0, 2),
      marginBottom: theme.spacing(2),
    },
  },
  comparisonItemDecideContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'row',
  },
  decideItemHover: {
    display: 'none',
  },
  decideItem: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    width: '18px',
    height: '18px',
    borderRadius: '100%',
    cursor: 'pointer',
    position: 'relative',
    '&:hover': {
      [`& .${classes.decideItemHover}`]: {
        display: 'flex',
        width: '2px',
        height: '2px',
        background: theme.palette.new.pink,
      },
    },
  },
  decideItemDisabled: {
    cursor: 'not-allowed',
    background: 'rgba(0, 0, 0, 0.05)',
    '&:hover': {
      [`& .${classes.decideItemHover}`]: {
        display: 'none',
      },
    },
  },
  decideItemActive: {
    background: theme.palette.new.pink,
    border: 'none',
  },
  comparisonItemDecideActive: {
    background: theme.palette.new.versatile_violet,

    [`& .${classes.comparisonItemText}`]: {
      color: theme.palette.new.white,
    },

    [`& .${classes.decideItemDisabled}`]: {
      background: '#7F51E6',
    },
  },
  comparisonItemDecideSelected: {
    background: 'transparent',
  },
  topLegendContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    padding: theme.spacing(0, 4),
    [theme.breakpoints.down('xl')]: {
      padding: theme.spacing(0, 2),
    },
  },
  topLegendText: {
    fontSize: '20px',
    fontWeight: 500,
    color: theme.palette.new.black,
    [theme.breakpoints.down('xl')]: {
      fontSize: '12px',
    },
  },
}))

export default useStyles
