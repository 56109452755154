import React from 'react'

import {
  TableBody, TableRow,
  TableCell, Box,
} from '@mui/material'

import LoadingCloudComponent from '@base/loading/LoadingCloud'

import useStyles from './TableLoading.styles'

export interface TableLoadingComponentProps {
  className?: string,
  colSpan?: number,
}

const TableLoadingComponent: React.FC<TableLoadingComponentProps> = ({
  className, colSpan,
}) => {
  const { classes, cx } = useStyles()

  return (
    <TableBody data-testid={TableLoadingComponent.name}>
      <TableRow
        hover={false}
        className={classes.row}
      >
        <TableCell colSpan={colSpan}>
          <Box className={cx(classes.root, className)}>
            <LoadingCloudComponent size='medium' />
          </Box>
        </TableCell>
      </TableRow>
    </TableBody>
  )
}

export default TableLoadingComponent
