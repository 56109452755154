import React from 'react'

import { useSelector } from '@redux/hooks'
import { getLiveMonitoringAbcTotals, getLiveMonitoringTable, isFetchingLiveMonitoringTable } from '@redux/modules/monitor/monitor.selectors'
import { requestLiveMonitoringGridStateChangeAction } from '@redux/modules/monitor/monitor.actions'
import { transformMonitorTableColumns } from '@utils/monitor.utils'

import {
  DEFAULT_LIVE_MONITORING_TARGET_COLUMN,
  DEFAULT_LIVE_MONITORING_PREDICTION_COLUMN,
  DEFAULT_REL_DEVIATION_COLUMN,
  DEFAULT_ABS_DEVIATION_COLUMN,
} from '@constants/insights.constants'

import InsightsTableComponent from '@components/(features)/insights/insights-table/InsightsTable.component'

const LiveMonitoringContainer = () => {
  const liveMonitoringTable = useSelector((state) => getLiveMonitoringTable(state))
  const isFetching = useSelector((state) => isFetchingLiveMonitoringTable(state))
  const abcTotals = useSelector((state) => getLiveMonitoringAbcTotals(state))

  const {
    tableId,
    rows,
    rowCount,
    columns,
    gridState,
  } = liveMonitoringTable

  const alwaysVisibleColumns = [
    DEFAULT_LIVE_MONITORING_TARGET_COLUMN,
    DEFAULT_LIVE_MONITORING_PREDICTION_COLUMN,
    DEFAULT_ABS_DEVIATION_COLUMN,
    DEFAULT_REL_DEVIATION_COLUMN,
  ]

  return (
    <InsightsTableComponent
      tableId={tableId}
      rows={rows}
      rowCount={rowCount}
      totalRowCount={abcTotals.total}
      columns={columns}
      gridState={gridState}
      isFetching={isFetching}
      requestGridStateChangeAction={requestLiveMonitoringGridStateChangeAction}
      columnTransformer={transformMonitorTableColumns}
      defaultSortingColumn={DEFAULT_LIVE_MONITORING_PREDICTION_COLUMN}
      alwaysVisibleColumns={alwaysVisibleColumns}
    />
  )
}

export default LiveMonitoringContainer
