import invert from 'lodash.invert'

export const LANGUAGE_SELECTOR_MODAL = 'LANGUAGE_SELECTOR_MODAL'
export const USER_PROFILE_MODAL = 'USER_PROFILE_MODAL'
export const CONNECT_DATA_SOURCE_MODAL_NAME = 'CONNECT_DATA_SOURCE_MODAL_NAME'
export const AUTH_DATA_SOURCE_MODAL_NAME = 'AUTH_DATA_SOURCE_MODAL_NAME'
export const PARETOS_DATA_SOURCE_MODAL_NAME = 'PARETOS_DATA_SOURCE_MODAL_NAME'
export const USE_CASE_TRAINING_DATA_MODAL_NAME = 'USE_CASE_TRAINING_DATA_MODAL_NAME'
export const USE_CASE_DATA_TYPES_MODAL_NAME = 'USE_CASE_DATA_TYPES_MODAL_NAME'
export const USE_CASE_DATA_TYPE_VERSIONS_MODAL_NAME = 'USE_CASE_DATA_TYPE_VERSIONS_MODAL_NAME'
export const DATA_SOURCES_MODAL_NAME = 'DATA_SOURCES_MODAL_NAME'
export const CONNECT_PARAMETER_CREATION_MODAL_NAME = 'CONNECT_PARAMETER_CREATION_MODAL_NAME'
export const USE_CASE_ARTIFACTS_MAPPING_MODAL_NAME = 'USE_CASE_ARTIFACTS_MAPPING_MODAL_NAME'
export const USE_CASE_FORECAST_PARAMETERS_MODAL_NAME = 'USE_CASE_FORECAST_PARAMETERS_MODAL_NAME'
export const PIPELINE_EXPECTED_DELIVERY_CONFIGURATION_MODAL_NAME = 'PIPELINE_EXPECTED_DELIVERY_CONFIGURATION_MODAL_NAME'
export const PIPELINE_EXECUTION_SCHEDULE_MODAL_NAME = 'PIPELINE_EXECUTION_SCHEDULE_MODAL_NAME'
export const PIPELINE_CONFIGURATION_MODAL_NAME = 'PIPELINE_CONFIGURATION_MODAL_NAME'
export const PIPELINE_REPORT_DELIVERY_MODAL_NAME = 'PIPELINE_REPORT_DELIVERY_MODAL_NAME'
export const MODEL_PLOTS_MODAL_NAME = 'MODEL_PLOTS_MODAL_NAME'
export const EXPORT_ARTIFACTS_MODAL_NAME = 'EXPORT_ARTIFACTS_MODAL_NAME'
export const FILE_BROWSER_ITEM_REMOVAL_DIALOG_NAME = 'FILE_BROWSER_ITEM_REMOVAL_DIALOG_NAME'
export const FILE_BROWSER_ITEM_PREVIEW_DIALOG_NAME = 'FILE_BROWSER_ITEM_PREVIEW_DIALOG_NAME'
export const USE_CASE_ADMIN_MODAL_NAME = 'USE_CASE_ADMIN_MODAL_NAME'
export const COMPANY_MODAL_NAME = 'COMPANY_MODAL_NAME'
export const MANAGE_PREDIFINED_PARAMETER_MODAL_NAME = 'MANAGE_PREDIFINED_PARAMETER_MODAL_NAME'
export const LIST_PREDIFINED_PARAMETER_MODAL_NAME = 'LIST_PREDIFINED_PARAMETER_MODAL_NAME'
export const DATA_SOURCE_CONNECTED_MODAL_NAME = 'DATA_SOURCE_CONNECTED_MODAL_NAME'
export const CONNECT_PARAMETER_MODAL_NAME = 'CONNECT_PARAMETER_MODAL_NAME'
export const CONNECT_GROUPING_MODAL_NAME = 'CONNECT_GROUPING_MODAL_NAME'
export const COMPANY_USERS_MODAL_NAME = 'COMPANY_USERS_MODAL_NAME'

export const MODAL_NAMES_TO_HASH_MAP = {
  [USE_CASE_DATA_TYPES_MODAL_NAME]: '#data-types',
  [EXPORT_ARTIFACTS_MODAL_NAME]: '#export-artifacts',
}

export const MODAL_HASH_TO_NAME_MAP = invert(MODAL_NAMES_TO_HASH_MAP)
