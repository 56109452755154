import React, {
  useCallback, useEffect,
  useMemo, useState,
} from 'react'

import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { useFormik } from 'formik'
import { useRouteMatch } from 'react-router-dom'

import {
  Box,
} from '@mui/material'

import {
  SidePanelCardComponent,
  SidePanelComponent,
  SidePanelCardActionsComponent,
  SidePanelLoadingComponent,
  SidePanelButtonContainerComponent,
  ModalButtonComponent,
  SidePanelActionButtonComponent,
} from '@base/sidepanel/SidePanel'

import {
  FormLayoutContainer,
  FormLayoutItem,
  FormLayoutItemsContainer,
} from '@base/forms/FormLayout'

import CalendarSettingsIcon from '@icons/calendarSettings.icon'
import CalendarMarkIcon from '@icons/calendarMark.icon'
import DeleteIcon from '@icons/delete.icon'
import TextConfirmationDialogComponent from '@base/dialogs/TextConfirmationDialog'
import SwitchFieldComponent from '@base/forms/SwitchField'
import AutocompleteSelectFieldComponent from '@base/autocomplete/AutocompleteSelectField'
import AutocompleteChipsFieldComponent from '@base/autocomplete/AutocompleteChipsField'
import TextFieldComponent from '@base/forms/TextField'

import type { PipelineScheduleConfigurationModalDetails } from '@containers/modals/pipeline-schedule-configuration-modal/PipelineScheduleConfigurationModal.container'
import { ConfigurePipelineActionPayload } from '@redux/modules/pipelines/pipelines.types'
import { deletePipelineAction, updatePipelineConfigurationAction } from '@redux/modules/pipelines/pipelines.actions'
import { getModalDetails, getOpenedModal } from '@redux/modules/modal-manager/modal-manager.selectors'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { getPipelinesList, isSubmittingPipeline } from '@redux/modules/pipelines/pipelines.selectors'
import { getIsAdmin } from '@redux/modules/customer/customer.selectors'

import {
  PIPELINE_CONFIGURATION_MODAL_NAME,
  PIPELINE_EXECUTION_SCHEDULE_MODAL_NAME,
  PIPELINE_EXPECTED_DELIVERY_CONFIGURATION_MODAL_NAME,
} from '@constants/modals.constants'

import {
  PIPELINES_WITH_GPU, PIPELINES_WITH_RESOURCE_MANAGEMENT,
  PIPELINE_MIN_RAM_MB, PIPELINE_TYPES,
} from '@constants/pipelines.constants'

export interface PipelineConfigurationModalDetails extends Common.ModalDetails {
  pipeline: Pipelines.ReduxPipelineItem
}

export interface ConfigurePipelineModalValues extends Omit<ConfigurePipelineActionPayload, 'dependsOn' | 'memberOf'> {
  dependsOn: Pipelines.ReduxPipelineItem[]
  memberOf: Pipelines.ReduxPipelineItem | null
}

const PipelineConfigurationModalContainer: React.FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { params: { usecase } } = useRouteMatch<Common.RouterMatch>()
  const [removeDialogState, setRemoveDialogState] = useState(false)

  const isAdmin = useSelector((state) => getIsAdmin(state))
  const isSubmitting = useSelector((state) => isSubmittingPipeline(state))
  const { pipeline } = useSelector((state) => getModalDetails<PipelineConfigurationModalDetails>(state))
  const modalPageName = useSelector((state) => getOpenedModal(state))
  const pipelinesList = useSelector((state) => getPipelinesList(state))

  const open = modalPageName === PIPELINE_CONFIGURATION_MODAL_NAME
  const pipelineId = pipeline?.pipelineId || ''
  const pipelineName = pipeline?.name || ''

  const dependsOnList = useMemo(() => {
    return pipelinesList.filter((item) => {
      return (item.type !== PIPELINE_TYPES.COMPOSITE_PIPELINE) && (pipelineId !== item.pipelineId)
    })
  }, [pipelinesList, pipelineId])

  const memberOfList = useMemo(() => {
    return pipelinesList.filter((item) => {
      return item.type === PIPELINE_TYPES.COMPOSITE_PIPELINE
    })
  }, [pipelinesList])

  const enrichedDependsOn = useCallback((dependsOn: string[]) => {
    return dependsOn.map((item) => {
      return dependsOnList.find((pipelineItem) => (pipelineItem.pipelineId === item))
    }).filter(Boolean)
  }, [
    dependsOnList,
  ])

  const enrichedMemberOf = useCallback((memberOf: string | null) => {
    return memberOfList.find((item) => item.pipelineId === memberOf)
  }, [
    memberOfList,
  ])

  const initialValues: ConfigurePipelineModalValues = useMemo(() => {
    if (!pipeline) {
      return {
        pipelineId,
        useCaseId: usecase,
        enabled: false,
        deliversToCustomer: false,
        gpu: false,
        memoryMb: PIPELINE_MIN_RAM_MB,
        type: PIPELINE_TYPES.DEMAND_PIPELINE,
        memberOf: null,
        dependsOn: [],
      } as ConfigurePipelineModalValues
    }

    return {
      ...pipeline,
      dependsOn: enrichedDependsOn(pipeline.dependsOn),
      memberOf: enrichedMemberOf(pipeline.memberOf),
    } as ConfigurePipelineModalValues
  }, [
    pipeline,
    pipelineId,
    usecase,
    enrichedDependsOn,
    enrichedMemberOf,
  ])

  const handleCloseAction = (toggleModal = true) => {
    if (toggleModal) {
      dispatch(setPrimaryModalPageName(''))
    }
  }

  const handleSubmitAction = (values: ConfigurePipelineModalValues) => {
    const payload: ConfigurePipelineActionPayload = {
      pipelineId,
      useCaseId: values.useCaseId,
      type: values.type,
      enabled: values.enabled || false,
      deliversToCustomer: values.deliversToCustomer || false,
      gpu: values.gpu || false,
      memoryMb: Number(values.memoryMb) || PIPELINE_MIN_RAM_MB,
      memberOf: values.memberOf ? values.memberOf.pipelineId : null,
      dependsOn: values.dependsOn.map((item) => item.pipelineId),
    }

    dispatch(
      updatePipelineConfigurationAction(payload),
    )
  }

  const handleConfigurationDialogClose = () => {
    setRemoveDialogState(false)
  }

  const handleConfigurationDialogSubmit = () => {
    dispatch(deletePipelineAction({ pipelineId }))
  }

  const handlePipelineScheduleUpdate = (primaryModalPage: typeof PIPELINE_EXPECTED_DELIVERY_CONFIGURATION_MODAL_NAME | typeof PIPELINE_EXECUTION_SCHEDULE_MODAL_NAME) => {
    dispatch(
      setPrimaryModalPageName({
        primaryModalPage,
        modalDetails: {
          returnTo: PIPELINE_CONFIGURATION_MODAL_NAME,
          pipeline,
        } as PipelineScheduleConfigurationModalDetails,
      }),
    )
  }

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    setFieldValue,
    isValid,
    errors,
    touched,
    values,
    dirty,
  } = useFormik({
    initialValues,
    onSubmit: handleSubmitAction,
    enableReinitialize: true,
  })

  useEffect(() => {
    if (!open) {
      resetForm()
    }
  }, [open, resetForm])

  return (
    <>
      <SidePanelComponent
        open={open && isAdmin}
        title={intl.formatMessage({ id: 'pipelines.config.dialog.title' }, { name: pipelineName })}
        handleClose={handleCloseAction}
        hasUnsavedChanges={dirty || isSubmitting}
      >
        <SidePanelLoadingComponent loading={false}>
          <Box component='form' onSubmit={handleSubmit}>
            <SidePanelCardComponent>
              <FormLayoutContainer>
                <FormLayoutItemsContainer
                  title={intl.formatMessage({ id: 'pipelines.config.dialog.generalConfig' })}
                >
                  <FormLayoutItem xs={12}>
                    <TextFieldComponent
                      name='pipelineName'
                      value={pipelineName}
                      label={intl.formatMessage({ id: 'pipelines.scheduleConfigurationModal.pipelineName' })}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      disabled={true}
                    />
                  </FormLayoutItem>
                  <FormLayoutItem xs={12} container={true}>
                    <FormLayoutItem xs={6}>
                      <SwitchFieldComponent
                        name='enabled'
                        label={intl.formatMessage({ id: 'pipelines.config.dialog.enabled' })}
                        checked={values.enabled}
                        value={values.enabled}
                        onChange={handleChange}
                      />
                    </FormLayoutItem>
                    <FormLayoutItem xs={6}>
                      <SwitchFieldComponent
                        name='deliversToCustomer'
                        label={intl.formatMessage({ id: 'pipelines.config.dialog.deliversToCustomer' })}
                        checked={values.deliversToCustomer}
                        value={values.deliversToCustomer}
                        onChange={handleChange}
                      />
                    </FormLayoutItem>
                  </FormLayoutItem>
                </FormLayoutItemsContainer>

                <FormLayoutItemsContainer
                  hidden={pipeline?.type === PIPELINE_TYPES.COMPOSITE_PIPELINE}
                  title={intl.formatMessage({ id: 'pipelines.config.dialog.dependencyManagement' })}
                >
                  <FormLayoutItem xs={12}>
                    <AutocompleteSelectFieldComponent<Pipelines.ReduxPipelineItem>
                      name='memberOf'
                      label={intl.formatMessage({ id: 'pipelines.config.dialog.memberOf' })}
                      floatingHelp={intl.formatMessage({ id: 'pipelines.config.dialog.memberOf.help' })}
                      placeholder={intl.formatMessage({ id: 'pipelines.config.dialog.memberOf.placeholder' })}
                      value={values.memberOf}
                      options={memberOfList}
                      getOptionLabel={(item: Pipelines.ReduxPipelineItem) => item.name}
                      handleChangeCallback={(e: React.SyntheticEvent, selectedValue) => {
                        setFieldValue('memberOf', selectedValue)
                      }}
                    />
                  </FormLayoutItem>

                  <FormLayoutItem xs={12}>
                    <AutocompleteChipsFieldComponent<Pipelines.ReduxPipelineItem>
                      name='dependsOn'
                      label={intl.formatMessage({ id: 'pipelines.config.dialog.dependsOn' })}
                      floatingHelp={intl.formatMessage({ id: 'pipelines.config.dialog.dependsOn.help' })}
                      placeholder={intl.formatMessage({ id: 'pipelines.config.dialog.dependsOn.placeholder' })}
                      value={values.dependsOn}
                      options={dependsOnList}
                      getOptionLabel={(item: Pipelines.ReduxPipelineItem) => item.name}
                      handleChangeCallback={(e: React.SyntheticEvent, selectedValue, newValue) => {
                        setFieldValue('dependsOn', newValue)
                      }}
                    />
                  </FormLayoutItem>
                </FormLayoutItemsContainer>

                <FormLayoutItemsContainer
                  title={intl.formatMessage({ id: 'pipelines.config.dialog.resourceManagement' })}
                  hidden={!PIPELINES_WITH_RESOURCE_MANAGEMENT.includes(pipeline?.type)}
                >
                  <FormLayoutItem
                    xs={12}
                    container={true}
                  >
                    <FormLayoutItem
                      xs={!PIPELINES_WITH_GPU.includes(pipeline?.type) ? 12 : 6}
                    >
                      <TextFieldComponent
                        name='memoryMb'
                        label={intl.formatMessage({ id: 'pipelines.config.dialog.memoryMb' })}
                        touched={touched.memoryMb}
                        errors={errors.memoryMb}
                        value={values.memoryMb}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type='number'
                        inputProps={{ min: PIPELINE_MIN_RAM_MB }}
                      />
                    </FormLayoutItem>
                    <FormLayoutItem
                      xs={6}
                      hidden={!PIPELINES_WITH_GPU.includes(pipeline?.type)}
                    >
                      <SwitchFieldComponent
                        name='gpu'
                        label={intl.formatMessage({ id: 'pipelines.config.dialog.gpu' })}
                        checked={values.gpu}
                        value={values.gpu}
                        onChange={handleChange}
                        useLabelPlaceholder={true}
                      />
                    </FormLayoutItem>
                  </FormLayoutItem>
                </FormLayoutItemsContainer>
              </FormLayoutContainer>

              <SidePanelButtonContainerComponent>
                <SidePanelActionButtonComponent
                  name='pipelineUpdateExecutionScheduleButton'
                  StartIconComponent={CalendarSettingsIcon}
                  label={intl.formatMessage({ id: 'pipelines.config.action.updateExecutionSchedule' })}
                  onClick={() => handlePipelineScheduleUpdate(PIPELINE_EXECUTION_SCHEDULE_MODAL_NAME)}
                  disabled={isSubmitting}
                />
                <SidePanelActionButtonComponent
                  name='pipelineUpdateDeliveryScheduleButton'
                  StartIconComponent={CalendarMarkIcon}
                  label={intl.formatMessage({ id: 'pipelines.config.action.updateDeliverySchedule' })}
                  onClick={() => handlePipelineScheduleUpdate(PIPELINE_EXPECTED_DELIVERY_CONFIGURATION_MODAL_NAME)}
                  disabled={isSubmitting}
                />
                <SidePanelActionButtonComponent
                  name='deletePipelineButton'
                  StartIconComponent={DeleteIcon}
                  label={intl.formatMessage({ id: 'pipelines.config.dialog.remove.button' })}
                  onClick={() => setRemoveDialogState(true)}
                  disabled={isSubmitting}
                />
              </SidePanelButtonContainerComponent>
            </SidePanelCardComponent>

            <SidePanelCardActionsComponent>
              <ModalButtonComponent
                name='pipelineConfigurationModalBackButton'
                onClick={() => handleCloseAction()}
                type='cancel'
              />

              <ModalButtonComponent
                name='pipelineConfigurationModalSubmitButton'
                onClick={(e) => handleSubmitAction(values)}
                loading={isSubmitting}
                disabled={isSubmitting || !dirty || !isValid}
                type='submit'
              />
            </SidePanelCardActionsComponent>
          </Box>
        </SidePanelLoadingComponent>
      </SidePanelComponent>

      <TextConfirmationDialogComponent
        open={Boolean(removeDialogState) && isAdmin}
        onClose={handleConfigurationDialogClose}
        onSubmit={handleConfigurationDialogSubmit}
        confirmationText={pipelineName}
        confirmationInputLabel={intl.formatMessage({ id: 'pipelines.list.confirmation.delete_pipeline_dialog.confirmation' })}
        description={
          intl.formatMessage({ id: 'pipelines.list.confirmation.delete_pipeline_dialog.content' }, {
            name: <Box component='strong'>{pipelineName}</Box>,
          })
        }
      />
    </>
  )
}

export default PipelineConfigurationModalContainer
