import { State } from '@redux/modules/types'

import {
  REQUEST_FILES_LIST,
  REQUEST_FILE_UPLOAD,
  REQUEST_FILE_PREVIEW,
  REQUEST_FILES_LIST_REFRESH,
} from './file-service.action-types'

export const getFilesList = (state: State) => {
  return state.fileService.filesList
}

export const getSortingKey = (state: State) => {
  return state.fileService.sortingKey
}

export const getSortingOrder = (state: State) => {
  return state.fileService.sortingOrder
}

export const getFilePreview = (state: State) => {
  return state.fileService.filePreview
}

export const getBrowserTree = (state: State) => {
  return state.fileService.browserTree
}

export const isFetchingFiles = (state: State) => {
  return state.fileService.isFetching && [REQUEST_FILES_LIST].includes(state.fileService.fetchingKey)
}

export const isUploadInProgress = (state: State) => {
  return state.fileService.isFetching && [REQUEST_FILE_UPLOAD].includes(state.fileService.fetchingKey)
}

export const isPreparingFilePreview = (state: State) => {
  return state.fileService.isFetching && [REQUEST_FILE_PREVIEW].includes(state.fileService.fetchingKey)
}

export const isRefreshingFilesList = (state: State) => {
  return state.fileService.isFetching && [REQUEST_FILES_LIST_REFRESH].includes(state.fileService.fetchingKey)
}
