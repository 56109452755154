import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme: Theme) => ({
  searchContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    minHeight: theme.spacing(18),
  },
  searchEmptyHeader: {
    fontWeight: 500,
    fontSize: '24px',
    color: theme.palette.new.black,
    marginBottom: theme.spacing(2),
  },
  searchEmptyText: {
    fontWeight: 400,
    fontSize: '18px',
    maxWidth: theme.spacing(70),
    color: theme.palette.new.grey_h,
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  row: {
    '&:last-child .MuiTableCell-root': {
      borderBottom: 'none',
    },
  },
}))

export default useStyles
