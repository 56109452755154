import { useEffect } from 'react'

import { DataGridComponentProps } from '@base/datagrid/data-grid/DataGrid.component'
import { useIntl } from 'react-intl'

export interface useDataGridAutosizeProps {
  apiRef: DataGridComponentProps['apiRef']
  autosizeOnMount: DataGridComponentProps['autosizeOnMount']
  loading: DataGridComponentProps['loading']
  disableVirtualization: DataGridComponentProps['disableVirtualization']
  disableAutosize: DataGridComponentProps['disableAutosize']
  uploading: DataGridComponentProps['uploading']
}

export const useDataGridAutosize = ({
  apiRef,
  autosizeOnMount,
  loading,
  disableVirtualization,
  disableAutosize,
  uploading,
}: useDataGridAutosizeProps) => {
  const intl = useIntl()

  useEffect(() => {
    if (apiRef && apiRef.current && autosizeOnMount && !loading && !uploading && disableVirtualization && !disableAutosize) {
      setTimeout(() => {
        apiRef.current?.autosizeColumns({
          includeHeaders: true,
          includeOutliers: true,
          outliersFactor: 1,
        })
      }, 100)
    }
  }, [
    apiRef, disableVirtualization, disableAutosize,
    loading, autosizeOnMount, intl, uploading,
  ])
}
