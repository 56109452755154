import { State } from '@redux/modules/types'

import {
  REQUEST_PARAMETER_OVERVIEW,
  REQUEST_PARAMETER,
  CREATE_PARAMETER,
  UPDATE_PARAMETER,
  DELETE_PARAMETER,
  REQUEST_GROUPING_OVERVIEW,
  REQUEST_GROUPING,
  CREATE_GROUPING,
  UPDATE_GROUPING,
  DELETE_GROUPING,
} from './parameters.action-types'

export const getParameterItem = (state: State) => {
  return state.parameters.item
}

export const getGroupingItem = (state: State) => {
  return state.parameters.groupingItem
}

export const isFetchingGrouping = (state: State) => {
  return state.parameters.isFetching && [REQUEST_GROUPING_OVERVIEW, REQUEST_GROUPING].includes(state.parameters.fetchingKey)
}

export const isSubmittingGrouping = (state: State) => {
  return state.parameters.isFetching && [CREATE_GROUPING, UPDATE_GROUPING, DELETE_GROUPING].includes(state.parameters.fetchingKey)
}

export const isFetchingParameter = (state: State) => {
  return state.parameters.isFetching && [REQUEST_PARAMETER_OVERVIEW, REQUEST_PARAMETER].includes(state.parameters.fetchingKey)
}

export const isSubmittingParameter = (state: State) => {
  return state.parameters.isFetching && [CREATE_PARAMETER, UPDATE_PARAMETER, DELETE_PARAMETER].includes(state.parameters.fetchingKey)
}

export const isFetchedParameter = (state: State) => {
  return state.parameters.isFetchedParameter
}

export const isFetchedGrouping = (state: State) => {
  return state.parameters.isFetchedGroupingParameter
}
