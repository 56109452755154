import React from 'react'
import PropTypes from 'prop-types'

import {
  BarChart, Bar,
  Cell, ResponsiveContainer,
} from 'recharts'

import palette from '@configuration/theme/theme.palette'
import useStyles from './SliderBarChart.styles'

export interface SliderBarCount {
  num: number,
  firstStep: number,
  secStep: number,
}

export type SliderBarValue = [number, number]

export interface SliderBarChartComponentProps {
  counts?: SliderBarCount[],
  value?: SliderBarValue,
}

/**
 * Slider Bar Chart Component
 */
const SliderBarChartComponent: React.FC<SliderBarChartComponentProps> = (props) => {
  const { counts, value } = props
  const { classes } = useStyles()

  if (!counts || !value) {
    return null
  }

  return (
    <div className={classes.root} data-testid={SliderBarChartComponent.name}>
      <ResponsiveContainer width='100%' height='100%'>
        <BarChart
          data={counts}
          barCategoryGap={1}
          margin={{
            top: 10,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <Bar dataKey='num' minPointSize={1}>
            {
              counts.map((entry, index) => {
                const leftPickerValue = value[0]
                const rightPickerValue = value[1]
                const isSelected = (entry.firstStep >= leftPickerValue && entry.secStep <= rightPickerValue)

                return (
                  <Cell
                    key={`fill-${index + 1}-${entry.num}`}
                    fill={isSelected ? palette.new.black : palette.new.grey_a}
                    strokeWidth={0}
                  />
                )
              })
            }
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

SliderBarChartComponent.propTypes = {
  /** Counts in range */
  counts: PropTypes.array.isRequired,
  /** Slider value */
  value: PropTypes.any,
}

export default SliderBarChartComponent
