import React from 'react'
import { TextField, TextFieldProps } from '@mui/material'

export interface DataGridBaseTextFieldComponentProps extends TextFieldProps<'outlined'> {
  forwardedRef?: any
}

export const DataGridBaseTextFieldComponent: React.FC<DataGridBaseTextFieldComponentProps> = ({
  forwardedRef,
  sx,
  ...props
}) => {
  return (
    <TextField
      data-testid={DataGridBaseTextFieldComponent.name}
      ref={forwardedRef}
      sx={{
        '& .MuiOutlinedInput-root': {
          borderRadius: '10px',
        },
        ...sx,
      }}
      {...props}
    />
  )
}

export default React.forwardRef((props, ref) => <DataGridBaseTextFieldComponent variant='outlined' {...props} forwardedRef={ref} />)
