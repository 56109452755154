import React from 'react'
import { useIntl } from 'react-intl'
import { bindActionCreators } from 'redux'
import { useRouteMatch } from 'react-router-dom'
import { Box, Grid, Typography } from '@mui/material'

import { formatNumberWithSuffixUnit } from '@utils/analysis.utils'
import { TRADE_OFFS_LABEL_REPLENISHMENT_COST, TRADE_OFFS_LABEL_REVENUE } from '@constants/optimize.constants'
import { useDispatch } from '@redux/hooks'
import { setStrategyAction } from '@redux/modules/optimize/optimize.actions'
import ModalButtonComponent from '@base/buttons/ModalButton'

import useStyles from './TradeOffsOptimizePanel.styles'

export type TradeOffsDatasetSolution = (Socrates.TradeOffsDatasetKpi & Socrates.TradeOffsDatasetDesign)

export interface TradeOffsOptimizePanelComponentProps {
  selectedPoints: Socrates.TradeOffsDatasetItem[],
  solutionToExecute: number | null,
}

const TradeOffsOptimizePanelComponent: React.FC<TradeOffsOptimizePanelComponentProps> = (props) => {
  const {
    selectedPoints,
    solutionToExecute,
  } = props

  const { classes, cx } = useStyles()
  const intl = useIntl()
  const dispatch = useDispatch()
  const setStrategy = bindActionCreators(setStrategyAction, dispatch)
  const {
    params: {
      usecase,
    },
  } = useRouteMatch<Common.RouterMatch>()

  const point = ((solutionToExecute !== null ? selectedPoints[solutionToExecute] : null) || {}) as Socrates.TradeOffsDatasetItem
  const datasetPayload = (point?.datasetPayload || {}) as Socrates.TradeOffsDatasetPayload
  const targets = (datasetPayload.datasetKpis || []) as Socrates.TradeOffsDatasetKpi[]
  const hasNoTargets = targets.length === 0

  return (
    <Grid item={true} xs={4} className={classes.rightContainer} data-testid={TradeOffsOptimizePanelComponent.name}>
      <div className={classes.solutionHeader}>
        <div className={classes.topLegendContainer}>
          <div className={classes.topLegendCircle} />
          <Typography className={classes.topLegendText}>
            {intl.formatMessage({ id: 'trade_offs.chart.pareto.selected_optimum' })}
          </Typography>
        </div>
        <div />
      </div>

      <div className={classes.container}>
        <div
          className={cx(classes.containerTop, {
            [classes.containerTopEmpty]: hasNoTargets,
          })}
        >
          <Typography className={classes.targetsHeader}>
            {intl.formatMessage({ id: 'trade_offs.chart.pareto.targets' })}
          </Typography>
          <div className={classes.targetsList}>
            {
              targets.map((target: TradeOffsDatasetSolution, index: number) => {
                return (
                  <div key={index} className={classes.targetItem}>
                    <Typography className={classes.targetName}>
                      {target.label}
                    </Typography>
                    <Typography className={classes.targetValue}>
                      {formatNumberWithSuffixUnit(target.value)}
                    </Typography>
                  </div>
                )
              })
            }
          </div>
        </div>

        {
          hasNoTargets ? (
            <Typography className={classes.configEmpty}>
              {intl.formatMessage({ id: 'trade_offs.details.empty_optimize' })}
            </Typography>
          ) : (
            null
          )
        }

        <Box className={classes.strategydButton}>
          <ModalButtonComponent
            name='setStrategyButton'
            onClick={() => setStrategy({
              targetRevenue: (targets.find((target: TradeOffsDatasetSolution) => target.label === TRADE_OFFS_LABEL_REVENUE)?.value || 0),
              targetReplenishmentCost: (targets.find((target: TradeOffsDatasetSolution) => target.label === TRADE_OFFS_LABEL_REPLENISHMENT_COST)?.value || 0),
              useCaseId: usecase,
            })}
            type='submit'
            loading={false}
            disabled={solutionToExecute === null}
            label={intl.formatMessage({ id: 'trade_offs.details.set_strategy' })}
          />
        </Box>
      </div>
    </Grid>
  )
}

export default TradeOffsOptimizePanelComponent
