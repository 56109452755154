import React, { useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useGridApiRef } from '@mui/x-data-grid-premium'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from '@redux/hooks'
import { requestConstraintsAnalyzeArtifactAction } from '@redux/modules/recommendation-artifacts/recommendation-artifacts.actions'
import { getDataGridId } from '@utils/data-grid.utils'
import { getColumnsConfigFromSchema } from '@utils/recommendation-artifacts.utils'
import { DATA_GRIDS } from '@constants/data-grid.constants'
import { isFetchingUseCase } from '@redux/modules/use-case/use-case.selectors'

import {
  isFetchingConstraintsAnalyzeArtifact,
  getConstraintsAnalyzeArtifact,
  getComparisonModeState,
} from '@redux/modules/recommendation-artifacts/recommendation-artifacts.selectors'

import DataGridComponent from '@base/datagrid/data-grid'
import SectionTileComponent from '@base/tiles/SectionTile'
import useGridInitialState from '@hooks/useGridInitialState.hook'

interface ConstraintsAnalyzeArtifactContainerProps {}

const ConstraintsAnalyzeArtifactContainer: React.FC<ConstraintsAnalyzeArtifactContainerProps> = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const apiRef = useGridApiRef()
  const match = useRouteMatch<Common.RouterMatch>()
  const useCaseId = match.params.usecase
  const tableId = getDataGridId(DATA_GRIDS.OPTIMIZATION_CONSTRAINTS_ANALYZE_OVERVIEW, 1)

  const constraintsArtifact = useSelector((state) => getConstraintsAnalyzeArtifact(state))
  const isFetching = useSelector((state) => isFetchingConstraintsAnalyzeArtifact(state))
  const isFetchingUseCaseDetails = useSelector((state) => isFetchingUseCase(state))
  const comparisonMode = useSelector((state) => getComparisonModeState(state))

  useEffect(() => {
    dispatch(
      requestConstraintsAnalyzeArtifactAction({
        useCaseId,
      }),
    )
  }, [useCaseId, dispatch])

  const initialState = useGridInitialState(tableId, constraintsArtifact.gridInitialState)
  const columns = getColumnsConfigFromSchema(constraintsArtifact.columns)

  useEffect(() => {
    if (apiRef.current && apiRef.current.setColumnVisibility) {
      constraintsArtifact.metaData.actualValueColumnNames.forEach((column) => {
        apiRef.current.setColumnVisibility(column, comparisonMode)
      })
    }
  }, [comparisonMode, apiRef, constraintsArtifact])

  return (
    <SectionTileComponent
      name='constraintsAnalyze'
      title={intl.formatMessage({ id: 'recommendation.analyze.constraints' })}
      noData={constraintsArtifact.rows.length === 0 && !isFetching}
      noDataMessage={intl.formatMessage({ id: 'recommendation.analyze.constraints.empty' })}
      loading={isFetching || isFetchingUseCaseDetails || !initialState}
      contentType='data-grid'
      contentMinHeight='400px'
    >
      <DataGridComponent
        id={tableId}
        name={DATA_GRIDS.OPTIMIZATION_CONSTRAINTS_ANALYZE_OVERVIEW}
        columns={columns}
        apiRef={apiRef}
        loading={isFetching}
        rows={constraintsArtifact.rows}
        getRowId={(row) => row.id}
        initialState={initialState}
        groupingColDef={{ width: 300 }}
        rounded={false}
        autosizeOnMount={true}
        disableAutosize={false}
      />
    </SectionTileComponent>
  )
}

export default ConstraintsAnalyzeArtifactContainer
