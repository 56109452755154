import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { getChartTooltipStyles } from '@utils/styles.utils'

const useStyles = makeStyles()((theme: Theme) => ({
  emptyState: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: theme.spacing(60),
    color: theme.palette.new.grey_c,
    [theme.breakpoints.down('xl')]: {
      minHeight: theme.spacing(40),
    },
  },
  emptyStateText: {
    color: theme.palette.new.black,
  },
  chartContainer: {
    height: theme.spacing(60),
    [theme.breakpoints.down('xl')]: {
      height: theme.spacing(40),
    },
  },
  ...getChartTooltipStyles(theme),
}))

export default useStyles
