import { HERA } from '@constants/api-provider.constants'
import { checkApiForError } from '@utils/redux.utils'
import apiProvider from '@redux/api-provider'

export const getForecastValue = ({ useCaseIds }: { useCaseIds: string[] }) => {
  return apiProvider
    .getApi(HERA)
    .post('v1/forecast-value/get', {
      useCaseIds,
    })
    .then(({ data }) => {
      checkApiForError(data)

      return data?.data?.forecastValues as Hera.ForecastValue[]
    })
    .catch((e) => {
      return []
    })
}

export const getAnalyzeTable = (
  payload: Analyze.AnalyzeTablePaginatedAPIRequest,
) => {
  return apiProvider
    .getApi(HERA)
    .post('v2/insights/live-forecasts/table-data/get', payload)
    .then(({ data }) => {
      checkApiForError(data)

      const tableData = data.data.tableData || {}

      return tableData as Analyze.AnalyzeTablePaginatedAPIResponse
    })
}

export const getBacktestingTable = (
  payload: Monitor.BacktestingTablePaginatedAPIRequest,
) => {
  return apiProvider
    .getApi(HERA)
    .post('v1/insights/backtests/table-data/get', payload)
    .then(({ data }) => {
      checkApiForError(data)

      const tableData = data.data.tableData || {}

      return tableData as Monitor.BacktestingTablePaginatedAPIResponse
    })
}

export const getBacktestingChart = (
  payload: Monitor.BacktestingChartAPIRequest,
) => {
  return apiProvider
    .getApi(HERA)
    .post('v1/insights/backtests/chart-data/get', payload)
    .then(({ data }) => {
      checkApiForError(data)

      return data.data.chartData as Monitor.BacktestingChartAPIResponse
    })
}

export const getAnalyzeChart = (payload: Analyze.AnalyzeChartAPIRequest) => {
  return apiProvider
    .getApi(HERA)
    .post('v1/insights/live-forecasts/chart-data/get', payload)
    .then(({ data }) => {
      checkApiForError(data)

      return data.data.chartData as Analyze.AnalyzeChartAPIResponse
    })
}

export const getABCTotals = ({ useCaseId }: Hera.AbcTotalsApiRequest) => {
  return apiProvider
    .getApi(HERA)
    .post('v2/insights/abc-totals/get', { useCaseId })
    .then(({ data }) => {
      checkApiForError(data)

      return data.data.abcTotals as Hera.AbcTotalsApiResponse
    })
}

export const getAvailableBacktestingFolds = ({
  useCaseId,
}: Hera.ListBacktestFoldsApiRequest) => {
  return apiProvider
    .getApi(HERA)
    .post('v1/insights/backtests/backtest-fold/list', { useCaseId })
    .then(({ data }) => {
      checkApiForError(data)

      return data.data.backtestFolds as Hera.ListBacktestFoldsApiResponse
    })
}

export const getLiveMonitoringForecastHorizons = ({
  useCaseId,
}: Hera.ListForecastHorizonsApiRequest) => {
  return apiProvider
    .getApi(HERA)
    .post('v1/insights/live-monitoring/live-forecasts-horizon/list', {
      useCaseId,
    })
    .then(({ data }) => {
      checkApiForError(data)

      return data.data
        .liveForecastHorizons as Hera.ListForecastHorizonsApiResponse
    })
}

export const getLiveMonitoringChart = (
  payload: Monitor.LiveMonitoringChartAPIRequest,
) => {
  return apiProvider
    .getApi(HERA)
    .post('v1/insights/live-monitoring/chart-data/get', payload)
    .then(({ data }) => {
      checkApiForError(data)

      return data.data.chartData as Monitor.LiveMonitoringChartAPIResponse
    })
}

export const getLiveMonitoringTable = (
  payload: Monitor.LiveMonitoringTablePaginatedAPIRequest,
) => {
  return apiProvider
    .getApi(HERA)
    .post('v1/insights/live-monitoring/table-data/get', payload)
    .then(({ data }) => {
      checkApiForError(data)

      const tableData = data.data.tableData || {}

      return tableData as Monitor.LiveMonitoringTablePaginatedAPIResponse
    })
}
