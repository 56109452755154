import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles<{ trackColor?: string }>()((theme: Theme, { trackColor }) => ({
  root: {
    color: theme.palette.new.black,
    height: 1,
    padding: '13px 0',
  },
  rootContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
  },
  min: {
    marginRight: theme.spacing(3),
  },
  max: {
    marginLeft: theme.spacing(3),
  },
  thumb: {
    zIndex: 5,
    height: 40,
    width: 19,
    backgroundColor: '#fff',
    border: `1px solid ${theme.palette.new.black}`,
    boxShadow: 'none',
    transition: 'none',
    borderRadius: 5,
    '&.Mui-disabled': {
      height: 40,
      width: 19,
      backgroundColor: theme.palette.new.grey_d,
      cursor: 'not-allowed',
      pointerEvents: 'all',
    },
    '&:focus, &:hover, &$active': {
      boxShadow: 'none !important',
    },
    '&.Mui-active': {
      boxShadow: 'none !important',
    },
    '&.Mui-focusVisible': {
      boxShadow: 'none !important',
    },
    '&:before': {
      boxShadow: 'none !important',
    },
    '& .bar': {
      height: 13,
      width: 1,
      backgroundColor: theme.palette.new.black,
      marginLeft: 1.5,
      marginRight: 1.5,
    },
    '&:hover': {
      boxShadow: 'none !important',
    },
  },
  mark: {
    height: '7px',
    width: 1,
    backgroundColor: theme.palette.new.black_d,
  },
  markActive: {
    opacity: 1,
    height: '7px',
    backgroundColor: theme.palette.new.white,
  },
  active: {},
  track: {
    height: 1,
    border: 'none',
    transition: 'none',
    color: trackColor || theme.palette.new.white,
  },
  rail: {
    opacity: 1,
    height: 1,
    color: theme.palette.new.black_d,
  },
}))

export default useStyles
