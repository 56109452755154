import React from 'react'

import {
  styled, FormGroup,
  FormGroupProps,
  useTheme, FormLabel,
  FormControl,
} from '@mui/material'

import typography from '@configuration/theme/theme.typography'
import { DEFAULT_BORDER_RADIUS, DEFAULT_PADDING } from '@constants/ui.constants'

const GroupFormControl = styled(FormControl)(({ theme }) => ({
  width: '100%',
  gap: '4px',
}))

export const GroupFormLabel = styled(FormLabel)(({ theme }) => ({
  ...typography.caption,
  color: theme.palette.new.business_black_60,
}))

const FormGroupBase = styled(FormGroup)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.new.business_black_20,
  borderRadius: DEFAULT_BORDER_RADIUS.SMALL,
  width: '100%',
  '& .MuiFormControl-root': {
    border: 'none',
    borderBottom: '1px solid',
    borderColor: theme.palette.new.business_black_20,

    '&:last-child': {
      borderBottom: 'none',
    },
  },
}))

export interface FormGroupComponentProps extends FormGroupProps {
  /**
   * The label of the form group
   */
  label?: string
}

const FormGroupComponent: React.FC<FormGroupComponentProps> = ({
  label,
  children,
  ...props
}) => {
  const theme = useTheme()

  const labelStyle = {
    paddingLeft: DEFAULT_PADDING.MEDIUM,
    color: theme.palette.new.business_black_60,
  }

  return (
    <GroupFormControl
      variant='standard'
      data-testid={FormGroupComponent.name}
    >
      {
        label ? (
          <GroupFormLabel
            sx={labelStyle}
          >
            {label}
          </GroupFormLabel>
        ) : (
          null
        )
      }

      <FormGroupBase
        {...props}
      >
        {children}
      </FormGroupBase>
    </GroupFormControl>
  )
}

export default FormGroupComponent
