import React from 'react'
import { useIntl } from 'react-intl'

import { Typography } from '@mui/material'

import useStyles from './TradeOffsLegend.styles'

export interface TradeOffsLegendComponentProps {
  optimizeEnabled?: boolean,
  showSuboptimalSolutions?: boolean,
}

const TradeOffsLegendComponent: React.FC<TradeOffsLegendComponentProps> = (props) => {
  const {
    showSuboptimalSolutions,
    optimizeEnabled,
  } = props

  const { classes, cx } = useStyles()
  const intl = useIntl()

  return (
    <div className={classes.legendContainer} data-testid={TradeOffsLegendComponent.name}>
      <div
        className={cx(classes.legendItem, {
          [classes.legendItemDisabled]: !showSuboptimalSolutions,
        })}
      >
        <div className={classes.legendItemIndicatorSubOptimum} />
        <Typography className={classes.legendItemIndicatorText}>
          {intl.formatMessage({ id: 'trade_offs.chart.pareto.sub_optimum' })}
        </Typography>
      </div>
      <div className={classes.legendItem}>
        <div className={classes.legendItemIndicatorOptimum} />
        <Typography className={classes.legendItemIndicatorText}>
          {intl.formatMessage({ id: 'trade_offs.chart.pareto.optimum' })}
        </Typography>
      </div>
      {
        optimizeEnabled ? (
          null
        ) : (
          <div className={classes.legendItem}>
            <Typography className={classes.legendItemIndicatorSelectedOptimum}>
              1
            </Typography>
            <Typography className={classes.legendItemIndicatorText}>
              {intl.formatMessage({ id: 'trade_offs.chart.pareto.selected_optimum' })}
            </Typography>
          </div>
        )
      }
      <div
        className={classes.legendItem}
      >
        <Typography className={classes.legendItemIndicatorOptimumToExecute}>
          {optimizeEnabled ? null : 1}
        </Typography>
        <Typography className={classes.legendItemIndicatorText}>
          {intl.formatMessage({ id: optimizeEnabled ? 'trade_offs.details.choosen_strategy' : 'trade_offs.chart.pareto.optimumToExecute' })}
        </Typography>
      </div>
    </div>
  )
}

export default TradeOffsLegendComponent
