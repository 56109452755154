import { createAction } from 'redux-actions'

import {
  REQUEST_FILES_LIST,
  RECEIVE_FILES_LIST,
  REQUEST_FILE_DOWNLOAD,
  REQUEST_FILE_DELETE,
  REQUEST_FILE_UPLOAD,
  CLOSE_FILE_PREVIEW,
  REQUEST_FILE_PREVIEW,
  RECEIVE_FILE_PREVIEW,
  REQUEST_SORTING_CHANGE,
  RECEIVE_SORTING_CHANGE,
  START_FETCHING_FILES,
  STOP_FETCHING_FILES,
} from './file-service.action-types'

import {
  RequestFilesListActionPayload,
  RequestFileDeleteActionPayload,
  RequestFileDownloadActionPayload,
  RequestFileUploadActionPayload,
  RequestFilePreviewActionPayload,
  RequestSortingChangeActionPayload,
} from './file-service.types'

export const requestFilesListAction = createAction<RequestFilesListActionPayload>(REQUEST_FILES_LIST)
export const receiveFilesListAction = createAction(RECEIVE_FILES_LIST)

export const requestFileDownloadAction = createAction<RequestFileDownloadActionPayload>(REQUEST_FILE_DOWNLOAD)
export const requestFileDeleteAction = createAction<RequestFileDeleteActionPayload>(REQUEST_FILE_DELETE)
export const requestFileUploadAction = createAction<RequestFileUploadActionPayload>(REQUEST_FILE_UPLOAD)

export const requestSortingChangeAction = createAction<RequestSortingChangeActionPayload>(REQUEST_SORTING_CHANGE)
export const receiveSortingChangeAction = createAction(RECEIVE_SORTING_CHANGE)

export const closeFilePreviewAction = createAction(CLOSE_FILE_PREVIEW)
export const requestFilePreviewAction = createAction<RequestFilePreviewActionPayload>(REQUEST_FILE_PREVIEW)
export const receiveFilePreviewAction = createAction(RECEIVE_FILE_PREVIEW)

export const startFetchingFilesAction = createAction<string>(START_FETCHING_FILES)
export const stopFetchingFilesAction = createAction(STOP_FETCHING_FILES)
