import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'

import {
  Box,
  Grid,
  Typography,
  useTheme,
} from '@mui/material'

import SectionTileComponent from '@base/tiles/SectionTile'
import VisualizationItemComponent from '@components/recommendation-artifacts/VisualizationItem'
import DropdownMenuComponent from '@base/dropdowns/DropdownMenu'
import { PARETOS_GRADIENT } from '@constants/ui.constants'

export interface ArticleBreakdownComponentProps {
  /**
   * If true, the component will be in loading state
   */
  loading?: boolean,
  /**
   * If true, the component will be in comparison mode
   */
  comparisonMode?: boolean,
  /**
   * Breakdown items
   */
  breakdown: RecommendationArtifacts.ArticlesOverviewDetailsArtifact['breakdown'],
}

const ArticleBreakdownComponent: React.FC<ArticleBreakdownComponentProps> = ({
  loading,
  comparisonMode,
  breakdown,
}) => {
  const [selectedItem, setSelectedItem] = useState<string | null>(null)
  const intl = useIntl()
  const theme = useTheme()

  const dropdownOptions = useMemo(() => {
    if (!breakdown || !breakdown.options) {
      return []
    }

    return breakdown.options.map((option) => {
      return {
        label: option,
        value: option,
      }
    })
  }, [
    breakdown,
  ])

  useEffect(() => {
    if (!breakdown || !breakdown.options) {
      return
    }

    if (breakdown.options && breakdown.options.length > 0) {
      setSelectedItem(breakdown.defaultOption || breakdown.options[0])
    } else {
      setSelectedItem(null)
    }
  }, [breakdown])

  const kpiItems = useMemo(() => {
    if (!breakdown || !selectedItem || !breakdown.kpiItems) {
      return []
    }

    return breakdown.kpiItems[selectedItem] || []
  }, [
    breakdown,
    selectedItem,
  ])

  const visualizationItems = useMemo(() => {
    if (!breakdown || !selectedItem || !breakdown.visualisations) {
      return []
    }

    return breakdown.visualisations[selectedItem] || []
  }, [
    breakdown,
    selectedItem,
  ])

  const leftSideActionsAfter = (
    <>
      <Box
        display='flex'
        alignItems='center'
      >
        <DropdownMenuComponent
          name='articleBreakdownDropdown'
          options={dropdownOptions}
          value={selectedItem!}
          onChange={(value) => setSelectedItem(String(value))}
          disabled={loading}
          buttonProps={{
            bold: true,
            ariaLabel: intl.formatMessage({ id: 'recommendation.articleOverview.dropdown' }),
          }}
        />
      </Box>
      <Box
        display='flex'
        alignItems='center'
        gap={2}
        ml={1}
      >
        {
          kpiItems.map((kpiItem, index) => {
            const hightlightedStyles = kpiItem.highlighted ? {
              background: PARETOS_GRADIENT,
              backgroundClip: 'text',
              textFillColor: 'transparent',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            } : {}

            return (
              <Box
                key={index}
                display='flex'
                alignItems='center'
              >
                <Typography
                  variant='subtitle1'
                  noWrap={true}
                  sx={hightlightedStyles}
                >
                  {kpiItem.title}
                </Typography>
                &nbsp;
                <Typography
                  variant='subtitle1'
                  noWrap={true}
                  sx={hightlightedStyles}
                >
                  {intl.formatMessage({ id: 'recommendation.articleOverview.kpi' }, { value: kpiItem.value, unit: kpiItem.unit || '' })}
                </Typography>
              </Box>
            )
          })
        }
      </Box>
    </>
  )

  const visualizations = useMemo(() => {
    if (!visualizationItems || visualizationItems.length === 0) {
      return null
    }

    return visualizationItems.map((visualizationItem, index) => {
      return (
        <Grid
          item={true}
          xs={6}
          sm={6}
          md={6}
          lg={6}
          key={index}
          columnGap={0}
          sx={{
            padding: '20px',
            borderRight: index % 2 === 0 ? `1px solid ${theme.palette.new.grey_b}` : 'none',
            borderBottom: index < visualizationItems.length - 2 ? `1px solid ${theme.palette.new.grey_b}` : 'none',
          }}
        >
          <VisualizationItemComponent
            id={`results-visualization-${index}`}
            key={index}
            visualization={visualizationItem}
            comparisonMode={comparisonMode}
            wappred={false}
            minHeight='280px'
          />
        </Grid>
      )
    })
  }, [theme, visualizationItems, comparisonMode])

  const hasItems = (
    breakdown && breakdown.options && breakdown.options.length > 0 && visualizationItems.length > 0
  )

  if (!hasItems) {
    return null
  }

  return (
    <SectionTileComponent
      name='articleBreakdown'
      container={true}
      item={true}
      title={breakdown?.title || intl.formatMessage({ id: 'recommendation.articleOverview.dropdownTitle' })}
      leftSideActionsAfter={leftSideActionsAfter}
      contentMinHeight='360px'
      contentPadding='0px'
      spacing={0}
      loading={loading}
    >
      {visualizations}
    </SectionTileComponent>
  )
}

export default ArticleBreakdownComponent
