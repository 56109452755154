import { checkApiForError } from '@utils/redux.utils'
import { CHAT } from '@constants/api-provider.constants'
import { CHAT_ROLES, CHAT_ROUTES_TYPES } from '@constants/chat.constnats'
import {
  LS_CHAT_TOKEN, LS_CHAT_ENDPOINT,
  LS_CHAT_ADDITIONAL_PAYLOAD,
  LS_CHAT_USE_TOKEN,
} from '@utils/local-storage.utils'

import apiProvider from '@redux/api-provider'

import {
  RequestChatMessagesAPIPayload,
  SendChatMessagePayload,
} from './chat.types'

export const listChatMessages = (payload: RequestChatMessagesAPIPayload = {} as RequestChatMessagesAPIPayload) => {
  return [{
    // eslint-disable-next-line max-len
    text: `\n## Hello, ${payload.name}!\n\nWhat an honour to have you as my first visitor! My name is **Socrates** and I am your virtual assistant.\n\nI am designed to provide helpful and informative status information, insights and recommendations for ongoing change management programs and projects.\n\nMy current knowledge is orbiting around a fixed set of topics. I understand you the best if you use the following keywords in your questions: data, recommendation, visualization.\n\nAs I am just starting this job my knowledge and abilities are currently still limited but will grow over time. Any interaction will help me get better so I kindly ask for your patience and support, specifically when you call out to me directly. But now: let's start!\n\nWhat would you like to do first? You can write your answer in the input field or directly select one of the items.`,
    role: CHAT_ROLES.ASSISTANT,
    route: CHAT_ROUTES_TYPES.DEFAULT,
    actions: [{
      title: 'Projects',
      text: 'How is the overall projects status? What is the project with the highest risk?',
      recommended: false,
    }, {
      title: 'Stakeholders',
      text: 'What Stakeholder Group has the most negative Employee Sentiment? Which Stakeholder Group needs to be prioritized?',
      recommended: false,
    }, {
      title: 'Test everything!!!',
      text: '__test__',
      recommended: true,
    }, {
      title: 'Data',
      text: 'What data do you have? How I can access it?',
      recommended: false,
    }, {
      title: 'Visualization',
      text: 'Visualize all the available data for me',
      recommended: false,
    }, {
      title: 'Recommendations',
      text: 'Give me recommendations based on my data.',
      recommended: false,
    }],
  }] as Chat.ChatMessages
}

const DEFAULT_CHAT_API_ENDPOINT = 'v1/chat'
const DEFAULT_CHAT_API_TOKEN = 'yW50knqVMU4CHU$lSux57R2h3DKW9twr25lNm1Eqv7fy0WWVWreDeJ'
export const DEFAULT_CHAT_API_CONFIG = {
  endpoint: DEFAULT_CHAT_API_ENDPOINT,
  apiToken: DEFAULT_CHAT_API_TOKEN,
  additionalPayload: '',
  useApiToken: true,
}

export const sendChatMessageAccenture = (payload: SendChatMessagePayload) => {
  const config = getChatAPIConfigFromLocalStorage()
  const reqPayload = {
    question: payload.message.text,
    sessionId: payload.sessionId,
    route: payload.nextRoute,
  }

  if (config.useApiToken) {
    Object.assign(reqPayload, {
      apiToken: config.apiToken,
    })
  }

  try {
    const additinalPayload = JSON.parse(config.additionalPayload)

    if (additinalPayload) {
      Object.assign(reqPayload, additinalPayload)
    }
  } catch (e) {
    /* no-op */
  }

  return apiProvider.getApi(CHAT).post(config.endpoint, reqPayload).then(({ data }) => {
    checkApiForError(data)

    return data.data as Chat.ChatMessage
  })
}

export const getChatAPIConfigFromLocalStorage = () => {
  if (!localStorage) {
    return DEFAULT_CHAT_API_CONFIG
  }

  return {
    endpoint: localStorage.getItem(LS_CHAT_ENDPOINT) || DEFAULT_CHAT_API_ENDPOINT,
    apiToken: localStorage.getItem(LS_CHAT_TOKEN) || DEFAULT_CHAT_API_TOKEN,
    additionalPayload: localStorage.getItem(LS_CHAT_ADDITIONAL_PAYLOAD) || '',
    // eslint-disable-next-line
    useApiToken: localStorage.getItem(LS_CHAT_USE_TOKEN) === 'false' ? false : true,
  }
}

export const setChatAPIConfigFieldToLocalStorage = (field: string, value: string) => {
  if (!localStorage) {
    return
  }

  localStorage.setItem(field, value)
}

export const clearChatAPIConfigFieldFromLocalStorage = () => {
  if (!localStorage) {
    return
  }

  localStorage.removeItem(LS_CHAT_ENDPOINT)
  localStorage.removeItem(LS_CHAT_TOKEN)
  localStorage.removeItem(LS_CHAT_USE_TOKEN)
  localStorage.removeItem(LS_CHAT_ADDITIONAL_PAYLOAD)
}
