import React from 'react'
import { BaseSelectProps, Select } from '@mui/material'

export interface DataGridSelectBaseComponentProps extends BaseSelectProps {
  forwardedRef?: any
}

export const DataGridSelectBaseComponent: React.FC<DataGridSelectBaseComponentProps> = ({
  forwardedRef,
  sx,
  ...props
}) => {
  return (
    <Select
      data-testid={DataGridSelectBaseComponent.name}
      ref={forwardedRef}
      sx={{
        '& .MuiBackdrop-root': {
          opacity: '0 !important',
        },
      }}
      {...props}
      labelId='1'
    />
  )
}

export default React.forwardRef((props, ref) => <DataGridSelectBaseComponent {...props} forwardedRef={ref} />)
