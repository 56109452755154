import React, { useRef, useState } from 'react'

import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import { useIntl } from 'react-intl'

import DropdownButtonComponent from '@base/dropdowns/DropdownButton'
import ParameterSelectorNoOptionSectionComponent from '@components/connect-view/parameters/ParameterSelectorNoOptionSection'
import ParameterSelectorListBoxComponent from '@components/connect-view/parameters/ParameterSelectorListBox'
import { SourceTypeOptionExtended } from '@utils/flow.utils'

import {
  CATEGORIES_TO_LABEL_MAP,
  SourceTypeOption,
  SOURCE_TYPES_CATEGORIES,
  SOURCE_TYPES_TO_DISABLED_ICONS_MAP,
  SOURCE_TYPES_TO_ICONS_MAP,
} from '@constants/flow.constants'

import {
  Popper, InputBase,
  Typography, Box,
} from '@mui/material'

import useStyles from './ParameterSourceSelector.styles'

export interface ParameterSourceSelectorComponentProps extends Omit<AutocompleteProps<any, true, false, false>, 'renderInput' | 'freeSolo'> {
  disabled?: boolean,
  isFetching?: boolean,
  handleChangeCallback(e: React.ChangeEvent<any>, selectedValue: any, newValue?: any, reason?: any): any,
  value: any,
}

const ParameterSourceSelectorComponent: React.FC<ParameterSourceSelectorComponentProps> = ({
  value,
  isFetching,
  handleChangeCallback,
  options,
  disabled,
}) => {
  const intl = useIntl()
  const containerRef = useRef<HTMLElement>(null)

  const [anchorEl, setAnchorEl] = useState(null)
  const [width, setWidth] = useState(0)

  const { classes, cx } = useStyles({
    inputWidth: `${width}px`,
  })

  const handleOpen = (event: React.ChangeEvent<any>) => {
    if (disabled) {
      return
    }

    if (containerRef && containerRef.current) {
      setWidth(containerRef.current.clientWidth || 0)
    }

    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event?: React.ChangeEvent<any>, reason?: any) => {
    if (reason !== 'toggleInput') {
      setAnchorEl(null)
    }
  }

  const handleChange = (e: React.ChangeEvent<any>, selectedValue: any, newValue?: any, reason?: any) => {
    if (selectedValue) {
      handleChangeCallback && handleChangeCallback(e, selectedValue)
    }

    handleClose()
  }

  const getOptionLabel = (option: SourceTypeOption) => {
    return option.labelKey ? intl.formatMessage({ id: option.labelKey }) : ''
  }

  const getButtonTitle = () => {
    if (getOptionLabel && value) {
      return getOptionLabel(value)
    } else if (value) {
      return value.name || value.label
    }

    if (isFetching) {
      return intl.formatMessage({ id: 'common.loading' })
    }

    return intl.formatMessage({ id: 'connect.modal.parameter.source.empty_selection' })
  }

  const buttonTitle = getButtonTitle()
  const open = Boolean(anchorEl)

  const renderOptionDefault = (props: React.HTMLAttributes<HTMLLIElement>, option: SourceTypeOptionExtended, { selected } : { selected: boolean }) => {
    const label = option.labelKey ? intl.formatMessage({ id: option.labelKey }) : ''
    const IconComponent = option.isDisabled ? SOURCE_TYPES_TO_DISABLED_ICONS_MAP[option.value] : SOURCE_TYPES_TO_ICONS_MAP[option.value]

    return (
      <Box component='li' {...props}>
        <Box
          className={classes.optionContainer}
        >
          <Box className={classes.optionIcon}>
            <IconComponent />
          </Box>
          <Typography
            title={label}
            className={cx(classes.optionLabel, {
              [classes.optionLabelSelected]: selected,
            })}
          >
            {label}
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      className={cx(classes.rootContainer, {
        [classes.disabled]: disabled,
      })}
      ref={containerRef}
      data-testid={ParameterSourceSelectorComponent.name}
    >
      <DropdownButtonComponent
        name='autocompleteButton'
        label={buttonTitle}
        onClick={handleOpen}
        open={open}
        disabled={disabled}
      />

      <Popper
        open={open}
        anchorEl={anchorEl}
        placement='bottom-start'
        className={classes.popper}
        modifiers={[{
          name: 'flip',
          options: {
            enabled: false,
          },
        }]}
      >
        <Box className={classes.root} data-testid={`${ParameterSourceSelectorComponent.name}-autocomplete`}>
          <Autocomplete
            id='parameterSourceSelector'
            open={true}
            multiple={true}
            autoHighlight={true}
            filterSelectedOptions={false}
            autoSelect={false}
            fullWidth={true}
            freeSolo={false}
            disablePortal={true}
            disableCloseOnSelect={false}
            value={value ? [value] : []}
            getOptionLabel={getOptionLabel}
            ListboxComponent={ParameterSelectorListBoxComponent as any}
            noOptionsText={(
              <ParameterSelectorNoOptionSectionComponent />
            )}
            groupBy={(option) => {
              const category: SOURCE_TYPES_CATEGORIES = option.category

              return CATEGORIES_TO_LABEL_MAP[category] ? intl.formatMessage({ id: CATEGORIES_TO_LABEL_MAP[category] }) : ''
            }}
            getOptionDisabled={(option) => {
              return option.isDisabled
            }}
            isOptionEqualToValue={(option, secValue) => {
              return option.value === secValue.value
            }}
            renderTags={() => null}
            onClose={handleClose}
            classes={{
              root: classes.rootAutocomplete,
              paper: classes.paper,
              option: classes.option,
              noOptions: classes.option,
              groupUl: classes.groupUl,
              groupLabel: classes.groupLabel,
              popperDisablePortal: classes.popperDisablePortal,
            }}
            onChange={(event, newValue, reason) => {
              const selectedValue = newValue[newValue.length - 1]

              handleChange(event, selectedValue, newValue, reason)
            }}
            renderGroup={(option) => {
              return (
                <Box component='li' key={option.key}>
                  <Box
                    className={cx(classes.groupLabel, {
                      [classes.groupLabelEmpty]: !option.group,
                    })}
                  >
                    {option.group}
                  </Box>
                  <Box component='ul' className={classes.groupUl}>
                    {option.children}
                  </Box>
                </Box>
              )
            }}
            renderOption={renderOptionDefault}
            options={options}
            renderInput={(params) => (
              <InputBase
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                autoFocus={true}
                type='search'
                className={classes.inputBase}
                placeholder={intl.formatMessage({ id: 'connect.modal.parameter.source.placeholder' })}
              />
            )}
          />
        </Box>
      </Popper>
    </Box>
  )
}

export default ParameterSourceSelectorComponent
