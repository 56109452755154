import { createAction } from 'redux-actions'

import {
  REQUEST_CHAT_MESSAGES,
  RECEIVE_CHAT_MESSAGES,
  SEND_CHAT_MESSAGE,
  SEND_CHAT_MESSAGE_DONE,
  START_FETCHING_CHAT,
  STOP_FETCHING_CHAT,
} from './chat.action-types'

import {
  RequestChatMessagesPayload,
  SendChatMessagePayload,
} from './chat.types'

export const requestChatMessagesAction = createAction<RequestChatMessagesPayload>(REQUEST_CHAT_MESSAGES)
export const receiveChatMessagesAction = createAction(RECEIVE_CHAT_MESSAGES)

export const sendChatMessageAction = createAction<SendChatMessagePayload>(SEND_CHAT_MESSAGE)
export const sendChatMessageDoneAction = createAction(SEND_CHAT_MESSAGE_DONE)

export const startFetchingChatAction = createAction<string>(START_FETCHING_CHAT)
export const stopFetchingChatAction = createAction(STOP_FETCHING_CHAT)
