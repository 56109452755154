import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

type allowedClassnames = 'subHeader'

const useStyles = makeStyles<void, allowedClassnames>()((theme: Theme, props, classnames) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '280px',
    padding: theme.spacing(3, 3),
    width: '100%',
  },
  borderRight: {
    borderRight: '1px solid rgba(255, 255, 255, 0.4)',
  },
  headerRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  header: {
    fontWeight: 500,
    fontSize: '36px',
    whiteSpace: 'nowrap',
    lineHeight: '40px',
    color: theme.palette.new.white,
    margin: theme.spacing(1.5, 0),
    [theme.breakpoints.down('xl')]: {
      fontSize: '28px',
      lineHeight: '30px',
    },
  },
  description: {
    fontWeight: 400,
    fontSize: '16px',
    width: '100%',
    lineHeight: '20px',
    color: theme.palette.new.white,
    opacity: 0.8,
    [theme.breakpoints.down('xl')]: {
      fontSize: '14px',
      lineHeight: '18px',
    },
  },
  subHeader: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    color: 'rgba(255, 255, 255, 0.8)',
  },
  valueRoot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  value: {
    fontWeight: 400,
    fontSize: '48px',
    lineHeight: '40px',
    color: theme.palette.new.white,
    marginRight: theme.spacing(2),
    minWidth: '175px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xl')]: {
      minWidth: '145px',
      fontSize: '36px',
      lineHeight: 1,
    },
  },
  subValue: {
    fontWeight: 400,
    fontSize: '28px',
    color: theme.palette.new.white,
    lineHeight: 1,
    opacity: 0.6,
    [theme.breakpoints.down('xl')]: {
      fontSize: '24px',
    },
  },
  colorless: {
    [`& .${classnames.subHeader}`]: {
      color: theme.palette.new.black_d,
    },
  },
}))

export default useStyles
