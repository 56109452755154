import {
  takeEvery, call,
  put, select, take,
  fork, cancel, delay,
  takeLatest,
} from 'redux-saga/effects'

import { push } from 'redux-first-history'

import { Task } from 'redux-saga'
import { TOAST_TYPE_SUCCESS, TOAST_TYPE_ERROR } from '@constants/common.constants'
import { parseAndReportErrorResponse } from '@utils/redux.utils'
import { generatePath, matchPath } from 'react-router-dom'

import { CONNECT_PATH, DATA_UPLOAD_PATH, DATA_FILE_LINK_PATH } from '@constants/routes.constants'
import { changeToastAction } from '@redux/modules/common/common.actions'
import { generateUploadToken, downloadByToken } from '@redux/modules/file-service/file-service.api'
import { fetchUseCaseAction, prepareConnectOverviewAction } from '@redux/modules/use-case/use-case.actions'
import { PREPARE_CONNECT_OVERVIEW_DONE, RECEIVE_USE_CASE } from '@redux/modules/use-case/use-case.action-types'
import { ActionPayload, State } from '@redux/modules/types'
import { ERR_CANCELED_CODE, REQUEST_CANCELED_BY_USER } from '@utils/request-cancelation.utils'
import { USE_CASE_DATA_TYPES_MODAL_NAME } from '@constants/modals.constants'
import { setPrimaryModalPageName } from '@redux/modules/modal-manager/modal-manager.actions'
import { AUTO_REFRESH_INTERVAL_IN_MS, LEGACY_FILES_IDENTIFIER, PROCESSING_STATES } from '@constants/files.constants'
import { isLegacyUploadCheck } from '@utils/use-cases.utils'

import * as API from './training-files.api'

import {
  REQUEST_TRAINING_DATA_FLAT_LIST,
  REQUEST_FILE_IDENTIFIERS_LIST,
  RECEIVE_FILE_IDENTIFIERS_LIST,
  CREATE_FILE_IDENTIFIER_VERSION,
  REMOVE_FILE_IDENTIFIER_VERSION,
  REQUEST_TRAINING_DATA_LIST,
  RECEIVE_TRAINING_DATA_LIST,
  UPLOAD_TRAINING_DATA,
  DOWNLOAD_TRAINING_DATA,
  REMOVE_TRAINING_DATA,
  CREATE_FILE_IDENTIFIER,
  REMOVE_FILE_IDENTIFIER,
  TRAINING_DATA_AUTO_REFRESH_START,
  TRAINING_DATA_AUTO_REFRESH_STOP,
  RECEIVE_TRAINING_DATA_FLAT_LIST,
} from './training-files.action-types'

import {
  receiveTrainingDataFlatList,
  requestTrainingDataListAction,
  receiveTrainingDataList,
  receiveFileIdentifiersList,
  startFetchingTrainingFilesAction,
  stopFetchingTrainingFilesAction,
  requestFileIdentifiersListAction,
  trainingDataAutoRefreshStartAction,
  trainingDataAutoRefreshStopAction,
  requestTrainingDataFlatListAction,
} from './training-files.actions'

import {
  ListTrainingDataFlatListPayload,
  RequestTrainingDataListActionPayload,
  DownloadTrainingDataActionPayload,
  UploadTrainingDataActionPayload,
  RemoveTrainingDataActionPayload,
  RequestFileIdentifiersListActionPayload,
  CreateFileIdentifiersActionPayload,
  RemoveFileIdentifiersActionPayload,
  CreateFileIdentifierVersionActionPayload,
  RemoveFileIdentifierVersionActionPayload,
} from './training-files.types'

import { getUseCaseFileIdentifiers, getUseCaseTrainingData } from './training-files.selectors'

const CREATE_FILE_SUCCESS = 'use_cases.files.confirmation.queued'
const CREATE_FILE_OLD_SUCCESS = 'use_cases.files.confirmation.create'
const CREATE_FILE_IDENTIFIER_SUCCESS = 'use_cases.file_identifier.confirmation.create'
const REMOVE_FILE_IDENTIFIER_SUCCESS = 'use_cases.file_identifier.confirmation.remove'
const FILE_IDENTIFIER_MISSING = 'use_cases.file_identifier.confirmation.missing'
const FILE_MISSING = 'use_cases.file_identifier.confirmation.missing_file'
const REMOVE_FILE_SUCCESS = 'use_cases.files.confirmation.delete'
const DOWNLOAD_FILE_SUCCESS = 'use_cases.files.confirmation.download'
const CREATE_FILE_IDENTIFIER_VERSION_SUCCESS = 'use_cases.file_identifier_version.confirmation.create'
const REMOVE_FILE_IDENTIFIER_VERSION_SUCCESS = 'use_cases.file_identifier_version.confirmation.remove'
const FILE_IDENTIFIER_VERSION_MISSING = 'use_cases.file_identifier_version.confirmation.missing'

function* storeTrainingDataGenerator({ payload } : ActionPayload<UploadTrainingDataActionPayload>) {
  try {
    yield put(startFetchingTrainingFilesAction(UPLOAD_TRAINING_DATA))

    const uploadToken: string = yield call(generateUploadToken)
    const { data } = payload

    data.append('uploadToken', uploadToken)

    yield call(API.uploadTrainingData, { data: payload.data, meta: payload.meta })

    const {
      useCaseId,
      fileIdentifier,
      version,
      showToast = true,
      fetchList = true,
    } = payload.meta

    if (fetchList) {
      yield put(requestTrainingDataListAction({
        useCaseId,
        fileIdentifier,
        version,
        withAutoRefresh: true,
      }))

      yield take(RECEIVE_TRAINING_DATA_LIST)
    }

    if (showToast) {
      const isLegacyUpload = isLegacyUploadCheck(payload.meta.fileIdentifier)

      yield put(changeToastAction({
        useIntl: true,
        message: isLegacyUpload ? CREATE_FILE_OLD_SUCCESS : CREATE_FILE_SUCCESS,
        severity: TOAST_TYPE_SUCCESS,
      }))
    }

    yield put(stopFetchingTrainingFilesAction(UPLOAD_TRAINING_DATA))
  } catch (e: any) {
    const message = parseAndReportErrorResponse(e, payload)

    if (message !== REQUEST_CANCELED_BY_USER && e?.code !== ERR_CANCELED_CODE) {
      yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
    }
  } finally {
    yield put(stopFetchingTrainingFilesAction(UPLOAD_TRAINING_DATA))
  }
}

function* removeTrainingDataGenerator({ payload }: ActionPayload<RemoveTrainingDataActionPayload>) {
  try {
    yield put(startFetchingTrainingFilesAction(REMOVE_TRAINING_DATA))

    const {
      useCaseId,
      trainingDataFileId,
      version,
      fetchFlatList = false,
      showToast = true,
      fetchList = true,
      fileIdentifier,
    } = payload.meta

    yield call(API.removeTrainingData, {
      useCaseId,
      trainingDataFileId,
    })

    if (fetchList) {
      yield put(requestTrainingDataListAction({
        useCaseId,
        fileIdentifier,
        version,
      }))

      yield take(RECEIVE_TRAINING_DATA_LIST)
    }

    if (fetchFlatList) {
      yield put(requestTrainingDataFlatListAction({ useCaseId }))

      yield take(RECEIVE_TRAINING_DATA_FLAT_LIST)
    }

    if (showToast) {
      yield put(changeToastAction({ useIntl: true, message: REMOVE_FILE_SUCCESS, severity: TOAST_TYPE_SUCCESS }))
    }

    yield put(stopFetchingTrainingFilesAction(REMOVE_TRAINING_DATA))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingTrainingFilesAction(REMOVE_TRAINING_DATA))
  }
}

function* downloadTrainingDataGenerator({ payload }: ActionPayload<DownloadTrainingDataActionPayload>) {
  try {
    yield put(startFetchingTrainingFilesAction(DOWNLOAD_TRAINING_DATA))

    const downloadToken: string = yield call(API.getTrainingDataFilesDownloadToken, payload)

    yield call(downloadByToken, {
      downloadToken,
      fileName: payload.fileName,
    })

    yield put(changeToastAction({ useIntl: true, message: DOWNLOAD_FILE_SUCCESS, severity: TOAST_TYPE_SUCCESS }))

    yield put(stopFetchingTrainingFilesAction(DOWNLOAD_TRAINING_DATA))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingTrainingFilesAction(DOWNLOAD_TRAINING_DATA))
  }
}

function* removeFileIdentifierGenerator({ payload }: ActionPayload<RemoveFileIdentifiersActionPayload>) {
  try {
    yield put(startFetchingTrainingFilesAction(REMOVE_FILE_IDENTIFIER))

    const {
      useCaseId,
      fileIdentifier,
      showToast = true,
      fetchList = true,
      resetModal = true,
      updateConnectVeiw = true,
    } = payload

    yield call(API.removeFileIdentifier, {
      useCaseId,
      fileIdentifier,
    })

    if (fetchList) {
      yield put(requestFileIdentifiersListAction({ useCaseId }))

      yield take(RECEIVE_FILE_IDENTIFIERS_LIST)
    }

    if (showToast) {
      yield put(changeToastAction({ useIntl: true, message: REMOVE_FILE_IDENTIFIER_SUCCESS, severity: TOAST_TYPE_SUCCESS }))
    }

    if (updateConnectVeiw) {
      yield put(prepareConnectOverviewAction({ useCaseId, recalculation: true }))

      yield take(PREPARE_CONNECT_OVERVIEW_DONE)
    }

    if (resetModal) {
      yield put(push(generatePath(CONNECT_PATH, { usecase: useCaseId })))

      yield put(setPrimaryModalPageName(USE_CASE_DATA_TYPES_MODAL_NAME))
    }

    yield put(stopFetchingTrainingFilesAction(REMOVE_FILE_IDENTIFIER))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingTrainingFilesAction(REMOVE_FILE_IDENTIFIER))
  }
}

function* createFileIdentifierGenerator({ payload }: ActionPayload<CreateFileIdentifiersActionPayload>) {
  try {
    yield put(startFetchingTrainingFilesAction(CREATE_FILE_IDENTIFIER))

    const {
      useCaseId,
      fileIdentifier,
      showToast = true,
      fetchList = true,
      updateConnectVeiw = true,
    } = payload

    yield call(API.createFileIdentifier, {
      useCaseId,
      fileIdentifier,
    })

    if (fetchList) {
      yield put(requestFileIdentifiersListAction({ useCaseId }))

      yield take(RECEIVE_FILE_IDENTIFIERS_LIST)
    }

    if (updateConnectVeiw) {
      yield put(prepareConnectOverviewAction({ useCaseId, recalculation: true }))

      yield take(PREPARE_CONNECT_OVERVIEW_DONE)
    }

    if (showToast) {
      yield put(changeToastAction({ useIntl: true, message: CREATE_FILE_IDENTIFIER_SUCCESS, severity: TOAST_TYPE_SUCCESS }))
    }

    yield put(stopFetchingTrainingFilesAction(CREATE_FILE_IDENTIFIER))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingTrainingFilesAction(CREATE_FILE_IDENTIFIER))
  }
}

function* createFileIdentifierVersionGenerator({ payload }: ActionPayload<CreateFileIdentifierVersionActionPayload>) {
  try {
    yield put(startFetchingTrainingFilesAction(CREATE_FILE_IDENTIFIER_VERSION))

    const {
      useCaseId,
      fileIdentifier,
    } = payload

    yield call(API.createVersion, {
      useCaseId,
      fileIdentifier,
    })

    yield put(requestFileIdentifiersListAction({ useCaseId }))

    yield take(RECEIVE_FILE_IDENTIFIERS_LIST)

    yield put(changeToastAction({ useIntl: true, message: CREATE_FILE_IDENTIFIER_VERSION_SUCCESS, severity: TOAST_TYPE_SUCCESS }))

    yield put(stopFetchingTrainingFilesAction(CREATE_FILE_IDENTIFIER_VERSION))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingTrainingFilesAction(CREATE_FILE_IDENTIFIER_VERSION))
  }
}

function* removeFileIdentifierVersionGenerator({ payload }: ActionPayload<RemoveFileIdentifierVersionActionPayload>) {
  try {
    yield put(startFetchingTrainingFilesAction(REMOVE_FILE_IDENTIFIER_VERSION))

    const {
      useCaseId,
      fileIdentifier,
      version,
    } = payload

    yield call(API.removeVersion, {
      useCaseId,
      fileIdentifier,
      version,
    })

    yield put(requestFileIdentifiersListAction({ useCaseId }))

    yield take(RECEIVE_FILE_IDENTIFIERS_LIST)

    const state: State = yield select()
    const latestFileIdentifiersList: TrainingFiles.FileIdentifierOption[] = yield call(getUseCaseFileIdentifiers, state)
    const fileIdentifierDetails = latestFileIdentifiersList.find((item) => item.value === fileIdentifier)

    if (fileIdentifierDetails) {
      const fileIdentifierVersions = fileIdentifierDetails.versions || []
      const latestVersion = Math.max(...fileIdentifierVersions.map((v) => v.versionNumber), 0)

      if (latestVersion) {
        yield put(push(generatePath(DATA_UPLOAD_PATH, { usecase: useCaseId, identifier: fileIdentifier, version: latestVersion })))
      }
    } else {
      yield put(push(generatePath(CONNECT_PATH, { usecase: useCaseId })))
    }

    yield put(changeToastAction({ useIntl: true, message: REMOVE_FILE_IDENTIFIER_VERSION_SUCCESS, severity: TOAST_TYPE_SUCCESS }))

    yield put(stopFetchingTrainingFilesAction(REMOVE_FILE_IDENTIFIER_VERSION))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingTrainingFilesAction(REMOVE_FILE_IDENTIFIER_VERSION))
  }
}

function* fetchTrainingDataGenerator({ payload } : ActionPayload<RequestTrainingDataListActionPayload>) {
  try {
    if (!payload.silent) {
      yield put(startFetchingTrainingFilesAction(REQUEST_TRAINING_DATA_LIST))
    }

    let fetchingAllowed = true

    const {
      fileIdentifier,
      version,
      checkIdentifier,
      checkVersion,
      withAutoRefresh,
      useCaseId,
    } = payload

    if (checkIdentifier && fileIdentifier) {
      yield put(requestFileIdentifiersListAction({ useCaseId }))

      yield take(RECEIVE_FILE_IDENTIFIERS_LIST)

      const latestState: State = yield select()
      const latestFileIdentifiersList: TrainingFiles.FileIdentifierOption[] = yield call(getUseCaseFileIdentifiers, latestState)
      const fileIdentifierDetails = latestFileIdentifiersList.find((item) => item.value === fileIdentifier)

      if (!fileIdentifierDetails) {
        yield put(changeToastAction({ useIntl: true, message: FILE_IDENTIFIER_MISSING, severity: TOAST_TYPE_ERROR }))

        yield put(push(generatePath(CONNECT_PATH, { usecase: useCaseId })))

        fetchingAllowed = false
      }

      if (checkVersion && fileIdentifierDetails) {
        const versionsList = fileIdentifierDetails.versions
        const isVersionExist = (versionsList.filter((item) => String(item.versionNumber) === String(version)) || []).length > 0
        const hasAnyVersions = versionsList.length > 0

        if (!isVersionExist) {
          yield put(changeToastAction({ useIntl: true, message: FILE_IDENTIFIER_VERSION_MISSING, severity: TOAST_TYPE_ERROR }))

          if (!hasAnyVersions) {
            yield put(push(generatePath(CONNECT_PATH, { usecase: useCaseId })))

            fetchingAllowed = false
          } else {
            yield put(push(generatePath(DATA_UPLOAD_PATH, { usecase: useCaseId, identifier: fileIdentifier, version: fileIdentifierDetails.latestVersion })))
          }
        }
      }
    }

    if (fetchingAllowed && !withAutoRefresh) {
      const filesList: TrainingFiles.TrainingFileItem[] = yield call(API.listTrainingData, {
        useCaseId,
        fileIdentifier,
        version,
      })

      yield put(receiveTrainingDataList(filesList))
    } else if (fetchingAllowed && withAutoRefresh) {
      yield put(trainingDataAutoRefreshStartAction({
        useCaseId,
        fileIdentifier,
        version,
        silent: true,
      }))

      yield take(RECEIVE_TRAINING_DATA_LIST)
    }

    yield put(stopFetchingTrainingFilesAction(REQUEST_TRAINING_DATA_LIST))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingTrainingFilesAction(REQUEST_TRAINING_DATA_LIST))
  }
}

function* fetchFileIdentifiersGenerator({ payload } : ActionPayload<RequestFileIdentifiersListActionPayload>) {
  try {
    yield put(startFetchingTrainingFilesAction(REQUEST_FILE_IDENTIFIERS_LIST))

    const fileIdentifiersList: TrainingFiles.FileIdentifierItem[] = yield call(API.listFileIdentifiersData, { useCaseId: payload.useCaseId })

    yield put(receiveFileIdentifiersList(fileIdentifiersList))

    yield put(stopFetchingTrainingFilesAction(REQUEST_FILE_IDENTIFIERS_LIST))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)
    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingTrainingFilesAction(REQUEST_FILE_IDENTIFIERS_LIST))
  }
}

function* fetchTrainingDataFlatListGenerator({ payload } : ActionPayload<ListTrainingDataFlatListPayload>) {
  try {
    yield put(startFetchingTrainingFilesAction(REQUEST_TRAINING_DATA_FLAT_LIST))

    const {
      useCaseId,
    } = payload

    yield put(fetchUseCaseAction({ useCaseId }))

    yield take(RECEIVE_USE_CASE)

    const fileIdentifiersList: TrainingFiles.FileIdentifierItem[] = yield call(API.listFileIdentifiersData, { useCaseId })
    const filerIdentifierToLatestVersionMap: {
      [key: string]: number,
    } = {}

    fileIdentifiersList.forEach((item) => {
      const fileIdentifierVersions = (item.versions || [])
      const latestVersion = Math.max(...fileIdentifierVersions.map((v) => v.versionNumber), 0)

      filerIdentifierToLatestVersionMap[item.fileIdentifier] = latestVersion
    }, {})

    const filesList: TrainingFiles.TrainingFileItem[] = yield call(API.listTrainingData, { useCaseId })
    const generalFilesList: TrainingFiles.TrainingFileItem[] = yield call(API.listTrainingData, { useCaseId, fileIdentifier: LEGACY_FILES_IDENTIFIER })

    const filesListProcessed = filesList.map((file) => {
      const {
        fileIdentifier = '',
        version = 0,
      } = file

      const versionFromMap = fileIdentifier ? filerIdentifierToLatestVersionMap[fileIdentifier] : 0

      return {
        ...file,
        isLatestVersion: versionFromMap === version,
      }
    })

    const generalFilesListProcessed = generalFilesList.map((file) => {
      return {
        ...file,
        fileIdentifier: LEGACY_FILES_IDENTIFIER,
        isLatestVersion: false,
      }
    })

    const finalList = [
      ...generalFilesListProcessed,
      ...filesListProcessed,
    ]

    yield put(receiveTrainingDataFlatList(finalList))

    yield put(stopFetchingTrainingFilesAction(REQUEST_TRAINING_DATA_FLAT_LIST))
  } catch (e) {
    const message = parseAndReportErrorResponse(e, payload)

    yield put(changeToastAction({ message, severity: TOAST_TYPE_ERROR }))
  } finally {
    yield put(stopFetchingTrainingFilesAction(REQUEST_TRAINING_DATA_FLAT_LIST))
  }
}

function* trainingDataAutoRefreshStart({ payload } : ActionPayload<RequestTrainingDataListActionPayload>) {
  while (true) {
    const {
      useCaseId,
      fileIdentifier,
      version,
      silent,
    } = payload

    yield put(requestTrainingDataListAction({
      useCaseId,
      fileIdentifier,
      version,
      silent,
    }))

    yield take(RECEIVE_TRAINING_DATA_LIST)

    const state: State = yield select()
    const latestFileIdentifiersList: TrainingFiles.FileIdentifierOption[] = yield call(getUseCaseFileIdentifiers, state)

    const trainingFileItems: TrainingFiles.TrainingFileItem[] = yield call(getUseCaseTrainingData, state)
    const shouldStopAutoRefresh = (trainingFileItems.filter((item) => [PROCESSING_STATES.RUNNING, PROCESSING_STATES.QUEUED].includes(item.processingStatus)) || []).length === 0
    const path = matchPath<Common.RouterMatch>(document.location.pathname, {
      path: DATA_FILE_LINK_PATH,
      exact: true,
      strict: true,
    })

    if (path && path.isExact && path.params && path.params.file) {
      const fileItem = trainingFileItems.find((item) => item.trainingDataFileId === path.params.file)

      if (!fileItem) {
        yield put(changeToastAction({ useIntl: true, message: FILE_MISSING, severity: TOAST_TYPE_ERROR }))

        const fileIdentifierDetails = latestFileIdentifiersList.find((item) => item.value === fileIdentifier)

        if (fileIdentifierDetails) {
          yield put(push(generatePath(DATA_UPLOAD_PATH, {
            usecase: useCaseId, identifier: fileIdentifier!, version: fileIdentifierDetails.latestVersion!,
          })))
        } else {
          yield put(push(generatePath(DATA_UPLOAD_PATH, {
            usecase: useCaseId, identifier: fileIdentifier!, version: version!,
          })))
        }
      }
    }

    if (shouldStopAutoRefresh) {
      yield put(trainingDataAutoRefreshStopAction({
        useCaseId, fileIdentifier, version,
      }))
    }

    yield delay(AUTO_REFRESH_INTERVAL_IN_MS)
  }
}

function* trainingDataAutoRefreshStop(task: Task<any>) {
  yield cancel(task)
}

export function* watchTrainingDataAutoRefreshStart(): any {
  while (true) {
    const action: any = yield take(TRAINING_DATA_AUTO_REFRESH_START)
    const workerTask: Task<any> = yield fork(trainingDataAutoRefreshStart, action)

    yield takeLatest(TRAINING_DATA_AUTO_REFRESH_STOP, trainingDataAutoRefreshStop, workerTask)
  }
}

export function* watchFetchTrainingDataFlatList() {
  yield takeEvery(REQUEST_TRAINING_DATA_FLAT_LIST, fetchTrainingDataFlatListGenerator)
}

export function* watchFetchTrainingData() {
  yield takeEvery(REQUEST_TRAINING_DATA_LIST, fetchTrainingDataGenerator)
}

export function* watchFetchFileIdentifiers() {
  yield takeEvery(REQUEST_FILE_IDENTIFIERS_LIST, fetchFileIdentifiersGenerator)
}

export function* watchStoreTrainingData() {
  yield takeEvery(UPLOAD_TRAINING_DATA, storeTrainingDataGenerator)
}

export function* watchRemoveFileIdentifier() {
  yield takeEvery(REMOVE_FILE_IDENTIFIER, removeFileIdentifierGenerator)
}

export function* watchCreateFileIdentifier() {
  yield takeEvery(CREATE_FILE_IDENTIFIER, createFileIdentifierGenerator)
}

export function* watchRemoveTrainingFileData() {
  yield takeEvery(REMOVE_TRAINING_DATA, removeTrainingDataGenerator)
}

export function* watchDownloadTrainingFileData() {
  yield takeEvery(DOWNLOAD_TRAINING_DATA, downloadTrainingDataGenerator)
}

export function* watchCreateFileIdentifierVersion() {
  yield takeEvery(CREATE_FILE_IDENTIFIER_VERSION, createFileIdentifierVersionGenerator)
}

export function* watchRemoveFileIdentifierVersion() {
  yield takeEvery(REMOVE_FILE_IDENTIFIER_VERSION, removeFileIdentifierVersionGenerator)
}
