import React from 'react'
import parser from 'cron-parser'
import cronstrue from 'cronstrue'

import { useIntl } from 'react-intl'
import { Position } from '@xyflow/react'
import { Link, generatePath, useRouteMatch } from 'react-router-dom'
import { Box, Typography, useTheme } from '@mui/material'
import { USE_CASE_PIPELINE_OVERVIEW_PATH } from '@constants/routes.constants'
import { formatDateTime } from '@utils/moment.utils'

import ReactFlowHandleComponent from '@components/connect-view/flow/ReactFlowHandle'
import DataGridChipComponent from '@base/datagrid/data-grid-cells/data-grid-chip/DataGridChip.component'
import DataGridTooltipCellComponent from '@base/datagrid/data-grid-cells/data-grid-tooltip-cell/DataGridTooltipCell.component'
import { PIPELINE_TYPES, getPipelineTypeBgColor } from '@constants/pipelines.constants'

export interface ReactFlowPipelineNodeComponentProps {
  /**
   * Node's data
   */
  data: Pipelines.PipelineGraphNodeData
}

export const guessPipelineTypeByName = (name?: string) => {
  if (!name) {
    return 'N/A'
  }

  if (name.startsWith('fcst')) {
    return PIPELINE_TYPES.DEMAND_PIPELINE
  }

  if (name.startsWith('postprocessing')) {
    return PIPELINE_TYPES.POSTPROCESSING_PIPELINE
  }

  if (name.startsWith('elt')) {
    return PIPELINE_TYPES.ELT_PIPELINE
  }

  if (name.startsWith('opt')) {
    return PIPELINE_TYPES.OPTIMIZATION_PIPELINE
  }

  return 'N/A'
}

const ReactFlowPipelineNodeComponent: React.FC<ReactFlowPipelineNodeComponentProps> = ({ data }) => {
  const intl = useIntl()
  const theme = useTheme()
  const { params: { pipeline } } = useRouteMatch<Common.PipelinesRouterMatch>()

  const {
    pipelineDetails,
    composite,
    last,
    step,
  } = data

  const {
    executionSchedule,
    name,
    useCaseId,
    pipelineId,
  } = (pipelineDetails || {})

  const executionScheduleCronInterval = executionSchedule ? parser.parseExpression(executionSchedule) : null
  const executionScheduleDate = executionSchedule ? cronstrue.toString(executionSchedule) : null
  const nextExecutionDate = executionScheduleCronInterval ? executionScheduleCronInterval.next().toDate() : null
  const isSelected = (pipeline === pipelineId)
  const type = composite ? PIPELINE_TYPES.COMPOSITE_PIPELINE : guessPipelineTypeByName(name)
  const path = useCaseId && pipelineId ? generatePath(USE_CASE_PIPELINE_OVERVIEW_PATH, {
    usecase: useCaseId,
    pipeline: pipelineId,
  }) : '/'

  return (
    <Box
      data-testid={ReactFlowPipelineNodeComponent.name}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '250px',
        height: '260px',
        background: theme.palette.new.white,
        border: '1px solid',
        borderColor: isSelected ? theme.palette.new.versatile_violet : theme.palette.new.grey_a,
        borderRadius: theme.spacing(0.5),
        position: 'relative',
        cursor: 'default',
      }}
    >

      {
        composite ? (
          null
        ) : (
          <ReactFlowHandleComponent
            type='target'
            position={Position.Left}
          />
        )
      }

      {
        step !== null ? (
          <Typography
            fontSize={16}
            fontWeight={500}
            sx={{
              position: 'absolute',
              right: '20px',
              top: '19px',
              color: isSelected ? theme.palette.new.versatile_violet : theme.palette.new.grey_a,
            }}
          >
            {step}
          </Typography>
        ) : (
          null
        )
      }

      <Box
        sx={{
          padding: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          height: '100%',
        }}
        gap={1}
      >
        <Box
          display='flex'
          flexDirection='column'
          maxWidth={200}
        >
          <Typography
            fontWeight={500}
          >
            {intl.formatMessage({ id: 'pipelines.table.name' })}
          </Typography>
          <Typography
            component={Link}
            to={path}
            color={theme.palette.new.versatile_violet}
            noWrap={true}
            title={name}
          >
            {name}
          </Typography>
        </Box>

        <Box
          display='flex'
          flexDirection='column'
        >
          <Typography
            fontWeight={500}
          >
            {intl.formatMessage({ id: 'pipelines.table.type' })}
          </Typography>

          <DataGridChipComponent
            value={type}
            getBgColorByValue={getPipelineTypeBgColor}
            uppercase={true}
          />
        </Box>

        {
          executionSchedule ? (
            <>
              <Box
                display='flex'
                flexDirection='column'
              >
                <Typography
                  fontWeight={500}
                >
                  {intl.formatMessage({ id: 'pipelines.table.executionSchedule' })}
                </Typography>
                <DataGridChipComponent
                  value={executionSchedule}
                  tooltip={intl.formatMessage({ id: 'pipelines.table.cronText' }, { cron: executionScheduleDate })}
                />
              </Box>
              <Box
                display='flex'
                flexDirection='column'
              >
                <Typography
                  fontWeight={500}
                >
                  {intl.formatMessage({ id: 'pipelines.table.nextExecutionDate' })}
                </Typography>
                <DataGridTooltipCellComponent
                  label={formatDateTime(intl, nextExecutionDate?.toString() || '', true)}
                  tooltip={intl.formatMessage({ id: 'pipelines.table.cronTimezone' })}
                />
              </Box>
            </>
          ) : (
            null
          )
        }
      </Box>

      {
        last ? (
          null
        ) : (
          <ReactFlowHandleComponent
            type='source'
            position={Position.Right}
          />
        )
      }
    </Box>
  )
}

export default ReactFlowPipelineNodeComponent
