import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

type allowedClasses = 'connectText' | 'listItem' | 'itemName'

const useStyles = makeStyles<void, allowedClasses>()((theme: Theme, props, classes) => ({
  connectContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    height: theme.spacing(8),
    margin: theme.spacing(3, 3, 4, 3),
    border: `1px solid ${theme.palette.new.grey_a}`,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(0, 2),
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${theme.palette.new.grey_c}`,
      backgroundColor: theme.palette.new.grey,
    },
    [theme.breakpoints.down('xl')]: {
      height: theme.spacing(6),

      [`& .${classes.connectText}`]: {
        fontSize: '18px',
      },
    },
  },
  connectTextContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  connectText: {
    fontSize: '22px',
    color: theme.palette.new.black,
    marginLeft: theme.spacing(2),
  },
  connectArrow: {
    display: 'flex',
    transform: 'rotate(-90deg)',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(0),
    width: '100%',
    [`& .${classes.listItem}:last-child`]: {
      borderBottom: `1px solid ${theme.palette.new.grey_a}`,
    },
  },
  withTemplates: {
    marginBottom: theme.spacing(3),
  },
  listItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: theme.spacing(6),
    padding: theme.spacing(0, 3),
    borderTop: `1px solid ${theme.palette.new.grey_a}`,
    '&:hover': {
      backgroundColor: theme.palette.new.grey_d,
    },
    '&:first-of-type': {
      borderTop: 'none',
      cursor: 'default',
      [`& .${classes.itemName}`]: {
        color: theme.palette.new.grey_c,
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  listItemConnected: {
    cursor: 'pointer',
  },
  emptyList: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderTop: `1px solid ${theme.palette.new.grey_a}`,
    borderBottom: `1px solid ${theme.palette.new.grey_a}`,
    color: theme.palette.new.grey_c,
    width: '100%',
    height: theme.spacing(6),
    padding: theme.spacing(0, 3),
  },
  statusField: {
    fontSize: '18px',
    color: theme.palette.new.grey_c,
    minWidth: '90px',
    maxWidth: '10%',
  },
  itemStatus: {
    minWidth: '90px',
  },
  itemStatusCircleActive: {
    border: `1px solid ${theme.palette.new.generous_green}`,
    backgroundColor: theme.palette.new.generous_green,
  },
  itemName: {
    fontSize: '18px',
    color: theme.palette.new.grey_c,
    width: '100%',
  },
  itemTextActive: {
    color: theme.palette.new.black,
  },
  activeMarkAsteriks: {
    fontSize: '18px',
    color: theme.palette.new.violet,
  },
  itemDate: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',
    color: theme.palette.new.grey_c,
    minWidth: theme.spacing(15),
    justifyContent: 'flex-end',
  },
  itemButton: {
    display: 'flex',
    cursor: 'pointer',
    marginLeft: theme.spacing(3),
    padding: theme.spacing(0),
    '& svg': {
      '&:hover': {
        opacity: 0.6,
      },
    },
  },
  itemDateHeaderMargin: {
    marginRight: theme.spacing(4.5),
  },
}))

export default useStyles
