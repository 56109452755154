import React from 'react'
import cn from 'classnames'
import { Box } from '@mui/material'

import {
  DataGridPremium,
  DataGridPremiumProps,
  GridSlotsComponentsProps,
  useGridApiRef,
  GridRowClassNameParams,
  useKeepGroupedColumnsHidden,
  GridRowGroupingModel,
} from '@mui/x-data-grid-premium'

import { DATA_GRID_CUSTOM_CLASS_NAMES, DEFAULT_DEBOUNCE_INTERVAL } from '@constants/data-grid.constants'

import { DataGridCheckboxBaseComponentProps } from '@base/datagrid/data-grid-checkbox-base/DataGridCheckboxBase.component'
import { DataGridCustomFooterComponentProps } from '@base/datagrid/data-grid-custom-footer/DataGridCustomFooter.component'

import { useDataGridOnChangeTracking } from './hooks/useDataGridOnChangeTracking.hook'
import { useDataGridSlots } from './hooks/useDataGridSlots.hook'
import { useDataGridSlotProps } from './hooks/useDataGridSlotProps.hook'
import { useDataGridAggregationFunctions } from './hooks/useDataGridAggregationFunctions.hook'
import { useDataGridLocales } from './hooks/useDataGridLocales.hook'
import { useDataGridOnClipboardCopy } from './hooks/useDataGridOnClipboardCopy.hook'
import { useDataGridReversPinningOrder } from './hooks/useDataGridReversPinningOrder.hook'
import { useDataGridPersistance } from './hooks/useDataGridPersistance.hook'
import { useDataGridAutosize } from './hooks/useDataGridAutosize.hook'
import { useDataGridTransformedColumns } from './hooks/useDataGridTransformedColumns.hook'

export interface DataGridComponentProps extends DataGridPremiumProps {
  id: string
  name: string
  height?: string
  disabled?: boolean
  readonly?: boolean
  uploading?: boolean
  enablePersistence?: boolean
  enableGroupExpand?: boolean
  reversedPinningOrder?: boolean,
  DataGridHeaderCheckboxComponent?: React.FC<any>
  rounded?: boolean
  groupingMode?: 'server' | 'client'
  aggregationMode?: 'server' | 'client'
  rowSelectionMode?: 'server' | 'client'
  groupingModel?: GridRowGroupingModel
  slotProps?: GridSlotsComponentsProps & {
    toolbar?: GridSlotsComponentsProps['toolbar'] & {
      disableColumnVisibilityPanelWhenGrouping?: boolean
      withExport?: boolean
      groupingMode?: 'server' | 'client'
      groupingModel?: GridRowGroupingModel
      onRowGroupingModelChange?: DataGridPremiumProps['onRowGroupingModelChange']
      customToolbarChildren?: React.ReactNode
    },
    noRowsOverlay?: GridSlotsComponentsProps['noRowsOverlay'] & {
      labels: string[]
    },
    loadingOverlay?: GridSlotsComponentsProps['loadingOverlay'] & {
      actionsMenuWidth?: number
      uploading?: boolean
    },
    columnMenu?: GridSlotsComponentsProps['columnMenu'] & {
      disableGrouping?: boolean
      disableColumnVisibility?: boolean
    },
    pagination?: GridSlotsComponentsProps['pagination'] & {
      disabledPagination?: boolean
    }
    baseCheckbox?: GridSlotsComponentsProps['baseCheckbox'] & DataGridCheckboxBaseComponentProps
    footer?: GridSlotsComponentsProps['footer'] & DataGridCustomFooterComponentProps
  },
  customLocales?: Record<string, string | Function>
}

export const DataGridComponent: React.FC<DataGridComponentProps> = ({
  id,
  name,
  apiRef,
  rows,
  columns,
  initialState,
  loading,
  disableVirtualization,
  rounded = true,
  disabled = false,
  readonly = false,
  pagination = true,
  enablePersistence = true,
  enableGroupExpand = true,
  reversedPinningOrder = true,
  uploading = false,
  height = '555px',
  slotProps,
  slots,
  autoHeight,
  aggregationFunctions,
  groupingColDef,
  groupingMode = 'client',
  aggregationMode = 'client',
  sortingMode = 'client',
  paginationMode = 'client',
  filterMode = 'client',
  rowSelectionMode = 'client',
  groupingModel,
  disableAutosize,
  autosizeOnMount,
  onPinnedColumnsChange: onPinnedColumnsChangeOriginal,
  onRowGroupingModelChange: onRowGroupingModelChangeOriginal,
  onPaginationModelChange: onPaginationModelChangeOriginal,
  onSortModelChange: onSortModelChangeOriginal,
  onFilterModelChange: onFilterModelChangeOriginal,
  onAggregationModelChange: onAggregationModelChangeOriginal,
  onColumnVisibilityModelChange: onColumnVisibilityModelChangeOriginal,
  onRowSelectionModelChange: onRowSelectionModelChangeOriginal,
  onDetailPanelExpandedRowIdsChange: onDetailPanelExpandedRowIdsChangeOriginal,
  checkboxSelection,
  disableMultipleRowSelection,
  DataGridHeaderCheckboxComponent,
  customLocales,
  sortingOrder = ['asc', 'desc'],
  ...props
}) => {
  const _apiRef = useGridApiRef()
  const finalApiRef = apiRef || _apiRef
  const empty = !loading && !rows.length
  const isLoading = loading || uploading
  const onClipboardCopy = useDataGridOnClipboardCopy()
  const getRowClassName = (params: GridRowClassNameParams) => {
    return params.indexRelativeToCurrentPage % 2 === 0 ? DATA_GRID_CUSTOM_CLASS_NAMES.EVEN : DATA_GRID_CUSTOM_CLASS_NAMES.ODD
  }

  const finalColumns = useDataGridTransformedColumns({
    columns,
    useCheckboxSelection: checkboxSelection!,
    rowSelectionMode,
    disableMultipleRowSelection,
    DataGridHeaderCheckboxComponent,
  })

  const {
    onPinnedColumnsChange: onPinnedColumnsChangeWithTracking,
    onRowGroupingModelChange: onRowGroupingModelChangeWithTracking,
    onPaginationModelChange: onPaginationModelChangeWithTracking,
    onSortModelChange: onSortModelChangeWithTracking,
    onFilterModelChange: onFilterModelChangeWithTracking,
    onAggregationModelChange: onAggregationModelChangeWithTracking,
    onColumnVisibilityModelChange: onColumnVisibilityModelChangeWithTracking,
    onRowSelectionModelChange: onRowSelectionModelChangeWithTracking,
    onClipboardCopy: onClipboardCopyWithTracking,
    onDetailPanelExpandedRowIdsChange: onDetailPanelExpandedRowIdsChangeWithTracking,
  } = useDataGridOnChangeTracking({
    name,
    onClipboardCopy,
    onPinnedColumnsChange: onPinnedColumnsChangeOriginal,
    onRowGroupingModelChange: onRowGroupingModelChangeOriginal,
    onPaginationModelChange: onPaginationModelChangeOriginal,
    onSortModelChange: onSortModelChangeOriginal,
    onFilterModelChange: onFilterModelChangeOriginal,
    onAggregationModelChange: onAggregationModelChangeOriginal,
    onColumnVisibilityModelChange: onColumnVisibilityModelChangeOriginal,
    onRowSelectionModelChange: onRowSelectionModelChangeOriginal,
    onDetailPanelExpandedRowIdsChange: onDetailPanelExpandedRowIdsChangeOriginal,
  })

  const finalSlots = useDataGridSlots({ slots })

  const localeText = useDataGridLocales({
    customLocales,
  })

  const finalSlotProps = useDataGridSlotProps({
    slotProps,
    disabled,
    groupingModel,
    enableGroupExpand,
    columns: finalColumns,
    groupingMode,
    onRowGroupingModelChange: onRowGroupingModelChangeWithTracking,
    uploading,
  })

  const finalAggregationFunctions = useDataGridAggregationFunctions({
    aggregationFunctions,
  })

  useDataGridPersistance({
    id,
    apiRef: finalApiRef,
    enablePersistence,
    groupingMode,
    rowSelectionMode,
    aggregationMode,
  })

  useDataGridAutosize({
    apiRef: finalApiRef,
    autosizeOnMount,
    loading,
    disableVirtualization,
    disableAutosize,
    uploading,
  })

  const finalState = useKeepGroupedColumnsHidden({
    apiRef: finalApiRef,
    initialState,
  })

  const {
    pinnedColumns,
    onPinnedColumnsChange: onPinnedColumnsChangeWithTrackingAndReversPinningOrder,
  } = useDataGridReversPinningOrder({
    apiRef: finalApiRef,
    initialState: finalState.pinnedColumns,
    onPinnedColumnsChange: onPinnedColumnsChangeWithTracking,
    reversedPinningOrder,
  })

  const classNames = cn({
    rounded,
    disabled,
    loading: isLoading,
    readonly,
    empty,
    singleSelection: disableMultipleRowSelection && checkboxSelection,
    'non-expandable-group': !enableGroupExpand,
  })

  return (
    <Box
      data-testid={DataGridComponent.name}
      sx={{
        width: '100%',
        height: autoHeight ? '100%' : height,
      }}
    >
      <DataGridPremium
        rows={rows}
        columns={finalColumns}
        apiRef={finalApiRef}
        rowHeight={38}
        loading={isLoading}
        disableVirtualization={disableVirtualization}
        columnHeaderHeight={58}
        disableAutosize={disableAutosize}
        autosizeOnMount={autosizeOnMount}
        pagination={pagination}
        autoHeight={autoHeight}
        getRowClassName={getRowClassName}
        className={classNames}
        slots={finalSlots}
        slotProps={finalSlotProps}
        columnBuffer={columns.length}
        initialState={finalState}
        localeText={localeText}
        aggregationFunctions={finalAggregationFunctions}
        sortingOrder={sortingOrder}
        pinnedColumns={pinnedColumns}
        filterDebounceMs={DEFAULT_DEBOUNCE_INTERVAL}
        sortingMode={sortingMode}
        paginationMode={paginationMode}
        filterMode={filterMode}
        checkboxSelection={checkboxSelection}
        disableMultipleRowSelection={disableMultipleRowSelection}
        onClipboardCopy={onClipboardCopyWithTracking}
        onRowGroupingModelChange={onRowGroupingModelChangeWithTracking}
        onPinnedColumnsChange={onPinnedColumnsChangeWithTrackingAndReversPinningOrder}
        onPaginationModelChange={onPaginationModelChangeWithTracking}
        onSortModelChange={onSortModelChangeWithTracking}
        onFilterModelChange={onFilterModelChangeWithTracking}
        onAggregationModelChange={onAggregationModelChangeWithTracking}
        onRowSelectionModelChange={onRowSelectionModelChangeWithTracking}
        onColumnVisibilityModelChange={onColumnVisibilityModelChangeWithTracking}
        onDetailPanelExpandedRowIdsChange={onDetailPanelExpandedRowIdsChangeWithTracking}
        {...props}
      />
    </Box>
  )
}

export default DataGridComponent
